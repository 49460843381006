import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useForm } from "react-hook-form";
import { uniqueId } from "lodash";
import filesize from 'filesize';

import api from '../../services/api';

import { MdHighlightOff, MdErrorOutline } from 'react-icons/md';

import { useToast } from '../../hooks/ToastContext';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';
import FormatarValor from '../../components/FormatarValor';

// Nota Fiscal
import UploadNotaFiscal from '../../components/Upload/notaFiscal';
import InfoNotaFiscal from '../../components/UploadInfo/notaFiscal';

// Boleto
import UploadBoleto from '../../components/Upload/boleto';
import InfoBoleto from '../../components/UploadInfo/boleto';

import {
  Container,
  Page,
  Content,
  Saldo,
  TableContent,
  Infos,
  FirstBox,
  SecondBox,
  ModalSection,
  ModalContent,
  ModalAccountData,
  TableContentMobile,
  Header,
  Line,
  MobileBody,
  NavegaçãoAbas,
  BotãoAba,
  Line1,
  Line2,
  FirstStep,
  SecondStep,
  ThirdStep,
  BoxInfo,
  InputArea,
  UploadContainer,
  InputContainer,
  Error,
} from './styles';

function ContaDigitalEC() {
  const { addToast } = useToast();

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');
  const loggedUser = JSON.parse(localStorage.getItem('@DeixaNoAzul:loggedUser'));
  const loggedEstabilishment = JSON.parse(localStorage.getItem('@DeixaNoAzul:estabelecimentoId'));

  const [contaDigital, setContaDigital] = useState({
    saldo_conta: "",
    total_comissao_mes: "",
    total_comissao_ano: ""
  });
  const [comissoes, setComissoes] = useState([]);
  const [dataBank, setDataBank] = useState([]);
  const [formasPagamento, setFormasPagamento] = useState('');

  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    if (loggedEstabilishment.licenciado_autorizado_id !== 'fec2f2a1-f662-4d51-a9f6-01e33172e934') {

      // api.get('/conta_digital_ec', auth).then(response => {
      api.get('/contaDigital', auth).then(response => {
        const result = response.data;
        // console.log('resultContaDigital', result)
  
        setContaDigital({
          saldo_conta: result.saldo_conta.toString(),
          total_comissao_mes: result.total_comissao_mes.toString(),
          total_comissao_ano: result.total_comissao_ano.toString(),
        });
  
        setComissoes(result.comissoes);
      });
  
      api.get(`/conta/${loggedEstabilishment.dados_bancarios_id}`, auth).then(response => {
        const result = response.data.data;
        // console.log(result)
  
        setDataBank(result);
  
        if (result.chave_pix === null && result.nome_titular !== null) {
          setFormasPagamento(
            <option value="TRANSF">Transferência</option>
          )
        };
  
        if (result.chave_pix !== null && result.nome_titular === null) {
          setFormasPagamento(
            <option value="PIX">PIX</option>
          )
        };
  
        if (result.chave_pix !== null && result.nome_titular !== null) {
          setFormasPagamento(
            <>
              <option value="PIX">PIX</option>
              <option value="TRANSF">Transferência</option>
            </>
          )
        };
      });

    };
    
  }, [userToken, loggedUser.role, loggedEstabilishment.dados_bancarios_id, loggedEstabilishment.licenciado_autorizado_id]);

  /**
   * TABELA DOS PAGAMENTOS (DESKTOP)
   */

  // Informações Head da tabela
  const headData = [
    { title: 'Data', field: 'data', sorting: false },
    // { title: 'Link', field: 'link', sorting: false },
    { title: 'Parcelas', field: 'parcelas', sorting: false },
    { title: 'Valor da transação', field: 'valor', sorting: false },
    { title: '% da comissão', field: 'porcentagem', sorting: false },
    { title: 'TS', field: 'ts', sorting: false },
    { title: 'Comissão', field: 'comissao', sorting: false },
    { title: 'Status', field: 'status', align: 'center', sorting: false },
  ];

  // Informações Body da tabela
  const bodyData = comissoes === [] || comissoes === "Nenhuma comissao encontrada" ? []
    : comissoes.map(comissao => {
    let colorStatus = '';
    if(comissao.status === "NAO_PAGO") {colorStatus = "#F35757"} // Não pago
    if(comissao.status === "PAGO") {colorStatus = "#5CB85C"} // Pago
    if(comissao.status === "PROCESSANDO") {colorStatus = "#5097FF"} // Pago

    let messageStatus = '';
    if(comissao.status === "NAO_PAGO") {messageStatus = "Não paga"} // Aprovado
    if(comissao.status === "PAGO") {messageStatus = "Paga"} // Em análise
    if(comissao.status === "PROCESSANDO") {messageStatus = "Processando"} // Em análise

    const dataComissao = comissao.data.substring(0, 10).split('-').reverse().join('/');

    return {
      data: dataComissao,
      parcelas: comissao.parcelas,
      valor: `R$ ${FormatarValor(comissao.valor_transacao)}`,
      porcentagem: `${comissao.percentual_da_comissao}%`,
      ts: `R$ ${FormatarValor(comissao.TS)}`,
      comissao: `R$ ${FormatarValor(comissao.comissao)}`,
      status: <span style={{ backgroundColor: colorStatus, color: '#fff', fontSize: '14px', fontWeight: 600, padding: '6px 12px', borderRadius: '3px' }}>{messageStatus}</span>,
    }
  });

  /**
   * FUNÇÕES DE REGRAS DE NEGÓCIO E DA APLICAÇÃO
   */

  const { register, handleSubmit, setError, clearErrors, getValues, setValue, errors } = useForm();
  const onSubmit = async (data) => {
    // Bearer token
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    const forma_pagamento = data.forma_pagamento;
    const boleto_pagamento = data.docBoleto;

    // Verifica se uma nota fiscal foi anexada
    if (uploadedNotaFiscal.length === 0) {
      setError("nota_fiscal", {
        type: "manual",
        message: "Campo obrigatório."
      });

      addToast({
        type: 'error',
        title: 'Erro no pedido de pagamento',
        description: 'Você precisa anexar uma nota fiscal.',
      });

      return;
    };

    // Faz o upload da nota fiscal
    if (uploadedNotaFiscal.length > 0) {

      const data = new FormData();

      uploadedNotaFiscal.forEach(item => {
        data.append(`image${uploadedNotaFiscal.indexOf(item)}`, item.file);
      });

      await api.post('/documento_upload_mult', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization : "Bearer "+userToken,
        },
        onUploadProgress: e => {
          setProgressNotaFiscal(parseInt(Math.round((e.loaded * 100) / e.total)))
        }
      }).then(async response => {
        setStatusNotaFiscal({
          uploaded: true,
          error: false
        })

        const value = response.data.data;
        // setValue("nota_fiscal", value.id);

        // Solicita o pagamento
        await api.post('/gera_pagamento', {
          doc_comprovante_quitacao_pagamento: value.id,
          forma_pagamento: forma_pagamento,
          boleto_pagamento: boleto_pagamento === "" ? null : boleto_pagamento
        }, auth).then(response => {
          // console.log(response);

          if (response.data.error || response.data.errors) {
            addToast({
              type: 'error',
              title: 'Erro no pedido de pagamento',
              description: `Não conseguimos solicitar seu pagamento. Tente novamente ou entre em contato com o Deixa no Azul.`,
            });
      
            return;
          };
        }).catch(err => {
          // console.log(err.response);

          addToast({
            type: 'error',
            title: 'Erro no pedido de pagamento',
            description: `Não conseguimos solicitar seu pagamento. Tente novamente ou entre em contato com o Deixa no Azul.`,
          });

          return;
        });

      }).catch(() => {
        // console.log('error', err)
        setStatusNotaFiscal({
          uploaded: false,
          error: true
        })

        return;
      });

    };

    // Solicita o pagamento
    /* const response = await api.post('/gera_pagamento', {
      doc_comprovante_quitacao_pagamento: getValues('nota_fiscal'),
    }, auth);

    // Verificação de erro na geração de pagamento
    if (response.data.error || response.data.errors) {
      addToast({
        type: 'error',
        title: 'Erro no pedido de pagamento',
        description: `Não conseguimos solicitar seu pagamento. Tente novamente ou entre em contato com o Deixa no Azul.`,
      });

      return;
    }; */

    addToast({
      type: 'success',
      title: 'Deu tudo certo',
      description: 'Pagamento solicitado com sucesso, aguarde o processamento..',
    });

    setModalOpened(!modalOpened);
  
    setTimeout(window.location.reload(true), 2000);
  };

  // Abrir/fechar o modal final
  const [modalOpened, setModalOpened] = useState(true);

  // Função que verifica se o usuário possui saldo inferior a R$ 300
  function permitirPagamento() {
    
    const saldo = Number(contaDigital.saldo_conta);

    if (saldo < 10) {
      addToast({
        type: 'error',
        title: 'Saldo insuficiente',
        description: 'Você não tem saldo o suficiente para solicitar um pagamento.',
      });

      return;
    }

    if (saldo < 300) {
      addToast({
        type: 'info',
        title: 'Saldo inferior a R$ 300',
        description: 'Será descontado R$ 10 do seu pagamento para taxas de transferência.',
      });
    };

    setModalOpened(false)
  };

  /* async function solicitarPagamento() {
    const saldo = Number(contaDigital.saldo_conta);

    if (saldo < 300) {
      setModalOpened(!modalOpened);

      addToast({
        type: 'error',
        title: 'Saldo insuficiente',
        description: 'Você precisa ter um saldo maior que R$ 300 para solicitar um pagamento.',
      });

      return;
    }

    const response = await api.post('/gera_pagamento', {}, {
      headers: {
        Authorization : "Bearer "+userToken
      }
    });

    if (response.data.data) {
      addToast({
        type: 'success',
        title: 'Deu tudo certo!',
        description: 'Pagamento solicitado com sucesso, aguarde o processamento.',
      });
  
      setModalOpened(!modalOpened)
  
      setTimeout(window.location.reload(), 2000);
    }

    if (response.data.errors) {
      addToast({
        type: 'error',
        title: 'Algo deu errado.',
        description: 'Não conseguimos solicitar seu pagamento. Tente novamente ou entre em contato com o Deixa no Azul.',
      });

      setModalOpened(!modalOpened)
    }
  } */

  /**
   * ABAS
   */
   const [tab, setTab] = useState('tab1');

   const open1 = useCallback(() => {
     setTab('tab1');
   }, []);
   const open2 = useCallback(() => {
     const formaPagamento = getValues('forma_pagamento');
     if (formaPagamento === "") {
       setError("forma_pagamento", {
         type: "manual",
         message: "Você deve indicar uma forma de pagamento."
       });
 
       return;
     }
 
     setTab('tab2');
   }, [getValues, setError]);
   const open3 = useCallback(() => {
     const formaPagamento = getValues('forma_pagamento');
     if (formaPagamento === "") {
       setError("forma_pagamento", {
         type: "manual",
         message: "Você deve indicar uma forma de pagamento."
       });
 
       return;
     }
 
     setTab('tab3');
   }, [getValues, setError]);
 
  const [pagamentoPix, setPagamentoPix] = useState('none');
  const [pagamentoTransf, setPagamentoTransf] = useState('none');
  const [pagamentoBoleto, setPagamentoBoleto] = useState('none');
  const [pagamentoTitulo, setPagamentoTitulo] = useState('block');
  const [botaoContinuar, setBotaoContinuar] = useState('none');
  const [botaoEnviarBoleto, setBotaoEnviarBoleto] = useState('none');
 
  function changeSelect() {
    clearErrors('forma_pagamento');
 
    const formaPagamento = getValues('forma_pagamento');
 
    if (formaPagamento === "PIX") {
      setPagamentoPix('flex');
      setPagamentoTransf('none');
      setPagamentoBoleto('none');
      setPagamentoTitulo('block');
      setBotaoContinuar('block');
      setBotaoEnviarBoleto('none');
    };
    if (formaPagamento === "TRANSF") {
      setPagamentoPix('none');
      setPagamentoTransf('flex');
      setPagamentoBoleto('none');
      setPagamentoTitulo('block');
      setBotaoContinuar('block');
      setBotaoEnviarBoleto('none');
    };
    if (formaPagamento === "BOLETO") {
      setPagamentoPix('none');
      setPagamentoTransf('none');
      setPagamentoBoleto('flex');
      setPagamentoTitulo('none');
      setBotaoContinuar('none');
      setBotaoEnviarBoleto('block');
    };
  };

  // Upload da Nota Fiscal ---------------------------------------------- START 
  const [uploadedNotaFiscal, setUploadedNotaFiscal] = useState([]);
  const [progressNotaFiscal, setProgressNotaFiscal] = useState(0);
  const [statusNotaFiscal, setStatusNotaFiscal] = useState({
    uploaded: false,
    error: false
  });

  // Preparar arquivos para upload
  const submitFileNotaFiscal = useCallback((file) => {
    const uploadFile = {
      file: file[0],
      id: uniqueId(),
      name: file[0].name,
      readableSize: filesize(file[0].size),
      preview: URL.createObjectURL(file[0]),
    };

    setUploadedNotaFiscal([...uploadedNotaFiscal, uploadFile]);
  }, [uploadedNotaFiscal]);
  
  // Remove o arquivo da lista de arquivos
  const handleDeleteNotaFiscal = useCallback((id) => {
    const newFiles = uploadedNotaFiscal.filter(termo => termo.id !== id);

    setUploadedNotaFiscal(newFiles);
  }, [uploadedNotaFiscal]);
  // Upload da Nota Fiscal ------------------------------------------------ END

  // Upload do Novo Boleto ------------------------------------------------- START 
  const [uploadedNovoBoleto, setUploadedNovoBoleto] = useState([]);
  const [progressNovoBoleto, setProgressNovoBoleto] = useState(0);
  const [statusNovoBoleto, setStatusNovoBoleto] = useState({
    uploaded: false,
    error: false
  });

  // Preparar arquivos para upload
  const submitFileNovoBoleto = useCallback((file) => {
    const uploadFile = {
      file: file[0],
      id: uniqueId(),
      name: file[0].name,
      readableSize: filesize(file[0].size),
      preview: URL.createObjectURL(file[0]),
      // progress: progressComprovante,
      // uploaded: statusComprovante.uploaded,
      // error: statusComprovante.error,
    };

    setUploadedNovoBoleto([...uploadedNovoBoleto, uploadFile]);
  }, [uploadedNovoBoleto]);

  // Faz o upload de todos os arquivos
  const handleUploadNovoBoleto = useCallback(async () => {

    // Envia para a API
    const data = new FormData();

    uploadedNovoBoleto.forEach(item => {
      data.append(`image${uploadedNovoBoleto.indexOf(item)}`, item.file);
    });

    await api.post('/documento_upload_mult', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization : "Bearer "+userToken,
      },
      onUploadProgress: e => {
        setProgressNovoBoleto(parseInt(Math.round((e.loaded * 100) / e.total)))
      }
    }).then(response => {
      setStatusNovoBoleto({
        uploaded: true,
        error: false
      })

      const value = response.data.data;
      setValue("docBoleto", value.id);

      open3();

    }).catch(() => {
      setStatusNovoBoleto({
        uploaded: false,
        error: true
      })
    });
  }, [uploadedNovoBoleto, setValue, open3, userToken]);
  
  // Remove o arquivo da lista de arquivos
  const handleDeleteNovoBoleto = useCallback((id) => {
    const newFiles = uploadedNovoBoleto.filter(termo => termo.id !== id);

    setUploadedNovoBoleto(newFiles);
  }, [uploadedNovoBoleto]);
  // Upload do Novo Boleto --------------------------------------------------- END

  /**
   * FUNÇÕES DO MOBILE
   */

  // Formata o valor da transação
  function valorFinalTransacao(comissao) {
    // console.log(comissao);

    // console.log(comissao);
    const valor_total = comissao.valor_transacao === null || comissao.valor_transacao === undefined ? '00' : comissao.valor_transacao.toString().split('.');
    const valorTotal = `${valor_total[0]},${valor_total[1] ? valor_total[1].substring(0, 2) : "00"}`;

    return <strong>R$ {valorTotal}</strong>
  };

  // Formata o valor da Taxa de serviço
  function valorTS(comissao) {

    const valor_comissao = comissao.TS.toString().split('.');
    const valorComissao = `${valor_comissao[0]},${valor_comissao[1] ? valor_comissao[1].substring(0, 2) : "00"}`;

    return <strong>R$ {valorComissao}</strong>
  };

  // Formata o valor da comissão
  function valorComissao(comissao) {

    const valor_comissao = comissao.comissao.toString().split('.');
    const valorComissao = `${valor_comissao[0]},${valor_comissao[1] ? valor_comissao[1].substring(0, 2) : "00"}`;

    return <strong>R$ {valorComissao}</strong>
  };

  // Formata o status de pagamento da transação
  function transacaoStatus(comissao) {
    let colorStatus = '';
    if(comissao.status === "PROCESSANDO") {colorStatus = "#5097FF"} // Solicitar documentos
    if(comissao.status === "PAGO") {colorStatus = "#5CB85C"} // Aprovada
    if(comissao.status === "NAO_PAGO") {colorStatus = "#F35757"} // Checagem financeira

    let messageStatus = '';
    if(comissao.status === "PROCESSANDO") {messageStatus = "Processando"} // Solicitar documentos
    if(comissao.status === "PAGO") {messageStatus = "Paga"} // Aprovada
    if(comissao.status === "NAO_PAGO") {messageStatus = "Não paga"} // Checagem financeira

    return <span style={{ color: colorStatus }}>{messageStatus}</span>
  };

  return (
    <Container>
      <ModalSection modalOpened={modalOpened}>
        <ModalContent>
          <MdHighlightOff size={30} onClick={() => setModalOpened(!modalOpened)} />

          <NavegaçãoAbas>
            <BotãoAba className="aba1" tab={tab} onClick={open1} />
            <Line1 tab={tab} />
            <BotãoAba className="aba2" tab={tab} onClick={open2} />
            <Line2 tab={tab} />
            <BotãoAba className="aba3" tab={tab} onClick={open3} />
          </NavegaçãoAbas>

          <form onSubmit={handleSubmit(onSubmit)}>
            <FirstStep tab={tab}>
              <span>Deseja solicitar o pagamento no valor de:</span>
              <span className="marginBottom">R$ {FormatarValor(contaDigital.saldo_conta)}</span>

              <InputArea>
                <label className="marginBottom">Escolha a forma de pagamento</label>
                <InputContainer style={{ borderColor: errors.forma_pagamento && "red" }}>
                  <select
                    name="forma_pagamento"
                    placeholder="Selecione"
                    ref={register}
                    onChange={() => changeSelect()}
                  >
                    <option value="">Selecione</option>
                    {formasPagamento}
                    {/* <option value="PIX">PIX</option> */}
                    {/* <option value="TRANSF">TRANSF</option> */}
                    <option value="BOLETO">Boleto</option>
                  </select>
                  {errors.forma_pagamento && <Error title={errors.forma_pagamento.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                </InputContainer>
              </InputArea>
              
              <button type="button" onClick={open2}>Continuar</button>
            </FirstStep>

            <SecondStep tab={tab}>
              <span style={{ display: pagamentoTitulo }}>Deseja solicitar o pagamento no valor de:</span>
              <span className="marginBottom" style={{ display: pagamentoTitulo }}>R$ {FormatarValor(contaDigital.saldo_conta)}</span>

              <ModalAccountData style={{ display: pagamentoTransf }}>
                <p>Na conta bancária:</p>
                <span>Nome: {dataBank.nome_titular}</span>
                <span>CPF/CNPJ: {dataBank.cpf_cnpj}</span>
                <span>Agência: {dataBank.agencia}</span>
                <span>Conta: {dataBank.numero_conta}</span>
                <span>Banco: {dataBank.nome_banco}</span>
              </ModalAccountData>

              <ModalAccountData style={{ display: pagamentoPix }}>
                <p>Na seguinte conta:</p>
                <span>Tipo de Chave Pix: {dataBank.tipo_chave_pix}</span>
                <span>Chave Pix: {dataBank.chave_pix}</span>
              </ModalAccountData>

              <ModalAccountData style={{ display: pagamentoBoleto, marginTop: 0 }}>
                <span style={{ fontSize: '20px' }}>Anexar Boleto</span>

                <BoxInfo>
                  <span>Anexe até 2 documentos em cada campo e, em seguida, clique em <b>Enviar arquivos.</b></span> <br />
                </BoxInfo>

                <InputArea className="campoAnexo">
                  <UploadContainer style={{ borderColor: errors.docBoleto && "red" }}>
                    <UploadBoleto
                      onUpload={submitFileNovoBoleto}
                      isDisabled={uploadedNovoBoleto.length === 2 ? true : false}
                      isBlue={true}
                    />
                    <input type="text" name="docBoleto" ref={register} hidden />
                    {errors.docBoleto && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </UploadContainer>
                  {!!uploadedNovoBoleto.length && <InfoBoleto files={uploadedNovoBoleto} percent={progressNovoBoleto} status={statusNovoBoleto} onDelete={(id) => handleDeleteNovoBoleto(id)} />}

                </InputArea>
              </ModalAccountData>

              <div className="buttonArea">
                <button  style={{ display: botaoEnviarBoleto }} type="button" onClick={() => handleUploadNovoBoleto()}>Enviar boleto</button>
                <button  style={{ display: botaoContinuar }} type="button" onClick={open3}>Continuar</button>
                <button className="cancelButton" type="button" onClick={open1}>Voltar</button>
              </div>
            </SecondStep>
          
            <ThirdStep tab={tab}>
              <span>Anexar Nota Fiscal</span>

              <BoxInfo>
                <span>Anexe até 2 documentos em cada campo e, em seguida, clique em <b>Enviar arquivos.</b></span> <br />
              </BoxInfo>

              <InputArea className="campoAnexo">
                <UploadContainer style={{ borderColor: errors.nota_fiscal && "red" }}>
                  <UploadNotaFiscal
                    onUpload={submitFileNotaFiscal}
                    isDisabled={uploadedNotaFiscal.length === 2 ? true : false}
                    isWhite={false}
                  />
                  <input type="text" name="nota_fiscal" ref={register} hidden />
                  {errors.nota_fiscal && <Error title={errors.nota_fiscal.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                </UploadContainer>
                {!!uploadedNotaFiscal.length && <InfoNotaFiscal files={uploadedNotaFiscal} percent={progressNotaFiscal} status={statusNotaFiscal} onDelete={(id) => handleDeleteNotaFiscal(id)} isBlack={false} />}

              </InputArea>

              <div className="buttonArea">
                <button type="submit">Solicitar</button>
                <button className="cancelButton" type="button" onClick={open2}>Voltar</button>
              </div>
            </ThirdStep>
          </form>
        </ModalContent>
      </ModalSection>
      
      <MenuBar active="CD" />
      <NewMobileMenu active="CD" />

      <Page>
        <TopBar pageTitle="Conta digital" />
        <Content>

          <Saldo>
            <span>Saldo da conta: <strong>R$ {FormatarValor(contaDigital.saldo_conta)}</strong></span>

            <button type="button" onClick={() => permitirPagamento()}>Solicitar pagamento</button>
          </Saldo>

          <TableContent>

            <MaterialTable
              title="Lista das comissões"
              columns={headData}
              data={bodyData}
              components={{
                Toolbar: props => (
                  <div>
                    <MTableToolbar {...props} />
                    
                  </div>
                )
              }}
              localization={{
                pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{from}-{to} de {count}',
                  firstTooltip: 'Primeira página',
                  previousTooltip: 'Página anterior',
                  nextTooltip: 'Próxima página',
                  lastTooltip: 'Última página'
                },
                toolbar: {
                  searchPlaceholder: 'Buscar',
                  searchTooltip: 'Buscar'
                },
                body: {
                  emptyDataSourceMessage: 'Nenhum dado encontrado',
                }
              }}
              options={{
                search: false,
                actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: '#10104F',
                  color: '#FFF'
                },
                emptyValue: "Nenhum dado encontrado"
              }}
            />

          </TableContent>

          <TableContentMobile>
            <Header>
              <h3>Lista de comissões</h3>

              <span>Total: {comissoes === [] || comissoes === "Nenhuma comissao encontrada" ? "0" : comissoes.length}</span>
            </Header>

            <MobileBody>
              {comissoes === [] || comissoes === "Nenhuma comissao encontrada" ? 
                <span>Nenhuma comissão encontrada</span>
              : comissoes.map((comissao) => (
                <Line key={comissao.id}>
                  <span>{comissao.data.substring(0, 10).split('-').reverse().join('/')}</span>
                  <p>Valor da transação: {valorFinalTransacao(comissao)}</p>
                  <p>Parcelas: <strong>{comissao.parcelas}</strong></p>
                  <p>TS: {valorTS(comissao)}</p>
                  <p>Comissão: {valorComissao(comissao)}</p>
          
                  {<div className="footer">
                    <span>{transacaoStatus(comissao)}</span>
                    {/* <img src={editIcon} alt="" onClick={() => editTransacao(transacao)}/> */}
                  </div>}
                </Line>
              ))}
            </MobileBody>

          </TableContentMobile>

          <Infos>
            <FirstBox>
              <div>
                <label>Extrato de pagamento</label>
                <Link to="/extratopagamento">Consulte aqui</Link>
              </div>

            </FirstBox>

            <SecondBox>
              <div>
                <label>Total de comissionado no mês</label>
                <p>R$ {FormatarValor(contaDigital.total_comissao_mes)}</p>
              </div>

              <div>
                <label>Total de comissionado no ano</label>
                <p>R$ {FormatarValor(contaDigital.total_comissao_ano)}</p>
              </div>
            </SecondBox>
          </Infos>

        </Content>
      </Page>
    </Container>
  );
}

export default ContaDigitalEC;
