import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import CountUp from 'react-countup';

import api from '../../services/api';

import logoCards from '../../assets/images/logo_cards.gif';

import { Container, Mask } from './styles';

function LoadingPage() {
  const history = useHistory();

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');
  const loggedUser = JSON.parse(localStorage.getItem('@DeixaNoAzul:loggedUser'));
  // console.log(loggedUser);

  // Se o usuário logado for um LA, salva os dados do licenciado no localstorage
  if (loggedUser.role === 'ROLE_PT') {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get('/partner?png=999999', auth).then(response => {
      const retorno = response.data.data;
      console.log('responsePT', response);

      const licensedLogged = retorno.filter(item => item.user_id === loggedUser.id);
      localStorage.setItem('@DeixaNoAzul:parceiroId', JSON.stringify(licensedLogged[0]));
    });
  };

  // Se o usuário logado for um LA, salva os dados do licenciado no localstorage
  if (loggedUser.role === 'ROLE_LA') {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get('/licenciado?png=999999', auth).then(response => {
      const retorno = response.data.data;
      console.log('responseLA', response);

      const licensedLogged = retorno.filter(item => item.user_id === loggedUser.id);
      localStorage.setItem('@DeixaNoAzul:licensedId', JSON.stringify(licensedLogged[0]));
    }).catch(err => {
      console.log('errLA', err.response);
    });
  };

  // Se o usuário logado for um EC, salva os dados do estabelecimento no localstorage
  if (loggedUser.role === 'ROLE_EC') {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get('/estabelecimento?png=999999', auth).then(response => {
      const retorno = response.data.data;
      console.log('responseEC', response);

      const licensedLogged = retorno.filter(item => item.user_id === loggedUser.id);
      localStorage.setItem('@DeixaNoAzul:estabelecimentoId', JSON.stringify(licensedLogged[0]));
    });
  };

  // Se o usuário logado for um Revendedor, salva os dados do estabelecimento no localstorage
  if (loggedUser.role === 'ROLE_RV') {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get('/revendedor?png=999999', auth).then(response => {
      const retorno = response.data.data;
      console.log('responseRV', response);

      const revenderLogged = retorno.filter(item => item.user_id === loggedUser.id);
      console.log('revenderLogged', revenderLogged);
      localStorage.setItem('@DeixaNoAzul:revendedorId', JSON.stringify(revenderLogged[0]));
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (loggedUser.role === 'ROLE_LA') {
        history.push('/ec');
      }
      if (loggedUser.role === 'ROLE_EC' || loggedUser.role === 'ROLE_CL') {
        history.push('/simulador');
      }
      if (loggedUser.role === 'ROLE_RV') {
        history.push('/ec/revendedor');
      }
      if (loggedUser.role === 'ROLE_ADMIN') {
        if (loggedUser.id === "269c9c16-fa72-445c-b437-24bb6f11c29a") {
          // Caso seja o Rogério do Certificado Digital
          history.push('/admin/certificado-digital');
        } else if (loggedUser.id === "4006cba8-13f5-4e21-ba8b-30ddc26ca562") {
          // Caso seja a Ana do FGTS
          history.push('/admin/fgts');
        } else {
          history.push('/admin/transacoes');
        }
      }
      if (loggedUser.role === 'ROLE_PT') {
        history.push('/parceiro/transacoes');
      }
    }, 4000);
  }, [history, loggedUser]);

  return (
    <Container>
      <img src={logoCards} alt="Deixa no Azul" />

      <div>
        <span>Estamos preparando<br />seu conteúdo</span>

        <div>
          <CountUp
            start={0}
            end={100}
            duration={3.8}
          />
          <span>%</span>
        </div>
      </div>

      <Mask />
    </Container>
  );
};

export default LoadingPage;
