import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';
import TopBar from '../../components/TopBar';

import ParceiroTransacoes from '../ParceiroTransacoes';
import ParceiroContaDeLuz from '../ParceiroContaDeLuz';
import ParceiroCertificadoDigital from '../ParceiroCertificadoDigital';
import ParceiroFGTS from '../ParceiroFGTS';

import { Container, Page, Content } from './styles';

function ParceiroLayout() {
  const location = useLocation();
  // const currentLocation = location.pathname;

  // console.log('location', location);
  // console.log('currentLocation', currentLocation);

  /**
   * Pagamento de contas
   * Conta de Luz
   * Certificado Digital
   * Saque FGTS
   */
  const [paginaAtual, setPaginaAtual] = useState('');

  const [menuActive, setMenuActive] = useState('');
  const [subMenuActive, setSubMenuActive] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [pageSuttitle, setPageSubtitle] = useState('');

  useEffect(() => {
    if (location.pathname === '/parceiro/transacoes') {
      setPaginaAtual('Pagamento de contas');
      setMenuActive('AdminTransações');
      setSubMenuActive('Deixa no Azul');
      setPageTitle('Transações -');
      setPageSubtitle('Deixa no Azul');
    }
    if (location.pathname === '/parceiro/conta-luz') {
      setPaginaAtual('Conta de Luz');
      setMenuActive('AdminTransações');
      setSubMenuActive('Conta de Luz');
      setPageTitle('Transações -');
      setPageSubtitle('Conta de Luz');
    }
    if (location.pathname === '/parceiro/certificado-digital') {
      setPaginaAtual('Certificado Digital');
      setMenuActive('AdminTransações');
      setSubMenuActive('Certificado Digital');
      setPageTitle('Transações -');
      setPageSubtitle('Certificado Digital');
    }
    if (location.pathname === '/parceiro/saque-fgts') {
      setPaginaAtual('Saque FGTS');
      setMenuActive('AdminTransações');
      setSubMenuActive('Saque FGTS');
      setPageTitle('Transações -');
      setPageSubtitle('Saque FGTS');
    }
  }, [location.pathname]);

  return (
    <Container>
      <MenuBar active={menuActive} subMenuActive={subMenuActive} />
      <NewMobileMenu active={menuActive} subMenuActive={subMenuActive} />

      <Page>
        <TopBar pageTitle={pageTitle} pageSubtitle={pageSuttitle} />

        <Content>
          {paginaAtual === 'Pagamento de contas' && <ParceiroTransacoes />}
          {paginaAtual === 'Conta de Luz' && <ParceiroContaDeLuz />}
          {/* {paginaAtual === 'Conta de Luz' && <span style={{ color: '#fff', fontSize: 20 }}>Dados disponíveis em breve...</span>} */}
          {paginaAtual === 'Certificado Digital' && <ParceiroCertificadoDigital />}
          {/* {paginaAtual === 'Certificado Digital' && <span style={{ color: '#fff', fontSize: 20 }}>Dados disponíveis em breve...</span>} */}
          {/* {paginaAtual === 'Saque FGTS' && <ParceiroFGTS />} */}
          {paginaAtual === 'Saque FGTS' && <span style={{ color: '#fff', fontSize: 20 }}>Dados disponíveis em breve...</span>}
        </Content>
      </Page>
    </Container>
  );
};

export default ParceiroLayout;
