import styled, { css } from 'styled-components';

import { shade } from 'polished';

import ToolTip from '../Tooltip';

export const Container = styled.div`

  button {
    padding: 8px 22px;
    background: #0073FC;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;

    transition: 0.2s;

    &:hover {
      background: ${shade(0.2, '#0073FC')}
    }

    @media (max-width: 500px) {
      margin-top: 20px;
    }
  }
`;

export const FirstRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  width: 100%;
  margin-bottom: 18px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  width: 100%;
  margin-bottom: 18px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  box-sizing: border-box;

  margin-bottom: 18px;
  padding: 1px 11px 1px 1px;

  ${props => props.isErrored && css`
    border: 1px solid #c53030;
  `}

  ${props => props.isFocused && css`
    border: 1px solid #0073FC;
  `}

  input {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;
  }

  select {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;
  }
`;

export const Error = styled(ToolTip)`
  span {
    background: #c53030;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  flex: 1;

  label {
    font-size: 16px;
    color: #373A3C;
  }

  p {
    color: #0073FC;
  }
`;

export const BoxEmail = styled.div`
  background: #F1F5F9;
  /* color: #0073FC; */
  /* color: #fff; */
  padding: 10px 20px;
  border-radius: 6px;

  margin-bottom: 20px;

  a {
    text-decoration: none;
  }
`;
