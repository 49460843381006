import React from "react";

import { AuthProvider } from "./AuthContext";
import { ToastProvider } from "./ToastContext";
// import { LicensedProvider } from './LicensedContext';
import { BankDataProvider } from "./BankDataContext";
import { SimulatorProvider } from "./SimulatorContext";
import { EcInfosProvider } from "./EcInfosContext";
import { TransacaoInfosProvider } from "./TransacaoInfosContext";
import { LaInfosProvider } from "./LaInfosContext";
import { RevendedorInfosProvider } from "./RevendedorInfosContext";

import { UserSimulatorInfosProvider } from "./UserSimulatorInfosContext";

// import { VerifyTokenProvider } from './VerifyTokenContext';
import { TransacaoExternaProvider } from "./TransacaoExternaContext";
import { PagamentoInfosProvider } from "./PagamentoInfosContext";

import { SimulatorContaDeLuzProvider } from "./SimulatorContaDeLuzContext";

const AppProvider = ({ children }) => (
  <ToastProvider>
    <AuthProvider>
      {/* <VerifyTokenProvider> */}
      {/* <LicensedProvider> */}
      <BankDataProvider>
        <SimulatorProvider>
          <EcInfosProvider>
            <LaInfosProvider>
              <RevendedorInfosProvider>
                <TransacaoInfosProvider>
                  <TransacaoExternaProvider>
                    <PagamentoInfosProvider>
                      <UserSimulatorInfosProvider>
                        <SimulatorContaDeLuzProvider>
                          {children}
                        </SimulatorContaDeLuzProvider>
                      </UserSimulatorInfosProvider>
                    </PagamentoInfosProvider>
                  </TransacaoExternaProvider>
                </TransacaoInfosProvider>
              </RevendedorInfosProvider>
            </LaInfosProvider>
          </EcInfosProvider>
        </SimulatorProvider>
      </BankDataProvider>
      {/* </LicensedProvider> */}
      {/* </VerifyTokenProvider> */}
    </AuthProvider>
  </ToastProvider>
);

export default AppProvider;
