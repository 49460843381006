import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { MdErrorOutline } from 'react-icons/md';
import LoadingIcons from 'react-loading-icons'

import api from '../../services/api';

import logoImg from '../../assets/images/logo.png';
import backgroundBlue from '../../assets/images/background-blue.png';
import backgroundGreen from '../../assets/images/background-green.png';

import { useToast } from '../../hooks/ToastContext';

import {
  Container,
  Content,
  Box,
  BgBlue,
  BgGreen,
  InputArea,
  InputContainer,
  Error,
} from './styles';

function ResetPassword() {
  const history = useHistory();
  const location = useLocation();
  
  const { addToast } = useToast();

  const token = location.search.replace('?id=', '');

  const [loading, setLoading] = useState(false); // Default false
  const [inputType, setInputType] = useState('password'); // Default false

  const {
    register,
    handleSubmit,
    errors,
  } = useForm();
  const onSubmit = async (data) => {
    // console.log(data);
    setLoading(true);

    if (!token) {
      addToast({
        type: 'error',
        title: 'Erro na criação de nova senha',
        description: 'Algo deu errado na criação da nova senha, tente novamente mais tarde.',
      });

      setLoading(false);
      return;
    };

    try {
      // envia para a API
      await api.post(`/change_pass?id=${token}`, {
        // id: userId,
        password: data.password
      }, {
        headers: {
          Authorization : "Bearer "+token
        }
      });

      setLoading(false);

      addToast({
        type: 'success',
        title: 'Deu tudo certo',
        description: 'Nova senha criada com sucesso.',
      });

      history.push('/');
    } catch (error) {
      console.log(error.response);

      setLoading(false);

      if (error.response.data.message === "The token has been blacklisted") {
        addToast({
          type: 'error',
          title: 'Erro na criação de nova senha',
          description: 'O token desta solicitação já foi utilizado. Solicite um novo processo de recuperação de senha.',
        });

        return;
      }

      addToast({
        type: 'error',
        title: 'Erro na criação de nova senha',
        description: 'Algo deu errado na criação da nova senha, cheque as senhas digitadas, tente novamente mais tarde ou entre em contato com o Deixa no Azul.',
      });
    }

  };
  const onError = (errors) => {
    addToast({
      type: 'error',
      title: 'Erro no envio do formulário',
      description: 'Você precisa preencher todos os campos obrigatórios (marcados com *).',
    });
  };

  const anoAtual = new Date().getFullYear();
  
  return (
    <Container>
      <BgBlue src={backgroundBlue} alt="Imagem decorativa" />
      <BgGreen src={backgroundGreen} alt="Imagem decorativa" />

      <Content>
        <Box>
          <img src={logoImg} alt="Deixa no Azul" />

          <h2>Tá quase lá!</h2>
          <p>Infome sua nova senha e tenha acesso ao sistema</p>

          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div style={{ marginBottom: 10 }}>
              <InputArea>
                <label>Nova senha</label>
                <InputContainer style={{ borderColor: errors.password && "red" }}>
                  <input
                    name="password" 
                    type={inputType}
                    // placeholder="Digite seu e-mail"
                    ref={register({ required: true })}
                  />
                  {errors.password && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>Confirmar senha</label>
                <InputContainer style={{ borderColor: errors.password_confirmation && "red", marginBottom: 5 }}>
                  <input
                    name="password_confirmation" 
                    type={inputType}
                    // placeholder="Digite seu e-mail"
                    ref={register({ required: true })}
                  />
                  {errors.password_confirmation && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                </InputContainer>
              </InputArea>

              <span
                onClick={() => {
                  inputType === 'password'
                    ? setInputType('text')
                    : setInputType('password')
                }}
                style={{ color: '#08BBE9', cursor: 'pointer' }}
              >
                Mostrar senha
              </span>
            </div>

            <button type="submit">
              {loading ? (
                <>
                  <LoadingIcons.Oval
                    speed={1.2}
                    style={{ width: 25, marginRight: 8 }}
                    strokeWidth={3.5}
                  />
                  <span>Confirmando</span>
                </>
              ) : (
                <span>Confirmar</span>
              )}
            </button>
          </form>

        </Box>

        <span>© {anoAtual}. Todos os direitos reservados</span>
      </Content>
    </Container>
  );
}

export default ResetPassword;
