import React, { useState } from 'react';
import { 
  Tabs,
  Tab,
  AppBar,
  Box,
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from '@material-ui/core';

import api from '../../services/api';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';

// import PersonalData from '../../components/PersonalData';
import PersonalDataView from '../../components/PersonalDataView';
// import BankingData from '../../components/BankingData';
import BankingDataView from '../../components/BankingDataView';

import NewMobileMenu from '../../components/NewMobileMenu';

import { Container, Page, Content, TabContent } from './styles';

const theme = createMuiTheme({
  overrides: {
    MuiContainer:{
      root:{
          position: "relative",
      },
    },
    MuiAppBar:{
      colorPrimary:{
        backgroundColor: '#FFFFFF'
      },
    },
    MuiButtonBase:{
      root:{
        '&:focus': {
          outline: 'none',
        }
      }
    },
    PrivateTabIndicator:{
      colorSecondary:{
        backgroundColor:"#0073FC"
      }
    },
    MuiTab:{
      textColorInherit:{
        color: '#373A3C'
      },
      root:{
        fontFamily: 'Source Sans Pro',
        fontSize: '20px',
        textTransform: 'none',
        padding:'10px 25px 0 25px',
        marginBottom:'10px'
      }
    },
  },  
 
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
};

function Profile() {
  const [selectedTab, setSelectedTab] = useState(0);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');
  const loggedUser = JSON.parse(localStorage.getItem('@DeixaNoAzul:loggedUser'));
  // console.log(loggedUser)

  // Se o usuário logado for um LA, salva os dados do licenciado no localstorage
  if (loggedUser.role === 'ROLE_LA') {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get('/licenciado?png=999999', auth).then(response => {
      const retorno = response.data.data;

      const licensedLogged = retorno.filter(item => item.user_id === loggedUser.id);
      localStorage.setItem('@DeixaNoAzul:licensedId', JSON.stringify(licensedLogged[0]));
    });
  };

  // Se o usuário logado for um EC, salva os dados do estabelecimento no localstorage
  if (loggedUser.role === 'ROLE_EC') {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get('/estabelecimento?png=999999', auth).then(response => {
      const retorno = response.data.data;

      const licensedLogged = retorno.filter(item => item.user_id === loggedUser.id);
      localStorage.setItem('@DeixaNoAzul:estabelecimentoId', JSON.stringify(licensedLogged[0]));
    });
  };

  // Se o usuário logado for um Revendedor, salva os dados do estabelecimento no localstorage
  if (loggedUser.role === 'ROLE_RV') {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get('/revendedor?png=999999', auth).then(response => {
      const retorno = response.data.data;

      const revenderLogged = retorno.filter(item => item.user_id === loggedUser.id);
      console.log(revenderLogged)
      localStorage.setItem('@DeixaNoAzul:revendedorId', JSON.stringify(revenderLogged[0]));
    });
  };

  // Alterna as abas entre Dados pessoais e Dados bancários
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Altera o background de acordo com o usuário logado
  const isAdmin = loggedUser.role === 'ROLE_ADMIN' || loggedUser.role === 'ROLE_PT' ? true : false;

  return (
    <Container>
      <MenuBar />
      <NewMobileMenu active="Perfil" />

      <Page>
        <TopBar pageTitle="Meu perfil" />
        <Content isAdmin={isAdmin}>

          <TabContent>

            <ThemeProvider theme={theme}>
              <AppBarCustom position="static">
                <Tabs value={selectedTab} onChange={handleChange} >
                  <Tab label="Dados pessoais" value={0} />
                  <Tab label="Dados bancários" value={1} />
                </Tabs>
              </AppBarCustom>

              <TabPanel value={selectedTab} index={0}>
                <PersonalDataView />
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                {/* <BankingData /> */}
                <BankingDataView />
              </TabPanel>
            </ThemeProvider>
            
          </TabContent>
          
        </Content>
      </Page>
    </Container>
  );
}

const AppBarCustom = withStyles({
  root:{
    boxShadow: "none",
    backgroundColor: "#FFF",
    color: '#373A3C',
    borderRadius: '8px'
  }
})(AppBar);

export default Profile;
