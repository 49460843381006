import styled from 'styled-components';

import backgroundImg from '../../assets/images/background_2.jpg';

export const Container = styled.div`
  .abled {
    cursor: pointer;
  }

  .disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  background: #F5F8FB;
  width: 100vw;
  height: 100vh;
`;

export const Page = styled.div`
  margin-left: 220px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  /* height: calc(100vh - 72px); */
  background: url(${backgroundImg}) no-repeat;
  background-position: top left;
  background-size: 100% 256px;
  box-sizing: border-box;

  padding: 100px 90px 60px 30px;

  @media (max-width: 500px) {
    padding: 50px 20px 20px;
  }
`;
