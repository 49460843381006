import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MdErrorOutline, MdHighlightOff } from "react-icons/md";

import api from "../../services/api";

import { useToast } from "../../hooks/ToastContext";

import { sendMessage } from '../../utils/sendMessage';

import TopBar from "../../components/TopBar";
import MenuBar from "../../components/MenuBar";
import NewMobileMenu from "../../components/NewMobileMenu";
import LoadingContent from '../../components/LoadingContent';
import FormatarValor from '../../components/FormatarValor';
import SendMessageComponent from "../../components/SendMessageComponent";

import {
  Container,
  Page,
  Content,
  RowBox1,
  Box,
  Row,
  Row3,
  Row4,
  InputArea,
  Flex,
  Separator,
  BlueBox,
  SelectStatus,
  InputContainer,
  ModalSection,
  ModalContent,
  Aprovado,
  Mensagem,
  Confirmar,
  Error,
} from './styles';

function AdminEditarContaDeLuz() {
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  const userToken = localStorage.getItem("@DeixaNoAzul:accessToken");

  const { addToast } = useToast();

  // const [userID, setUserID] = useState("");

  const idTransacao = location.pathname
    .replace("/admin/conta-luz/editar/", "")
    .split("")
    .reverse()
    .join("");
  
  const [transacaoInfos, setTransacaoInfos] = useState({});
  const [transacaoCliente, setTransacaoCliente] = useState({});
  const [transacaoClienteEndereco, setTransacaoClienteEndereco] = useState({});
  const [transacaoClienteDadosBancarios, setTransacaoClienteDadosBancarios] = useState({});

  // const [mensagensParaEC, setMensagensParaEC] = useState([]);

  const [valorParcela, setValorParcela] = useState('');
  const [valorPagamento, setValorPagamento] = useState('');
  const [totalTransação, setTotalTransação] = useState('');
  const [sexo, setSexo] = useState('');

  const [ecInfos, setEcInfos] = useState({});

  const { register, handleSubmit, getValues, setValue, errors } = useForm();
  const onSubmit = async (data) => {
    // console.log(data);
    
    // Bearer token
    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    try {
      // Muda para Aprovada
      if (data.status === "APROVADO" || data.status === "EM_TRATAMENTO") {
        await api.put(`/energy/transaction/status/${idTransacao}`,{
          status: data.status
        }, auth).then(response => {
          console.log(response);
        });

        // console.log(responseUpdateStatus);
      }

      if (data.status === "REJEITADO" || data.status === "CANCELADO") {
        await api.put(`/energy/transaction/status/${idTransacao}`,{
          status: data.status
        }, auth);

        // console.log(responseUpdateStatus);

        // mensagem
        const messageSender = await sendMessage({
          recipient_id: transacaoInfos.estabelecimento.data.user_id,
          subject: "Atualização de status",
          message: data.message,
          transaction_type: 'TransacaoPagamentoContaDeLuz',
          transaction_id: idTransacao,
        });

        // Exige mensagem de erro no envio da mensagem
        if (messageSender.status === false) {
          addToast({
            type: "error",
            title: "Erro ao enviar mensagem",
            description: "A transação foi editada mas não conseguimos enviar sua mensagem.",
          });
        }
      }

      addToast({
        type: "success",
        title: "Deu tudo certo",
        description: "Dados atualizados com sucesso.",
      });
  
      history.push("/admin/conta-luz");
      
    } catch (error) {
      addToast({
        type: "error",
        title: "Erro na atualização",
        description: "Ocorreu um erro ao atualizar a transação, tente novamente ou entre em contato.",
      });
    }

  };
  const onError = (errors) => {
    // console.log(errors)
    addToast({
      type: "error",
      title: "Erro na atualização",
      description: "Ocorreu um erro ao atualizar seus dados, tente novamente.",
    });
  };

  const [userID, setUserID] = useState("");
  const [allowMessage, setAllowMessage] = useState(false);

  // Faz as requisições para preencher as informações
  useEffect(() => {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    async function requestTransacao() {
      const responseTransacao = await api.get(
        `/energy/transaction/${idTransacao}`,
        auth
      );
      const resultTransacao = responseTransacao.data.data;

      if (resultTransacao.status === "EM_TRATAMENTO" || resultTransacao.status === "CANCELADO" || resultTransacao.status === "REJEITADO") {
        setAllowMessage(true);
      } else {
        setAllowMessage(false);
      }

      // console.log('resultTransacao', resultTransacao);
      setTransacaoInfos(resultTransacao);
      setUserID(resultTransacao.estabelecimento.data.user_id);
      
      const responseCliente = await api.get(
        `/energy/transaction/client/${resultTransacao.cliente_id}`,
        auth
      );
      const resultCliente = responseCliente.data.data;

      // console.log('resultCliente', resultCliente);

      setTransacaoCliente(resultCliente);
      setTransacaoClienteEndereco(resultCliente.endereco);
      setTransacaoClienteDadosBancarios(resultCliente.dados_bancarios.data);

      resultCliente.sex === 'M' ? setSexo('Masculino') : setSexo('Feminino');

      /**
       * VALORES DA TRANSAÇÃO
       */

      // Valor da parcela
      setValorParcela(resultTransacao.valor_parcela);

      // Valor Total transação
      setTotalTransação(resultTransacao.valor_final);

      // Valor do pagamento (feito ao cliente final)
      setValorPagamento(resultTransacao.valor_pagamento);

      // Muda o valor e a cor do select
      setValue("status", resultTransacao.status);
      setStatusColor(resultTransacao.status.toString());

      if (resultTransacao.status === 'APROVADO') {
        setEditStatusHide(true);
      };

      // Pega os dados do EC
      const responseEC = await api.get(
        `/estabelecimento/${resultTransacao.estabelecimento_id}`,
        auth
      );
      const resultEC = responseEC.data.data;
      // console.log('resultEC', resultEC);
      setEcInfos(resultEC);

      // Pega as mensagens enviadas
      /* const responseMensagensEnviadas = await api.get(
        `/sent_messages`,
        auth
      );
      const resultMensagensEnviadas = responseMensagensEnviadas.data.data;
      const mensagemFiltrada = resultMensagensEnviadas.filter(item => item.send_to_id === resultTransacao.estabelecimento_id || item.send_to_id === resultEC.user_id); */
      // console.log('resultMensagensEnviadas', resultMensagensEnviadas);
      // console.log('mensagemFiltrada', mensagemFiltrada);
      // setMensagensParaEC(mensagemFiltrada);

      setIsLoading(false);
    }
    
    requestTransacao();
  }, [idTransacao, setValue, userToken]);

  // Abrir e fechar modal
  const [modalOpened, setModalOpened] = useState(true);

  const [editStatusHide, setEditStatusHide] = useState(false);
  // State para mudança de cor do select
  const [statusColor, setStatusColor] = useState("");

  // State para definir regra de obrigatoriedade
  const [requiredMessage, setRequiredMessage] = useState(false);

  // State para mostrar campo de upload de arquivos ou mensagem
  const [aprovadoHide, setAprovadoHide] = useState(true);
  const [messageHide, setMessageHide] = useState(true);
  const [confirmarHide, setConfirmarHide] = useState(true);

  const changeStatus = useCallback(() => {
    const selectValue = getValues("status");

    setStatusColor(selectValue);

    if (selectValue === "APROVADO") {
      // setRequiredUpload(true);
      setRequiredMessage(false);

      setAprovadoHide(false);
      setMessageHide(true);
      setConfirmarHide(true);
    }

    if (selectValue === "REJEITADO" || selectValue === "CANCELADO") {
      setRequiredMessage(true);
      // setRequiredUpload(false);

      setMessageHide(false);
      setAprovadoHide(true);
      setConfirmarHide(true);
    }

    if (selectValue === "EM_TRATAMENTO") {
      setRequiredMessage(false);
      // setRequiredUpload(false);

      setConfirmarHide(false);
      setMessageHide(true);
      setAprovadoHide(true);
    }
  }, [getValues]);

  const openModal = useCallback(() => {
    const selectValue = getValues("status");

    if (selectValue !== "EM_ANALISE") {
      setModalOpened(false);
    }
  }, [getValues]);

  // const [mensagensParaECAposTransacao, setMensagensParaECAposTransacao] = useState([]);

  // Faz a verificação das mensagens para exibir a resposta a essa transação
  /* useEffect(() => {
    async function verificarMensagem() {
      const dataTransacao = transacaoInfos.created_at !== undefined
        ? transacaoInfos.created_at.substring(0, 10)
        : '';
      
      const anoTransacao = dataTransacao.substring(0, 4);
      const mesTransacao = dataTransacao.substring(5, 7);
      // const diaTransacao = dataTransacao.substring(8, 10);

      const mensagensMaiorAno = mensagensParaEC.filter(item => Number(item.created_at.substring(0, 4)) >= Number(anoTransacao));
      const mensagensMaiorMes = mensagensMaiorAno.filter(item => Number(item.created_at.substring(5, 7)) >= Number(mesTransacao));
      // const mensagensMaiorDia = mensagensMaiorMes.filter(item => Number(item.created_at.substring(8, 10)) >= Number(diaTransacao));

      setMensagensParaECAposTransacao(mensagensMaiorMes);
    }

    verificarMensagem();
  }, [mensagensParaEC, transacaoInfos]); */

  // Faz o download do documento com foto
  async function downloadContaDeLuz() {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    await api
      .get(`/documento_download/${transacaoInfos.documento_conta_de_luz_id}`, auth)
      // .get(`/documento_download/f77d0128-60ea-4f3c-8d1c-3c7f8d15d798`, auth)
      .then((response) => {
        const result = response.data.data;

        // setComprovantePagamento(result.replace(`<img src= '`, '').replace(`' />`, ''))
        const linkSource = result;
        const downloadLink = document.createElement("a");
        const fileName = "conta_de_luz.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });

    setIsLoading(false);
  }

  // Faz o download do documento com foto
  async function downloadDocFoto() {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    await api
      .get(`/documento_download/${transacaoInfos.documento_pessoal_com_foto_id}`, auth)
      // .get(`/documento_download/6325f5b4-63e7-4758-9a77-77cbf4b8fa9d`, auth)
      .then((response) => {
        const result = response.data.data;

        // setComprovantePagamento(result.replace(`<img src= '`, '').replace(`' />`, ''))
        const linkSource = result;
        const downloadLink = document.createElement("a");
        const fileName = "documento_com_foto.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });

    setIsLoading(false);
  }

  return (
    <Container>
      <SendMessageComponent
        userID={userID}
        transactionID={transacaoInfos.id}
        transactionType={"TransacaoPagamentoContaDeLuz"}
        allowMessage={allowMessage}
      />

      <LoadingContent isLoading={isLoading} />

      <ModalSection modalOpened={modalOpened}>
        <ModalContent>
          <MdHighlightOff
            size={30}
            onClick={() => setModalOpened(!modalOpened)}
          />
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Aprovado aprovadoHide={aprovadoHide}>
              <span style={{ textAlign: "center" }}>
                Tem certeza que deseja aprovar essa transação?
              </span>
            </Aprovado>

            <Mensagem messageHide={messageHide}>
              <span>Digite um comentário para o Estabelecimento</span>

              <div className="inputArea">
                <div className="labelRow">
                  <label>Digite aqui</label>
                  {errors.message && (
                    <Error
                      title="Campo obrigatório"
                      style={{ marginLeft: "8px" }}
                    >
                      <MdErrorOutline
                        color="c53030"
                        size={20}
                        style={{ marginBottom: "8px" }}
                      />
                    </Error>
                  )}
                </div>
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="10"
                  ref={register({ required: requiredMessage })}
                  style={{ borderColor: errors.message && "red" }}
                />
              </div>
            </Mensagem>

            <Confirmar confirmarHide={confirmarHide}>
              <span>Tem certeza que deseja alterar o status da transação?</span>
            </Confirmar>

            <div className="buttonRow">
              <button type="submit">Enviar</button>
              <button
                className="cancelar"
                type="button"
                onClick={() => setModalOpened(!modalOpened)}
              >
                Cancelar
              </button>
            </div>
          </form>
        </ModalContent>
      </ModalSection>

      <MenuBar subMenuActive="Conta de Luz" />
      <NewMobileMenu active="AdminTransações" />

      <Page>
        <TopBar pageTitle="Transação -" pageSubtitle="Conta de Luz" />

        <Content>
          <RowBox1>
            <Box>
              <h3>Dados do Cliente</h3>

              <Row>
                <InputArea>
                  <label>Nome do Cliente</label>
                  <p>{transacaoCliente.name}</p>
                </InputArea>

                <InputArea>
                  <label>Data de Nascimento</label>
                  <p>{
                    transacaoCliente.data_aniversario === undefined
                      ? ''
                      : transacaoCliente.data_aniversario.split("-").reverse().join("/")
                  }</p>
                </InputArea>
              </Row>

              <Row>
                <InputArea>
                  <label>Número do CPF</label>
                  <p>{transacaoCliente.cpf}</p>
                </InputArea>

                <InputArea>
                  <label>Sexo</label>
                  <p>{sexo}</p>
                </InputArea>
              </Row>

              <Row>
                <InputArea>
                  <label>E-mail</label>
                  <p>{transacaoCliente.email}</p>
                </InputArea>

                <InputArea>
                  <label>Telefone</label>
                  <p>{transacaoCliente.numero_telefone_1}</p>
                </InputArea>
              </Row>
            
              <Row4>
                <InputArea>
                  <label>Número do RG</label>
                  <p>{transacaoCliente.rg}</p>
                </InputArea>

                <InputArea>
                  <label>Órgão Emissor</label>
                  <p>{transacaoCliente.rg_emissor}</p>
                </InputArea>

                <InputArea>
                  <label>UF</label>
                  <p>{transacaoCliente.rg_uf}</p>
                </InputArea>

                <InputArea>
                  <label>Data de Emissão</label>
                  <p>{
                    transacaoCliente.rg_data_emissao === undefined
                      ? ''
                      : transacaoCliente.rg_data_emissao.split("-").reverse().join("/")
                  }</p>
                </InputArea>
              </Row4>
            
              <Row4>
                <InputArea>
                  <label>Naturalidade</label>
                  <p>{transacaoCliente.naturalidade}</p>
                </InputArea>

                <InputArea>
                  <label>Nacionalidade</label>
                  <p>{transacaoCliente.nacionalidade}</p>
                </InputArea>

                <InputArea>
                  <label>Estado Civil</label>
                  <p>{transacaoCliente.estado_civil}</p>
                </InputArea>
              </Row4>
            
              <Row>
                <InputArea>
                  <label>Nome da Mãe</label>
                  <p>{transacaoCliente.nome_mae}</p>
                </InputArea>

                <InputArea>
                  <label>Nome do Pai</label>
                  <p>{transacaoCliente.nome_pai}</p>
                </InputArea>
              </Row>
            </Box>

            <div>
              <Box>
                <h3>Endereço</h3>

                <Row>
                  <InputArea>
                    <label>CEP</label>
                    <p>{transacaoClienteEndereco.cep}</p>
                  </InputArea>

                  <InputArea>
                    <label>Endereço</label>
                    <p>{transacaoClienteEndereco.rua}</p>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>Número</label>
                    <p>{transacaoClienteEndereco.numero}</p>
                  </InputArea>

                  <InputArea>
                    <label>Complemento</label>
                    <p>{transacaoClienteEndereco.complemento}</p>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>Bairro</label>
                    <p>{transacaoClienteEndereco.bairro}</p>
                  </InputArea>

                  <InputArea>
                    <label>Cidade</label>
                    <p>{transacaoClienteEndereco.cidade}</p>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>Estado</label>
                    <p>{transacaoClienteEndereco.uf}</p>
                  </InputArea>
                </Row>
              </Box>
            </div>

            <div>
              <Box>
                <h3>Dados Bancários</h3>
              
                <Row>
                  <InputArea>
                    <label>Banco</label>
                    <p>{transacaoClienteDadosBancarios.nome_banco}</p>
                  </InputArea>

                  <Flex>
                    <InputArea style={{ marginRight: 40 }}>
                      <label>Agência</label>
                      <p>{transacaoClienteDadosBancarios.agencia}</p>
                    </InputArea>

                    {/* <InputArea>
                      <label>Dígito</label>
                      <p>{transacaoClienteDadosBancarios.agencia}</p>
                    </InputArea> */}
                  </Flex>
                </Row>
              
                <Row>
                  <InputArea>
                    <label>Tipo de Conta</label>
                    <p>{transacaoClienteDadosBancarios.tipo_conta}</p>
                  </InputArea>

                  <Flex>
                    <InputArea style={{ marginRight: 40 }}>
                      <label>Número</label>
                      <p>{transacaoClienteDadosBancarios.numero_conta}</p>
                    </InputArea>

                    {/* <InputArea>
                      <label>Dígito</label>
                      <p>{transacaoClienteDadosBancarios.numero_conta}</p>
                    </InputArea> */}
                  </Flex>
                </Row>            
              </Box>
              
              <BlueBox style={{ marginTop: 30 }}>
                <h3>Valores da parcela</h3>
              
                <Row3> 
                  <InputArea>
                    <label>Valor da parcela</label>
                    <p>{transacaoInfos.numero_parcelas}x R$ {FormatarValor(valorParcela)}</p>
                  </InputArea>

                  <InputArea>
                    <label>Valor do Contrato</label>
                    <p>R$ {FormatarValor(valorPagamento)}</p>
                  </InputArea>

                  <InputArea>
                    <label>Valor total </label>
                    <p>R$ {FormatarValor(totalTransação)}</p>
                  </InputArea>
                </Row3>

                <Separator style={{ opacity: 0.3, marginTop: 20, marginBottom: 20 }} />
              
                <Row3>
                  <InputArea>
                    <label>Cia de Energia</label>
                    <p>{transacaoInfos.companhia}</p>
                  </InputArea>

                  <InputArea>
                    <label>Nº da Instalação</label>
                    <p>{transacaoInfos.numero_instalacao}</p>
                  </InputArea>

                  <InputArea>
                    <label>Data da Leitura</label>
                    <p>{transacaoInfos.data_leitura}</p>
                  </InputArea>
                </Row3>  
              </BlueBox>
            </div>

            <div>
              <Box className="referencias">
                <h3>Referências</h3>

                <InputArea>
                  <label>Nome</label>
                  <p>{transacaoCliente.contato_adicional_1_nome}</p>
                </InputArea>

                <InputArea>
                  <label>Endereço</label>
                  <p>{transacaoCliente.contato_adicional_1_endereco}</p>
                </InputArea>

                <InputArea>
                  <label>Telefone</label>
                  <p>{transacaoCliente.contato_adicional_1_numero_telefone}</p>
                </InputArea>

                <Separator />

                <InputArea>
                  <label>Nome</label>
                  <p>{transacaoCliente.contato_adicional_2_name}</p>
                </InputArea>

                <InputArea>
                  <label>Endereço</label>
                  <p>{transacaoCliente.contato_adicional_2_endereco}</p>
                </InputArea>

                <InputArea>
                  <label>Telefone</label>
                  <p>{transacaoCliente.contato_adicional_2_numero_telefone}</p>
                </InputArea>
              </Box>
            </div>
          </RowBox1>

          <RowBox1>
            <Box>
              <h3>Dados do Estabelecimento</h3>

              <Row>
                <InputArea>
                  <label>Razão social</label>
                  <p>{ecInfos.razao_social}</p>
                </InputArea>

                <InputArea>
                  <label>Nome fantasia</label>
                  <p>{ecInfos.nome_fantasia}</p>
                </InputArea>
              </Row>

              {/* <Separator />

              <h3 style={{ marginBottom: 6 }}>Mensagens enviadas a este Estabelecimento</h3>

              <div
                style={{
                  background: '#F1F5F9',
                  marginBottom: 20,
                  borderRadius: 6,
                  padding: 10,
                }}
              >
                <p style={{ fontSize: 14 }}>As mensagens listadas aqui foram enviadas após a data de criação desta transação.</p>
              </div>

              {mensagensParaECAposTransacao.map(item => (
                <Row>
                  <InputArea>
                    <label>Mensagem</label>
                    <p>{item.body}</p>
                  </InputArea>

                  <InputArea>
                    <label>Data de envio</label>
                    <p>{item.created_at.substring(0, 10).split('-').reverse().join('/')}</p>
                  </InputArea>
                </Row>
              ))} */}
            </Box>

            <Box>
              <h3>Anexos</h3>

              <InputArea>
                <label>Conta de Luz</label>
                {/* <p>{transacaoCliente.contato_adicional_2_numero_telefone}</p> */}
                <button
                  type="button"
                  className="downloadButton"
                  onClick={() => downloadContaDeLuz()}
                >
                  Fazer Download
                </button>
              </InputArea>

              <InputArea>
                <label>Documento com foto</label>
                <button
                  type="button"
                  className="downloadButton"
                  onClick={() => downloadDocFoto()}
                >
                  Fazer Download
                </button>
              </InputArea>
            </Box>
          </RowBox1>
          
          <SelectStatus editStatusHide={editStatusHide}>
            <form /* onSubmit={handleSubmit(onSubmit, onError)} */>
              <InputContainer statusColor={statusColor}>
                <select
                  name="status"
                  placeholder="Selecione"
                  ref={register({ required: true })}
                  onChange={changeStatus}
                >
                  <option value="EM_ANALISE">Em Análise</option>
                  {/* <option value="2">Solicitar documentos</option> */}
                  <option value="APROVADO">Aprovada</option>
                  <option value="CANCELADO">Cancelada</option>
                  <option value="REJEITADO">Rejeitada</option>
                  <option value="EM_TRATAMENTO">Em processamento</option>
                </select>
              </InputContainer>

              <button
                type="button"
                onClick={() => {
                  openModal();
                }}
              >
                Salvar
              </button>
            </form>
          </SelectStatus>
        </Content>
      </Page>
    </Container>
  );
};

export default AdminEditarContaDeLuz;
