import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Tooltip } from '@material-ui/core/';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';
import FormatarValor from '../../components/FormatarValor';

import FormAddLA from '../../components/FormAddLA';

import {
  MdAdd,
  MdRemove,
} from 'react-icons/md';
import editIcon from '../../assets/images/edit_icon.svg';

import api from '../../services/api';

import {
  Container,
  Page,
  Content,
  TableContent,
  AddLA,
  Button,
  FormSection,
  TableContentMobile,
  Header,
  Line,
  MobileBody,
} from './styles';

function AdminLicenciados() {
  const history = useHistory();
  const [formOpened, setFormOpened] = useState(true);

  const [licenciados, setLicenciados] = useState([]);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get('/list_la', auth).then(response => {
      const result = response.data;
      // console.log(result);

      setLicenciados(result)
    })

  }, [userToken]);

  // Informações Head da tabela
  const headData = [
    { title: 'Nome', field: 'nome', sorting: false },
    { title: 'Estabelecimento', field: 'estabelecimento', sorting: false },
    { title: 'Cidade de atuação', field: 'cidade', sorting: false },
    { title: 'Data criação', field: 'data', sorting: false },
    { title: 'ECs ativos', field: 'ec_ativos', sorting: false },
    { title: 'Saldo conta digital', field: 'saldo', sorting: false },
    { title: '', field: 'edit', align: 'right', sorting: false },
  ];

  // Informações Body da tabela
  const bodyData = licenciados.map(licenciado => {
    // const status = <Tooltip title={messageStatus} placement="top" arrow><span><MdFiberManualRecord color={colorStatus} /></span></Tooltip>

    // const saldoFinal = licenciado.saldo_conta_digital === null ? "Sem saldo" : `R$ ${saldoContaDigital}`;
    const saldoFinal = licenciado.saldo_conta_digital === null
      ? <span style={{ color: "#A6AAB4", fontWeight: 600 }}>R$ -</span>
      : <span style={{ color: "#0073FC", fontWeight: 600 }}>R$ {FormatarValor(licenciado.saldo_conta_digital)}</span>;;

    const dataCriacao = licenciado.created_at.substring(0, 10).split('-').reverse().join('/');
    const horaCriacao = licenciado.created_at.substring(11, 16);

    const dataEHora = <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{dataCriacao}</span>
      <span style={{ color: '#A6AAB4', fontSize: 14 }}>{horaCriacao}</span>
    </div>;

    return {
      nome: licenciado.nome,
      estabelecimento: licenciado.nome_fantasia_la,
      cidade: licenciado.cidade_atuacao,
      data: dataEHora,
      ec_ativos: licenciado.ec_ativos,
      saldo: saldoFinal,
      // status: status,
      edit: <Tooltip title="Editar Dados do LA" placement="top" arrow><span style={{ cursor: "pointer" }} onClick={() => editLA(licenciado)}><img src={editIcon} alt=""/></span></Tooltip>
    }
  });

  const editLA = useCallback((licenciado) => {
    const idReverse = licenciado.id.split('').reverse().join('');
    
    // redireciona para a página de edição
    history.push(`/admin/licenciados/editar/${idReverse}`);
  }, [history]);

  /* -------------------- FUNÇÕES PARA O MOBILE -------------------- */

  // Mostra o saldo da conta digital na lista mobile
  function saldoContaDigital(licenciado) {
    const saldo_conta_digital = licenciado.saldo_conta_digital !== null ? licenciado.saldo_conta_digital.toString().split('.') : "";
    const saldoContaDigital = `${saldo_conta_digital[0]},${saldo_conta_digital[1] ? saldo_conta_digital[1].substring(0, 2) : "00"}`;

    const saldoFinal = licenciado.saldo_conta_digital === null ? "Sem saldo" : `R$ ${saldoContaDigital}`;

    return saldoFinal
  };

  return (
    <Container>
      <MenuBar active="CadastroLA" />
      <NewMobileMenu active="CadastroLA" />

      <Page>
        <TopBar pageTitle="Cadastro de Licenciado" />

        <Content>
          
          <AddLA>
            <span>Adicionar Licenciado</span>
            <Button onClick={() => setFormOpened(!formOpened)}>
              {formOpened === true ? <MdAdd color="#fff" /> : <MdRemove color="#fff" />}
            </Button>
          </AddLA>

          <FormSection formOpened={formOpened}>
            <FormAddLA />
          </FormSection>

          <TableContent>
            
            {/* <Select
              placeholder="Status"
              onChange={(value) => filterByStatus(value)}
              className="filterStatusSelect"
              options={[
                { value: "0", label: 'Aprovado' },
                { value: "1", label: 'Em Analise' },
                { value: "2", label: 'Doc Pendentes' },
                { value: "3", label: 'Doc Checados' },
                { value: "4", label: 'Reprovado' },
                { value: "5", label: 'Todos' },
              ]}
            /> */}

            <MaterialTable
              title="Lista de Licenciados"
              columns={headData}
              data={bodyData}
              components={{
                Toolbar: props => (
                  <div>
                    <MTableToolbar {...props} />
                    
                  </div>
                )
              }}
              localization={{
                pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{from}-{to} de {count}',
                  firstTooltip: 'Primeira página',
                  previousTooltip: 'Página anterior',
                  nextTooltip: 'Próxima página',
                  lastTooltip: 'Última página'
                },
                toolbar: {
                  searchPlaceholder: 'Buscar',
                  searchTooltip: 'Buscar'
                },
                body: {
                  emptyDataSourceMessage: 'Nenhum dado encontrado',
                }
              }}
              options={{
                actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: '#10104F',
                  color: '#FFF'
                },
                emptyValue: "Nenhum dado encontrado"
              }}
            />

          </TableContent>

          <TableContentMobile>
            <Header>
              <h3>Lista de Licenciados</h3>

              <span>Total: {licenciados.length}</span>
            </Header>

            <MobileBody>
              {licenciados.map((licenciado) => (
                <Line key={licenciado.id}>
                  <span>{licenciado.nome}</span>
                  <p>{licenciado.cidade_atuacao}</p>
                  <p>ECs ativos: {licenciado.ec_ativos}</p>
                  <p>Conta digital: {saldoContaDigital(licenciado)}</p>
          
                  <div className="footer">
                    <span></span>
                    <img src={editIcon} alt="" onClick={() => editLA(licenciado)}/>
                  </div>
                </Line>
              ))}
            </MobileBody>
          </TableContentMobile>

        </Content>
      </Page>
    </Container>
  );
}

export default AdminLicenciados;