import styled, { css } from 'styled-components';

import backgroundImg from '../../assets/images/background_2.jpg';

import { shade } from 'polished';

import ToolTip from '../../components/Tooltip';

export const Container = styled.div``;

export const Page = styled.div`
  margin-left: 220px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  /* height: calc(100vh - 72px); */
  background: url(${backgroundImg}) no-repeat;
  background-position: top left;
  background-size: 100% 256px;
  box-sizing: border-box;

  padding: 100px 160px 60px 30px;

  @media (max-width: 500px) {
    padding: 50px 20px 20px;
  }
`;

export const TabContent = styled.div`
  background: #fff;
  border-radius: 8px;
`;

export const NavegaçãoAbas = styled.div`
  /* display: grid;
  grid-template-columns: repeat(5, 1fr); */

  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const BotãoAba = styled.div`
  width: 100%;
  max-width: 160px;

  padding: 20px 0;
  text-align: center;
  font-size: 20px;

  border-bottom: 2px solid #fff;

  ${props => props.tab1 && css`
    border-bottom: 2px solid #0073FC;
  `}

  ${props => props.tab2 && css`
    border-bottom: 2px solid #0073FC;
  `}

  ${props => props.tab3 && css`
    border-bottom: 2px solid #0073FC;
  `}

  ${props => props.tab4 && css`
    border-bottom: 2px solid #0073FC;
  `}
`;

export const Abas = styled.div`
  padding: 24px;
`;

export const Aba1 = styled.div`
  visibility: hidden;
  height: 0;

  ${props => props.tab1 && css`
    visibility: visible;
    height: 100%;
  `}
`;

export const Aba2 = styled.div`
  visibility: hidden;
  height: 0;

  ${props => props.tab2 && css`
    visibility: visible;
    height: 100%;
  `}
`;

export const Aba3 = styled.div`
  visibility: hidden;
  height: 0;

  ${props => props.tab3 && css`
    visibility: visible;
    height: 100%;
  `}
`;

export const Aba4 = styled.div`
  visibility: hidden;
  height: 0;

  ${props => props.tab4 && css`
    visibility: visible;
    height: 100%;
  `}
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  width: 100%;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const Row3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  width: 100%;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const Row4 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;

  width: 100%;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  
  > p {
    color: #0073FC;
    margin-bottom: 30px;

    &.link {
      cursor: pointer;
      text-decoration: underline;
      /* border-bottom: 1px solid #0073FC; */
    }

    @media (max-width: 500px) {
      margin-bottom: 20px;
    }
  }

  button.downloadButton {
    display: inline-flex;
    background: transparent;
    padding: 0;
    margin: 5px 0 0;
    color: #0073FC;
    border: none;
    outline-style: none;
    cursor: pointer;

    &:hover {
      background: transparent;
      color: ${shade(0.2, '#0073FC')}
    }
  }
`;

export const Separator = styled.div`
  border-bottom: 1px solid #efefef;
  margin: 30px 0;
  font-size: 20px;
`;

export const SelectStatus = styled.div`
  margin-top: 30px;

  display: flex;
  align-items: center;

  .sc-cjzMPC.dRiRcZ {
    margin: 0;
  }

  button {
    padding: 8px 22px;
    background: #0073FC;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;

    transition: 0.2s;
    outline-style: none;

    &:hover {
      background: ${shade(0.2, '#0073FC')}
    }
  }
`;

export const SelectInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 220px;
  height: 40px;

  background: #FEAC02;
  /* border: 1px solid #CCCCCC; */
  border-radius: 4px;
  box-sizing: border-box;

  margin-right: 30px;
  padding: 1px 11px 1px 1px;

  ${props => props.isFocused && css`
    border: 1px solid #0073FC;
  `}

  input {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;
  }

  select {
    border: none;
    background: #FEAC02;
    color: #fff;

    height: 34px;
    width: 100%;
    padding: 0 10px;

    ${props => props.statusColor === "0" && css`
      background: #5CB85C;
    `}

    ${props => props.statusColor === "1" && css`
      background: #FEAC02;
    `}

    ${props => props.statusColor === "2" && css`
      background: #5097FF;
    `}

    ${props => props.statusColor === "3" && css`
      background: #8850FF;
    `}

    ${props => props.statusColor === "4" && css`
      background: #F35757;
    `}
  }

  option {
    color: #000;
    background: #fff;
  }

  ${props => props.statusColor === "0" && css`
    background: #5CB85C;
  `}

  ${props => props.statusColor === "1" && css`
    background: #FEAC02;
  `}

  ${props => props.statusColor === "2" && css`
    background: #5097FF;
  `}

  ${props => props.statusColor === "3" && css`
    background: #8850FF;
  `}

  ${props => props.statusColor === "4" && css`
    background: #F35757;
  `}
`;

export const ModalSection = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(55, 58, 60, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 5;

  ${props => props.modalOpened && css`
    display: none;
  `}
`;

export const ModalContent = styled.div`
  color: #fff;
  background: #10104F;
  padding: 30px;
  border-radius: 8px;
  max-width: 640px;
  /* max-height: 420px; */

  width: 100%;
  /* height: 100%; */

  > svg {
    margin-left: calc(100% - 30px);
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      color: ${shade(0.2, '#fff')};
    }
  }

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 30px;

    @media (max-width: 500px) {
      font-size: 18px;
      display: block;
      margin-bottom: 20px;
    }
  }

  div.buttonRow {
    margin: 30px auto 0;
    text-align: center;

    button {
      padding: 8px 27px;
      background: #0073FC;
      border: 1px solid #0073FC;
      color: #FFFFFF;
      border: none;
      border-radius: 4px;

      transition: 0.2s;

      &:hover {
        background: ${shade(0.2, '#0073FC')};
      }
    }

    button.cancelar {
      padding: 8px 27px;
      background: #F5F8FB;
      border: 1px solid #ADADAD;
      color: #373A3C;
      border-radius: 4px;

      margin-left: 30px;
      transition: 0.2s;

      &:hover {
        background: ${shade(0.2, '#F5F8FB')};
      }
    }

    @media (max-width: 500px) {
      margin: 20px auto 0;
    }
  }

  @media (max-width: 500px) {
    width: 94%;
    padding: 20px;
    margin-bottom: 20px;
  }
`;

export const Aprovado = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props => props.aprovadoHide && css`
    display: none;
  `}

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 14px;

    &:last-child {
      margin-top: 14px;;
    }

    @media (max-width: 500px) {
      font-size: 18px;
      text-align: center;
    }
  }
`;

export const Mensagem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 30px;

  ${props => props.messageHide && css`
    display: none;
  `}

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 14px;

    &:last-child {
      margin-top: 14px;
    }

    @media (max-width: 500px) {
      font-size: 18px;
    }
  }

  div.inputArea {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 170px;

    textarea {
      border-radius: 4px;
    }

    div.labelRow {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 500px) {
    padding: 0;
  }
`;

export const Confirmar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props => props.confirmarHide && css`
    display: none;
  `}

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 14px;

    &:last-child {
      margin-top: 14px;;
    }

    @media (max-width: 500px) {
      font-size: 18px;
      text-align: center;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  box-sizing: border-box;

  margin-bottom: 18px;
  padding: 1px 11px 1px 1px;

  ${props => props.isErrored && css`
    border: 1px solid #c53030;
  `}

  ${props => props.isFocused && css`
    border: 1px solid #0073FC;
  `}

  input {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;
  }

  select {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;
  }
`;

export const Error = styled(ToolTip)`
  span {
    background: #c53030;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;

  button {
    padding: 8px 22px;
    width: 120px;
    background: #0073FC;
    border: 1px solid #0073FC;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;

    transition: 0.2s;

    &:hover {
      background: ${shade(0.2, '#0073FC')}
    }

    &.back {
      margin-left: 30px;
      /* margin-right: 20px; */

      width: 110px;
      color: #373A3C;
      background: #F5F8FB;
      border: 1px solid #ADADAD;

      &:hover {
        background: ${shade(0.1, '#F5F8FB')}
      }
    }
  }

  @media (max-width: 500px) {
    margin-top: 20px;
  }
`;

export const BoxInfo = styled.div`
  background: #F1F5F9;
  padding: 8px 16px;
  border-radius: 6px;
  margin-bottom: 16px;

  span {
    font-size: 14px;
  }

  &.modalBox {
    background: transparent;
    color: #F1F5F9;
    border: 1px solid #F1F5F9;
    margin: 10px 20px 10px;
  }
`;

export const SelectLa = styled.div`
  margin-top: 30px;
  padding: 25px 20px 7px;
  background: #fff;
  border-radius: 8px;
  width: 70%;

  ${props => props.LAHide && css`
    display: none;
  `}
`;

export const SelectCodigoRastreio = styled.div`
  margin-top: 30px;
  padding: 25px 20px 25px;
  background: #fff;
  border-radius: 8px;
  width: 100%;

  ${props => props.LAHide && css`
    display: none;
  `}
`;

export const Box = styled.div`
  margin-top: 30px;
  padding: 25px 20px;
  background: #fff;
  border-radius: 8px;
  width: 50%;

  h4 {
    margin-bottom: 10px;
  }

  li {
    /* list-style: none; */
    margin-left: 20px;
    margin-bottom: 5px;
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 11px;

  border: 1px solid #CCCCCC;
  border-radius: 4px;

  width: 200px;
`;
