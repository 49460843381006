import styled, { css } from 'styled-components';

import backgroundImg from '../../assets/images/background_2.jpg';

export const Container = styled.div``;

export const Page = styled.div`
  margin-left: 220px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  /* height: calc(100vh - 72px); */
  background: url(${backgroundImg}) no-repeat;
  background-position: top left;
  background-size: 100% 256px;
  box-sizing: border-box;

  padding: 30px 160px 40px 30px;

  @media (max-width: 500px) {
    padding: 50px 20px 20px;
  }
`;

export const NavegaçãoAbas = styled.div`
  /* display: grid;
  grid-template-columns: repeat(5, 1fr); */
  background: #ffffff;
  border-radius: 8px 8px 0 0;

  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const BotãoAba = styled.div`
  width: 100%;
  max-width: 160px;

  padding: 20px 0;
  text-align: center;
  font-size: 20px;

  border-bottom: 2px solid #fff;

  ${props => props.tab1 && css`
    border-bottom: 2px solid #0073FC;
  `}

  ${props => props.tab2 && css`
    border-bottom: 2px solid #0073FC;
  `}

  ${props => props.tab3 && css`
    border-bottom: 2px solid #0073FC;
  `}

  ${props => props.tab4 && css`
    border-bottom: 2px solid #0073FC;
  `}
`;

export const Box = styled.div`
  background: #FFFFFF;
  border-radius: 0 0 8px 8px;
  padding: 20px;
`;

export const Aba1 = styled.div`
  visibility: hidden;
  height: 0;

  ${props => props.tab1 && css`
    visibility: visible;
    height: 100%;
  `}
`;

export const Aba2 = styled.div`
  visibility: hidden;
  height: 0;

  ${props => props.tab2 && css`
    visibility: visible;
    height: 100%;
  `}
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  margin-bottom: 12px;

  span {
    font-size: 20px;
    font-weight: 600;
    color: #333333;
  }
`;

export const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  padding: 12px 0;
  /* margin-top: 12px; */

  border-top: 1px solid rgba(0, 0, 0, 0.07);

  span {
    font-size: 20px;
    font-weight: normal;
    color: #333333;
  }
`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: #333333;
`;
