import React from 'react';
import LoadingIcons from 'react-loading-icons';

import { Container } from './styles';

function LoadingContent({ isLoading }) {
  return (
    <Container isLoading={isLoading}>
      <LoadingIcons.Oval
        speed={1.2}
        style={{ width: 25, marginRight: 8 }}
        strokeWidth={4}
      />

      <span>Carregando conteúdo</span>
    </Container>
  );
};

export default LoadingContent;
