import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaSquareFull, FaRegHandshake } from "react-icons/fa";
import {
  FiCreditCard,
  FiDollarSign,
  FiFileText,
  FiTag,
  FiPercent,
  FiUsers,
} from "react-icons/fi";

import api from "../../services/api";

import logoImg from "../../assets/images/logo_white.png";
import transacaoIcon from "../../assets/images/transacao_new_icon.svg";
import cadastroLaIcon from "../../assets/images/licenciados_icon.svg";
import pagamentosIcon from "../../assets/images/pagamentos_new_icon.svg";
import calculadora from "../../assets/images/calculadora_new.svg";

import { Container, Logo, Nav, MenuItem, MenuItemPagamento, SubMenuItem } from "./styles";

function MenuBar({ active, subMenuActive }) {
  const loggedUser = JSON.parse(
    localStorage.getItem("@DeixaNoAzul:loggedUser")
  );
  // const loggedEstabelecimento = JSON.parse(localStorage.getItem('@DeixaNoAzul:estabelecimentoId'));

  const userToken = localStorage.getItem("@DeixaNoAzul:accessToken");

  const [pagamentosAbertos, setPagamentosAbertos] = useState([]);

  // const [displayContaDigitalEC, setDisplayContaDigitalEC] = useState('flex');

  useEffect(() => {
    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    if (loggedUser.role === "ROLE_ADMIN") {
      api.get("/pagamento", auth).then((response) => {
        const result = response.data;
        const pagamentosProcessando = result.filter(
          (item) => item.status === "PROCESSANDO"
        );
        // console.log('pagamentos', response);

        setPagamentosAbertos(pagamentosProcessando);
      });
    }

    /* if (loggedUser.role === "ROLE_EC") {

      if (loggedEstabelecimento.licenciado_autorizado_id === 'a1426c43-1680-41d4-a6aa-c142e0e461b2') {
        setDisplayContaDigitalEC('none');
      }
    } */
  }, [loggedUser.role, userToken]);

  const classNotify = pagamentosAbertos.length === 0 ? "hide notify" : "notify";

  const [acessoAdmin, setAcessoAdmin] = useState({
    transacoes: true,
    conta_luz: true,
    certificado_digital: true,
    fgts: true,
    prepago: false, // mudar pra true qnd liberar o e-prepag
    serasa: true,
    licenciados: true,
    revendedores: true,
    estabelecimentos: true,
    pagamentos: true,
    parceiros: true,
    colaboradores: true,
    taxas_simulacao: true,
    taxas_comissao: true,
  });

  const isBlocked = 
    loggedUser.email === "Bko@deixanoazul.com.br" ||
    loggedUser.email === "rogerio@one-consultoria.com" ||
    loggedUser.email === "ana.pascale@prismapromotora.com.br"
    ? true 
    : false;

  useEffect(() => {
    // Acesso exclusivo ao certificado digital
    if (
      loggedUser.email === "rogerio@one-consultoria.com" ||
      loggedUser.id === "269c9c16-fa72-445c-b437-24bb6f11c29a"
    ) {
      setAcessoAdmin({
        transacoes: false,
        conta_luz: false,
        certificado_digital: true,
        fgts: false,
        prepago: false,
        serasa: false,
        licenciados: false,
        revendedores: false,
        estabelecimentos: false,
        pagamentos: false,
        parceiros: false,
        colaboradores: false,
        taxas_simulacao: false,
        taxas_comissao: false,
      })
    }

    // Acesso exclusivo ao FGTS
    if (
      loggedUser.email === "ana.pascale@prismapromotora.com.br" ||
      loggedUser.id === "4006cba8-13f5-4e21-ba8b-30ddc26ca562"

    ) {
      setAcessoAdmin({
        transacoes: false,
        conta_luz: false,
        certificado_digital: false,
        fgts: true,
        prepago: false,
        licenciados: false,
        revendedores: false,
        estabelecimentos: false,
        pagamentos: false,
        parceiros: false,
        colaboradores: false,
        taxas_simulacao: false,
        taxas_comissao: false,
      })
    }
  }, [loggedUser.email, loggedUser.id]);

  return (
    <Container>
      <Logo>
        <img src={logoImg} alt="Deixa no azul" />
      </Logo>

      <Nav>
        <Link
          to="/ec"
          className={loggedUser.role === "ROLE_LA" ? "" : "disabled"}
        >
          <MenuItem className={active === "EC" ? "active" : ""}>
            <FiFileText color="#08BBE9" size={22} />
            <span>Estabelecimentos</span>
          </MenuItem>
        </Link>

        {/* Conta digital do LA */}
        <Link
          to="/contadigital/la"
          className={
            loggedUser.role !== "ROLE_LA"
              ? "disabled"
              : ""
          }
        >
          <MenuItem className={active === "CD" ? "active" : ""}>
            <FiDollarSign color="#08BBE9" size={22} />
            <span>Conta digital</span>
          </MenuItem>
        </Link>

        <Link
          to="/simulador"
          className={loggedUser.role === "ROLE_EC" ? "" : "disabled"}
        >
          <MenuItem className={active === "Simulador" ? "active" : ""}>
            <FiCreditCard color="#08BBE9" size={22} />
            {/* <img src={simuladorIcon} alt="Simulador" /> */}
            <span>Simulador</span>
          </MenuItem>
        </Link>

        <Link
          style={{ display: 'none' }}
          to="/eprepag"
          className={loggedUser.role === "ROLE_LA" ? "" : "disabled"}
        >
          <MenuItem className={active === "Transações" ? "active" : ""}>
            <img src={transacaoIcon} alt="Transações" />
            <span>Transações</span>
          </MenuItem>
        </Link>

        {/* Submenu */}
        <Link
          style={{ display: 'none' }}
          to="/eprepag"
          className={loggedUser.role === "ROLE_LA" ? "" : "disabled"}
        >
          <SubMenuItem style={{marginTop: -15}}>
            <FaSquareFull color={subMenuActive === "E-prepag" ? "0073FC" : "transparent"} size={10} />
            <span
              style={{
                fontWeight: subMenuActive === "E-prepag" ? "bold" : "normal"
              }}
            >
              E-prepag 
            </span>
          </SubMenuItem>
        </Link>

        <Link
          to="/transacoes"
          className={loggedUser.role === "ROLE_EC" ? "" : "disabled"}
        >
          <MenuItem className={active === "Transações" ? "active" : ""}>
            <img src={transacaoIcon} alt="Transações" />
            <span>Transações</span>
          </MenuItem>
        </Link>

        {/* Submenu */}
        <Link
          to="/transacoes"
          className={loggedUser.role === "ROLE_EC" ? "" : "disabled"}
        >
          <SubMenuItem style={{ marginBottom: 10, marginTop: -10 }}>
            <FaSquareFull color={subMenuActive === "Deixa no Azul" ? "0073FC" : "transparent"} size={10} />
            <span
              style={{
                fontWeight: subMenuActive === "Deixa no Azul" ? "bold" : "normal"
              }}
            >
              Deixa no Azul
            </span>
          </SubMenuItem>
        </Link>

        {/* Submenu */}
        <Link
          to="/conta-de-luz"
          className={loggedUser.role === "ROLE_EC" ? "" : "disabled"}
        >
          <SubMenuItem style={{ marginBottom: 10 }}>
            <FaSquareFull color={subMenuActive === "Conta de Luz" ? "0073FC" : "transparent"} size={10} />
            <span
              style={{
                fontWeight: subMenuActive === "Conta de Luz" ? "bold" : "normal"
              }}
            >
              Contas de Luz
            </span>
          </SubMenuItem>
        </Link>

        {/* Submenu */}
        <Link
          to="/certificado-digital"
          className={loggedUser.role === "ROLE_EC" ? "" : "disabled"}
        >
          <SubMenuItem>
            <FaSquareFull color={subMenuActive === "Certificado Digital" ? "0073FC" : "transparent"} size={10} />
            <span
              style={{
                fontWeight: subMenuActive === "Certificado Digital" ? "bold" : "normal"
              }}
            >
              Certificado Digital
            </span>
          </SubMenuItem>
        </Link>

        <Link
          style={{ display: "none" }}
          to="/eprepag"
          className={loggedUser.role === "ROLE_EC" ? "" : "disabled"}
        >
          <SubMenuItem style={{marginTop: -15}}>
            <FaSquareFull color={subMenuActive === "E-prepag" ? "0073FC" : "transparent"} size={10} />
            <span
              style={{
                fontWeight: subMenuActive === "E-prepag" ? "bold" : "normal"
              }}
            >
              E-prepag
            </span>
          </SubMenuItem>
        </Link>

        {/* Conta digital do EC */}
        <Link
          to="/contadigital/ec"
          className={
            loggedUser.role !== "ROLE_EC"
              ? "disabled"
              : ""
          }
        >
          <MenuItem className={active === "CD" ? "active" : ""}>
            <FiDollarSign color="#08BBE9" size={22} />
            <span>Conta digital</span>
          </MenuItem>
        </Link>
        
        <Link
          to="/ec/revendedor"
          className={loggedUser.role === "ROLE_RV" ? "" : "disabled"}
        >
          <MenuItem className={active === "EC" ? "active" : ""}>
            <FiFileText color="#08BBE9" size={22} />
            <span>Estabelecimentos</span>
          </MenuItem>
        </Link>

        {/* Conta digital do Revendedor */}
        <Link
          to="/contadigital/revendedor"
          className={
            loggedUser.role !== "ROLE_RV"
              ? "disabled"
              : ""
          }
        >
          <MenuItem className={active === "CD" ? "active" : ""}>
            <FiDollarSign color="#08BBE9" size={22} />
            <span>Conta digital</span>
          </MenuItem>
        </Link>

        {/* ADMIN */}
        <Link
          to={
            loggedUser.id !== "4006cba8-13f5-4e21-ba8b-30ddc26ca562" && loggedUser.id !== "269c9c16-fa72-445c-b437-24bb6f11c29a"
              ? "/admin/transacoes"
              : "/loading"
          }
          // to="/admin/transacoes-novas"
          className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
        >
          <MenuItem className={active === "AdminTransações" ? "active" : ""}  style={{ marginBottom: 15 }}>
            <img src={transacaoIcon} alt="Transações" />
            <span>Transações</span>
          </MenuItem>
        </Link>

        {/* Submenu */}
        {acessoAdmin.transacoes && (
          <Link
            to="/admin/transacoes"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <SubMenuItem style={{ marginBottom: 10 }}>
              <FaSquareFull color={subMenuActive === "Deixa no Azul" ? "0073FC" : "transparent"} size={10} />
              <span
                style={{
                  fontWeight: subMenuActive === "Deixa no Azul" ? "bold" : "normal"
                }}
              >
                Deixa no Azul
              </span>
            </SubMenuItem>
          </Link>
        )}

        {/* Submenu */}
        {acessoAdmin.conta_luz && (
          <Link
            to="/admin/conta-luz"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <SubMenuItem style={{ marginBottom: 10 }}>
              <FaSquareFull color={subMenuActive === "Conta de Luz" ? "0073FC" : "transparent"} size={10} />
              <span
                style={{
                  fontWeight: subMenuActive === "Conta de Luz" ? "bold" : "normal"
                }}
              >
                Contas de Luz
              </span>
            </SubMenuItem>
          </Link>
        )}

        {/* Submenu */}
        {acessoAdmin.certificado_digital && (
          <Link
            to="/admin/certificado-digital"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <SubMenuItem>
              <FaSquareFull color={subMenuActive === "Certificado Digital" ? "0073FC" : "transparent"} size={10} />
              <span
                style={{
                  fontWeight: subMenuActive === "Certificado Digital" ? "bold" : "normal"
                }}
              >
                Certificado Digital
              </span>
            </SubMenuItem>
          </Link>
        )}

        {/* Submenu */}
        {acessoAdmin.fgts && (
          <Link
            to="/admin/saque-fgts"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <SubMenuItem style={{marginTop: -15}}>
              <FaSquareFull color={subMenuActive === "Saque FGTS" ? "0073FC" : "transparent"} size={10} />
              <span
                style={{
                  fontWeight: subMenuActive === "Saque FGTS" ? "bold" : "normal"
                }}
              >
                Saque FGTS
              </span>
            </SubMenuItem>
          </Link>
        )}

        {/* Submenu */}
        {acessoAdmin.prepago && (
          <Link
            style={{ display: 'none' }}
            to="/admin/eprepag"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <SubMenuItem style={{marginTop: -15}}>
              <FaSquareFull color={subMenuActive === "E-prepag" ? "0073FC" : "transparent"} size={10} />
              <span
                style={{
                  fontWeight: subMenuActive === "E-prepag" ? "bold" : "normal"
                }}
              >
                Prepago
              </span>
            </SubMenuItem>
          </Link>
        )}

        {/* Submenu */}
        {acessoAdmin.serasa && (
          <Link
            style={{ display: 'none' }}
            to="/admin/consulta-serasa"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <SubMenuItem style={{ marginTop: -15 }}>
              <FaSquareFull color={subMenuActive === "Consulta Serasa" ? "0073FC" : "transparent"} size={10} />
              <span
                style={{
                  fontWeight: subMenuActive === "Consulta Serasa" ? "bold" : "normal"
                }}
              >
                Consulta Serasa
              </span>
            </SubMenuItem>
          </Link>
        )}
        
        {acessoAdmin.licenciados && (
          <Link
            to="/admin/licenciados"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <MenuItem className={active === "CadastroLA" ? "active" : ""}>
              <img src={cadastroLaIcon} alt="Cadastro LA" />
              <span>Licenciados</span>
            </MenuItem>
          </Link>
        )}

        {acessoAdmin.revendedores && (
          <Link
            to="/admin/revendedor"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <MenuItem className={active === "Revendedores" ? "active" : ""}>
              <FiTag color="#08BBE9" size={22} />
              <span>Revendedores</span>
            </MenuItem>
          </Link>
        )}

        {acessoAdmin.estabelecimentos && (
          <Link
            to="/admin/estabelecimentos"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <MenuItem className={active === "AnaliseEC" ? "active" : ""}>
              <FiFileText color="#08BBE9" size={22} />
              <span>Estabelecimentos</span>
            </MenuItem>
          </Link>
        )}

        {acessoAdmin.pagamentos && (
          <Link
            to="/admin/pagamentos"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <MenuItemPagamento
              className={active === "Pagamentos" ? "active" : ""}
            >
              <div className={classNotify}>
                <span>
                  {pagamentosAbertos.length < 100
                    ? pagamentosAbertos.length
                    : "99+"}
                </span>
              </div>

              <img src={pagamentosIcon} alt="Pagamentos" style={{ width: 22 }} />
              <span>Pagamentos</span>
            </MenuItemPagamento>
          </Link>
        )}

        {acessoAdmin.parceiros && (
          <Link
            to="/admin/parceiros"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <MenuItemPagamento
              className={active === "Parceiros" ? "active" : ""}
            >
              <FaRegHandshake color="#08BBE9" size={22} />
              <span>Parceiros</span>
            </MenuItemPagamento>
          </Link>
        )}

        {acessoAdmin.colaboradores && (
          <Link
            to="/admin/colaboradores"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <MenuItemPagamento
              className={active === "Colaboradores" ? "active" : ""}
            >
              <FiUsers color="#08BBE9" size={22} />
              <span>Colaboradores</span>
            </MenuItemPagamento>
          </Link>
        )}

        {!isBlocked && (
          <Link
            to="/admin/taxadesimulacao"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <MenuItemPagamento
              className={active === "TaxaDeSilumacao" ? "active" : ""}
            >
              <FiPercent color="#08BBE9" size={22} />
              <span>Taxas de Simulação</span>
            </MenuItemPagamento>
          </Link>
        )}

        {/* Taxas de Comissão */}
        {!isBlocked && (
          <Link
            to="/admin/taxa-comissao/deixa-no-azul"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <MenuItem
              className={active === "TaxaComissão" ? "active" : ""}
            >
              <img style={{ width: 18 }} src={calculadora} alt="TaxaComissao" />
              <span>Taxas de Comissão</span>
            </MenuItem>
          </Link>
        )}

        {/* Submenu */}
        {!isBlocked && (
          <Link
            to="/admin/taxa-comissao/deixa-no-azul"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <SubMenuItem style={{marginTop: -15}}>
              <FaSquareFull color={subMenuActive === "Taxa de Comissão - Deixa no Azul" ? "0073FC" : "transparent"} size={10} />
              <span
                style={{
                  fontWeight: subMenuActive === "Taxa de Comissão - Deixa no Azul" ? "bold" : "normal"
                }}
              >
                Deixa no Azul
              </span>
            </SubMenuItem>
          </Link>
        )}

        {/* Submenu */}
        {!isBlocked && (
          <Link
            to="/admin/taxa-comissao/conta-de-luz"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <SubMenuItem style={{marginTop: -15}}>
              <FaSquareFull color={subMenuActive === "Taxa de Comissão - Contas de Luz" ? "0073FC" : "transparent"} size={10} />
              <span
                style={{
                  fontWeight: subMenuActive === "Taxa de Comissão - Contas de Luz" ? "bold" : "normal"
                }}
              >
                Contas de Luz
              </span>
            </SubMenuItem>
          </Link>
        )}

        {/* Submenu */}
        {!isBlocked && (
          <Link
            to="/admin/taxa-comissao/certificado-digital"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <SubMenuItem style={{marginTop: -15}}>
              <FaSquareFull color={subMenuActive === "Taxa de Comissão - Certificado Digital" ? "0073FC" : "transparent"} size={10} />
              <span
                style={{
                  fontWeight: subMenuActive === "Taxa de Comissão - Certificado Digital" ? "bold" : "normal"
                }}
              >
                Certificado Digital
              </span>
            </SubMenuItem>
          </Link>
        )}

        {/* Submenu */}
        {!isBlocked && (
          <Link
            // to="/admin/taxacomissaofgts"
            to="/admin/taxa-comissao/saque-fgts"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <SubMenuItem style={{marginTop: -15}}>
              <FaSquareFull color={subMenuActive === "Taxa de Comissão - Saque FGTS" ? "0073FC" : "transparent"} size={10} />
              <span
                style={{
                  fontWeight: subMenuActive === "Taxa de Comissão - Saque FGTS" ? "bold" : "normal"
                }}
              >
                Saque FGTS
              </span>
            </SubMenuItem>
          </Link>
        )}

        {/* Submenu */}
        {/* <Link
          to="/admin/taxacomissaoeprepag"
          className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
        >
          <SubMenuItem style={{marginTop: -15}}>
            <FaSquareFull color={subMenuActive === "E-prepag Taxas" ? "0073FC" : "transparent"} size={10} />
            <span
              style={{
                fontWeight: subMenuActive === "E-prepag Taxas" ? "bold" : "normal"
              }}
            >
              E-prepag
            </span>
          </SubMenuItem>
        </Link> */}

        {/* PARCEIRO */}
        <Link
          to="/parceiro/transacoes"
          className={loggedUser.role === "ROLE_PT" ? "" : "disabled"}
        >
          <MenuItem className={active === "AdminTransações" ? "active" : ""}  style={{ marginBottom: 15 }}>
            <img src={transacaoIcon} alt="Transações" />
            <span>Transações</span>
          </MenuItem>
        </Link>

        {/* Submenu */}
        <Link
          to="/parceiro/transacoes"
          className={loggedUser.role === "ROLE_PT" ? "" : "disabled"}
        >
          <SubMenuItem style={{ marginBottom: 10 }}>
            <FaSquareFull color={subMenuActive === "Deixa no Azul" ? "0073FC" : "transparent"} size={10} />
            <span
              style={{
                fontWeight: subMenuActive === "Deixa no Azul" ? "bold" : "normal"
              }}
            >
              Deixa no Azul
            </span>
          </SubMenuItem>
        </Link>

        {/* Submenu */}
        <Link
          to="/parceiro/conta-luz"
          className={loggedUser.role === "ROLE_PT" ? "" : "disabled"}
        >
          <SubMenuItem style={{ marginBottom: 10 }}>
            <FaSquareFull color={subMenuActive === "Conta de Luz" ? "0073FC" : "transparent"} size={10} />
            <span
              style={{
                fontWeight: subMenuActive === "Conta de Luz" ? "bold" : "normal"
              }}
            >
              Contas de Luz
            </span>
          </SubMenuItem>
        </Link>

        {/* Submenu */}
        <Link
          to="/parceiro/certificado-digital"
          className={loggedUser.role === "ROLE_PT" ? "" : "disabled"}
        >
          <SubMenuItem>
            <FaSquareFull color={subMenuActive === "Certificado Digital" ? "0073FC" : "transparent"} size={10} />
            <span
              style={{
                fontWeight: subMenuActive === "Certificado Digital" ? "bold" : "normal"
              }}
            >
              Certificado Digital
            </span>
          </SubMenuItem>
        </Link>

        {/* Submenu */}
        <Link
          to="/parceiro/saque-fgts"
          className={loggedUser.role === "ROLE_PT" ? "" : "disabled"}
        >
          <SubMenuItem>
            <FaSquareFull color={subMenuActive === "Saque FGTS" ? "0073FC" : "transparent"} size={10} />
            <span
              style={{
                fontWeight: subMenuActive === "Saque FGTS" ? "bold" : "normal"
              }}
            >
              Saque FGTS
            </span>
          </SubMenuItem>
        </Link>

        <Link
          to="/parceiro/licenciados"
          className={loggedUser.role === "ROLE_PT" ? "" : "disabled"}
        >
          <MenuItem className={active === "CadastroLA" ? "active" : ""}>
            <img src={cadastroLaIcon} alt="Cadastro LA" />
            <span>Licenciados</span>
          </MenuItem>
        </Link>

        <Link
          to="/parceiro/revendedores"
          className={loggedUser.role === "ROLE_PT" ? "" : "disabled"}
        >
          <MenuItem className={active === "Revendedores" ? "active" : ""}>
            <FiTag color="#08BBE9" size={22} />
            <span>Revendedores</span>
          </MenuItem>
        </Link>

        <Link
          to="/parceiro/estabelecimentos"
          className={loggedUser.role === "ROLE_PT" ? "" : "disabled"}
        >
          <MenuItem className={active === "AnaliseEC" ? "active" : ""}>
            <FiFileText color="#08BBE9" size={22} />
            <span>Estabelecimentos</span>
          </MenuItem>
        </Link>
        
        {/* Conta digital do Parceiro */}
        <Link
          // style={{ display: 'none' }}
          to="/parceiro/contadigital"
          className={
            loggedUser.role !== "ROLE_PT"
              ? "disabled"
              : ""
          }
        >
          <MenuItem className={active === "CD" ? "active" : ""}>
            <FiDollarSign color="#08BBE9" size={22} />
            <span>Conta digital</span>
          </MenuItem>
        </Link>
      </Nav>
    </Container>
  );
}

export default MenuBar;
