import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MdErrorOutline } from 'react-icons/md';
import LoadingIcons from 'react-loading-icons'

import { useAuth } from "../../hooks/AuthContext";
import { useToast } from "../../hooks/ToastContext";

// import backgroundImg from "../../assets/images/img-background-login.png";
import backgroundImg from "../../assets/images/background_login.png";
import logoImg from "../../assets/images/logo.png";
// import loading from "../../assets/images/loading3.gif";

import {
  Container,
  BackgroundImg,
  Content,
  Box,
  RememberAndForgot,
  Remember,
  InputArea,
  InputContainer,
  Error,
} from "./styles";

function SignIn() {
  const { signIn } = useAuth();
  const { addToast } = useToast();
  // const history = useHistory();

  // const [entrarDisplay, setEntrarDisplay] = useState("block");
  // const [loadingDisplay, setLoadingDisplay] = useState("none");

  const [loading, setLoading] = useState(false); // Default false

  const {
    register,
    handleSubmit,
    errors,
  } = useForm();
  const onSubmit = async (data) => {
    // console.log(data);
    setLoading(true);

    try {
      const ultimoCaracter = data.email.slice(-1);

      if (ultimoCaracter === ' ') {
        await signIn({
          email: data.email.substring(0, data.email.length - 1),
          password: data.password,
        });
      } else {
        await signIn({
          email: data.email,
          password: data.password,
        });
      }

      setLoading(false);
    } catch (error) {
      console.log(error.response);

      setLoading(false);

      addToast({
        type: "error",
        title: "Erro na autenticação",
        description: "Ocorreu um erro ao fazer login, cheque as credenciais.",
      });
    }

  };
  const onError = (errors) => {
    addToast({
      type: 'error',
      title: 'Erro no envio do formulário',
      description: 'Você precisa preencher todos os campos obrigatórios (marcados com *).',
    });
  };

  /* const handleSubmitOld = useCallback(
    async (data) => {
      setEntrarDisplay("none");
      setLoadingDisplay("block");

      // console.log(data.email.substring(0, data.email.length - 1));
      // console.log(data.email.slice(-1));

      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required("E-mail obrigatório"),
          password: Yup.string().required("Senha obrigatória"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const ultimoCaracter = data.email.slice(-1);

        if (ultimoCaracter === ' ') {
          await signIn({
            email: data.email.substring(0, data.email.length - 1),
            password: data.password,
          });
        } else {
          await signIn({
            email: data.email,
            password: data.password,
          });
        }

        setEntrarDisplay("block");
        setLoadingDisplay("none");
        // history.push('/ec');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);
        }

        setEntrarDisplay("block");
        setLoadingDisplay("none");

        addToast({
          type: "error",
          title: "Erro na autenticação",
          description: "Ocorreu um erro ao fazer login, cheque as credenciais.",
        });
      }
    },
    [signIn, addToast]
  ); */

  const anoAtual = new Date().getFullYear();

  return (
    <Container>
      <BackgroundImg>
        <img src={backgroundImg} alt="Deixa no azul" />
      </BackgroundImg>

      <Content>
        <Box>
          <img src={logoImg} alt="Deixa no Azul" />

          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <InputArea>
              <label>E-mail</label>
              <InputContainer style={{ borderColor: errors.email && "red" }}>
                <input
                  name="email" 
                  type="text" 
                  // placeholder="Digite seu e-mail"
                  ref={register({ required: true })}
                />
                {errors.email && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
              </InputContainer>
            </InputArea>

            <InputArea>
              <label>Senha</label>
              <InputContainer style={{ borderColor: errors.password && "red" }}>
                <input
                  name="password" 
                  type="password" 
                  // placeholder="Digite seu e-mail"
                  ref={register({ required: true })}
                />
                {errors.password && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
              </InputContainer>
            </InputArea>

            <RememberAndForgot>
              <Remember>
                <input
                  type="checkbox"
                  id="remember"
                  name="remember"
                  value="Remember"
                />
                <label htmlFor="remember">Lembrar</label>
              </Remember>

              <Link to="/forgotpassword">Esqueci minha senha</Link>
            </RememberAndForgot>

            <button type="submit">
              {loading ? (
                <>
                  <LoadingIcons.Oval
                    speed={1.2}
                    style={{ width: 25, marginRight: 8 }}
                    strokeWidth={3.5}
                  />
                  <span>Entrando</span>
                </>
              ) : (
                <span>Entrar</span>
              )}
            </button>

            <Link
              to="/politicaprivacidade"
              style={{ color: "#7D7D7D", marginTop: 20 }}
            >
              Política de Privacidade
            </Link>
          </form>
        </Box>

        <span>© {anoAtual}. Todos os direitos reservados</span>
      </Content>
    </Container>
  );
}

export default SignIn;
