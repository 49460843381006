import styled from 'styled-components';

import backgroundImg from '../../assets/images/background.jpg';

export const Container = styled.div`
  .abled {
    cursor: pointer;
  }

  .disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export const Page = styled.div`
  margin-left: 220px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  /* height: calc(100vh - 72px); */
  background: url(${backgroundImg}) no-repeat;
  background-position: top left;
  background-size: 100% 256px;
  box-sizing: border-box;

  padding: 100px 160px 60px 30px;

  @media (max-width: 500px) {
    padding: 50px 20px 20px;
  }
`;

export const TableContent = styled.div`
  position: relative;
  
  th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-paddingCheckbox {
    background: #10104F;
  }

  span.MuiButtonBase-root.MuiTableSortLabel-root.Mui-disabled {
    color: transparent;
  }

  .MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.Component-paginationToolbar-2.MuiToolbar-gutters {
    background-color: #fff;
  }

  tr.MuiTableRow-root {
    border: none;
  }

  tr.MuiTableRow-root:nth-of-type(odd) {
    background-color: #F5F5F5;
  }

  .MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-5.MuiToolbar-gutters {
    padding-bottom: 20px;
    height: 100px;
  }

  .MuiToolbar-regular {
    min-height: 84px;
  }
  
  .css-2b097c-container {
    /* position: absolute; */
    /* box-sizing: border-box; */
    width: 140px;
    /* top: 23px; */
    /* right: 200px; */
    /* z-index: 50; */
  }
  
  .css-2b097c-container.filterStatusSelect2 {
    /* position: absolute; */
    /* box-sizing: border-box; */
    width: 160px;
    margin-left: 20px;
    /* top: 23px; */
    /* right: 25px; */
    /* z-index: 50; */
  }

  .MuiInput-underline:before {
    border-bottom: none;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const FilterHeader = styled.span`
  /* position: absolute; */
  /* box-sizing: border-box; */
  /* width: 140px; */
  /* top: 26px; */
  /* right: 360px; */
  /* z-index: 50; */
  margin-right: 20px;

  font-size: 20px;
  font-weight: 600;
  color: #373A3C;
`;

export const FilterArea = styled.div`
  position: absolute;
  box-sizing: border-box;
  /* width: 400px; */
  top: 25px;
  right: 25px;
  z-index: 50;

  display: flex;
  align-items: center;
`;
