import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyCab1m-0AcMwDRplsgkpNngn59mIeEhgWQ",
  authDomain: "deixa-no-azul-estabelecimentos.firebaseapp.com",
  projectId: "deixa-no-azul-estabelecimentos",
  storageBucket: "deixa-no-azul-estabelecimentos.appspot.com",
  messagingSenderId: "410406052496",
  appId: "1:410406052496:web:974d27f9b664319092ae15",
  measurementId: "G-FHQ1LH2R7B"
});

export const firebaseDatabase = getFirestore(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);