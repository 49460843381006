import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { MdErrorOutline, MdHighlightOff, MdGetApp } from 'react-icons/md';
import { Tooltip } from '@material-ui/core/';
import filesize from 'filesize';
import { jsPDF } from "jspdf";
import { uniqueId } from "lodash";
import moment from 'moment';

import api from '../../services/api';

// Context API
import { useToast } from '../../hooks/ToastContext';
import { useSimulator } from '../../hooks/SimulatorContext';
// import { useUserSimulatorInfos } from '../../hooks/UserSimulatorInfosContext';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import InfoClient from '../../components/InfoClient';
import NewMobileMenu from '../../components/NewMobileMenu';

// Comprovante
import UploadComprovante from '../../components/Upload/comprovante';
import InfoComprovante from '../../components/UploadInfo/comprovante';

// Termo
import UploadTermo from '../../components/Upload/termo';
import InfoTermo from '../../components/UploadInfo/termo';

// Boleto
import UploadBoleto from '../../components/Upload/boleto';
import InfoBoleto from '../../components/UploadInfo/boleto';

// Documento com foto
import UploadDocFoto from '../../components/Upload/docFoto';
import InfoDocFoto from '../../components/UploadInfo/docFoto';

// JSONs
import BankList from '../../assets/json/bancos.json';
import CodigoBarrasIcon from '../../assets/images/codigo_barras.svg';

// Imagens
import logoImg from '../../assets/images/logo.png';
import loading from '../../assets/images/loading.gif';

// Máscaras
import contaBancariaMask from '../../components/InputMasks/contaBancariaMask';
import cpfMask from '../../components/InputMasks/cpfMask';
import rgMask from '../../components/InputMasks/rgMask';
import cnpjMask from '../../components/InputMasks/cnpjMask';
import telefoneMask from '../../components/InputMasks/telefoneMask';

import {
  Container,
  Page,
  Content,
  RowBox1,
  Box,
  BoletoOptions,
  InputArea,
  Row,
  Row3,
  RowBox2,
  RowBox3,
  InputContainer,
  Error,
  ModalSection,
  ModalContent,
  ModalClientName,
  ModalValueSection,
  ModalValueArea,
  UploadContainer,
  RowBoxBoleto,
  RowBoleto,
  BoxInfo,
  ModalSectionDados,
  ModalContentDados,
  ModalSectionBoleto,
  ModalContentBoleto,
  RowBoxBoletoModal,
  LoadingContainer,
} from './styles';

function CriarTransacao() {
  const { addToast } = useToast();
  const { simulator, setSimulator } = useSimulator();

  const [loadingHide, setLoadingHide] = useState(true);

  // const { userSimulatorInfos } = useUserSimulatorInfos();

  const { register, handleSubmit, getValues, setValue, setError, clearErrors, errors } = useForm();
  const onSubmit = async (data) => {

    // Valida se a página foi atualizada e os valores do simulator se perderam
    if (
      simulator.numero_parcela === "" ||
      simulator.valor_pagamento_cliente === "" ||
      simulator.valor_total_ec === "" ||
      simulator.bandeira === ""
    ) {

      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description: 'Os valores do simulador podem ter sido modificados, volte novamente à página de simulação',
      });

      return;
    };

    // Valida se algum boleto foi anexado corretamente
    if (data.tipo_transacao === "Boleto" && boletos.length === 0) {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description: 'Você precisa enviar um boleto para finalizar a transação.',
      });

      return;
    };
    
    // Bearer token
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    // UPLOADS DE ARQUIVOS
    // Verifica se um comprovante foi anexado
    if (uploadedComprovante.length === 0) {
      setError("comprovante", {
        type: "manual",
        message: "Campo obrigatório."
      });

      addToast({
        type: 'error',
        title: 'Erro na criação',
        description: 'Você precisa anexar um comprovante.',
      });

      return;
    };

    // Verifica se um termo foi anexado
    if (uploadedTermo.length === 0) {
      setError("termo", {
        type: "manual",
        message: "Campo obrigatório."
      });

      addToast({
        type: 'error',
        title: 'Erro na criação',
        description: 'Você precisa anexar um termo.',
      });

      return;
    };

    // Verifica se um documento com foto foi anexado
    if (uploadedDocFoto.length === 0) {
      setError("docFoto", {
        type: "manual",
        message: "Campo obrigatório."
      });

      addToast({
        type: 'error',
        title: 'Erro na criação',
        description: 'Você precisa anexar um documento com foto.',
      });

      return;
    };

    setLoadingHide(false);

    // Faz o upload do comprovante
    if (uploadedComprovante.length > 0) {

      const data = new FormData();

      uploadedComprovante.forEach(item => {
        data.append(`image${uploadedComprovante.indexOf(item)}`, item.file);
      });
      
      await api.post('/documento_upload_mult', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization : "Bearer "+userToken,
        },
        onUploadProgress: e => {
          setProgressComprovante(parseInt(Math.round((e.loaded * 100) / e.total)))
        }
      }).then(response => {
        setStatusComprovante({
          uploaded: true,
          error: false
        })

        const value = response.data.data;
        setValue("comprovante", value.id);

      }).catch(() => {
        // setLoadingHide(true);

        setStatusComprovante({
          uploaded: false,
          error: true
        })

        return;
      });

      if (statusComprovante.error === true) {
        addToast({
          type: 'error',
          title: 'Erro no upload',
          description: 'Encontramos um erro no upload do comprovante, tente novamente.',
        });

        return;
      }

    };

    // Faz o upload do termo
    if (uploadedTermo.length > 0) {

      // Envia para a API
      const data = new FormData();

      uploadedTermo.forEach(item => {
        data.append(`image${uploadedTermo.indexOf(item)}`, item.file);
      });

      await api.post('/documento_upload_mult', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization : "Bearer "+userToken,
        },
        onUploadProgress: e => {
          setProgressTermo(parseInt(Math.round((e.loaded * 100) / e.total)))
        }
      }).then(response => {
        setStatusTermo({
          uploaded: true,
          error: false
        })

        const value = response.data.data;
        setValue("termo", value.id);
        
      }).catch(() => {
        setLoadingHide(true);

        setStatusTermo({
          uploaded: false,
          error: true
        })
      });

      if (statusTermo.error === true) {
        addToast({
          type: 'error',
          title: 'Erro no upload',
          description: 'Encontramos um erro no upload do comprovante, tente novamente.',
        });

        return;
      }

    };

    // Faz o upload do documento com foto
    if (uploadedDocFoto.length > 0) {
      
      // Envia para a API
      const data = new FormData();

      uploadedDocFoto.forEach(item => {
        data.append(`image${uploadedDocFoto.indexOf(item)}`, item.file);
      });

      await api.post('/documento_upload_mult', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization : "Bearer "+userToken,
        },
        onUploadProgress: e => {
          setProgressDocFoto(parseInt(Math.round((e.loaded * 100) / e.total)))
        }
      }).then(response => {
        setStatusDocFoto({
          uploaded: true,
          error: false
        })

        const value = response.data.data;
        setValue("docFoto", value.id);

      }).catch(() => {
        setLoadingHide(true);

        setStatusDocFoto({
          uploaded: false,
          error: true
        })
      });

      if (statusDocFoto.error === true) {
        addToast({
          type: 'error',
          title: 'Erro no upload',
          description: 'Encontramos um erro no upload do comprovante, tente novamente.',
        });

        return;
      }

    };

    setLoadingHide(true);

    const quantidade_boleto = data.tipo_transacao === "Depósito bancário" ? 0 : getValues('quantidade_boletos');

    const taxa = simulator.taxa_servico.replace('%', '');

    try {
      // MÉTODO NOVO ///////////////////////////////////////////////////////
      api.post('/transacao', {
        estabelecimento_id: estabelecimentoID,
        numero_autorizacao: '-',
        numero_parcelas: simulator.quantidade_parcelas,
        valor_pagamento: simulator.valor_pagamento,
        valor_acrescimo: simulator.valor_acrescimo,
        bandeira: simulator.bandeira,
        quantidade_boleto: quantidade_boleto,
        status: 1,
        //
        id_doc_autorizacao: getValues("comprovante"),
        id_doc_termo: getValues("termo"),
        // id_doc_boleto: data.docBoleto,
        id_doc_documento_foto: getValues("docFoto"),
        //
        nome: data.nome,
        email: data.email,
        cpf: data.cpf,
        rg: data.numero_rg,
        taxa_servico: taxa,
        valor_final: simulator.valor_total,
        conjunto_de_taxa: simulator.conjunto_de_taxa,
        maquineta: simulator.maquineta,
        valor_parcela: simulator.valor_parcela,
        valor_total: simulator.valor_total,
        //
        telefone: data.telefone,
      }, auth).then(response => {
        console.log('transação:', response);
        const value = response.data.data;

        setComprovanteIdTransacao(value.id);

        if (response.data.error || response.data.errors) {
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: 'Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul',
          });
    
          return;
        };
  
        if (value === '') {
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: 'Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul',
          });
    
          return;
        };

        // Faz a requisição do boleto
        if (data.tipo_transacao === "Boleto") {
          boletos.forEach(item => {
            api.post('/boleto_trans', {
              id_transacao: value.id,
              codigo_de_barras: item.codigo_de_barras,
              id_boleto: item.id_doc_boleto,
              valor: item.valor,
              data_vencimento: item.data_vencimento
            }, auth).then(res => {

              if (res.data.error || res.data.errors) {
                // mensagem de erro
                addToast({
                  type: 'error',
                  title: 'Erro no cadastro',
                  description: 'Algo deu errado ao salvar o boleto, tente novamente ou entre em contato com o Deixa no Azul',
                });

                // Zera o array de boletos
                setBoletos([]);
                // Zera o array de uploads de boletos
                setUploadedBoleto([]);

                // Abrir modal para reanexar o boleto
                setModalBoletoHide(false);
                setValue('quantidade_boletos', 0)
          
                return;
              } else {
                // Pega o nome do cliente para mostrar no modal
                setModalNomeCliente(data.nome);
                
                // Abre o modal
                setModalOpened(!modalOpened)

                // Limpa os dados do localstorage
                localStorage.removeItem('@DeixaNoAzul:dadosSimulaçãoTransação');
              }
            });
          });
        };
  
        if (data.tipo_transacao === "Depósito bancário") {
          api.post('/dados_banc_trans', {
            // tipo_chave_pix: null,
            // chave_pix: null,
            id_transacao: value.id,
            nome_banco: data.nome_banco,
            agencia: data.agencia,
            numero_conta: data.numero_conta,
            nome_titular: data.nome_titular,
            tipo_conta: data.tipo_conta,
            conta_juridica: data.conta_juridica,
            CPF_CNPJ: data.cpf_titular,
            operacao: data.operacao,
          }, auth).then(res => {
            // res.status;
            console.log('depósito', res);

            // Pega o nome do cliente para mostrar no modal
            setModalNomeCliente(data.nome);
                
            // Abre o modal
            setModalOpened(!modalOpened);

            // Limpa os dados do localstorage
            localStorage.removeItem('@DeixaNoAzul:dadosSimulaçãoTransação');
          });
  
        };
      });
      
    } catch (error) {
      console.log('error', error.response)
      
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description: 'Algo deu errado. Tente novamente ou entre em contato com o Deixa no Azul.',
      });
    }
    
  };
  const onError = (errors) => {
    addToast({
      type: 'error',
      title: 'Erro no envio do formulário',
      description: 'Você precisa preencher todos os campos obrigatórios (marcados com *).',
    });
  };

  function reanexarBoleto() {
    const nomeCliente = getValues('nome')

    // Bearer token
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    boletos.forEach(item => {
      api.post('/boleto_trans', {
        id_transacao: comprovanteIdTransação,
        codigo_de_barras: item.codigo_de_barras,
        id_boleto: item.id_doc_boleto
      }, auth).then(res => {

        if (res.data.error || res.data.errors) {
          // mensagem de erro
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: 'Algo deu errado ao salvar o boleto, tente novamente ou entre em contato com o Deixa no Azul',
          });
    
          return;
        } else {
          // Abrir modal para reanexar o boleto
          setModalBoletoHide(true);

          // Pega o nome do cliente para mostrar no modal
          setModalNomeCliente(nomeCliente);
          
          // Abre o modal
          setModalOpened(!modalOpened)
        }
      });
    });
  };

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');
  const loggedUser = JSON.parse(localStorage.getItem('@DeixaNoAzul:loggedUser'));
  const loggedEstab = JSON.parse(localStorage.getItem('@DeixaNoAzul:estabelecimentoId'));
  const dadosLocalStorage = JSON.parse(localStorage.getItem('@DeixaNoAzul:dadosSimulaçãoTransação'));

  const [estabelecimentoID, setEstabelecimentoID] = useState('');

  // Função que exibe o box de dados bancários ou boleto de acordo com o tipo de transação
  const onDepositoSelected = useCallback(() => {
    const inputValue = getValues('tipo_transacao');

    if (inputValue === "") {
      setDepositoOpened(true);
      setBoletoOptions(true);
    }

    if (inputValue === "Depósito bancário") {
      setDepositoOpened(false);
      setBoletoOptions(true);

      setInfosBoleto(false);
      setInfosDeposito("Campo obrigatório");
    } else if (inputValue === "Boleto") {
      setDepositoOpened(true);
      setBoletoOptions(false);

      setInfosBoleto(true);
      setInfosDeposito(false);
    }
  }, [getValues]);

  // Lista de bancos
  const listaBancos = BankList;

  // Função que altera o número do banco de acordo com o nome selecionado
  const setNumeroBanco = useCallback(() => {
    const nomeBanco = getValues('nome_banco');

    if (nomeBanco === "") {
      return;
    }

    if (nomeBanco === "Banco do Brasil S.A.") {
      setValue('numero_banco', "001");
      return
    }
    if (nomeBanco === "Banco Bradesco S.A.") {
      setValue('numero_banco', "237");
      return
    }
    if (nomeBanco === "Caixa Econômica Federal") {
      setValue('numero_banco', "104");
      return
    }
    if (nomeBanco === "Banco Santander (Brasil) S. A.") {
      setValue('numero_banco', "033");
      return
    }
    if (nomeBanco === "Itaú Unibanco S.A.") {
      setValue('numero_banco', "341");
      return
    }
    
    const numerobanco = listaBancos.filter(item => item.label === nomeBanco);
    setValue('numero_banco', numerobanco[0].value);
    
  }, [getValues, setValue, listaBancos]);

  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get('/estabelecimento?png=999999', auth).then(response => {
      const result = response.data.data;

      const estabelecimentoFiltrado = result.filter(item => item.user_id === loggedUser.id);
  
      setEstabelecimentoID(estabelecimentoFiltrado[0].id);
    })

    // Insere os dados no infoClient com os dados do localStorage para persistência dos dados
    setSimulator({
      bandeira: dadosLocalStorage.bandeira,
      conjunto_de_taxa: dadosLocalStorage.conjunto_de_taxa,
      maquineta: dadosLocalStorage.maquineta,
      quantidade_parcelas: dadosLocalStorage.quantidade_parcelas,
      taxa_servico: dadosLocalStorage.taxa_servico,
      valor_acrescimo: dadosLocalStorage.valor_acrescimo,
      valor_comissao_ec: dadosLocalStorage.valor_comissao_ec,
      valor_pagamento: dadosLocalStorage.valor_pagamento,
      valor_parcela: dadosLocalStorage.valor_parcela,
      valor_total: dadosLocalStorage.valor_total,
    });
    
    setValue('quantidade_boletos', '0')
    setValue('nome', dadosLocalStorage.nome);
    setValue('email', dadosLocalStorage.email);
    setValue('telefone', dadosLocalStorage.telefone);
    setValue('cpf', dadosLocalStorage.cpf);
    setValue('numero_rg', dadosLocalStorage.rg);
    setValue('numero_autorizacao', dadosLocalStorage.numero_autorizacao);
    setValue('tipo_transacao', dadosLocalStorage.tipo_transacao);
    onDepositoSelected();
    setValue('nome_titular', dadosLocalStorage.nome_titular);
    setValue('nome_banco', dadosLocalStorage.nome_banco);
    setValue('conta_juridica', dadosLocalStorage.conta_juridica);
    setValue('cpf_titular', dadosLocalStorage.cpf_cnpj);
    setValue('tipo_conta', dadosLocalStorage.tipo_conta);
    setValue('agencia', dadosLocalStorage.agencia);
    setValue('numero_conta', dadosLocalStorage.numero_conta);
    setValue('operacao', dadosLocalStorage.operacao);
    setNumeroBanco();
    
  }, [userToken, loggedUser.id, setValue, dadosLocalStorage.email, dadosLocalStorage.nome, dadosLocalStorage.telefone, dadosLocalStorage.bandeira, dadosLocalStorage.conjunto_de_taxa, dadosLocalStorage.maquineta, dadosLocalStorage.quantidade_boletos, dadosLocalStorage.quantidade_parcelas, dadosLocalStorage.taxa_servico, dadosLocalStorage.valor_acrescimo, dadosLocalStorage.valor_comissao_ec, dadosLocalStorage.valor_pagamento, dadosLocalStorage.valor_parcela, dadosLocalStorage.valor_total, setSimulator, dadosLocalStorage.agencia, dadosLocalStorage.conta_juridica, dadosLocalStorage.cpf, dadosLocalStorage.cpf_cnpj, dadosLocalStorage.nome_banco, dadosLocalStorage.nome_titular, dadosLocalStorage.numero_autorizacao, dadosLocalStorage.numero_conta, dadosLocalStorage.operacao, dadosLocalStorage.rg, dadosLocalStorage.tipo_conta, dadosLocalStorage.tipo_transacao, onDepositoSelected, setNumeroBanco]);

  const history = useHistory();

  // Informações Modal ---------------------------------------------- START
  // Nome do cliente
  const [modalNomeCliente, setModalNomeCliente] = useState("");
  const [comprovanteIdTransação, setComprovanteIdTransacao] = useState("");

  // Valor total (líquido)
  const valor_liquido = simulator.valor_pagamento.split('.')
  const valor_total = `${valor_liquido[0]},${valor_liquido[1] ? valor_liquido[1].substring(0, 2) : "00"}`

  // Valor pago
  const total_tansacao = simulator.valor_total.split('.');
  const valor_pago = `${total_tansacao[0]},${total_tansacao[1] ? total_tansacao[1].substring(0, 2) : "00"}`

  // Data de processamento
  const atualData = new Date();
  const data_processamento = `${atualData.getDate()}/${atualData.getMonth() + 1}/${atualData.getFullYear()}`;

  // Valor pago
  const valor_parcela = simulator.valor_parcela.split('.');
  const valorParcela = `${valor_parcela[0]},${valor_parcela[1] ? valor_parcela[1].substring(0, 2) : "00"}`

  // Informações Modal ---------------------------------------------- END

  // Abrir/fechar o modal informando que tem dados com o CPF
  const [modalDadosHide, setModalDadosHide] = useState(true);

  const [dadosAntigos, setDadosAntigos] = useState({});

  // Faz a validação do CPF e verifica se há dados com o cpf digitado
  function validarCPF() {
    const valorDigitado = getValues("cpf");
    const stringDigitada = valorDigitado.replaceAll('.', '').replace('-', '');

    let Soma;
    let Resto;
    Soma = 0;

    if (
      stringDigitada === "00000000000" || 
      stringDigitada === "11111111111" || 
      stringDigitada === "22222222222" || 
      stringDigitada === "33333333333" || 
      stringDigitada === "44444444444" || 
      stringDigitada === "55555555555" || 
      stringDigitada === "66666666666" || 
      stringDigitada === "77777777777" || 
      stringDigitada === "88888888888" || 
      stringDigitada === "99999999999"
    ) {
      setError("cpf", {
        type: "manual",
        message: "O CPF digitado é inválido."
      });

      return;
    };

    for (let i = 1; i <= 9; i++) {
      Soma = Soma + parseInt(stringDigitada.substring(i-1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;
    }

    if ((Resto === 10) || (Resto === 11)) { Resto = 0 };
    if (Resto !== parseInt(stringDigitada.substring(9, 10)) ) {
      setError("cpf", {
        type: "manual",
        message: "O CPF digitado é inválido."
      });

      return;
    };

    Soma = 0;
    for (let i = 1; i <= 10; i++) {
      Soma = Soma + parseInt(stringDigitada.substring(i-1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;
    }

    if ((Resto === 10) || (Resto === 11)) { Resto = 0 };
    if (Resto !== parseInt(stringDigitada.substring(10, 11) ) ) {
      setError("cpf", {
        type: "manual",
        message: "O CPF digitado é inválido."
      });

      return;
    };
    
    // CPF ok
    clearErrors("cpf")
    // return;

    // Verificar se já cadastros com esse CPF
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get(`/info_trans/${valorDigitado}`, auth).then(response => {
      const retorno = response.data;

      if (retorno.error === "Dados nao encontrados") {
        return
      }

      setModalDadosHide(false);
      setDadosAntigos(retorno);
    });

    setLocalStorage();
  };

  // Faz a validação do CPF/CNPJ
  function validarCPF_CNPJ() {
    const contaJuridica = getValues("conta_juridica");

    // Valida o CPF
    if (contaJuridica === "0") {

      const valorDigitado = getValues("cpf_titular");
      const stringDigitada = valorDigitado.replaceAll('.', '').replace('-', '');

      let Soma;
      let Resto;
      Soma = 0;

      if (
        stringDigitada === "00000000000" || 
        stringDigitada === "11111111111" || 
        stringDigitada === "22222222222" || 
        stringDigitada === "33333333333" || 
        stringDigitada === "44444444444" || 
        stringDigitada === "55555555555" || 
        stringDigitada === "66666666666" || 
        stringDigitada === "77777777777" || 
        stringDigitada === "88888888888" || 
        stringDigitada === "99999999999"
      ) {
        setError("cpf_titular", {
          type: "manual",
          message: "O CPF digitado é inválido."
        });

        return;
      };

      for (let i = 1; i <= 9; i++) {
        Soma = Soma + parseInt(stringDigitada.substring(i-1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;
      }

      if ((Resto === 10) || (Resto === 11)) { Resto = 0 };
      if (Resto !== parseInt(stringDigitada.substring(9, 10)) ) {
        setError("cpf_titular", {
          type: "manual",
          message: "O CPF digitado é inválido."
        });

        return;
      };

      Soma = 0;
      for (let i = 1; i <= 10; i++) {
        Soma = Soma + parseInt(stringDigitada.substring(i-1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;
      }

      if ((Resto === 10) || (Resto === 11)) { Resto = 0 };
      if (Resto !== parseInt(stringDigitada.substring(10, 11) ) ) {
        setError("cpf_titular", {
          type: "manual",
          message: "O CPF digitado é inválido."
        });

        return;
      };

      // CPF ok
      clearErrors("cpf_titular")
      setLocalStorage();

      return;
    }

    // Valida o CNPJ
    if (contaJuridica === "1") {

      const valorDigitado = getValues("cpf_titular");
      const stringDigitada = valorDigitado.replaceAll('.', '').replace('/', '').replace('-', '');
  
      // cnpj = cnpj.replace(/[^\d]+/g,'');
      // if(stringDigitada === '') { return false };
      
      if (stringDigitada.length !== 14) { return false };
  
      // Elimina CNPJs invalidos conhecidos
      if (
        stringDigitada === "00000000000000" || 
        stringDigitada === "11111111111111" || 
        stringDigitada === "22222222222222" || 
        stringDigitada === "33333333333333" || 
        stringDigitada === "44444444444444" || 
        stringDigitada === "55555555555555" || 
        stringDigitada === "66666666666666" || 
        stringDigitada === "77777777777777" || 
        stringDigitada === "88888888888888" || 
        stringDigitada === "99999999999999"
      ) {
        setError("cpf_titular", {
          type: "manual",
          message: "O CNPJ digitado é inválido."
        });

        return;
      }

      let tamanho;
      let numeros;
      let digitos;
      let soma;
      let pos;
      let resultado;
          
      // Valida DVs
      tamanho = stringDigitada.length - 2
      numeros = stringDigitada.substring(0,tamanho);
      digitos = stringDigitada.substring(tamanho);
      soma = 0;
      pos = tamanho - 7;

      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) { pos = 9 };
      }

      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado.toString() !== digitos.charAt(0)) {
        // Erro no primeiro dígito
        setError("cpf_titular", {
          type: "manual",
          message: "O CNPJ digitado é inválido."
        });

        return;
      };
          
      tamanho = tamanho + 1;
      numeros = stringDigitada.substring(0,tamanho);
      soma = 0;
      pos = tamanho - 7;

      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) { pos = 9 };
      }

      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

      if (resultado.toString() !== digitos.charAt(1)) {
        // Erro no segundo dígito
        setError("cpf_titular", {
          type: "manual",
          message: "O CNPJ digitado é inválido."
        });

        return;
      };
      
      // CNPJ ok
      clearErrors("cpf_titular")
      setLocalStorage();

      return;
    }

  };

  // Faz a validação do E-mail
  function validarEmail() {
    const valorDigitado = getValues("email");
    const indexArroba = valorDigitado.indexOf("@");

    const usuario = valorDigitado.substring(0, indexArroba);
    const dominio = valorDigitado.substring(indexArroba + 1, valorDigitado.length);

    if (
      (usuario.length >= 1) &&
      (dominio.length >= 3) &&
      (usuario.search("@") === -1) &&
      (dominio.search("@") === -1) &&
      (usuario.search(" ") === -1) &&
      (dominio.search(" ") === -1) &&
      (dominio.search(".") !== -1) &&
      (dominio.indexOf(".") >= 1)&&
      (dominio.lastIndexOf(".") < dominio.length - 1)
    ) {
      // e-mail válido
      clearErrors("email");
    } else { 
      // e-mail inválido
      setError("email", {
        type: "manual",
        message: "O E-mail digitado é inválido."
      });
    }

    setLocalStorage()
  };

  function preencherDadosAntigos() {
    setModalDadosHide(true);

    setValue("nome", dadosAntigos.dados_pessoais[0].nome);
    setValue("numero_rg", dadosAntigos.dados_pessoais[0].rg);
    setValue("email", dadosAntigos.dados_pessoais[0].email);
    setValue("telefone", dadosAntigos.dados_pessoais[0].telefone);

    if (dadosAntigos.dados_pessoais[0].quantidade_boleto === 0) {
      setValue("tipo_transacao", "Depósito bancário");
      setDepositoOpened(false);

      setValue("nome_titular", dadosAntigos.dadosbancarios[0].nome_titular);
      setValue("nome_banco", dadosAntigos.dadosbancarios[0].nome_banco);
      setValue("conta_juridica", dadosAntigos.dadosbancarios[0].conta_juridica);
      setValue("cpf_titular", dadosAntigos.dadosbancarios[0].CPF_CNPJ);
      setValue("tipo_conta", dadosAntigos.dadosbancarios[0].tipo_conta);
      setValue("agencia", dadosAntigos.dadosbancarios[0].agencia);
      setValue("numero_conta", dadosAntigos.dadosbancarios[0].numero_conta);
      setValue("operacao", dadosAntigos.dadosbancarios[0].operacao);
    }
    
    return;
  };

  const [depositoOpened, setDepositoOpened] = useState(true);
  const [boletoOptions, setBoletoOptions] = useState(true);

  const [infosBoleto, setInfosBoleto] = useState(false);
  const [infosDeposito, setInfosDeposito] = useState(false);

  // Abrir/fechar o modal final
  const [modalOpened, setModalOpened] = useState(true);
  const [modalBoletoHide, setModalBoletoHide] = useState(true);

  const closeModal = useCallback(() => {
    setModalOpened(!modalOpened);

    history.push('/transacoes');
  }, [modalOpened, history]);

  // Upload do Comprovante -------------------------------------------- START 
  const [uploadedComprovante, setUploadedComprovante] = useState([]);
  const [progressComprovante, setProgressComprovante] = useState(0);
  const [statusComprovante, setStatusComprovante] = useState({
    uploaded: false,
    error: false
  });

  // Preparar arquivos para upload
  const submitFileComprovante = useCallback((file) => {
    const uploadFile = {
      file: file[0],
      id: uniqueId(),
      name: file[0].name,
      readableSize: filesize(file[0].size),
      preview: URL.createObjectURL(file[0]),
      // progress: progressComprovante,
      // uploaded: statusComprovante.uploaded,
      // error: statusComprovante.error,
    };

    setUploadedComprovante([...uploadedComprovante, uploadFile]);
    // setUploadedComprovante(uploadFile);
  }, [uploadedComprovante]);
  
  // Faz o upload de todos os arquivos
  /* const handleUploadComprovante = useCallback(() => {

    // Envia para a API
    const data = new FormData();

    uploadedComprovante.forEach(item => {
      // console.log(`image${uploadedComprovante.indexOf(item)}`);
      data.append(`image${uploadedComprovante.indexOf(item)}`, item.file);
    });

    api.post('/documento_upload_mult', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization : "Bearer "+userToken,
      },
      onUploadProgress: e => {
        setProgressComprovante(parseInt(Math.round((e.loaded * 100) / e.total)))
      }
    }).then(response => {
      setStatusComprovante({
        uploaded: true,
        error: false
      })

      const value = response.data.data;
      setValue("comprovante", value.id);
    }).catch(() => {
      setStatusComprovante({
        uploaded: false,
        error: true
      })
    });
  }, [uploadedComprovante, setValue, userToken]); */
  
  // Remove o arquivo da lista de arquivos
  const handleDeleteComprovante = useCallback((id) => {
    const newFiles = uploadedComprovante.filter(comprovante => comprovante.id !== id);

    setUploadedComprovante(newFiles);
  }, [uploadedComprovante]);
  // Upload do Comprovante ---------------------------------------------- END 

  // Upload do Termo -------------------------------------------------- START 
  const [uploadedTermo, setUploadedTermo] = useState([]);
  const [progressTermo, setProgressTermo] = useState(0);
  const [statusTermo, setStatusTermo] = useState({
    uploaded: false,
    error: false
  });

  // Preparar arquivos para upload
  const submitFileTermo = useCallback((file) => {
    const uploadFile = {
      file: file[0],
      id: uniqueId(),
      name: file[0].name,
      readableSize: filesize(file[0].size),
      preview: URL.createObjectURL(file[0]),
    };

    setUploadedTermo([...uploadedTermo, uploadFile]);
  }, [uploadedTermo]);
  
  // Faz o upload de todos os arquivos
  /* const handleUploadTermo = useCallback(() => {

    // Envia para a API
    const data = new FormData();

    uploadedTermo.forEach(item => {
      // console.log(`image${uploadedComprovante.indexOf(item)}`);
      data.append(`image${uploadedTermo.indexOf(item)}`, item.file);
    });

    api.post('/documento_upload_mult', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization : "Bearer "+userToken,
      },
      onUploadProgress: e => {
        setProgressTermo(parseInt(Math.round((e.loaded * 100) / e.total)))
      }
    }).then(response => {
      setStatusTermo({
        uploaded: true,
        error: false
      })

      const value = response.data.data;
      setValue("termo", value.id);
      
    }).catch(() => {
      setStatusTermo({
        uploaded: false,
        error: true
      })
    });
  }, [uploadedTermo, setValue, userToken]); */
  
  // Remove o arquivo da lista de arquivos
  const handleDeleteTermo = useCallback((id) => {
    const newFiles = uploadedTermo.filter(termo => termo.id !== id);

    setUploadedTermo(newFiles);
  }, [uploadedTermo]);
  // Upload do Termo ---------------------------------------------------- END 

  // Upload do Documento com foto ------------------------------------- START 
  const [uploadedDocFoto, setUploadedDocFoto] = useState([]);
  const [progressDocFoto, setProgressDocFoto] = useState(0);
  const [statusDocFoto, setStatusDocFoto] = useState({
    uploaded: false,
    error: false
  });

  // Preparar arquivos para upload
  const submitFileDocFoto = useCallback((file) => {
    const uploadFile = {
      file: file[0],
      id: uniqueId(),
      name: file[0].name,
      readableSize: filesize(file[0].size),
      preview: URL.createObjectURL(file[0]),
    };

    setUploadedDocFoto([...uploadedDocFoto, uploadFile]);
  }, [uploadedDocFoto]);
  
  // Faz o upload de todos os arquivos
  /* const handleUploadDocFoto = useCallback(() => {

    // Envia para a API
    const data = new FormData();

    uploadedDocFoto.forEach(item => {
      data.append(`image${uploadedDocFoto.indexOf(item)}`, item.file);
    });

    api.post('/documento_upload_mult', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization : "Bearer "+userToken,
      },
      onUploadProgress: e => {
        setProgressDocFoto(parseInt(Math.round((e.loaded * 100) / e.total)))
      }
    }).then(response => {
      setStatusDocFoto({
        uploaded: true,
        error: false
      })

      const value = response.data.data;
      setValue("docFoto", value.id);

    }).catch(() => {
      setStatusDocFoto({
        uploaded: false,
        error: true
      })
    });
  }, [uploadedDocFoto, setValue, userToken]); */
  
  // Remove o arquivo da lista de arquivos
  const handleDeleteDocFoto = useCallback((id) => {
    const newFiles = uploadedDocFoto.filter(termo => termo.id !== id);

    setUploadedDocFoto(newFiles);
  }, [uploadedDocFoto]);
  // Upload do Documento com foto --------------------------------------- END 

  const [boletos, setBoletos] = useState([]);

  // const [anexoDisabled, setAnexoDisabled] = useState(true);

  /* const changeAnexoDisabled = useCallback(() => {
    const inputValue = Number(getValues('quantidade_boletos'))
    const boletoLength = boletos.length;

    if (inputValue <= boletoLength) {
      setAnexoDisabled(true)
    }

    if (inputValue > boletoLength) {
      setAnexoDisabled(false)
    }
  }, [boletos.length, getValues]); */

  // Upload do Boleto ------------------------------------------------- START 
  const [uploadedBoleto, setUploadedBoleto] = useState([]);
  const [progressBoleto, setProgressBoleto] = useState(0);
  const [statusBoleto, setStatusBoleto] = useState({
    uploaded: false,
    error: false
  });

  // Preparar arquivos para upload
  const submitFileBoleto = useCallback((file) => {
    const uploadFile = {
      file: file[0],
      id: uniqueId(),
      name: file[0].name,
      readableSize: filesize(file[0].size),
      preview: URL.createObjectURL(file[0]),
      // progress: progressComprovante,
      // uploaded: statusComprovante.uploaded,
      // error: statusComprovante.error,
    };

    setUploadedBoleto([...uploadedBoleto, uploadFile]);
  }, [uploadedBoleto]);

  // Faz o upload de todos os arquivos
  const handleUploadBoleto = useCallback(() => {

    setLoadingHide(false);

    // Envia para a API
    const data = new FormData();

    uploadedBoleto.forEach(item => {
      data.append(`image${uploadedBoleto.indexOf(item)}`, item.file);
    });

    api.post('/documento_upload_mult', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization : "Bearer "+userToken,
      },
      onUploadProgress: e => {
        setProgressBoleto(parseInt(Math.round((e.loaded * 100) / e.total)))
      }
    }).then(response => {
      setStatusBoleto({
        uploaded: true,
        error: false
      });

      const value = response.data.data;
      setValue("docBoleto", value.id);

      // handleAddBoleto START
      const barCodeValue = getValues('codigo_boleto');
      const idBoletoValue = getValues('docBoleto');
      const valorBoleto = getValues('valor_boleto');
      const dataVentimento = getValues('data_vencimento');

      const valorFinal = Number(valorBoleto.replace('R$ ', '').replace(',', '.'));
      const dataVencimentoFinal = dataVentimento.split('/').reverse().join('-');

      setBoletos([...boletos, {
        codigo_de_barras: barCodeValue,
        id_doc_boleto: idBoletoValue,
        valor: valorFinal,
        data_vencimento: dataVencimentoFinal,
      }])

      const quantidadeBoletos = getValues('quantidade_boletos');

      setValue('quantidade_boletos', Number(quantidadeBoletos) + 1);

      setUploadedBoleto([]);
      setProgressBoleto(0);
      setStatusBoleto({
        uploaded: false,
        error: false
      });
      setValue('codigo_boleto', '');
      setValue('valor_boleto', '');
      setValue('data_vencimento', '');
      serValorEData(false);

      // changeAnexoDisabled()
      // handleAddBoleto END

      setLoadingHide(true);

    }).catch(() => {
      setStatusBoleto({
        uploaded: false,
        error: true
      })

      setLoadingHide(true);
    });
  }, [uploadedBoleto, setValue, userToken, boletos, /* changeAnexoDisabled, */ getValues]);
  
  // Remove o arquivo da lista de arquivos
  const handleDeleteBoleto = useCallback((id) => {
    const newFiles = uploadedBoleto.filter(termo => termo.id !== id);

    setUploadedBoleto(newFiles);
  }, [uploadedBoleto]);
  // Upload do Boleto --------------------------------------------------- END

  // Upload do Novo Boleto ------------------------------------------------- START 
  const [uploadedNovoBoleto, setUploadedNovoBoleto] = useState([]);
  const [progressNovoBoleto, setProgressNovoBoleto] = useState(0);
  const [statusNovoBoleto, setStatusNovoBoleto] = useState({
    uploaded: false,
    error: false
  });

  // Preparar arquivos para upload
  const submitFileNovoBoleto = useCallback((file) => {
    const uploadFile = {
      file: file[0],
      id: uniqueId(),
      name: file[0].name,
      readableSize: filesize(file[0].size),
      preview: URL.createObjectURL(file[0]),
      // progress: progressComprovante,
      // uploaded: statusComprovante.uploaded,
      // error: statusComprovante.error,
    };

    setUploadedNovoBoleto([...uploadedNovoBoleto, uploadFile]);
  }, [uploadedNovoBoleto]);

  // Faz o upload de todos os arquivos
  const handleUploadNovoBoleto = useCallback(() => {

    // Envia para a API
    const data = new FormData();

    uploadedNovoBoleto.forEach(item => {
      data.append(`image${uploadedNovoBoleto.indexOf(item)}`, item.file);
    });

    api.post('/documento_upload_mult', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization : "Bearer "+userToken,
      },
      onUploadProgress: e => {
        setProgressNovoBoleto(parseInt(Math.round((e.loaded * 100) / e.total)))
      }
    }).then(response => {
      setStatusNovoBoleto({
        uploaded: true,
        error: false
      })

      const value = response.data.data;
      setValue("docNovoBoleto", value.id);

      const barCodeValue = getValues('novo_codigo_boleto');
      const idBoletoValue = getValues('docNovoBoleto');

      setBoletos([...boletos, {
        codigo_de_barras: barCodeValue,
        id_doc_boleto: idBoletoValue,
      }])

      const quantidadeBoletos = getValues('quantidade_boletos');

      setValue('quantidade_boletos', Number(quantidadeBoletos) + 1);

      setUploadedNovoBoleto([]);
      setProgressNovoBoleto(0);
      setStatusNovoBoleto({
        uploaded: false,
        error: false
      });
      setValue('novo_codigo_boleto', '');

      // changeAnexoDisabled();

    }).catch(() => {
      setStatusNovoBoleto({
        uploaded: false,
        error: true
      })
    });
  }, [uploadedNovoBoleto, setValue, boletos, getValues, userToken]);
  
  // Remove o arquivo da lista de arquivos
  const handleDeleteNovoBoleto = useCallback((id) => {
    const newFiles = uploadedNovoBoleto.filter(termo => termo.id !== id);

    setUploadedNovoBoleto(newFiles);
  }, [uploadedNovoBoleto]);
  // Upload do Novo Boleto --------------------------------------------------- END

  // const [boletos, setBoletos] = useState([]);

  function handleAddBoleto() {
    const barCodeValue = getValues('codigo_boleto');
    const idBoletoValue = getValues('docBoleto');
    const valorBoleto = getValues('valor_boleto');
    const dataVentimento = getValues('data_vencimento');

    const valorFinal = Number(valorBoleto.replace('R$ ', '').replace(',', '.'));
    const dataVencimentoFinal = dataVentimento.split('/').reverse().join('-');

    setBoletos([...boletos, {
      codigo_de_barras: barCodeValue,
      id_doc_boleto: idBoletoValue,
      valor: valorFinal,
      data_vencimento: dataVencimentoFinal,
    }])

    setUploadedBoleto([]);
    setValue('codigo_boleto', '');
    setValue('valor_boleto', '');
    setValue('data_vencimento', '');
    serValorEData(false);

    // changeAnexoDisabled()
  };

  function handleAddNovoBoleto() {
    const barCodeValue = getValues('novo_codigo_boleto');
    const idBoletoValue = getValues('docNovoBoleto');
    const valorBoleto = getValues('valor_boleto');
    const dataVentimento = getValues('data_vencimento');

    const valorFinal = Number(valorBoleto.replace('R$ ', '').replace(',', '.'));
    const dataVencimentoFinal = dataVentimento.split('/').reverse().join('-');

    setBoletos([...boletos, {
      codigo_de_barras: barCodeValue,
      id_doc_boleto: idBoletoValue,
      valor: valorFinal,
      data_vencimento: dataVencimentoFinal,
    }])

    setUploadedNovoBoleto([]);
    setValue('novo_codigo_boleto', '');
    serValorEData(false);

    // changeAnexoDisabled()
  };

  /* const [anexoDisabled, setAnexoDisabled] = useState(true);

  const changeAnexoDisabled = useCallback(() => {
    const inputValue = Number(getValues('quantidade_boletos'))
    const boletoLength = boletos.length;

    if (inputValue <= boletoLength) {
      setAnexoDisabled(true)
    }

    if (inputValue > boletoLength) {
      setAnexoDisabled(false)
    }
  }, [boletos.length, getValues]); */

  function downloadComprovante() {
    const doc = new jsPDF();

    doc.addImage(logoImg, 'PNG', 15, 20);

    /* doc.setDrawColor(207, 204, 204)
    doc.setLineWidth(0.1)
    doc.line(70, 20, 70, 40) */

    doc.setFontSize(11)
    doc.setFont("helvetica", "bold")
    doc.text("Comprovante de acompanhamento de transação", 105, 26)

    doc.setFontSize(9)
    doc.setFont("helvetica", "bold")
    doc.setTextColor('#969696')
    doc.text(data_processamento, 105, 31)

    doc.setFontSize(9)
    doc.setFont("helvetica", "bold")
    doc.setTextColor('#969696')
    doc.text('https://app.deixanoazul.com.br/acompanhamento', 105, 36)

    doc.setDrawColor(207, 204, 204)
    doc.setLineWidth(0.1)
    doc.line(15, 50, 195, 50)

    // Nome do cliente
    doc.setFontSize(11)
    doc.setFont("helvetica", "bold")
    doc.setTextColor('#000')
    doc.text("Nome do cliente: ", 15, 65)

    doc.setFontSize(11)
    doc.setFont("helvetica", "normal")
    doc.text(modalNomeCliente, 50, 65)

    // id da Transação
    doc.setFontSize(11)
    doc.setFont("helvetica", "bold")
    doc.setTextColor('#000')
    doc.text("Id da transação: ", 15, 75)

    doc.setFontSize(11)
    doc.setFont("helvetica", "normal")
    doc.text(comprovanteIdTransação, 50, 75)

    // Estabelecimento
    doc.setFontSize(11)
    doc.setFont("helvetica", "bold")
    doc.setTextColor('#000')
    doc.text("Estabelecimento: ", 15, 85)

    doc.setFontSize(11)
    doc.setFont("helvetica", "normal")
    doc.text(loggedEstab.nome_fantasia, 50, 85)

    // Valor passado ao cliente
    doc.setFontSize(11)
    doc.setFont("helvetica", "bold")
    doc.setTextColor('#000')
    doc.text("Valor solicitado: ", 15, 95)

    doc.setFontSize(11)
    doc.setFont("helvetica", "normal")
    doc.text(`R$ ${valor_total}`, 50, 95)

    // Valor total
    doc.setFontSize(11)
    doc.setFont("helvetica", "bold")
    doc.setTextColor('#000')
    doc.text("Valor total: ", 15, 105)

    doc.setFontSize(11)
    doc.setFont("helvetica", "normal")
    doc.text(`R$ ${valor_pago}`, 50, 105)

    // Parcelas
    doc.setFontSize(11)
    doc.setFont("helvetica", "bold")
    doc.setTextColor('#000')
    doc.text("Parcelamento: ", 15, 115)

    doc.setFontSize(11)
    doc.setFont("helvetica", "normal")
    doc.text(`${simulator.quantidade_parcelas}x de R$ ${valorParcela}`, 50, 115)
    

    doc.save("comprovante.pdf");
  };

  const [placeholderCpfCnpj, serPlaceholderCpfCnpj] = useState('');

  function setLocalStorage() {

    const infosLocalStorage = {
      /** simulador */
      bandeira: dadosLocalStorage.bandeira,
      conjunto_de_taxa: dadosLocalStorage.conjunto_de_taxa,
      maquineta: dadosLocalStorage.maquineta,
      quantidade_parcelas: dadosLocalStorage.quantidade_parcelas,
      taxa_servico: dadosLocalStorage.taxa_servico,
      valor_acrescimo: dadosLocalStorage.valor_acrescimo,
      valor_comissao_ec: dadosLocalStorage.valor_comissao_ec,
      valor_pagamento: dadosLocalStorage.valor_pagamento,
      valor_parcela: dadosLocalStorage.valor_parcela,
      valor_total: dadosLocalStorage.valor_total,
      /** pessoais */
      nome: getValues("nome"),
      email: getValues("email"),
      telefone: getValues("telefone"),
      cpf: getValues("cpf"),
      rg: getValues("numero_rg"),
      /** transação */
      numero_autorizacao: getValues("numero_autorizacao"),
      tipo_transacao: getValues("tipo_transacao"),
      nome_titular: getValues("nome_titular"),
      nome_banco: getValues("nome_banco"),
      numero_banco: getValues("nome"),
      conta_juridica: getValues("conta_juridica"),
      cpf_cnpj: getValues("cpf_titular"),
      tipo_conta: getValues("tipo_conta"),
      agencia: getValues("agencia"),
      numero_conta: getValues("numero_conta"),
      operacao: getValues("operacao"),
    };

    localStorage.setItem('@DeixaNoAzul:dadosSimulaçãoTransação', JSON.stringify(infosLocalStorage));
  };

  const changeContaJuridica = useCallback(() => {
    const inputValue = getValues('conta_juridica');

    if (inputValue === "0") {
      serPlaceholderCpfCnpj("Digite o CPF");
    }
    if (inputValue === "1") {
      serPlaceholderCpfCnpj("Digite o CNPJ");
    }
  }, [getValues]);

  const [valorEData, serValorEData] = useState(false);

  // Máscara do input de valor da transação
  function inputValueMask(e) {
    let valor = e.currentTarget.value;
    valor = valor.replace(/\D/g, '');
    valor = valor.replace(/(\d)(\d{2})$/, '$1,$2');
    valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '.');

    return e.currentTarget.value = `R$ ${valor}`;
    // setValue('valor', `R$ ${valor}`);
  }

  // Máscara do input de data de vencimento
  function inputDateMask(e) {
    e.currentTarget.maxLength = 10;

    let valor = e.currentTarget.value;
    valor = valor.replace(/\D/g, '');
    valor = valor.replace(/^(\d{2})(\d)/, '$1/$2');
    valor = valor.replace(/(\d{2})(\d)/, '$1/$2');

    return e.currentTarget.value = valor;
    // setValue('data_vencimento', valor);
  }

  // Máscara do input de código de barras
  function codigoDeBarrasMask(e) {
    e.currentTarget.maxLength = 54;

    let value = e.currentTarget.value;

    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{5})(\d)/, '$1.$2');
    value = value.replace(/(\d{5})(\d)/, '$1 $2');
    value = value.replace(/(\d{5})(\d)/, '$1.$2');
    value = value.replace(/(\d{1})(\d{5})(\d)/, '$1$2 $3');
    value = value.replace(/(\d{5})(\d{6})(\d)/, '$1.$2 $3');
    value = value.replace(/(\d{1})(\d{10})/, '$1 $2');

    return e.currentTarget.value = value;
  }

  function verificarCodigoDeBarras() {
    const barCode = getValues('codigo_boleto');

    // console.log(codigo.length);
    if (barCode.length < 54) {
      return;
    } else {
      // console.log(barCode);

      if (barCode[3] !== '9') {
        // Mensagem de erro
        addToast({
          type: 'info',
          title: 'Ops, informações não obtidas',
          description: 'Não conseguimos captar as informações do boleto.',
        });

        serValorEData(true);
        setValue('valor_boleto', ``)
        setValue('data_vencimento', '');
      }

      if (barCode[3] === '9') {
        // Valor
        const campoValor = barCode.substring(barCode.length - 10, barCode.length);
  
        const valorString = `${Number(campoValor.substring(0, 8))},${Number(
          campoValor.substring(8, 10),
        )}`;

        setValue('valor_boleto', `R$ ${valorString}`)

        // Data de vencimento
        const campoData = barCode.substring(barCode.length - 14, barCode.length - 10);

        const resultadoData = moment("19971007", "YYYYMMDD").add(Number(campoData), 'days').format();
        const resultadoSomenteData = resultadoData.split('T');

        const dataVencimento = resultadoSomenteData[0];
        
        setValue('data_vencimento', dataVencimento.split('-').reverse().join('/'));
      }
    }
  }

  return (
    <Container>
      <LoadingContainer loadingHide={loadingHide}>
        <img src={loading} alt="Carregando..."/>
      </LoadingContainer>

      <ModalSection modalOpened={modalOpened}>
        <ModalContent>
          <MdHighlightOff size={30} onClick={() => closeModal()} />
          <span><strong>Parabéns</strong>, as informações foram enviadas com sucesso!</span>

          <ModalClientName>
            <p>Nome do cliente final</p>
            <span>{modalNomeCliente}</span>
          </ModalClientName>

          <ModalValueSection>

            <ModalValueArea>
              <label>Valor total</label>
              <span>R$ {valor_total}</span>
            </ModalValueArea>

            <ModalValueArea>
              <label>Valor pago</label>
              <span>R$ {valor_pago}</span>
            </ModalValueArea>

            <ModalValueArea className="dataArea">
              <label>Data do processamento</label>
              <span>{data_processamento}</span>
            </ModalValueArea>

          </ModalValueSection>
          
          <p>
          *Caso deseje editar alguma informação dessa operação, <br />
          verifique a lista de transações para proceder com a mudança.
          </p>

          <div className="buttonArea">
            <button type="button" onClick={() => closeModal()}>Ok</button>
            <button className="downloadPDF" onClick={downloadComprovante} type="button"><MdGetApp /> Baixar comprovante</button>
          </div>
        </ModalContent>
      </ModalSection>

      <ModalSectionDados modalDadosHide={modalDadosHide}>
        <ModalContentDados>
          <span>Percebemos que esse CPF já realizou uma transação conosco.</span>
          <span>Você gostaria de aproveitar os dados que encontramos para preencher os campos mais rápido?</span>
          <p>Você ainda poderá alterar esses dados em seguida.</p>

          <div className="buttonArea">
            <button type="button" onClick={() => preencherDadosAntigos()}>Sim, quero usar os dados</button>
            <button type="button" className="cancelar" onClick={() => setModalDadosHide(true)}>Não, quero digitar todos</button>
          </div>
        </ModalContentDados>
      </ModalSectionDados>

      <ModalSectionBoleto modalBoletoHide={modalBoletoHide}>
        <ModalContentBoleto>
          <span>Algo deu errado com o envio do boleto para o nosso servidor.</span>
          {/* <span>Você gostaria de aproveitar os dados que encontramos para preencher os campos mais rápido?</span> */}
          <p>Por favor, anexe e envie ele novamente.</p>

          <RowBoxBoletoModal>
            {/* <Row> */}
              <BoxInfo>
                <span>Anexe até 2 documentos em cada campo e, em seguida, clique em <b>Enviar arquivos.</b></span> <br />
              </BoxInfo>
            {/* </Row> */}
            
            <Row3>
              <InputArea className="barCode">
                <label>Código de barras</label>
                <InputContainer className="barCode" style={{ borderColor: errors.novo_codigo_boleto && "red" }}>
                  <input
                    name="novo_codigo_boleto"
                    type="text"
                    // disabled={anexoDisabled}
                    ref={register}
                    placeholder="Digite o valor"
                  />
                  {errors.novo_codigo_boleto && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                </InputContainer>
              </InputArea>

              <InputArea className="campoAnexo">
                <label>*Anexar Boleto</label>
                <UploadContainer style={{ borderColor: errors.docBoleto && "red" }}>
                  <UploadBoleto
                    onUpload={submitFileNovoBoleto}
                    isDisabled={uploadedNovoBoleto.length === 2 ? true : false}
                    isBlue={false}
                  />
                  <input type="text" name="docNovoBoleto" ref={register} hidden />
                  {errors.docBoleto && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                </UploadContainer>
                {!!uploadedNovoBoleto.length && <InfoBoleto files={uploadedNovoBoleto} percent={progressNovoBoleto} status={statusNovoBoleto} onDelete={(id) => handleDeleteNovoBoleto(id)} />}

                {!!uploadedNovoBoleto.length && statusNovoBoleto.uploaded === false && <button type="button" style={{ marginBottom: 0 }} onClick={() => handleUploadNovoBoleto()}>Enviar arquivos</button>}
              </InputArea>

              {!!uploadedNovoBoleto.length && statusNovoBoleto.uploaded === true && <button type="button" className="addBoleto" onClick={handleAddNovoBoleto}>Salvar boleto</button>}
            </Row3>

            <RowBoleto>
              {boletos.map(boleto => (
                <span key={boleto.id_doc_boleto}><img src={CodigoBarrasIcon} alt=""/>Boleto {boletos.indexOf(boleto) + 1}</span>
              ))}
            </RowBoleto>

          </RowBoxBoletoModal>

          <div className="buttonArea">
            <button type="button" onClick={() => reanexarBoleto()}>Enviar</button>
            {/* <button type="button" className="cancelar" onClick={() => setModalBoletoHide(true)}>Cancelar</button> */}
          </div>
        </ModalContentBoleto>
      </ModalSectionBoleto>

      <MenuBar />
      <NewMobileMenu />

      <Page>
        <TopBar pageTitle="Criar Transação" />

        <Content>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <RowBox1>
              <Box>
                <h3>Informações da transação</h3>

                {/* <Row> */}
                  {/* <InputArea>
                    <label>*Número da Autorização (aprovação) </label>
                    <InputContainer style={{ borderColor: errors.numero_autorizacao && "red" }}>
                      <input
                        name="numero_autorizacao"
                        type="text"
                        ref={register({ required: true })}
                        placeholder="Digite o número da autorização"
                        onBlur={() => setLocalStorage()}
                      /> 
                      {errors.numero_autorizacao && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea> */}

                  <InputArea>
                    <label>*Nome completo</label>
                    <InputContainer style={{ borderColor: errors.nome && "red" }}>
                      <input
                        name="nome"
                        type="text"
                        placeholder="Digite o nome completo"
                        ref={register({ required: true })}
                        onBlur={() => setLocalStorage()}
                      />
                      {errors.nome && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                {/* </Row> */}

                <Row>
                  <InputArea>
                    <label>*Número do CPF</label>
                    <InputContainer style={{ borderColor: errors.cpf && "red" }}>
                      <input
                        name="cpf"
                        placeholder="Digite o CPF (apenas números)"
                        onKeyUp={(e) => cpfMask(e)}
                        ref={register({ required: "Campo obrigatório" })}
                        onBlur={() => validarCPF()}
                      />
                      {errors.cpf && <Error title={errors.cpf.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*Número do RG</label>
                    <InputContainer style={{ borderColor: errors.numero_rg && "red" }}>
                      <input
                        name="numero_rg"
                        type="text"
                        placeholder="Digite o RG"
                        ref={register({ required: true })}
                        onKeyUp={(e) => rgMask(e)}
                        onBlur={() => setLocalStorage()}
                      />
                      {errors.numero_rg && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>*E-mail</label>
                    <InputContainer style={{ borderColor: errors.email && "red" }}>
                      <input
                        name="email"
                        type="text"
                        placeholder="Digite o e-mail"
                        ref={register({ required: "Campo obrigatório" })}
                        onBlur={() => validarEmail()}
                      />
                      {errors.email && <Error title={errors.email.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*Telefone</label>
                    <InputContainer style={{ borderColor: errors.telefone && "red" }}>
                    <input
                      name="telefone"
                      onKeyUp={(e) => telefoneMask(e)}
                      placeholder="Apenas números"
                      ref={register({ required: true })}
                      onBlur={() => setLocalStorage()}
                    />
                    {errors.telefone && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Row>
              </Box>

              <div>
                <Box>
                  <h3>Informações para pagamentos</h3>

                  <InputArea>
                    <label>*Tipo de transação</label>
                    <InputContainer style={{ borderColor: errors.tipo_transacao && "red" }}>
                    <select
                      name="tipo_transacao"
                      onChange={() => onDepositoSelected()}
                      placeholder="Selecione"
                      ref={register({ required: true })}
                      onBlur={() => setLocalStorage()}
                    >
                      <option value="">Selecione</option>
                      <option value="Boleto">Boleto</option>
                      <option value="Depósito bancário">Depósito bancário</option>
                    </select>
                    {errors.tipo_transacao && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Box>

                <BoletoOptions boletoOptions={boletoOptions} className="boleto_infos">
                  <InputArea>
                    <label>Quantidade de Boletos</label>

                    <BoxInfo style={{ marginBottom: '4px', marginTop: '2px' }}>
                      <span>Campo preenchido automaticamente</span> <br />
                    </BoxInfo>

                    <InputContainer style={{ borderColor: errors.quantidade_boletos && "red" }}>
                      <input
                        name="quantidade_boletos"
                        type="number"
                        disabled={true}
                        // onChange={changeAnexoDisabled}
                        // value={boletos.length}
                        ref={register({ required: infosBoleto })}
                        placeholder="Digite o valor"
                      />
                      {errors.quantidade_boletos && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                </BoletoOptions>
              </div>
            </RowBox1>

            <RowBoxBoleto boletoOptions={boletoOptions}>
              <Row>
                <BoxInfo>
                  <span>Você pode anexar até 10 documentos por boleto. Ao selecionar os arquivos, clique em <b>Enviar arquivos</b> para submeter seu boleto.</span> <br />
                </BoxInfo>
              </Row>
              
              <Row3>
                <div>
                  <InputArea className="barCode">
                    <label>*Código de barra do boleto</label>
                    <InputContainer className="barCode" style={{ borderColor: errors.codigo_boleto && "red" }}>
                      <input
                        name="codigo_boleto"
                        type="text"
                        onKeyUp={(e) => codigoDeBarrasMask(e)}
                        onChange={() => verificarCodigoDeBarras()}
                        // disabled={anexoDisabled}
                        ref={register}
                        placeholder="Somente números"
                      />
                      {errors.codigo_boleto && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <div hidden={!valorEData}> 
                    {/* Input de valor */}
                    <InputArea style={{ marginTop: 10 }}>
                      <label>*Valor do boleto</label>
                      <InputContainer className="barCode" style={{ borderColor: errors.valor_boleto && "red" }}>
                        <input
                          name="valor_boleto"
                          type="text"
                          onKeyUp={(e) => inputValueMask(e)}
                          // disabled={anexoDisabled}
                          ref={register}
                          placeholder="Somente números"
                        />
                        {errors.valor_boleto && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                      </InputContainer>
                    </InputArea>

                    {/* Input da data de vencimento */}
                    <InputArea style={{ marginTop: 10 }}>
                      <label>*Data de vencimento do boleto</label>
                      <InputContainer className="barCode" style={{ borderColor: errors.data_vencimento && "red" }}>
                        <input
                          name="data_vencimento"
                          type="text"
                          onKeyUp={(e) => inputDateMask(e)}
                          // disabled={anexoDisabled}
                          ref={register}
                          placeholder="Somente números"
                        />
                        {errors.data_vencimento && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                      </InputContainer>
                    </InputArea>
                  </div>

                </div>

                <InputArea className="campoAnexo">
                  <label>*Anexar Boleto(s)</label>
                  <UploadContainer style={{ borderColor: errors.docBoleto && "red" }}>
                    <UploadBoleto
                      onUpload={submitFileBoleto}
                      isDisabled={uploadedBoleto.length === 10 ? true : false}
                      isBlue={false}
                    />
                    <input type="text" name="docBoleto" ref={register} hidden />
                    {errors.docBoleto && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </UploadContainer>
                  {!!uploadedBoleto.length && <InfoBoleto files={uploadedBoleto} percent={progressBoleto} status={statusBoleto} onDelete={(id) => handleDeleteBoleto(id)} />}

                  {!!uploadedBoleto.length && statusBoleto.uploaded === false && <button type="button" style={{ marginBottom: 0 }} onClick={() => handleUploadBoleto()}>Enviar arquivos</button>}
                </InputArea>

                {!!uploadedBoleto.length && statusBoleto.uploaded === true && <button type="button" className="addBoleto" onClick={() => handleAddBoleto()}>Adicionar boleto</button>}
              </Row3>

              {/* {valorEData && (
                <Row3 style={{ marginTop: 20 }}>
                  <InputArea>
                    <label>*Valor do boleto</label>
                    <InputContainer className="barCode" style={{ borderColor: errors.valor_boleto && "red" }}>
                      <input
                        name="valor_boleto"
                        type="text"
                        onKeyUp={(e) => inputValueMask(e)}
                        // disabled={anexoDisabled}
                        ref={register}
                        placeholder="Somente números"
                      />
                      {errors.valor_boleto && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*Data de vencimento do boleto</label>
                    <InputContainer className="barCode" style={{ borderColor: errors.data_vencimento && "red" }}>
                      <input
                        name="data_vencimento"
                        type="text"
                        onKeyUp={(e) => inputDateMask(e)}
                        // disabled={anexoDisabled}
                        ref={register}
                        placeholder="Somente números"
                      />
                      {errors.data_vencimento && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Row3>
              )} */}

              <RowBoleto>
                {boletos.map(boleto => (
                  <span key={boleto.id_doc_boleto}><img src={CodigoBarrasIcon} alt=""/>Boleto {boletos.indexOf(boleto) + 1}</span>
                ))}
              </RowBoleto>

            </RowBoxBoleto>

            <RowBox2 depositoOpened={depositoOpened}>
              <h3>Informações da conta bancária</h3>

              <Row>
                <InputArea>
                  <label>*Nome do titular</label>
                  <InputContainer style={{ borderColor: errors.nome_titular && "red" }}>
                    <input
                      name="nome_titular"
                      type="text"
                      ref={register({ required: infosDeposito })}
                      placeholder="Digite o nome completo do titular"
                      onBlur={() => setLocalStorage()}
                    />
                    {errors.nome_titular && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>

                <InputArea>
                  <label>*Nome do banco</label>
                  <InputContainer style={{ borderColor: errors.nome_banco && "red" }}>
                    <select
                      name="nome_banco"
                      placeholder="Selecione"
                      onChange={() => setNumeroBanco()}
                      ref={register({ required: infosDeposito })}
                      onBlur={() => setLocalStorage()}
                    >
                      <option value="">Selecione</option>
                      {/* <option value="Banco do Brasil S.A.">Banco do Brasil S.A.</option>
                      <option value="Banco Bradesco S.A.">Banco Bradesco S.A.</option>
                      <option value="Caixa Econômica Federal">Caixa Econômica Federal</option>
                      <option value="Banco Santander (Brasil) S. A.">Banco Santander (Brasil) S. A.</option>
                      <option value="Itaú Unibanco S.A.">Itaú Unibanco S.A.</option> */}

                      {listaBancos.map(item => (
                        <option value={item.label}>{item.value+' - '+item.label}</option>
                      ))}
                    </select>
                    {errors.nome_banco && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                  <input type="text" name="numero_banco" ref={register} hidden />
                </InputArea>
              </Row>

              <Row3>
                <InputArea>
                  <label>*Tipo de conta</label>
                  <InputContainer style={{ borderColor: errors.conta_juridica && "red" }}>
                    <select
                      name="conta_juridica"
                      ref={register({ required: infosDeposito })}
                      placeholder="Selecione"
                      onChange={changeContaJuridica}
                      onBlur={() => setLocalStorage()}
                    >
                      <option value="">Selecione</option>
                      <option value="0">Pessoa Física</option>
                      <option value="1">Pessoa Jurídica</option>
                    </select>
                    {errors.conta_juridica && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>

                <InputArea>
                  <label>*CPF/CNPJ</label>
                  <InputContainer style={{ borderColor: errors.cpf_titular && "red" }}>
                    <input
                      name="cpf_titular"
                      onKeyUp={ getValues('conta_juridica') === '0' ? (e) => cpfMask(e) : (e) => cnpjMask(e) }
                      ref={register({ required: infosDeposito })}
                      placeholder={placeholderCpfCnpj}
                      onBlur={() => validarCPF_CNPJ()}
                    />
                    {errors.cpf_titular && <Error title={errors.cpf_titular.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>

                <InputArea>
                  <label>*Conta</label>
                  <InputContainer style={{ borderColor: errors.tipo_conta && "red" }}>
                    <select
                      name="tipo_conta"
                      placeholder="Selecione"
                      ref={register({ required: infosDeposito })}
                      onBlur={() => setLocalStorage()}
                    >
                      <option value="">Selecione</option>
                      <option value={0}>Corrente</option>
                      <option value={1}>Poupança</option>
                    </select>
                    {errors.tipo_conta && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>
              </Row3>

              <Row3>
                <InputArea>
                  <label>*Agência</label>
                  <InputContainer style={{ borderColor: errors.agencia && "red" }}>
                    <input
                      name="agencia"
                      type="text"
                      ref={register({ required: infosDeposito })}
                      placeholder="xxxx"
                      onBlur={() => setLocalStorage()}
                    />
                    {errors.agencia && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>

                <InputArea>
                  <label>*Número da conta</label>
                  <InputContainer style={{ borderColor: errors.numero_conta && "red" }}>
                    <input
                      name="numero_conta"
                      onKeyUp={(e) => contaBancariaMask(e)}
                      type="text"
                      ref={register({ required: infosDeposito })}
                      placeholder="Apenas números"
                      onBlur={() => setLocalStorage()}
                    />
                    {errors.numero_conta && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>

                <InputArea>
                  <label>Operação</label>
                  <InputContainer>
                    <input
                      name="operacao"
                      type="text"
                      ref={register}
                      placeholder="xxx"
                      onBlur={() => setLocalStorage()}
                    />
                  </InputContainer>
                </InputArea>
              </Row3>

            </RowBox2>
            
            <RowBox3>

              <Box>
                <h3>Anexos</h3>
                <BoxInfo>
                  <span>Anexe até 4 documentos em cada campo e, em seguida, clique em <b>Enviar arquivos.</b></span> <br />
                </BoxInfo>

                <Row>
                  <InputArea className="campoAnexo">
                    <Tooltip title="Anexe todos os comprovantes da transação aqui" placement="top" arrow>
                      <label>*Comprovante</label>
                    </Tooltip>
                    <UploadContainer style={{ borderColor: errors.comprovante && "red" }}>
                      <UploadComprovante
                        onUpload={submitFileComprovante}
                        isDisabled={uploadedComprovante.length === 4 ? true : false}
                      />
                      <input type="text" name="comprovante" ref={register} hidden />
                      {errors.comprovante && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </UploadContainer>
                    {!!uploadedComprovante.length && <InfoComprovante files={uploadedComprovante} percent={progressComprovante} status={statusComprovante} onDelete={(id) => handleDeleteComprovante(id)} />}

                    {/* {!!uploadedComprovante.length && statusComprovante.uploaded === false && <button type="button" style={{ marginBottom: 0 }} onClick={() => handleUploadComprovante()}>Enviar arquivos</button>} */}
                  </InputArea>

                  <InputArea className="campoAnexo">
                    <Tooltip title="Anexe o termo da transação" placement="top" arrow>
                      <label>*Anexar Termo</label>
                    </Tooltip>
                      <UploadContainer style={{ borderColor: errors.termo && "red" }}>
                        <UploadTermo
                          onUpload={submitFileTermo}
                          isDisabled={uploadedTermo.length === 4 ? true : false}
                        />
                        <input type="text" name="termo" ref={register} hidden />
                        {errors.termo && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                      </UploadContainer>
                      {!!uploadedTermo.length && <InfoTermo files={uploadedTermo} percent={progressTermo} status={statusTermo} onDelete={(id) => handleDeleteTermo(id)} />}

                      {/* {!!uploadedTermo.length && statusTermo.uploaded === false && <button style={{ marginBottom: 0 }} type="button" onClick={() => handleUploadTermo()}>Enviar arquivos</button>} */}
                  </InputArea>
                </Row>

                <Row className="marginTop">
                  <InputArea className="campoAnexo">
                    <Tooltip title="Anexe o RG, CNH, Carteira de Trabalho ou Passaporte" placement="top" arrow>
                      <label>*Documento com foto</label>
                    </Tooltip>
                    <UploadContainer style={{ borderColor: errors.docFoto && "red" }}>
                      <UploadDocFoto
                        onUpload={submitFileDocFoto}
                        isDisabled={uploadedDocFoto.length === 4 ? true : false}
                      />
                      <input type="text" name="docFoto" ref={register} hidden />
                      {errors.docFoto && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </UploadContainer>
                    {!!uploadedDocFoto.length && <InfoDocFoto files={uploadedDocFoto} percent={progressDocFoto} status={statusDocFoto} onDelete={(id) => handleDeleteDocFoto(id)} />}

                    {/* {!!uploadedDocFoto.length && statusDocFoto.uploaded === false && <button type="button" style={{ marginBottom: 0 }} onClick={() => handleUploadDocFoto()}>Enviar arquivos</button>} */}
                  </InputArea>

                </Row>
              </Box>

              <InfoClient />
            </RowBox3>
            
            <button type="submit">Solicitar transação</button>
          </form>
        </Content>
      </Page>
      
    </Container>
  );
}

export default CriarTransacao;
