import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
// import Select from 'react-select';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Tooltip } from '@material-ui/core/';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';
import FormatarValor from '../../components/FormatarValor';

import FormAddRevendedor from '../../components/FormAddRevendedor';

import {
  MdAdd,
  MdRemove,
  // MdEdit,
  // MdNavigateBefore,
  // MdNavigateNext,
} from 'react-icons/md';
import editIcon from '../../assets/images/edit_icon.svg';

import api from '../../services/api';
// import { useLicensed } from '../../hooks/LicensedContext';

import {
  Container,
  Page,
  Content,
  TableContent,
  AddLA,
  Button,
  FormSection,
  TableContentMobile,
  Header,
  Line,
  MobileBody,
  // Pagination,
  // PaginationButton,
  // PaginationItem,
} from './styles';

function Revendedores() {
  const history = useHistory();
  const [formOpened, setFormOpened] = useState(true);

  const [revendedores, setRevendedores] = useState([]);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get('/lista_revendedores', auth).then(response => {
      const result = response.data;
      // console.log(result)

      setRevendedores(result)
    })

  }, [userToken]);

  // Informações Head da tabela
  const headData = [
    { title: 'Nome', field: 'nome', sorting: false },
    { title: 'Cidade de atuação', field: 'cidade', sorting: false },
    { title: 'Data criação', field: 'data', sorting: false },
    { title: 'ECs ativos', field: 'ec_ativos', sorting: false },
    { title: 'LA responsável', field: 'la', sorting: false },
    { title: 'Saldo conta digital', field: 'saldo', sorting: false },
    { title: '', field: 'edit', align: 'right', sorting: false },
  ];

  // Informações Body da tabela
  const bodyData = revendedores.map(revendedor => {
    // const status = <Tooltip title={messageStatus} placement="top" arrow><span><MdFiberManualRecord color={colorStatus} /></span></Tooltip>

    const saldoFinal = revendedor.saldo_conta_digital === null
      ? <span style={{ color: "#A6AAB4", fontWeight: 600 }}>R$ -</span>
      : <span style={{ color: "#0073FC", fontWeight: 600 }}>R$ {FormatarValor(revendedor.saldo_conta_digital)}</span>;

    const dataCriacao = revendedor.created_at.substring(0, 10).split('-').reverse().join('/');
    const horaCriacao = revendedor.created_at.substring(11, 16);

    const dataEHora = <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{dataCriacao}</span>
      <span style={{ color: '#A6AAB4', fontSize: 14 }}>{horaCriacao}</span>
    </div>;

    return {
      nome: revendedor.nome,
      cidade: revendedor.cidade_de_atuacao,
      data: dataEHora,
      ec_ativos: revendedor.ec_ativos,
      saldo: saldoFinal,
      la: revendedor.nome_fantasia_licenciado,
      // status: status,
      edit: <Tooltip title="Editar Dados do Revendedor" placement="top" arrow><span style={{ cursor: "pointer" }} onClick={() => editRevendedor(revendedor)}><img src={editIcon} alt=""/></span></Tooltip>
    }
  });

  const editRevendedor = useCallback((revendedor) => {
    const idReverse = revendedor.revendedor_id.split('').reverse().join('');

    // redireciona para a página de edição
    history.push(`/admin/revendedor/editar/${idReverse}`);
  }, [history]);

  /* -------------------- FUNÇÕES PARA O MOBILE -------------------- */

  // Mostra o saldo da conta digital na lista mobile
  function saldoContaDigital(revendedor) {
    const saldo_conta_digital = revendedor.saldo_conta_digital !== null ? revendedor.saldo_conta_digital.toString().split('.') : "";
    const saldoContaDigital = `${saldo_conta_digital[0]},${saldo_conta_digital[1] ? saldo_conta_digital[1].substring(0, 2) : "00"}`;

    const saldoFinal = revendedor.saldo_conta_digital === null ? "Sem saldo" : `R$ ${saldoContaDigital}`;

    return saldoFinal;
  };

  return (
    <Container>
      <MenuBar active="Revendedores" />
      <NewMobileMenu active="Revendedores" />

      <Page>
        <TopBar pageTitle="Cadastro de Revendedores" />

        <Content>
          
          <AddLA>
            <span>Adicionar Revendedor</span>
            <Button onClick={() => setFormOpened(!formOpened)}>
              {formOpened === true ? <MdAdd color="#fff" /> : <MdRemove color="#fff" />}
            </Button>
          </AddLA>

          <FormSection formOpened={formOpened}>
            <FormAddRevendedor />
          </FormSection>

          <TableContent>
          
            <MaterialTable
              title="Lista de Revendedores"
              columns={headData}
              data={bodyData}
              components={{
                Toolbar: props => (
                  <div>
                    <MTableToolbar {...props} />
                    
                  </div>
                )
              }}
              localization={{
                pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{from}-{to} de {count}',
                  firstTooltip: 'Primeira página',
                  previousTooltip: 'Página anterior',
                  nextTooltip: 'Próxima página',
                  lastTooltip: 'Última página'
                },
                toolbar: {
                  searchPlaceholder: 'Buscar',
                  searchTooltip: 'Buscar'
                },
                body: {
                  emptyDataSourceMessage: 'Nenhum dado encontrado',
                }
              }}
              options={{
                actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: '#10104F',
                  color: '#FFF'
                },
                emptyValue: "Nenhum dado encontrado"
              }}
            />

          </TableContent>

          <TableContentMobile>
            <Header>
              <h3>Lista de Licenciados</h3>

              <span>Total: {revendedores.length}</span>
            </Header>

            <MobileBody>
              {revendedores.map((revendedor) => (
                <Line key={revendedor.id}>
                  <span>{revendedor.nome}</span>
                  <p>{revendedor.cidade_atuacao}</p>
                  <p>ECs ativos: {revendedor.ec_ativos}</p>
                  <p>Conta digital: {saldoContaDigital(revendedor)}</p>
          
                  <div className="footer">
                    <span></span>
                    <img src={editIcon} alt="" onClick={() => editRevendedor(revendedor)}/>
                  </div>
                </Line>
              ))}
            </MobileBody>
          </TableContentMobile>

        </Content>
      </Page>
    </Container>
  );
}

export default Revendedores;
