import styled, { css } from 'styled-components';
import { shade } from "polished";

import ToolTip from "../../components/Tooltip";

export const Container = styled.div``;

export const ChatButton = styled.button`
  position: fixed;
  right: 230px;
  bottom: 30px;
  width: 50px;
  height: 50px;

  background: #10104f;
  border: none;
  color: #fff;
  border-radius: 25px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: transform 300ms ease;

  ${props => props.chatOpened && css`
    border-radius: 0 0 25px 25px;
    /* height: 55px; */
    &::after {
      position: absolute;
      top: -5px;

      content: "";
      width: 50px;
      height: 5px;
      background: #10104f;
    }
  `}

  ${props => props.sentMessagesOpened && css`
    transform: translate(-30vw);
  `}
`;

export const ChatContainer = styled.div`
  position: fixed;
  right: 30px;
  bottom: 85px;

  background: #10104f;
  padding: 10px;
  border-radius: 8px 8px 8px 8px;

  h3 {
    color: #FFF;
    margin: 6px 0;
  }

  > button {
    position: absolute;
    top: -10px;
    right: -10px;
    background: #c53030;
    border: 3px solid #FFFFFF;
    color: #FFFFFF;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
  }

  ${props => props.sentMessagesOpened && css`
    transform: translate(-30vw);
  `}
`;

export const ChatContent = styled.div`
  background: #FFFFFF;
  border-radius: 8px;
  padding: 10px;
  width: 300px;

  button {
    padding: 8px 22px;
    background: #0073fc;
    color: #ffffff;
    border: none;
    border-radius: 4px;

    transition: 0.2s;
    outline-style: none;

    &:hover {
      background: ${shade(0.2, "#0073FC")};
    }
  }
`;

export const ChatInputArea = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  flex: 1;

  label {
    font-size: 16px;
    color: #373A3C;
  }

  input[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;
  }
  input[type=number] { 
    -moz-appearance: textfield;
    appearance: textfield;
  }

  /* input {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    box-sizing: border-box;
    
    height: 34px;
    width: 100%;
    padding: 0 10px;

    &.errored {
      border: 1px solid #c53030;
    }

    ${props => props.isErrored && css`
      border: 1px solid #c53030;
    `}
  } */
`;

export const ChatInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  box-sizing: border-box;

  margin-bottom: 18px;
  padding: 1px 11px 1px 1px;

  ${props => props.isErrored && css`
    border: 1px solid #c53030;
  `}

  ${props => props.isFocused && css`
    border: 1px solid #0073FC;
  `}

  input {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset;
    }
  }

  textarea {
    border: none;

    /* height: 34px; */
    width: 100%;
    padding: 0 10px;
    margin-top: 5px;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset;
    }
  }

  select {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset;
    }
  }
`;

export const Error = styled(ToolTip)`
  span {
    background: #c53030;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const SentMessagesButton = styled.button`
  position: fixed;
  right: 0;
  bottom: 30px;

  width: 220px;
  height: 50px;
  padding: 0 15px;

  background: #10104f;
  border: none;
  color: #fff;
  border-radius: 25px 0px 0px 25px;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: 300ms ease;

  ${props => props.sentMessagesOpened && css`
    transform: translate(-30vw);
  `}
`;

export const SentMessagesContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 30vw;
  height: 100%;
  background: #10104f;
  padding: 10px 10px;
  
  transition: 300ms ease;
  
  transform: translateX(100%);
  box-shadow: none;
  
  ${props => props.sentMessagesOpened && css`  
    transform: translateX(0);
    box-shadow: -5px 0 20px rgba(0, 0, 0, 0.2);
  `}
`;

export const SentMessagesHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  h3 {
    color: #FFF;
  }

  > button {
    background: #c53030;
    border: 3px solid #FFFFFF;
    color: #FFFFFF;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
  }
`;

export const SentMessagesContent = styled.div`
  background: #FFFFFF;
  background: #F5F8FB;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  height: calc(100% - 45px);

  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */

  button {
    padding: 8px 22px;
    background: #0073fc;
    color: #ffffff;
    border: none;
    border-radius: 4px;

    transition: 0.2s;
    outline-style: none;

    &:hover {
      background: ${shade(0.2, "#0073FC")};
    }
  }
`;

export const Message = styled.div`
  background: #D1E7F4;
  padding: 6px;
  border-radius: 0 10px 10px 10px;
  width: 80%;
  margin: 0 auto 10px 0;

  div {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 2fr 1fr;
    gap: 10px;
    margin-bottom: 10px;

    p {
      color: #9FA7BA;
      font-weight: bold;
    }

    span {
      color: #9FA7BA;
      font-size: 14px;
    }
  }

  h4 {
    margin-bottom: 10px;
  }
`;
