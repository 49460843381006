import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Tooltip } from '@material-ui/core/';
import { DateRange } from 'react-date-range';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';

import api from '../../services/api';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';
import FormatarValor from '../../components/FormatarValor';

import {
  MdFiberManualRecord,
  // MdAdd,
  // MdRemove,
} from 'react-icons/md';
import { FiCalendar, FiXCircle } from 'react-icons/fi';
import editIcon from '../../assets/images/edit_icon.svg';

import {
  Container,
  Page,
  Content,
  TableContent,
  TableContentMobile,
  Header,
  Line,
  MobileBody,
  DatePicker,
  FilterDate,
  ButtonBox,
  // AddEC,
  // Button,
} from './styles';

function AdminEstabelecimentos() {
  const history = useHistory();

  const [estabelecimentos, setEstabelecimentos] = useState([]);
  const [estabelecimentosVisiveis, setEstabelecimentosVisiveis] = useState([]);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get('/lista_ec_admin', auth).then(response => {
      const result = response.data;
      console.log('lista_ec_admin:', result);

      setEstabelecimentos(result);
      setEstabelecimentosVisiveis(result);
    });

  }, [userToken]);

  // Informações Head da tabela
  const headData = [
    { title: 'Responsável', field: 'resp', sorting: false },
    { title: 'Estabelecimento', field: 'est', sorting: false },
    { title: 'Data criação', field: 'data', sorting: false },
    { title: 'Licenciado Autorizado', field: 'la', sorting: false },
    { title: 'Revendedor', field: 'rv', sorting: false },
    { title: 'Saldo conta digital', field: 'saldo', sorting: false },
    { title: 'Status', field: 'status', align: 'right', sorting: false },
    { title: '', field: 'edit', align: 'center', sorting: false },
  ];

  // Informações Body da tabela
  const bodyData = estabelecimentosVisiveis === [] || estabelecimentosVisiveis === "Nenhum estabelecimento encontrado" ? []
  : estabelecimentosVisiveis.map(estabelecimento => {

    let colorStatus = '';
    if(estabelecimento.status === "0") {colorStatus = "#5CB85C"} // Aprovado
    if(estabelecimento.status === "1") {colorStatus = "#FEAC02"} // Em análise
    if(estabelecimento.status === "2") {colorStatus = "#5097FF"} // Documentos pendentes
    if(estabelecimento.status === "3") {colorStatus = "#8850FF"} // Documentos checados
    if(estabelecimento.status === "4") {colorStatus = "#F35757"} // Reprovado

    let messageStatus = '';
    if(estabelecimento.status === "0") {messageStatus = "Aprovado"} // Aprovado
    if(estabelecimento.status === "1") {messageStatus = "Em análise"} // Em análise
    if(estabelecimento.status === "2") {messageStatus = "Documentos pendentes"} // Documentos pendentes
    if(estabelecimento.status === "3") {messageStatus = "Documentos checados"} // Documentos checados
    if(estabelecimento.status === "4") {messageStatus = "Reprovado"} // Reprovado
    
    const status = <Tooltip title={messageStatus} placement="top" arrow><span><MdFiberManualRecord color={colorStatus} /></span></Tooltip>

    const saldoFinal = estabelecimento.saldo_conta === null
      ? <span style={{ color: "#A6AAB4", fontWeight: 600 }}>R$ -</span>
      : <span style={{ color: "#0073FC", fontWeight: 600 }}>R$ {FormatarValor(estabelecimento.saldo_conta)}</span>;

    const revendedor = estabelecimento.revendedor === null ? <span style={{ color: "#A6AAB4", fontWeight: 600 }}>-</span> : estabelecimento.revendedor;

    const dataCriacao = estabelecimento.created_at.substring(0, 10).split('-').reverse().join('/');
    const horaCriacao = estabelecimento.created_at.substring(11, 16);

    const dataEHora = <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{dataCriacao}</span>
      <span style={{ color: '#A6AAB4', fontSize: 14 }}>{horaCriacao}</span>
    </div>;

    const licenciado = estabelecimento.la === 'Escola Niteroi' || estabelecimento.la === 'La Default' ? '---' : estabelecimento.la;

    return {
      resp: estabelecimento.responsavel,
      est: estabelecimento.estabelecimento,
      data: dataEHora,
      la: licenciado,
      rv: revendedor,
      saldo: saldoFinal,
      status: status,
      edit: <Tooltip title="Editar Dados do EC" placement="top" arrow><span /* className={disabled} */ style={{ cursor: "pointer" }} onClick={() => editEC(estabelecimento)}>
            <img src={editIcon} alt=""/>
        </span></Tooltip>
    }
  });

  const editEC = useCallback((estabelecimento) => {
    const idReverse = estabelecimento.id.split('').reverse().join('');
  
    // redireciona para a página de edição
    history.push(`/admin/estabelecimentos/editar/${idReverse}`);
  }, [history]);

  const filterByStatus = useCallback((value) => {
    const filterValue = value.value;

    if (filterValue !== '5') {
      const filteredData = estabelecimentos.filter(
        item => item.status === filterValue
      );

      setEstabelecimentosVisiveis(filteredData);
    }

    if (filterValue === '5') {
      setEstabelecimentosVisiveis(estabelecimentos);
    }

  }, [estabelecimentos]);
  
  const [dataFiltrada, setDataFiltrada] = useState(false);
  const [filtroAberto, setFiltroAberto] = useState(false);

  const [selectionDate, setSelectionDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
    }
  ]);

  const filterByDate = useCallback((item) => {
    const dataInicial = format(selectionDate[0].startDate, 'yyyy-MM-dd');
    const dataFinal = format(selectionDate[0].endDate, 'yyyy-MM-dd');

    const momentStartDate = moment(dataInicial);
    const momentEndDate = moment(dataFinal);

    // const anoInicial = Number(dataInicial.substring(0,4));
    // const mesInicial = Number(dataInicial.substring(5,7));
    // const diaInicial = Number(dataInicial.substring(8,10));

    // const anoFinal = Number(dataFinal.substring(0,4));
    // const mesFinal = Number(dataFinal.substring(5,7));
    // const diaFinal = Number(dataFinal.substring(8,10));

    /* const ecsFiltrados = estabelecimentos.filter(item => 
      Number(item.created_at.substring(0, 4)) >= anoInicial && Number(item.created_at.substring(0, 4)) <= anoFinal &&
      Number(item.created_at.substring(5, 7)) >= mesInicial && Number(item.created_at.substring(5, 7)) <= mesFinal &&
      Number(item.created_at.substring(8, 10)) >= diaInicial && Number(item.created_at.substring(8, 10)) <= diaFinal
    ); */

    const ecsFiltrados = estabelecimentos.filter(
      item =>
        moment(item.created_at) >= momentStartDate &&
        moment(item.created_at) <= momentEndDate,
    );

    // console.log(ecsFiltrados);
    setEstabelecimentosVisiveis(ecsFiltrados);

    setDataFiltrada(true);
    setFiltroAberto(false)
  }, [estabelecimentos, selectionDate]);

  const estabelecimentoStatus = useCallback((estabelecimento) => {
    let colorStatus = '';
    if(estabelecimento.status === '0') {colorStatus = "#5CB85C"} // Aprovado
    if(estabelecimento.status === '1') {colorStatus = "#FEAC02"} // Em análise
    if(estabelecimento.status === '2') {colorStatus = "#5097FF"} // Documentos pendentes
    if(estabelecimento.status === '3') {colorStatus = "#8850FF"} // Documentos checados
    if(estabelecimento.status === '4') {colorStatus = "#F35757"} // Reprovado

    let messageStatus = '';
    if(estabelecimento.status === '0') {messageStatus = "Aprovado"} // Aprovado
    if(estabelecimento.status === '1') {messageStatus = "Em análise"} // Em análise
    if(estabelecimento.status === '2') {messageStatus = "Documentos pendentes"} // Documentos pendentes
    if(estabelecimento.status === '3') {messageStatus = "Documentos checados"} // Documentos checados
    if(estabelecimento.status === '4') {messageStatus = "Reprovado"} // Reprovado

    return <span style={{ color: colorStatus }}>{messageStatus}</span>
  }, []);

  // const [formOpened, setFormOpened] = useState(true);

  return (
    <Container>
      <MenuBar active="AnaliseEC" />
      <NewMobileMenu active="AnaliseEC" />

      <Page>
        <TopBar pageTitle="Estabelecimentos" />
        
        <Content>

          {/* <AddEC>
            <span>Adicionar EC</span>
            <Button onClick={() => setFormOpened(!formOpened)}>
              {formOpened === true ? <MdAdd /> : <MdRemove />}
            </Button>
          </AddEC> */}

          <TableContent>
            <FilterDate style={{ background: dataFiltrada ? "#EFF2F7" : "#FFFFFF" }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
                onClick={() => setFiltroAberto(!filtroAberto)}
              >
                <span>Filtrar data</span>
                <FiCalendar color="#0073FC" />
              </div>

              {dataFiltrada && (
                <FiXCircle
                  className="apagarFiltro"
                  color="#c53030"
                  size={22}
                  onClick={() => {
                    setEstabelecimentosVisiveis(estabelecimentos);
                    setDataFiltrada(false);
                    setFiltroAberto(false);
                  }}
                />
              )}
            </FilterDate>

            <DatePicker filtroAberto={filtroAberto}>
              <DateRange
                locale={ptBR}
                dateDisplayFormat="dd/MM/yyyy"
                monthDisplayFormat="MMMMM"
                weekdayDisplayFormat="EEEEE"
                onChange={item => setSelectionDate([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={selectionDate}
              />

              <ButtonBox>
                <button type="button" className="back" onClick={() => setFiltroAberto(false)}>Cancelar</button>
                <button type="button" onClick={() => filterByDate()}>Filtrar</button>
              </ButtonBox>
            </DatePicker>

            <Select
              placeholder="Selecione"
              onChange={(value) => filterByStatus(value)}
              className="filterStatusSelect"
              options={[
                { value: "0", label: 'Aprovado' },
                { value: "1", label: 'Em Analise' },
                { value: "2", label: 'Doc Pendentes' },
                { value: "3", label: 'Doc Checados' },
                { value: "4", label: 'Reprovado' },
                { value: "5", label: 'Todos' },
              ]}
            />

            <MaterialTable
              title="Lista de Estabelecimentos"
              columns={headData}
              data={bodyData}
              components={{
                Toolbar: props => (
                  <div>
                    <MTableToolbar {...props} />
                    
                  </div>
                )
              }}
              localization={{
                pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{from}-{to} de {count}',
                  firstTooltip: 'Primeira página',
                  previousTooltip: 'Página anterior',
                  nextTooltip: 'Próxima página',
                  lastTooltip: 'Última página'
                },
                toolbar: {
                  searchPlaceholder: 'Buscar',
                  searchTooltip: 'Buscar'
                },
                body: {
                  emptyDataSourceMessage: 'Nenhum dado encontrado',
                }
              }}
              options={{
                actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: '#10104F',
                  color: '#FFF'
                },
                emptyValue: "Nenhum dado encontrado"
              }}
            />

          </TableContent>

          <TableContentMobile>
            <Header>
              <h3>Lista de Estabelecimentos</h3>

              <Select
                placeholder="Status"
                onChange={(value) => filterByStatus(value)}
                className="filterStatusSelect" options={[
                  { value: "0", label: 'Aprovado' },
                  { value: "1", label: 'Em Analise' },
                  { value: "2", label: 'Doc Pendentes' },
                  { value: "3", label: 'Doc Checados' },
                  { value: "4", label: 'Reprovado' },
                  { value: "5", label: 'Todos' },
                ]}
              />

              <span>Total: {estabelecimentosVisiveis.length}</span>
            </Header>

            <MobileBody>
              {estabelecimentosVisiveis.map((estabelecimento) => (
                <Line key={estabelecimento.id}>
                  <span>{estabelecimento.estabelecimento}</span>
                  <p>{estabelecimento.responsavel}</p>
                  <p>LA: {estabelecimento.la}</p>
          
                  <div className="footer">
                    <span>{estabelecimentoStatus(estabelecimento)}</span>
                    { estabelecimento.status === "0" ? <img src={editIcon} alt="" style={{ opacity: 0.4 }} /> : <img src={editIcon} alt="" onClick={() => editEC(estabelecimento)}/> }
                  </div>
                </Line>
              ))}
            </MobileBody>

            {/* <Pagination>
              <span>Total: {total}</span>
              <PaginationButton>
                <PaginationItem><MdNavigateBefore /></PaginationItem>
                {pages.map(page => (
                  <PaginationItem 
                    key={page}
                    onClick={() => setCurrentPage(page)}
                  >{page}</PaginationItem>
                ))}
                <PaginationItem><MdNavigateNext /></PaginationItem>
              </PaginationButton>
            </Pagination> */}

          </TableContentMobile>
        </Content>
      </Page>
    </Container>
  );
}

export default AdminEstabelecimentos;
