import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Tooltip } from '@material-ui/core/';
import { MdFiberManualRecord } from 'react-icons/md';
import { FaEye } from 'react-icons/fa';

import api from '../../services/api';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';
import FormatarValor from '../../components/FormatarValor';

import editIcon from '../../assets/images/edit_icon.svg';

// import { useTransacaoInfos } from '../../hooks/TransacaoInfosContext';

import {
  Container,
  Page,
  Content,
  TableContent,
  TableContentMobile,
  Header,
  Line,
  MobileBody,
} from './styles';

function EPrepag() {
  const history = useHistory();

  const [transacoes, setTransacoes] = useState([]);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  const [produtos, setProdutos] = useState([]);
  const [estabelecimentos, setEstabelecimentos] = useState([]);

  // transações
  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get('/eprepag', auth).then(response => {
      const result = response.data;
      // console.log(response)
      // console.log(result);

      setTransacoes(result.data.data);
    });

    api.get("/eprepag/get/portifolio", auth).then((response) => {
      const result = response.data.data.produtos.JOGO;
      // console.log(result)
       setProdutos(result)
    });
    api.get("/estabelecimento?png=99999", auth).then((response) => {
      const result = response.data.data;
      // console.log(result)
       setEstabelecimentos(result)
    });

    }, [userToken]);

  // console.log(transacoes)

  // Informações Head da tabela
  const headData = [
    { title: 'Data da transação', field: 'data', sorting: false },
    { title: 'Tipo do produto', field: 'tipo', sorting: false },
    { title: 'Estabelecimento', field: 'est', sorting: false },
    // { title: 'Licenciado', field: 'lic', sorting: false },
    { title: 'Valor', field: 'valor', sorting: false },
    { title: 'Status', field: 'status', align: 'right', sorting: false },
    { title: '', field: 'edit', align: 'center', sorting: false },
  ];

  // Informações Body da tabela
  const bodyData = transacoes === [] || transacoes === "Nenhuma transação encontrada" ? []
  : transacoes.map(transacao => {

    let colorStatus = '';
    if(transacao.status_id === 1) {colorStatus = "#FEAC02"} // Em análise
    if(transacao.status_id === 2) {colorStatus = "#5097FF"} // Solicitar documentos
    if(transacao.status_id === 4) {colorStatus = "#F35757"} // Cancelada
    if(transacao.status_id === 3) {colorStatus = "#5CB85C"} // Aprovada 
    if(transacao.status_id === 5) {colorStatus = "#8850FF"} // Checagem financeira

    let messageStatus = '';
    if(transacao.status_id === 1) {messageStatus = "Em análise"} // Em análise
    if(transacao.status_id === 2) {messageStatus = "Solicitar documentos"} // Solicitar documentos
    if(transacao.status_id === 4) {messageStatus = "Cancelada"} // Cancelada
    if(transacao.status_id === 3) {messageStatus = "Aprovada"} // Aprovada 
    if(transacao.status_id === 5) {messageStatus = "Checagem financeira"} // Checagem financeira

    // console.log(transacao);

    const status = <Tooltip title={messageStatus} placement="top" arrow><span><MdFiberManualRecord color={colorStatus} /></span></Tooltip>

    // const valor = <span style={{ color: "#0073FC", fontWeight: 600 }}>R$ {totalTransação}</span>;

    const imgEdit = <FaEye color="#333333" size={21} />;
    const iconTooltip = transacao.status === 3 ? "Ver Dados da Transação" : "Editar Dados da Transação";

    const dataTransacao = transacao.created_at.substring(0, 10).split('-').reverse().join('/');
    const horaTransacao = transacao.created_at.substring(11, 16);

    const dataEHora = <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{dataTransacao}</span>
      <span style={{ color: '#A6AAB4', fontSize: 14 }}>{horaTransacao}</span>
    </div>;

    let produto = '';

    produtos.filter((prod) => {
      if(String(prod.id) === String(transacao.code)){
        produto = prod.nome;
      }
    });

    let estabelecimento = '';

    estabelecimentos.filter((esta) => {
      // console.log(esta);
      console.log();
      if(String(esta.id) === String(transacao.estabelecimento_id)){
        estabelecimento = esta.nome_fantasia;
      }
    });


    return {
      tipo: produto,
      resp: transacao.cliente,
      est: estabelecimento,
      // lic: transacao.colaborador_id,
      valor: <span style={{ color: "#0073FC", fontWeight: 600 }}>R$ {FormatarValor(transacao.valor)}</span>,
      data: dataEHora,
      status: status,
      edit: <Tooltip title={iconTooltip} placement="top" arrow><span style={{ cursor: "pointer" }} onClick={() => editTransacao(transacao.id)}>
            { imgEdit }
        </span></Tooltip>
    }
  });

  const editTransacao = useCallback((id) => {
    history.push(`/aprovar-eprepag/${id}`);
  }, [history]);

  const filterByStatus = useCallback((value) => {
    const filterValue = Number(value.value);

    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    if (filterValue !== 0) {
      api.get('/eprepag', auth).then(response => {
        const valueTransacoes = response.data.data.data;
      
        const filteredData = valueTransacoes.filter(item => item.status_id === filterValue);
  
        setTransacoes(filteredData);
      })
    }

    if (filterValue === 0) {
      api.get('/eprepag', auth).then(response => {
        const valueTransacoes = response.data.data.data;

        setTransacoes(valueTransacoes);
      })
    }

  }, [userToken]);

  const transacaoStatus = useCallback((transacao) => {
    let colorStatus = '';
    if(transacao.status_id === 1) {colorStatus = "#FEAC02"} // Em análise
    if(transacao.status_id === 2) {colorStatus = "#5097FF"} // Solicitar documentos
    if(transacao.status_id === 3) {colorStatus = "#5CB85C"} // Aprovada
    if(transacao.status_id === 4) {colorStatus = "#F35757"} // Cancelado
    if(transacao.status_id === 5) {colorStatus = "#8850FF"} // Checagem financeira

    let messageStatus = '';
    if(transacao.status_id === 1) {messageStatus = "Em análise"} // Em análise
    if(transacao.status_id === 2) {messageStatus = "Solicitar documentos"} // Solicitar documentos
    if(transacao.status_id === 3) {messageStatus = "Aprovada"} // Aprovada
    if(transacao.status_id === 4) {messageStatus = "Cancelado"} // Cancelado
    if(transacao.status_id === 5) {messageStatus = "Checagem financeira"} // Checagem financeira

    return <span style={{ color: colorStatus }}>{messageStatus}</span>
  }, []);

  return (
    <Container>
      <MenuBar active="Transações" subMenuActive="E-prepag" />
      <NewMobileMenu active="Transações" subMenuActive="E-prepag" />

      <Page>
        <TopBar pageTitle="Transações -" pageSubtitle="E-prepag" />

        <Content>
          <TableContent>

            <Select
              placeholder="Selecione"
              onChange={(value) => filterByStatus(value)}
              className="filterStatusSelect"
              options={[
                { value: '1', label: 'Em Analise' },
                { value: '2', label: 'Solicitar documentos' },
                { value: '3', label: 'Aprovada' },
                { value: '4', label: 'Cancelada' },
                { value: '5', label: 'Checagem financeira' },
                { value: '0', label: 'Todos' }
              ]}
            />

            <MaterialTable
              title="Lista de Transações"
              columns={headData}
              data={bodyData}
              components={{
                Toolbar: props => (
                  <div>
                    <MTableToolbar {...props} />
                    
                  </div>
                )
              }}
              localization={{
                pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{from}-{to} de {count}',
                  firstTooltip: 'Primeira página',
                  previousTooltip: 'Página anterior',
                  nextTooltip: 'Próxima página',
                  lastTooltip: 'Última página'
                },
                toolbar: {
                  searchPlaceholder: 'Buscar',
                  searchTooltip: 'Buscar'
                },
                body: {
                  emptyDataSourceMessage: 'Nenhum dado encontrado',
                }
              }}
              options={{
                actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: '#10104F',
                  color: '#FFF'
                },
                emptyValue: "Nenhum dado encontrado"
              }}
            />

          </TableContent>

          <TableContentMobile>
            <Header>
              <h3>Lista de Transações</h3>

              <Select
                placeholder="Status"
                onChange={(value) => filterByStatus(value)}
                className="filterStatusSelect"
                options={[
                  { value: '1', label: 'Em Analise' },
                  { value: '2', label: 'Solicitar documentos' },
                  { value: '3', label: 'Aprovada' },
                  { value: '4', label: 'Cancelada' },
                  { value: '5', label: 'Checagem financeira' },
                  { value: '0', label: 'Todos' }
                ]}
              />

              <span>Total: {transacoes.length}</span>
            </Header>

            <MobileBody>
              {transacoes.map((transacao) =>  { 
                let produto = '';

                  produtos.filter((prod) => {
                    if(String(prod.id) === String(transacao.code)){
                      produto = prod.nome;
                    }
                  });
                  const dataTransacao = transacao.created_at.substring(0, 10).split('-').reverse().join('/');
                  const horaTransacao = transacao.created_at.substring(11, 16);
              
                  const dataEHora = <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <span>{dataTransacao}</span>
                    <span style={{ color: '#A6AAB4', fontSize: 14, marginLeft: 5 }}>{horaTransacao}</span>
                  </div>;

                  let estabelecimento = '';

                  estabelecimentos.filter((esta) => {
                    // console.log(esta);
                    console.log();
                    if(String(esta.id) === String(transacao.estabelecimento_id)){
                      estabelecimento = esta.nome_fantasia;
                    }
                  });

                  return(
                    <Line key={transacao.id}>
                      <span>{produto}</span>
                      <p>{dataEHora}</p>

                      <p>Valor: {FormatarValor(transacao.valor)}</p>
                      
                      <p>{estabelecimento}</p>
              
                      <div className="footer">
                        <span>{transacaoStatus(transacao)}</span>
                        { transacao.status === 3 ? <img src={editIcon} alt="" style={{ opacity: 0.4 }}/> : <img src={editIcon} alt="" onClick={() => editTransacao(transacao.id)}/> }
                      </div>
                    </Line>
              )})}
            </MobileBody>
          </TableContentMobile>
        </Content>
      </Page>
    </Container>
  );
}

export default EPrepag;
