import React, { createContext, useState, useContext } from 'react';

const UserSimulatorInfosContext = createContext();

function UserSimulatorInfosProvider({ children }) {
  const [userSimulatorInfos, setUserSimulatorInfos] = useState({
    nome: '',
    email: '',
    telefone: ''
  });

  return (
    <UserSimulatorInfosContext.Provider
      value={{ userSimulatorInfos, setUserSimulatorInfos }}
    >
      {children}
    </UserSimulatorInfosContext.Provider>
  );
};

function useUserSimulatorInfos() {
  const context = useContext(UserSimulatorInfosContext);
  const {
    userSimulatorInfos, setUserSimulatorInfos
  } = context;
  return {
    userSimulatorInfos, setUserSimulatorInfos
  };
};

export { UserSimulatorInfosProvider, useUserSimulatorInfos };
