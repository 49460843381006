import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';

import api from '../../services/api';

import backgroundImg from '../../assets/images/acompanhamento_interno_2.png';
import logoImg from '../../assets/images/logo.png';

import {
  Container,
  BackgroundImg,
  Content,
  Box,
  Infos,
} from './styles';

function AcompanhamentoInterna() {
  const history = useHistory();
  const location = useLocation();

  const [transacaoInfos, setTransacaoInfos] = useState('');

  const idTransacao = location.pathname.replace('/acompanhamento/transacao/', '');
  
  useEffect(() => {
    if (idTransacao === '') {
      history.push('/acompanhamento');
    }
    
    async function getTransacaoInfos() {
      const response = await api.get(`/consulta_transacao/${idTransacao}`);
      const result = response.data;

      // console.log(result);

      setTransacaoInfos(result[0]);
    }

    getTransacaoInfos()

  }, [history, idTransacao, location.pathname]);

  // Função que configura a data de solicitação
  function data() {
    if (transacaoInfos.data_criacao === undefined) {
      return
    }
    
    const dataSolicitacao = transacaoInfos.data_criacao.substring(0, 10).split('-').reverse().join('/');

    return dataSolicitacao
  }

  // Função que configura o status da transação
  function status() {

    if (transacaoInfos.status === 1) {
      return <span style={{ backgroundColor: "#FEAC02", color: '#fff', fontSize: '14px', fontWeight: 600, padding: '6px 12px', borderRadius: '3px' }}>Em análise</span>
    }

    if (transacaoInfos.status === 2) {
      return <span style={{ backgroundColor: "#5097FF", color: '#fff', fontSize: '14px', fontWeight: 600, padding: '6px 12px', borderRadius: '3px' }}>Solicitar documentos</span>
    }

    if (transacaoInfos.status === 3) {
      return <span style={{ backgroundColor: "#5CB85C", color: '#fff', fontSize: '14px', fontWeight: 600, padding: '6px 12px', borderRadius: '3px' }}>Aprovada</span>
    }

    if (transacaoInfos.status === 4) {
      return <span style={{ backgroundColor: "#F35757", color: '#fff', fontSize: '14px', fontWeight: 600, padding: '6px 12px', borderRadius: '3px' }}>Cancelada</span>
    }

    if (transacaoInfos.status === 5) {
      return <span style={{ backgroundColor: "#8850FF", color: '#fff', fontSize: '14px', fontWeight: 600, padding: '6px 12px', borderRadius: '3px' }}>Checagem financeira</span>
    }
  }

  return (
    <Container>
      <BackgroundImg>
        <img src={backgroundImg} alt="Deixa no azul"/>
      </BackgroundImg>

      <Content>
        <Box>
          <img src={logoImg} alt="Deixa no Azul" />

          <Infos>
            {/* <p><strong>Id da transação:</strong> {transacaoInfos.id}</p> */}
            <p><strong>Nome do solicitante:</strong> {transacaoInfos.nome_cliente}</p>
            <p><strong>Data de solicitação:</strong> {data()}</p>
            <p><strong>Estabelecimento responsável:</strong> {transacaoInfos.nome_fantasia_ec}</p>
            <p><strong>Status da transação:</strong> {status()}</p>
          </Infos>

          <Link to="/acompanhamento"><MdArrowBack size={16} />Voltar para o login</Link>
        </Box>

        <span>© 2020. Todos os direitos reservados</span>
      </Content>
    </Container>
  );
}

export default AcompanhamentoInterna;
