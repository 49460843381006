import styled, { css } from 'styled-components';

import { shade } from 'polished';

import backgroundImg from '../../assets/images/background.jpg';

import ToolTip from '../../components/Tooltip';

export const Container = styled.div``;

export const Page = styled.div`
  margin-left: 220px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  /* height: calc(100vh - 72px); */
  background: url(${backgroundImg}) no-repeat;
  background-position: top left;
  background-size: 100% 256px;
  box-sizing: border-box;

  padding: 100px 160px 0 30px;
  margin-bottom: 140px;

  @media (max-width: 500px) {
    padding: 50px 20px 20px;
    margin-bottom: 0;
  }
`;

export const SimuladorBox = styled.div`
  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  margin-bottom: 30px;

  button {
    padding: 8px 22px;
    width: 110px;
    background: #FE9902;
    border: 1px solid #FE9902;
    color: #FFFFFF;
    border: none;
    outline-style: none;
    border-radius: 4px;

    margin-top: 20px;
    transition: 0.2s;

    &:hover {
      background: ${shade(0.2, '#FE9902')};
    }
  }

  @media (max-width: 500px) {
    ${props => props.infosOpened && css`
      margin-bottom: 100px;
    `}
  } 
  
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 18px;

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 10px;
  }

  h3 {
    font-size: 20px;
    font-weight: normal;
    margin: 0;

    @media (max-width: 500px) {
      margin-bottom: 14px;
    }
  }

  > div {
    display: grid;
    grid-template-columns: 1.4fr 1fr;
    gap: 20px;
    max-width: 370px;
    width: 100%;

    .imgSelect {
      margin-left: 6px;
    }

    @media (max-width: 500px) {
      grid-template-columns: 1.2fr 1fr;
    }
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  width: 100%;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  } 
`;

export const Row3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  width: 100%;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  } 
`;

export const Row4 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;

  width: 100%;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const InputArea = styled.div``;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  /* border: none; */
  border-radius: 4px;
  box-sizing: border-box;

  margin-bottom: 18px;
  padding: 1px 11px 1px 1px;

  ${props => props.isErrored && css`
    border: 1px solid #c53030;
  `}

  ${props => props.isFocused && css`
    border: 1px solid #0073FC;
  `}

  input {
    border: none;
    /* border: 1px solid #CCCCCC; */

    height: 34px;
    width: 100%;
    padding: 0 10px;
  }

  select {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;
  }
`;

export const Error = styled(ToolTip)`
  span {
    background: #c53030;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;

  height: 38px;
  margin-bottom: 18px;
  padding: 1px 0 1px 1px;
  width: 100%;
  box-sizing: border-box;

  div {
    display: flex;
    align-items: center;
    margin-right: 24px;

    input {
      margin-right: 6px;
    }
  }

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 70px;
  }
`;

export const InfoBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  ${props => props.infosOpened && css`
    display: none;
  `}

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 30px;
    margin-bottom: 100px;
  }
`;

export const InfoEC = styled.div`
  /* max-width: 380px;
  width: 100%; */
  height: 234px;
`;

export const HeadInfoEC = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;

  h3 {
    font-size: 20px;
    font-weight: normal;
    margin: 0;
  }

  > div {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

export const BodyInfoEC = styled.div`
  ${props => props.infoECOpened && css`
    display: none;
  `}

  margin-top: -8px;

  background: #fff;
  padding: 8px 30px 20px;
  border-radius: 0 0 8px 8px;
  height: 180px;

  > div {
    margin-bottom: 20px;

    label {
      display: block;
      margin: 0;
    }

    span {
      font-size: 24px;
      font-weight: 600;
    }
  }

  p {
    font-size: 14px;
    line-height: 18px;
    color: #0275D8;
    padding-right: 40px;
  }
`;

export const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(55, 58, 60, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 5;

  img {
    width: 220px;
  }

  ${props => props.loadingHide && css`
    display: none;
  `}
`;
