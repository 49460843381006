import React from 'react';

import backgroundImg from "../../assets/images/background-credenciamento_fim.png";

import { Container, BackgroundImg, Content } from './styles';

function SelfCredenciamentoFim() {
  const anoAtual = new Date().getFullYear();

  return (
    <Container>
      <Content>
        <h1>Tudo certo!</h1>

        <span>Recebemos o seu cadastro e quando for aprovado você será notficado por e-mail.</span>

        <span><blue>É só aguardar!</blue></span>
      </Content>

      <span>© {anoAtual}. Todos os direitos reservados</span>

      <BackgroundImg>
        <img src={backgroundImg} alt="Deixa no azul" />
      </BackgroundImg>
    </Container>
  );
};

export default SelfCredenciamentoFim;
