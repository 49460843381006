import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import * as firestore from "firebase/firestore";
import { FiMessageCircle, FiX, FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { MdErrorOutline } from "react-icons/md";
import moment from "moment";

import { firebaseDatabase } from '../../services/firebase';

import { useToast } from "../../hooks/ToastContext";

import { sendMessage } from '../../utils/sendMessage';

import {
  Container,
  ChatButton,
  ChatContainer,
  ChatContent,
  ChatInputArea,
  ChatInputContainer,
  Error,
  SentMessagesButton,
  SentMessagesContainer,
  SentMessagesHeader,
  SentMessagesContent,
  Message,
} from './styles';

function SendMessageComponent({ userID, transactionID, transactionType, allowMessage }) {
  const { addToast } = useToast();

  const [chatOpened, setChatOpended] = useState(false); // Default false
  const [sentMessagesOpened, setSentMessagesOpended] = useState(false); // Default false

  const [messages, setMessages] = useState([]); // Default false

  const {
    register,
    // handleSubmit,
    getValues,
    // setValue,
    setError,
    clearErrors,
    errors,
  } = useForm();

  // Função que envia mensagem para o Estabelecimento
  async function sendMessageToEC() {
    const inputTitle = getValues('titulo_mensagem');
    const inputMessage = getValues('corpo_mensagem');

    if (inputTitle === '') {
      setError("titulo_mensagem", {
        type: "manual",
        message: "Campo obrigatório.",
      });

      addToast({
        type: "error",
        title: "Erro no envio",
        description: "Você precisa digitar um título para a mensagem.",
      });

      return;
    } else {
      clearErrors('titulo_mensagem');
    }

    if (inputMessage === '') {
      setError("corpo_mensagem", {
        type: "manual",
        message: "Campo obrigatório.",
      });

      addToast({
        type: "error",
        title: "Erro no envio",
        description: "Você precisa digitar uma mensagem.",
      });

      return;
    } else {
      clearErrors('corpo_mensagem');
    }

    // mensagem
    const messageSender = await sendMessage({
      recipient_id: userID,
      subject: inputTitle,
      message: inputMessage,
      transaction_type: transactionType,
      transaction_id: transactionID,
    });

    // Exige mensagem de erro no envio da mensagem
    if (messageSender.status === false) {
      addToast({
        type: "error",
        title: "Erro ao enviar mensagem",
        description: "Não conseguimos enviar sua mensagem por um erro desconhecido.",
      });

      return;
    }

    // Fecha o box de mensagem
    setChatOpended(false);
  }

  async function getMessagesSentToThisTransaction() {
    const mensagensCollectionRef = firestore.collection(firebaseDatabase, 'mensagens');

    const customQuery = firestore.query(mensagensCollectionRef, firestore.where("transaction_id", "==", transactionID));

    await firestore.getDocs(customQuery).then(response => {
      // const data = response.docs.map((doc) => ({...doc.data(), id: doc.id}));
      const data = response.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      // console.log('dataMensagens', data);
      setMessages(data);
    });
  }

  function dia(value) {
    const dia = moment(value.seconds*1000).format('DD/MM/yyyy');
    // const hora = moment(value.seconds*1000).format('HH:mm');
    // console.log(hora);

    return dia;
  }

  return (
    <Container>
      {allowMessage && (
        <ChatButton
          type="button"
          onClick={() => {
            setChatOpended(!chatOpened);
            // setSentMessagesOpended(false);
          }}
          chatOpened={chatOpened}
          sentMessagesOpened={sentMessagesOpened}
        >
          {/* {chatOpened ? <FiX size={24} /> : <FiMessageCircle size={24} />} */}
          <FiMessageCircle size={24} />
        </ChatButton>
      )}

      <SentMessagesButton
        type="button"
        onClick={() => {
          setSentMessagesOpended(!sentMessagesOpened);
          setChatOpended(false);
          messages.length === 0 && getMessagesSentToThisTransaction();
        }}
        sentMessagesOpened={sentMessagesOpened}
      >
        {sentMessagesOpened ? <FiArrowRight size={20} /> : <FiArrowLeft size={20} />}
        Mensagens enviadas
      </SentMessagesButton>

      {chatOpened && (
        <ChatContainer sentMessagesOpened={sentMessagesOpened}>
          <h3>Enviar mensagem para EC</h3>

          <button type="button" onClick={() => setChatOpended(!chatOpened)}><FiX size={24} /></button>

          <ChatContent>
            <ChatInputArea>
              <label>Título da mensagem</label>
              <ChatInputContainer style={{ borderColor: errors.titulo_mensagem && "red" }}>
                <input
                  name="titulo_mensagem"
                  type="text"
                  placeholder="Digite aqui o título"
                  ref={register({ required: chatOpened })}
                  // onBlur={validDate}
                />
                {errors.titulo_mensagem && <Error title={errors.titulo_mensagem.message}><MdErrorOutline color="c53030" size={20} /></Error>}
              </ChatInputContainer>
            </ChatInputArea>

            <ChatInputArea>
              <label>Mensagem</label>
              <ChatInputContainer style={{ borderColor: errors.corpo_mensagem && "red" }}>
                <textarea
                  name="corpo_mensagem"
                  rows={5}
                  type="text"
                  placeholder="Digite aqui o título"
                  ref={register({ required: chatOpened })}
                  // onBlur={validDate}
                />
                {errors.corpo_mensagem && <Error title={errors.corpo_mensagem.message}><MdErrorOutline color="c53030" size={20} /></Error>}
              </ChatInputContainer>
            </ChatInputArea>

            <button
              type="button"
              onClick={() => {
                sendMessageToEC();
              }}
            >
              Enviar
            </button>
          </ChatContent>
        </ChatContainer>
      )}

      <SentMessagesContainer sentMessagesOpened={sentMessagesOpened}>
        <SentMessagesHeader>
          <h3>Mensagens enviadas ao EC</h3>

          <button type="button" onClick={() => setSentMessagesOpended(!sentMessagesOpened)}><FiX size={24} /></button>
        </SentMessagesHeader>

        <SentMessagesContent>
          {messages.map(message => (
            <Message>
              <div>
                <p>{message.sender_name ? message.sender_name : 'Sem nome'}</p>
                <span>{dia(message.created_at)}</span>
              </div>

              <h4>{message.subject}</h4>

              <p>{message.message}</p>
            </Message>
          ))}
        </SentMessagesContent>
      </SentMessagesContainer>
    </Container>
  );
}

export default SendMessageComponent;
