import styled, { css } from 'styled-components';

import { shade } from 'polished';

import backgroundImg from '../../assets/images/background.jpg';

import ToolTip from '../../components/Tooltip';

export const Container = styled.div``;

export const Page = styled.div`
  margin-left: 220px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  /* height: calc(100vh - 72px); */
  background: url(${backgroundImg}) no-repeat;
  background-position: top left;
  background-size: 100% 256px;
  box-sizing: border-box;

  padding: 100px 160px 0 30px;

  @media (max-width: 500px) {
    padding: 50px 20px 20px;
  }
`;

export const Saldo = styled.div`
  display: inline-block;

  background: #10104F;
  border-radius: 8px;
  padding: 30px 25px;

  margin-bottom: 30px;

  span {
    color: #fff;
    font-size: 20px;
  }

  button {
    padding: 8px 22px;
    background: #5CB85C;
    border: 1px solid #5CB85C;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    outline-style: none;

    margin-left: 40px;
    transition: 0.2s;

    &:hover {
      background: ${shade(0.2, '#5CB85C')}
    }
  }

  @media (max-width: 500px) {
    button {
      margin-top: 20px;
      margin-left: 0;
    }
  }
`;

export const TableContent = styled.div`
  position: relative;

  th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-paddingCheckbox {
    background: #10104F;
  }

  span.MuiButtonBase-root.MuiTableSortLabel-root.Mui-disabled {
    color: transparent;
  }

  .MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.Component-paginationToolbar-2.MuiToolbar-gutters {
    background-color: #fff;
  }

  tr.MuiTableRow-root {
    border: none;
  }

  tr.MuiTableRow-root:nth-of-type(odd) {
    background-color: #F5F5F5;
  }

  .MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-5.MuiToolbar-gutters {
    padding-bottom: 20px;
    height: 100px;
  }

  .MuiToolbar-regular {
    min-height: 84px;
  }
  
  .css-2b097c-container {
    position: absolute;
    box-sizing: border-box;
    width: 140px;
    top: 23px;
    right: 300px;
    z-index: 50;
  }

  .MuiInput-underline:before {
    border-bottom: none;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const Infos = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 30px;

  margin-top: 30px;
  margin-bottom: 60px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

export const FirstBox = styled.div`
  padding: 20px 22px;
  background: #fff;
  border-radius: 8px;

  > div {
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-bottom: 30px;
    }

    label {
      font-size: 18px;
      margin: 0;
    }

    a {
      display: inline;
      font-size: 18px;
      text-decoration: none;
      color: #5097FF;
    }
  }

  > div.disabled {
    display: none;
  }
`;

export const SecondBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  padding: 20px 22px;
  background: #fff;
  border-radius: 8px;

  max-height: 110px;

  > div {

    label {
      font-size: 18px;
      margin: 0;
    }

    p {
      font-size: 18px;
      color: #5097FF;
    }
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    margin-bottom: 80px;
    max-height: 100%;
  }
`;

export const ModalSection = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(55, 58, 60, 0.2);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 20;

  ${props => props.modalOpened && css`
    display: none;
  `}
`;

export const ModalContent = styled.div`
  color: #fff;
  background: #10104F;
  padding: 30px;
  border-radius: 8px;
  max-width: 640px;
  /* max-height: 420px; */
  position: relative;

  width: 100%;
  height: 440px;

  > svg {
    margin-left: calc(100% - 30px);
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      color: ${shade(0.2, '#fff')};
    }
  }

  span.marginBottom {
    font-weight: 600;
    color: #FEAC02;
  }

  @media (max-width: 500px) {
    width: 94%;
    padding: 20px;
    margin-bottom: 20px;

    > p {
      br {
        display: none;
      }
    }
  }
`;

export const ModalAccountData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 10px;

  > p {
    font-size: 20px;
    margin-bottom: 5px;
  }

  span {
    font-size: 17px;
    font-weight: 400;
  }

  @media (max-width: 500px) {
    margin-bottom: 20px;
  }
`;

export const TableContentMobile = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 20px 3px;
  /* margin-bottom: 100px; */

  @media (min-width: 500px) {
    display: none;
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid #EEEEEE;
  padding: 0 17px 10px;

  h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .filterStatusSelect.css-2b097c-container {
    width: 200px;
    margin-bottom: 20px;
  }

  > span {
    margin-bottom: 20px;
  }
`;

export const Line = styled.div`
  padding: 15px 17px;
  border-bottom: 1px solid #EEEEEE;

  > span {
    font-size: 18px;
    color: #313131;
  }

  > p {
    font-size: 14px;
    color: #9FA7BA;
    margin: 0;
  }

  div.footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    > span {
      font-size: 12px;
      font-weight: 700;
      /* color: #5CB85C; */
    }
  }
`;

export const MobileBody = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  height: 448px;
`;

export const NavegaçãoAbas = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* cursor: pointer; */
  margin-bottom: 10px;

  > span {
    color: #404072;
    margin: 0 5px;
    font-weight: 700;
    margin-bottom: 4px;

    cursor: default;
  }

  ${props => props.points && css`
    span {
      color: #0073FC;
    }
  `}
`;

export const BotãoAba = styled.div`
  /* width: 100%; */
  /* max-width: 24px; */
  /* color: #404072; */

  width: 18px;
  height: 18px;

  border-radius: 9px;

  background: #23236C;
  border: 2px solid #23236C;

  text-align: center;
  cursor: pointer;

  ${props => props.tab === 'tab1' && css`
    &.aba1 {
      background: #23236C;
      border: 2px solid #0073FC;
    }

    &.aba2 {  
      background: #23236C;
      border: 2px solid #23236C;
    }

    &.aba3 {  
      background: #23236C;
      border: 2px solid #23236C;
    }
  `}

  ${props => props.tab === 'tab2' && css`
    &.aba1 {
      background: #0073FC;
      border: 2px solid #0073FC;
    }

    &.aba2 {  
      background: #23236C;
      border: 2px solid #0073FC;
    }

    &.aba3 {  
      background: #23236C;
      border: 2px solid #23236C;
    }
  `}

  ${props => props.tab === 'tab3' && css`
    &.aba1 {
      background: #0073FC;
      border: 2px solid #0073FC;
    }

    &.aba2 {  
      background: #0073FC;
      border: 2px solid #0073FC;
    }

    &.aba3 {  
      background: #23236C;
      border: 2px solid #0073FC;
    }
  `}
`;

export const Line1 = styled.div`
  width: 50px;
  height: 0px;
  border: 1px solid #23236C;

  ${props => props.tab === 'tab2' && css`
    border: 1px solid #0073FC;
  `}

  ${props => props.tab === 'tab3' && css`
    border: 1px solid #0073FC;
  `}
`;

export const Line2 = styled.div`
  width: 50px;
  height: 0px;
  border: 1px solid #23236C;

  ${props => props.tab === 'tab3' && css`
    border: 1px solid #0073FC;
  `}
`;

export const FirstStep = styled.div`
  visibility: hidden;
  height: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  ${props => props.tab === 'tab1' && css`
    visibility: visible;
    height: 100%;
  `}

  > span {
    font-size: 20px;
    display: block;
    text-align: center;

    &.marginBottom {
      margin-bottom: 20px;
    }
  }

  button {
    position: absolute;
    bottom: 30px;
    
    padding: 8px 22px;
    width: 130px;
    background: #0073FC;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;

    margin-top: 10px;
    transition: 0.2s;

    &:hover {
      background: ${shade(0.2, '#0073FC')};
    }
  }
`;

export const SecondStep = styled.div`
  visibility: hidden;
  height: 0;

  ${props => props.tab === 'tab2' && css`
    visibility: visible;
    height: 100%;
  `}

  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 6px;
  margin: 0 auto;
  max-width: 420px;
  width: 100%;

  > span {
    font-size: 20px;
    display: block;
    text-align: center;
  }

  div.buttonArea {
    display: flex;
    justify-content: center;

    position: absolute;
    bottom: 30px;

    button {
      padding: 8px 22px;
      width: 140px;
      background: #0073FC;
      color: #FFFFFF;
      border: none;
      border-radius: 4px;

      margin-top: 20px;
      transition: 0.2s;

      &:hover {
        background: ${shade(0.2, '#0073FC')};
      }
    }

    button.cancelButton {
      background: #fff;
      border: none;
      color: #373A3C;
      outline-style: none;

      margin-left: 30px;

      &:hover {
        background: ${shade(0.2, '#fff')};
      }
    }
  }
`;

export const ThirdStep = styled.div`
  visibility: hidden;
  height: 0;

  ${props => props.tab === 'tab3' && css`
    visibility: visible;
    height: 100%;
  `}

  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 6px;
  margin: 0 auto;
  max-width: 420px;
  width: 100%;

  > span {
    font-size: 20px;
    display: block;
    /* text-align: center; */
  }

  div.buttonArea {
    display: flex;
    justify-content: center;

    position: absolute;
    bottom: 30px;

    button {
      padding: 8px 22px;
      width: 130px;
      background: #0073FC;
      color: #FFFFFF;
      border: none;
      border-radius: 4px;

      margin-top: 20px;
      transition: 0.2s;

      &:hover {
        background: ${shade(0.2, '#0073FC')};
      }
    }

    button.cancelButton {
      background: #fff;
      border: none;
      color: #373A3C;
      outline-style: none;

      margin-left: 30px;

      &:hover {
        background: ${shade(0.2, '#fff')};
      }
    }
  }
`;

export const BoxInfo = styled.div`
  background: transparent;
  /* border: 1px solid #F1F5F9; */
  padding: 8px 16px 12px;
  border-radius: 6px;
  max-width: 300px;
  opacity: 0.6;
  text-align: center;

  span {
    font-size: 14px;
  }
`;

export const InputArea = styled.div`
  > label {
    display: block;

    &.marginTop {
      margin-top: 10px;
    }
  }

  a {
    display: block;
    margin: 5px 0 18px;
  }

  > p {
    color: #0073FC;
    margin: 5px 0 18px;

    &.link {
      cursor: pointer;
      text-decoration: underline;
      /* border-bottom: 1px solid #0073FC; */
    }
  }

  > button {
    padding: 8px 22px;
    width: 100%;
    background: #0073FC;
    border: 1px solid #0073FC;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;

    margin-top: 20px;
    margin-bottom: 60px;
    transition: 0.2s;

    &:hover {
      background: ${shade(0.2, '#0073FC')}
    }
  }

  div.action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    img {
      width: 16px;
      height: 16px;

      cursor: pointer;
    }
  }

  @media (max-width: 500px) {
    &.campoAnexo {
      margin-bottom: 15px;
    }
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 11px;

  border: 1px solid #fff;
  border-radius: 4px;

  width: 200px;

  &.edição {
    border: 1px solid #CCCCCC;
    background: transparent;
  }

  ${props => props.pagamentoHide && css`
    display: none;
  `}
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #10104F;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  box-sizing: border-box;

  margin-top: 10px;
  margin-bottom: 18px;
  padding: 1px 11px 1px 1px;

  ${props => props.isErrored && css`
    border: 1px solid #c53030;
  `}

  ${props => props.isFocused && css`
    border: 1px solid #0073FC;
  `}

  input {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;
  }

  select {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;

    background: #10104F;
    color: #fff;
  }
`;

export const Error = styled(ToolTip)`
  span {
    background: #c53030;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
