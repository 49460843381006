/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import MaterialTable, { MTableToolbar } from "material-table";
import { Tooltip } from "@material-ui/core/";

import TopBar from "../../components/TopBar";
import MenuBar from "../../components/MenuBar";
import NewMobileMenu from "../../components/NewMobileMenu";

import soNumeroSimbolosMask from "../../components/InputMasks/soNumeroSimbolosMask";

import FormAddTaxaSimulacao from "../../components/FormAddTaxaSimulacao";

import { useToast } from "../../hooks/ToastContext";

import {
  MdAdd,
  MdRemove,
  // MdEdit,
  // MdNavigateBefore,
  // MdNavigateNext,
} from "react-icons/md";
import editIcon from "../../assets/images/edit_icon.svg";

import api from "../../services/api";
// import { useLicensed } from '../../hooks/LicensedContext';

import {
  Container,
  Page,
  Content,
  TableContent,
  AddLA,
  Button,
  FormSection,
  TableContentMobile,
  Header,
  Line,
  MobileBody,
  BoxEditaTaxa,
} from "./styles";

function TaxaDeSilumacao() {
  const { addToast } = useToast();
  const [formOpened, setFormOpened] = useState(true);

  const [taxasDeSimulacao, setTaxasDeSimulacao] = useState([]);

  const [editarTaxaDeSimulacao, setEditarTaxaDeSimulacao] = useState(false);
  const [editarTaxaDeSimulacaoId, setEditarTaxaDeSimulacaoId] = useState();

  const [taxasDeSimulacaoValor, setTaxasDeSimulacaoValor] = useState("");

  const [numeroDeParcelas, setNumeroDeParcelas] = useState("");
  const [tipoBandeira, setTipoBandeira] = useState("");
  const [tipoMaquinete, setTipoMaquinete] = useState("");
  const [conjuntoDeTaxa, setConjuntoDeTaxa] = useState("");
  const [taxaDeServico, setTaxaDeServico] = useState("");

  const [taxaId, setTaxaId] = useState("");

  const [filtroBandeira, setFiltroBandeira] = useState("");
  const [filtroConjuntoTaxa, setFiltroConjuntoTaxa] = useState("");
  const [filtroMaquineta, setFiltroMaquineta] = useState("");

  const [atualizaTaxaSimulacao, setAtualizaTaxaSimulacao] = useState(false);

  const [
    taxasDeSimulacaoValorMenorQueZero,
    setTaxasDeSimulacaoValorMenorQueZero,
  ] = useState(false);

  const userToken = localStorage.getItem("@DeixaNoAzul:accessToken");

  useEffect(() => {
    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    api.get("/tax_simul?png=999999", auth).then((response) => {
      const result = response.data.data;
      console.log('responseTaxSim', response);

      if (
        filtroBandeira !== "" ||
        filtroConjuntoTaxa !== "" ||
        filtroMaquineta !== ""
      ) {
        // FILTRO POR BANDEIRA
        if (
          filtroBandeira &&
          filtroConjuntoTaxa === "" &&
          filtroMaquineta === ""
        ) {
          const filtroResult = result.filter((item) => {
            if (item.bandeira === filtroBandeira) {
              return item;
            }
          });
          setTaxasDeSimulacao(filtroResult);
        }

        // FILTRO POR CONJUNTO DE TAXA
        if (
          filtroConjuntoTaxa &&
          filtroBandeira === "" &&
          filtroMaquineta === ""
        ) {
          const filtroResult = result.filter((item) => {
            if (item.conjunto_de_taxa === filtroConjuntoTaxa) {
              return item;
            }
          });
          setTaxasDeSimulacao(filtroResult);
        }

        // FILTRO POR MAQUINETA
        if (
          filtroMaquineta &&
          filtroBandeira === "" &&
          filtroConjuntoTaxa === ""
        ) {
          const filtroResult = result.filter((item) => {
            if (item.maquineta === filtroMaquineta) {
              return item;
            }
          });
          setTaxasDeSimulacao(filtroResult);
        }

        // FILTRO POR CONJUNTO DE TAXA E BANDEIRA
        if (filtroConjuntoTaxa && filtroBandeira && filtroMaquineta === "") {
          const filtroResult = result.filter((item) => {
            if (
              item.bandeira === filtroBandeira &&
              item.conjunto_de_taxa === filtroConjuntoTaxa
            ) {
              return item;
            }
          });
          setTaxasDeSimulacao(filtroResult);
        }

        // FILTRO POR CONJUNTO DE TAXA E MAQUINETA
        if (filtroConjuntoTaxa && filtroMaquineta && filtroBandeira === "") {
          const filtroResult = result.filter((item) => {
            if (
              item.maquineta === filtroMaquineta &&
              item.conjunto_de_taxa === filtroConjuntoTaxa
            ) {
              return item;
            }
          });
          setTaxasDeSimulacao(filtroResult);
        }

        // FILTRO POR BANDEIRA E MAQUINETA
        if (filtroBandeira && filtroMaquineta && filtroConjuntoTaxa === "") {
          const filtroResult = result.filter((item) => {
            if (
              item.maquineta === filtroMaquineta &&
              item.bandeira === filtroBandeira
            ) {
              return item;
            }
          });
          setTaxasDeSimulacao(filtroResult);
        }

        // FILTRO BANDEIRA MAQUINETA E CONJUNTO DE TAXA
        if (filtroBandeira && filtroMaquineta && filtroConjuntoTaxa) {
          const filtroResult = result.filter((item) => {
            if (
              item.maquineta === filtroMaquineta &&
              item.bandeira === filtroBandeira &&
              item.conjunto_de_taxa === filtroConjuntoTaxa
            ) {
              return item;
            }
          });
          setTaxasDeSimulacao(filtroResult);
        }
      } else {
        setTaxasDeSimulacao(result);
      }
    });
  }, [
    userToken,
    filtroBandeira,
    filtroConjuntoTaxa,
    filtroMaquineta,
    atualizaTaxaSimulacao,
  ]);

  // Informações Head da tabela
  const headData = [
    { title: "Número de Parcelas", field: "numero_parcela", sorting: false },
    { title: "Bandeira", field: "bandeira", sorting: false },
    { title: "Maquineta", field: "maquineta", sorting: false },
    { title: "Conjunto de Taxas", field: "conjunto_taxa", sorting: false },
    { title: "Taxas de Simulação", field: "taxa_simulacao", sorting: false },
  ];

  function verificaValorTaxaSimulacao(e) {
    setTaxasDeSimulacaoValor(e);

    if (Number(taxasDeSimulacaoValor) <= 0) {
      setTaxasDeSimulacaoValorMenorQueZero(true);
    } else {
      setTaxasDeSimulacaoValorMenorQueZero(false);
    }

    if (e === "" || Number(e) <= 0) {
      setTaxasDeSimulacaoValorMenorQueZero(true);
    } else {
      setTaxasDeSimulacaoValorMenorQueZero(false);
    }
  }

  useEffect(() => {
    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    if (taxaId !== "") {
      async function update() {
        try {
          await api.put(
            `/tax_simul/${taxaId}`,
            {
              numero_parcela: numeroDeParcelas,
              bandeira: tipoBandeira,
              maquineta: tipoMaquinete,
              conjunto_de_taxa: conjuntoDeTaxa,
              taxa_servico: taxaDeServico,
            },
            auth
          );

          addToast({
            type: "success",
            title: "Deu tudo certo!",
            description: "Taxa de simulação atualizada com sucesso.",
          });

          setAtualizaTaxaSimulacao(!atualizaTaxaSimulacao);
          setEditarTaxaDeSimulacao(false);
          setTaxaId("");
        } catch (error) {
          console.log(error);
          addToast({
            type: "error",
            title: "Erro em alterar parcela",
            description: "Erro em alterar taxa  ",
          });
        }
      }

      update();
    }
  }, [
    addToast,
    atualizaTaxaSimulacao,
    conjuntoDeTaxa,
    numeroDeParcelas,
    taxaDeServico,
    taxaId,
    tipoBandeira,
    tipoMaquinete,
    userToken,
  ]);

  const bodyData = taxasDeSimulacao.map((taxa) => {
    const numero_parcela = (
      // <select
      //   name="numero_parcela"
      //   placeholder="Selecione"
      //   // ref={register({ required: true })}
      //   className="listagem"
      //   onChange={(e) => {
      //     setNumeroDeParcelas(e.target.value);
      //     setTaxaId(taxa.id);
      //     setTipoBandeira(taxa.bandeira);
      //     setTipoMaquinete(taxa.maquineta);
      //     setConjuntoDeTaxa(taxa.conjunto_de_taxa);
      //     setTaxaDeServico(taxa.taxa_servico);
      //   }}
      // >
      //   <option value="">{taxa.numero_parcela}</option>
      //   <option value="1">1</option>
      //   <option value="2">2</option>
      //   <option value="3">3</option>
      //   <option value="4">4</option>
      //   <option value="5">5</option>
      //   <option value="6">6</option>
      //   <option value="7">7</option>
      //   <option value="8">8</option>
      //   <option value="9">9</option>
      //   <option value="10">10</option>
      //   <option value="11">11</option>
      //   <option value="12">12</option>
      // </select>
      <p>{taxa.numero_parcela}</p>
    );

    const bandeira = (
      // <select
      //   name="bandeira"
      //   placeholder="Selecione"
      //   // ref={register({ required: true })}
      //   className="listagem"
      //   onChange={(e) => {
      //     setNumeroDeParcelas(taxa.numero_parcela);
      //     setTaxaId(taxa.id);
      //     setTipoBandeira(e.target.value);
      //     setTipoMaquinete(taxa.maquineta);
      //     setConjuntoDeTaxa(taxa.conjunto_de_taxa);
      //     setTaxaDeServico(taxa.taxa_servico);
      //   }}
      // >
      //   <option value="">{taxa.bandeira}</option>
      //   <option value="VME">VME</option>
      //   <option value="DA">DA</option>
      // </select>
      <p>{taxa.bandeira}</p>
    );

    const maquineta = (
      // <select
      //   name="maquineta"
      //   placeholder="Selecione"
      //   // ref={register({ required: true })}
      //   className="listagem"
      //   onChange={(e) => {
      //     setNumeroDeParcelas(taxa.numero_parcela);
      //     setTaxaId(taxa.id);
      //     setTipoBandeira(taxa.bandeira);
      //     setTipoMaquinete(e.target.value);
      //     setConjuntoDeTaxa(taxa.conjunto_de_taxa);
      //     setTaxaDeServico(taxa.taxa_servico);
      //   }}
      // >
      //   <option value="">{taxa.maquineta}</option>
      //   <option value="Celer">Celer</option>
      // </select>
      <p>{taxa.maquineta}</p>
    );

    const conjunto_taxa = (
      // <select
      //   name="conjunto_taxa"
      //   placeholder="Selecione"
      //   // ref={register({ required: true })}
      //   className="listagem"
      //   onChange={(e) => {
      //     setNumeroDeParcelas(taxa.numero_parcela);
      //     setTaxaId(taxa.id);
      //     setTipoBandeira(taxa.bandeira);
      //     setTipoMaquinete(taxa.maquineta);
      //     setConjuntoDeTaxa(e.target.value);
      //     setTaxaDeServico(taxa.taxa_servico);
      //   }}
      // >
      //   <option value="">{taxa.conjunto_de_taxa}</option>
      //   {/* <option value="PDV2">PDV2</option>
      //   <option value="PDV3">PDV3</option> */}
      //   <option value="PDV4">PDV4</option>
      // </select>
      <p>{taxa.conjunto_de_taxa}</p>
    );

    const taxa_simulacao = (
      <BoxEditaTaxa
        taxasDeSimulacaoValorMenorQueZero={taxasDeSimulacaoValorMenorQueZero}
      >
        {/* {editarTaxaDeSimulacao === false && (
          
        )} */}
        {editarTaxaDeSimulacao === true &&
        editarTaxaDeSimulacaoId === taxa.id ? (
          <div className="boxInput">
            <div className="input">
              <span className="porcentagem">%</span>
              <input
                value={taxasDeSimulacaoValor}
                name="taxa_simulacao"
                placeholder={taxa.taxa_servico}
                onChange={(e) => verificaValorTaxaSimulacao(e.target.value)}
                onKeyUp={(e) => soNumeroSimbolosMask(e)}
              />
            </div>
            <button
              onClick={() => {
                if (taxasDeSimulacaoValorMenorQueZero === false) {
                  setNumeroDeParcelas(taxa.numero_parcela);
                  setTaxaId(taxa.id);
                  setTipoBandeira(taxa.bandeira);
                  setTipoMaquinete(taxa.maquineta);
                  setConjuntoDeTaxa(taxa.conjunto_de_taxa);
                  setTaxaDeServico(taxasDeSimulacaoValor);
                } else {
                  return;
                }
              }}
            >
              ok
            </button>
            <button
              className="cancelar"
              onClick={() => {
                setEditarTaxaDeSimulacao(false);
              }}
            >
              x
            </button>
          </div>
        ) : (
          <>
            <span>%</span> <span>{taxa.taxa_servico}</span>{" "}
            <span
              style={{ cursor: "pointer", marginLeft: 15 }}
              onClick={() => {
                setEditarTaxaDeSimulacao(true);
                setEditarTaxaDeSimulacaoId(taxa.id);
                setTaxasDeSimulacaoValor(taxa.taxa_servico);
                setTaxasDeSimulacaoValorMenorQueZero(false);
              }}
            >
              <img src={editIcon} alt="" style={{ width: 12 }} />
            </span>
          </>
        )}
      </BoxEditaTaxa>
    );

    return {
      numero_parcela: numero_parcela,
      bandeira: bandeira,
      maquineta: maquineta,
      conjunto_taxa: conjunto_taxa,
      taxa_simulacao: taxa_simulacao,

      edit: (
        <Tooltip title="Editar Dados do LA" placement="top" arrow>
          <span
            style={{ cursor: "pointer" }}
            // onClick={() => editLA(licenciado)}
          >
            <img src={editIcon} alt="" />
          </span>
        </Tooltip>
      ),
    };
  });

  /* const editLA = useCallback(
    (licenciado) => {
      const idReverse = licenciado.id.split("").reverse().join("");

      // redireciona para a página de edição
      history.push(`/admin/licenciados/editar/${idReverse}`);
    },
    [history]
  ); */

  /* -------------------- FUNÇÕES PARA O MOBILE -------------------- */

  const taxaListagemPorNumeroParcela = taxasDeSimulacao.sort(
    (a, b) =>
      a.numero_parcela - b.numero_parcela ||
      a.maquineta.localeCompare(b.maquineta)
  );

  return (
    <>
      <Container>
        <MenuBar active="TaxaDeSilumacao" />
        <NewMobileMenu active="TaxaDeSilumacao" />

        <Page>
          <TopBar pageTitle="Taxas de Simulação" />

          <Content>
            <AddLA>
              {/* <span>Adicionar Taxa de Simulação</span> */}
              <Button onClick={() => setFormOpened(!formOpened)}>
                {formOpened === true ? (
                  <MdAdd color="#fff" />
                ) : (
                  <MdRemove color="#fff" />
                )}
              </Button>
            </AddLA>

            <FormSection formOpened={formOpened}>
              <FormAddTaxaSimulacao />
            </FormSection>

            <TableContent style={{ zIndex: 0 }}>
              {/* <span className="title">Filtrar por:</span> */}
              <Select
                placeholder="Bandeira"
                onChange={(value) => setFiltroBandeira(value.value)}
                className="select1"
                options={[
                  { value: "VM", label: "VM" },
                  { value: "DAE", label: "DAE" },
                  { value: "", label: "Todos" },
                ]}
              />
              <Select
                placeholder="Maquineta"
                onChange={(value) => setFiltroMaquineta(value.value)}
                className="select2"
                options={[
                  { value: "Celer", label: "Celer" },
                  { value: "", label: "Todos" },
                ]}
              />

              <Select
                placeholder="Conj. de Taxas"
                onChange={(value) => setFiltroConjuntoTaxa(value.value)}
                className="select3"
                options={[
                  { value: "PDV2", label: "PDV2" },
                  { value: "PDV3", label: "PDV3" },
                  { value: "PDV4", label: "PDV4" },
                  { value: "", label: "Todos" },
                ]}
              />

              <MaterialTable
                style={{ position: "relative" }}
                title="Lista de taxas"
                columns={headData}
                data={bodyData}
                components={{
                  Toolbar: (props) => (
                    <div>
                      <MTableToolbar {...props} />
                    </div>
                  ),
                }}
                localization={{
                  pagination: {
                    labelRowsSelect: "linhas",
                    labelDisplayedRows: "{from}-{to} de {count}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                  },

                  toolbar: {
                    searchPlaceholder: false,
                    searchTooltip: false,
                  },
                  body: {
                    emptyDataSourceMessage: "Nenhum dado encontrado",
                  },
                }}
                options={{
                  actionsColumnIndex: -1,
                  headerStyle: {
                    backgroundColor: "#10104F",
                    color: "#FFF",
                  },
                  emptyValue: "Nenhum dado encontrado",
                  search: false,
                }}
              />
            </TableContent>

            <TableContentMobile>
              <Header>
                <h3>Taxa de Simulação</h3>

                <Select
                  placeholder="Bandeira"
                  onChange={(value) => setFiltroBandeira(value.value)}
                  className="select4"
                  options={[
                    { value: "VME", label: "VME" },
                    { value: "DA", label: "DA" },
                    { value: "", label: "Todos" },
                  ]}
                />
                <Select
                  placeholder="Maquineta"
                  onChange={(value) => setFiltroMaquineta(value.value)}
                  className="select4"
                  options={[
                    { value: "Celer", label: "Celer" },
                    { value: "", label: "Todos" },
                  ]}
                />

                <Select
                  placeholder="Conj. de Taxas"
                  onChange={(value) => setFiltroConjuntoTaxa(value.value)}
                  className="select4"
                  options={[
                    { value: "PDV2", label: "PDV2" },
                    { value: "PDV3", label: "PDV3" },
                    { value: "PDV4", label: "PDV4" },
                    { value: "", label: "Todos" },
                  ]}
                />
              </Header>

              <MobileBody>
                {taxaListagemPorNumeroParcela.map((taxa) => (
                  <Line key={taxa.id}>
                    {/* <span>{taxa.nome}</span> */}
                    {/* <p>Número de parcelas: {taxa.numero_parcela}X</p>
                    <p>Bandeira: {taxa.bandeira}</p>
                    <p>Taxa de simulação: {taxa.taxa_servico}</p> */}

                    {/* <div className="footer">
                      <span></span>
                      <img src={editIcon} alt="" onClick={() => editLA(taxa)} />
                    </div> */}
                    <div className="coluna1">
                      <div>
                        <p>Número de parcelas</p>
                        <div>
                          {/* <select
                            name="numero_parcela"
                            placeholder="Selecione"
                            // ref={register({ required: true })}
                            className="listagem"
                            onChange={(e) => {
                              setNumeroDeParcelas(e.target.value);
                              setTaxaId(taxa.id);
                              setTipoBandeira(taxa.bandeira);
                              setTipoMaquinete(taxa.maquineta);
                              setConjuntoDeTaxa(taxa.conjunto_de_taxa);
                              setTaxaDeServico(taxa.taxa_servico);
                            }}
                          >
                            <option value="">{taxa.numero_parcela}</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </select> */}
                          <span>{taxa.numero_parcela}</span>
                        </div>
                      </div>

                      <div>
                        <p>Bandeira</p>
                        <div>
                          {/* <select
                            name="bandeira"
                            placeholder="Selecione"
                            // ref={register({ required: true })}
                            className="listagem"
                            onChange={(e) => {
                              setNumeroDeParcelas(taxa.numero_parcela);
                              setTaxaId(taxa.id);
                              setTipoBandeira(e.target.value);
                              setTipoMaquinete(taxa.maquineta);
                              setConjuntoDeTaxa(taxa.conjunto_de_taxa);
                              setTaxaDeServico(taxa.taxa_servico);
                            }}
                          >
                            <option value="">{taxa.bandeira}</option>
                            <option value="VM">VM</option>
                            <option value="E">E</option>
                            <option value="HDA">HDA</option>
                          </select> */}
                          <span>{taxa.bandeira}</span>
                        </div>
                      </div>

                      <div>
                        <p>Maquineta</p>
                        <div>
                          {/* <select
                            name="maquineta"
                            placeholder="Selecione"
                            // ref={register({ required: true })}
                            className="listagem"
                            onChange={(e) => {
                              setNumeroDeParcelas(taxa.numero_parcela);
                              setTaxaId(taxa.id);
                              setTipoBandeira(taxa.bandeira);
                              setTipoMaquinete(e.target.value);
                              setConjuntoDeTaxa(taxa.conjunto_de_taxa);
                              setTaxaDeServico(taxa.taxa_servico);
                            }}
                          >
                            <option value="">{taxa.maquineta}</option>
                            <option value="Celer">Celer</option>
                          </select> */}
                          <span>{taxa.maquineta}</span>
                        </div>
                      </div>
                    </div>
                    <div className="coluna2">
                      <div>
                        <p>Conjunto de taxas</p>
                        <div>
                          {/* <select
                            name="conjunto_taxa"
                            placeholder="Selecione"
                            // ref={register({ required: true })}
                            className="listagem"
                            onChange={(e) => {
                              setNumeroDeParcelas(taxa.numero_parcela);
                              setTaxaId(taxa.id);
                              setTipoBandeira(taxa.bandeira);
                              setTipoMaquinete(taxa.maquineta);
                              setConjuntoDeTaxa(e.target.value);
                              setTaxaDeServico(taxa.taxa_servico);
                            }}
                          >
                            <option value="">{taxa.conjunto_de_taxa}</option>
                            <option value="PDV2">PDV2</option>
                            <option value="PDV3">PDV3</option>
                            <option value="PDV4">PDV4</option>
                          </select> */}
                          <span>{taxa.conjunto_de_taxa}</span>
                        </div>
                      </div>

                      <div>
                        <p>Taxas de simulação</p>
                        <BoxEditaTaxa
                          taxasDeSimulacaoValorMenorQueZero={
                            taxasDeSimulacaoValorMenorQueZero
                          }
                        >
                          {/* {editarTaxaDeSimulacao === false && ()} */}
                          {editarTaxaDeSimulacao === true &&
                          editarTaxaDeSimulacaoId === taxa.id ? (
                            <div className="boxInput">
                              <div className="input">
                                <span
                                  style={{
                                    fontSize: 16,
                                  }}
                                  className="porcentagem"
                                >
                                  %
                                </span>
                                <input
                                  value={taxasDeSimulacaoValor}
                                  name="taxa_simulacao"
                                  placeholder={taxa.taxa_servico}
                                  onChange={(e) =>
                                    verificaValorTaxaSimulacao(e.target.value)
                                  }
                                  onKeyUp={(e) => soNumeroSimbolosMask(e)}
                                />
                              </div>
                              <button
                                onClick={() => {
                                  if (
                                    taxasDeSimulacaoValorMenorQueZero === false
                                  ) {
                                    setNumeroDeParcelas(taxa.numero_parcela);
                                    setTaxaId(taxa.id);
                                    setTipoBandeira(taxa.bandeira);
                                    setTipoMaquinete(taxa.maquineta);
                                    setConjuntoDeTaxa(taxa.conjunto_de_taxa);
                                    setTaxaDeServico(taxasDeSimulacaoValor);
                                  } else {
                                    return;
                                  }
                                }}
                              >
                                ok
                              </button>
                              <button
                                className="cancelar"
                                onClick={() => {
                                  setEditarTaxaDeSimulacao(false);
                                }}
                              >
                                x
                              </button>
                            </div>
                          ) : (
                            <>
                              <span>%</span>{" "}
                              <span>{taxa.taxa_servico.toFixed(2)}</span>{" "}
                              <span
                                style={{ cursor: "pointer", marginLeft: 15 }}
                                onClick={() => {
                                  setEditarTaxaDeSimulacao(true);
                                  setEditarTaxaDeSimulacaoId(taxa.id);
                                  setTaxasDeSimulacaoValor(taxa.taxa_servico);
                                  setTaxasDeSimulacaoValorMenorQueZero(false);
                                }}
                              >
                                <img
                                  src={editIcon}
                                  alt=""
                                  style={{ width: 12 }}
                                />
                              </span>
                            </>
                          )}
                        </BoxEditaTaxa>
                      </div>
                    </div>
                  </Line>
                ))}
              </MobileBody>
            </TableContentMobile>
          </Content>
        </Page>
      </Container>
    </>
  );
}

export default TaxaDeSilumacao;
