import styled from 'styled-components';

import { shade } from 'polished';

export const Container = styled.div`
  position: fixed;

  width: 220px;
  height: 100vh;

  background: #10104F;

  padding: 27px 17px;

  @media (max-width: 500px) {
    display: none;
  }
`;

export const Logo = styled.div`
  padding-bottom: 27px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  img {
    width: 155px;
  }
`;

export const Nav = styled.div`
  margin-top: 30px;
  margin-right: 17px;
  
  position: absolute;
  top: 116px;
  bottom: 0;
  
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #10104F;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  a {
    text-decoration: none;

    &.disabled {
      display: none;
    }
  }
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;

  padding: 7px 15px;
  margin-bottom: 26px;

  border-radius: 4px;

  background: #10104F;

  transition: 0.2s;

  &:hover {
    background: ${shade(0.2, '#10104F')};
  }

  &.active {
    background: #0073FC;
  }

  img {
    width: 20px;
  }

  span {
    font-size: 15px;
    color: #fff;
    margin-left: 12px;
  }
`;

export const MenuItemPagamento = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  padding: 7px 15px;
  margin-bottom: 26px;

  border-radius: 4px;

  background: #10104F;

  transition: 0.2s;

  &:hover {
    background: ${shade(0.2, '#10104F')};
  }

  &.active {
    background: #0073FC;
  }

  img {
    width: 20px;
  }

  span {
    font-size: 16px;
    color: #fff;
    margin-left: 12px;
  }

  .notify {
    position: absolute;
    top: -6px;
    left: 22px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #0073FC;
    border: 2px solid #fff;

    min-width: 18px;
    height: 18px;
    border-radius: 100px 100px 100px 100px;
    padding: 0 3px;

    &.hide {
      display: none;
    }

    > span {
      margin: 0;
      color: #fff;
      font-size: 12px;
      font-weight: 700;
    }
  }
`;

export const SubMenuItem = styled.div`
  display: flex;
  align-items: center;

  padding: 7px 15px 7px 26px;
  margin-bottom: 26px;

  border-radius: 4px;

  background: #10104F;

  transition: 0.2s;

  &:hover {
    background: ${shade(0.2, '#10104F')};
  }

  &.active {
    background: #0073FC;
  }

  img {
    width: 20px;
  }

  span {
    font-size: 16px;
    color: #fff;
    margin-left: 12px;
  }
`;
