import styled, { css } from 'styled-components';

import { shade } from 'polished';

import ToolTip from '../Tooltip';

export const Container = styled.div`
  background: #fff;
  border-radius: 8px;
  width: 100%;

  position: relative;

  h4 {
    font-size: 20px;
    font-weight: 500;
    color: #373A3C;
    margin-bottom: 30px;
  }
  
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  width: 100%;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const Row3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  width: 100%;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const Row4 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;

  width: 100%;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const ButtonRow = styled.div`
  button {
    padding: 8px 22px;
    width: 110px;
    background: #0073FC;
    border: 1px solid #0073FC;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;

    transition: 0.2s;

    &:hover {
      background: ${shade(0.2, '#0073FC')}
    }

    &.back {
      margin-right: 30px;

      width: 110px;
      color: #373A3C;
      background: #F5F8FB;
      border: 1px solid #ADADAD;

      &:hover {
        background: ${shade(0.1, '#F5F8FB')}
      }
    }
  }

  @media (max-width: 500px) {
    margin-top: 20px;
  }
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  flex: 1;

  label {
    font-size: 16px;
    color: #373A3C;
  }

  input[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;
  }
  input[type=number] { 
    -moz-appearance: textfield;
    appearance: textfield;
  }

  /* input {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    box-sizing: border-box;
    
    height: 34px;
    width: 100%;
    padding: 0 10px;

    &.errored {
      border: 1px solid #c53030;
    }

    ${props => props.isErrored && css`
      border: 1px solid #c53030;
    `}
  } */
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  box-sizing: border-box;

  margin-bottom: 18px;
  padding: 1px 11px 1px 1px;

  ${props => props.isErrored && css`
    border: 1px solid #c53030;
  `}

  ${props => props.isFocused && css`
    border: 1px solid #0073FC;
  `}

  input {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset;
    }
  }

  select {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;
    
    &:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset;
    }
  }
`;

export const Error = styled(ToolTip)`
  span {
    background: #c53030;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const Separator = styled.div`
  border-bottom: 1px solid #efefef;
  margin: 30px 0;
  font-size: 20px;
`;

export const NavegaçãoAbas = styled.div`
  /* display: grid;
  grid-template-columns: repeat(5, 1fr); */

  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const BotãoAba = styled.button`
  width: 100%;
  max-width: 160px;

  background: transparent;
  border: none;
  outline-style: none;
  padding: 20px 0;
  text-align: center;
  font-size: 20px;

  border-bottom: 2px solid #fff;

  ${props => props.tab1 && css`
    border-bottom: 2px solid #0073FC;
  `}

  ${props => props.tab2 && css`
    border-bottom: 2px solid #0073FC;
  `}

  ${props => props.tab3 && css`
    border-bottom: 2px solid #0073FC;
  `}

  ${props => props.tab4 && css`
    border-bottom: 2px solid #0073FC;
  `}
`;

export const Abas = styled.div`
  padding: 30px;
`;

export const Aba1 = styled.div`
  /* visibility: hidden; */
  height: 0;
  display: none;

  ${props => props.tab1 && css`
    /* visibility: visible; */
    height: 100%;
    display: block;
  `}
`;

export const Aba2 = styled.div`
  /* visibility: hidden; */
  height: 0;
  display: none;

  ${props => props.tab2 && css`
    /* visibility: visible; */
    height: 100%;
    display: block;
  `}
`;

export const Aba3 = styled.div`
  /* visibility: hidden; */
  height: 0;
  display: none;

  ${props => props.tab3 && css`
    /* visibility: visible; */
    height: 100%;
    display: block;
  `}
`;

export const Aba4 = styled.div`
  /* visibility: hidden; */
  height: 0;
  display: none;

  ${props => props.tab4 && css`
    /* visibility: visible; */
    height: 100%;
    display: block;
  `}
`;

export const BoxInfo = styled.div`
  background: #F1F5F9;
  padding: 8px 16px;
  border-radius: 6px;
  margin-bottom: 16px;

  span {
    font-size: 14px;
  }

  &.modalBox {
    background: transparent;
    color: #F1F5F9;
    border: 1px solid #F1F5F9;
    margin: 10px 20px 10px;
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(55, 58, 60, 0.2);
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  z-index: 5;

  img {
    width: 220px;
  }

  ${props => props.loadingHide && css`
    display: none;
  `}
`;

export const Aba3Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  span {
    font-size: 14px;
    margin-left: 6px;
  }

  div {
    position: relative;
    margin-top: 4px;
  }

  > button {
    background: transparent;
    border: none;
    outline-style: none;
    display: flex;
    align-items: center;
  }
`;
