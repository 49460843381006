import * as firestore from "firebase/firestore";
import { firebaseDatabase } from '../services/firebase';

export async function sendMessage({
  recipient_id,
  subject,
  message,
  transaction_type,
  transaction_id,
}) {
  // importa a collection do firebase
  const mensagensCollectionRef = firestore.collection(firebaseDatabase, 'mensagens');

  const loggedUser = JSON.parse(localStorage.getItem('@DeixaNoAzul:loggedUser'));

  // console.log(loggedUser);
  // return;

  try {
    await firestore.addDoc(mensagensCollectionRef, {
      recipient_id: recipient_id,
      sender_id: loggedUser.id,
      sender_name: loggedUser.name,
      subject: subject,
      message: message,
      message_read: false,
      transaction_type: transaction_type,
      transaction_id: transaction_id,
      created_at: firestore.serverTimestamp(),
      updated_at: firestore.serverTimestamp(),
    }).then(() => console.log('Mensagem enviada')).catch(err => console.log('erro na mensagem', err));
    
    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
}