import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: -70px;
  padding: 0 60px;

  h2 {
    margin-top: 40px;
    font-size: 28px;
    font-weight: normal;
    text-align: center;
  }

  p {
    font-size: 15px;
    text-align: center;
  }

  > span {
    position: absolute;
    bottom: 50px;
  }
`;

export const BgBlue = styled.img`
  position: absolute;
  left: 0;
  margin-top: 40px;
  width: 76px;

  @media (max-width: 500px) {
    display: none;
  }
`;

export const BgGreen = styled.img`
  position: absolute;
  top: 10%;
  right: 0;

  @media (max-width: 500px) {
    display: none;
  }
`;
