import React, { createContext, useState, useContext } from 'react';

const TransacaoInfosContext = createContext();

function TransacaoInfosProvider({ children }) {
  const [transacaoInfos, setTransacaoInfos] = useState([]);
  const [transacaoInfosCliente, setTransacaoInfosCliente] = useState([]);
  const [transacaoInfosTelefone, setTransacaoInfosTelefone] = useState([]);
  const [transacaoInfosBoleto, setTransacaoInfosBoleto] = useState([]);
  const [transacaoInfosDadosBancarios, setTransacaoInfosDadosBancarios] = useState([]);
  const [transacaoInfosComprovante, setTransacaoInfosComprovante] = useState([]);
  const [transacaoInfosTermo, setTransacaoInfosTermo] = useState([]);
  const [transacaoInfosDocBoleto, setTransacaoInfosDocBoleto] = useState([]);
  const [transacaoInfosDocFoto, setTransacaoInfosDocFoto] = useState([]);
  const [transacaoComissoes, setTransacaoComissoes] = useState({
    comissao_ec: "",
    comissao_la: ""
  });

  return (
    <TransacaoInfosContext.Provider
      value={{
        transacaoInfos, setTransacaoInfos,
        transacaoInfosCliente, setTransacaoInfosCliente,
        transacaoInfosTelefone, setTransacaoInfosTelefone,
        transacaoInfosBoleto, setTransacaoInfosBoleto,
        transacaoInfosDadosBancarios, setTransacaoInfosDadosBancarios,
        transacaoInfosComprovante, setTransacaoInfosComprovante,
        transacaoInfosTermo, setTransacaoInfosTermo,
        transacaoInfosDocBoleto, setTransacaoInfosDocBoleto,
        transacaoInfosDocFoto, setTransacaoInfosDocFoto,
        transacaoComissoes, setTransacaoComissoes
      }}
    >
      {children}
    </TransacaoInfosContext.Provider>
  );
};

function useTransacaoInfos() {
  const context = useContext(TransacaoInfosContext);
  const {
    transacaoInfos, setTransacaoInfos,
    transacaoInfosCliente, setTransacaoInfosCliente,
    transacaoInfosTelefone, setTransacaoInfosTelefone,
    transacaoInfosBoleto, setTransacaoInfosBoleto,
    transacaoInfosDadosBancarios, setTransacaoInfosDadosBancarios,
    transacaoInfosComprovante, setTransacaoInfosComprovante,
    transacaoInfosTermo, setTransacaoInfosTermo,
    transacaoInfosDocBoleto, setTransacaoInfosDocBoleto,
    transacaoInfosDocFoto, setTransacaoInfosDocFoto,
    transacaoComissoes, setTransacaoComissoes
  } = context;
  return {
    transacaoInfos, setTransacaoInfos,
    transacaoInfosCliente, setTransacaoInfosCliente,
    transacaoInfosTelefone, setTransacaoInfosTelefone,
    transacaoInfosBoleto, setTransacaoInfosBoleto,
    transacaoInfosDadosBancarios, setTransacaoInfosDadosBancarios,
    transacaoInfosComprovante, setTransacaoInfosComprovante,
    transacaoInfosTermo, setTransacaoInfosTermo,
    transacaoInfosDocBoleto, setTransacaoInfosDocBoleto,
    transacaoInfosDocFoto, setTransacaoInfosDocFoto,
    transacaoComissoes, setTransacaoComissoes
  };
};

export { TransacaoInfosProvider, useTransacaoInfos };
