import styled, { css } from 'styled-components';

import { shade } from 'polished';

import backgroundImg from '../../assets/images/background.jpg';

import ToolTip from '../../components/Tooltip';

export const Container = styled.div``;

export const Page = styled.div`
  margin-left: 220px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  /* height: calc(100vh - 72px); */
  background: url(${backgroundImg}) no-repeat;
  background-position: top left;
  background-size: 100% 256px;
  box-sizing: border-box;

  padding: 100px 160px 0 30px;

  button {
    padding: 8px 22px;
    /* width: 110px; */
    background: #0073FC;
    border: 1px solid #0073FC;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;

    margin-top: 0px;
    margin-bottom: 60px;
    transition: 0.2s;

    &:hover {
      background: ${shade(0.2, '#0073FC')}
    }

    @media (max-width: 500px) {
      margin-bottom: 100px;
    }
  }

  @media (max-width: 500px) {
    padding: 50px 20px 20px;
  }
`;

export const RowBox1 = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 30px;

  margin-bottom: 30px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

export const RowBoxInfos = styled.div`
  display: none;
  
  margin-bottom: 30px;

  ${props => props.infosOpened && css`
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 30px;
  `}

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

export const Box = styled.div`
  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;

  h3 {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 20px;
  }

  > button {
    margin: 10px 0;
    background: #FE9902;
    border: 1px solid #FE9902;

    &:hover {
      background: ${shade(0.2, '#FE9902')};
    }
  }
`;

export const BoxBlue = styled.div`
  background: #10104F;
  padding: 20px 30px;
  border-radius: 8px;

  display: none;

  h3 {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 20px;
    color: #FFF;
  }

  a {
    > button {
      margin: 20px 0 10px;
    }
  }

  ${props => props.infosOpened && css`
    display: block;
  `}
`;

export const ValueArea = styled.div`
  margin-top: 15px;

  > label {
    display: block;
    margin: 0;
    color: #FFF;
  }

  > span {
    font-size: 24px;
    font-weight: 600;
    color: #FFF;
  }
`;

export const BoletoOptions = styled.div`
  ${props => props.boletoOptions && css`
    display: none;
  `}

  background: #fff;
  padding: 0 30px 20px;
  border-radius: 8px;
  margin-top: -20px;
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;

  /* &.barCode {
    width: 70%;
  } */

  @media (max-width: 500px) {
    &.campoAnexo {
      margin-bottom: 15px;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  box-sizing: border-box;

  margin-bottom: 18px;
  padding: 1px 11px 1px 1px;

  &.barCode {
    margin-bottom: 0;
  }

  ${props => props.isErrored && css`
    border: 1px solid #c53030;
  `}

  ${props => props.isFocused && css`
    border: 1px solid #0073FC;
  `}

  input {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;
  }

  select {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;
  }

  img {
    margin-left: 10px;
  }
`;

export const Error = styled(ToolTip)`
  span {
    background: #c53030;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const InputFileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid #CCCCCC;
  border-radius: 4px;
  padding-right: 10px;
  padding-bottom: -1px;
  width: 200px;

  > input {
    width: 170px;
  }

  @media(max-width: 1400px) {
    width: 180px;

    > input {
      width: 150px;
    }
  }

  @media(max-width: 1340px) {
    width: 160px;

    > input {
      width: 130px;
    }
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  width: 100%;  

  &.marginTop {
    margin-top: 18px;
  }

  > div.barCodeSection {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 18px;

    > button.addBarCode {
      padding: 8px 12px;
      margin: 0;
    }
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;

    &.marginTop {
      margin-top: 0;
    }
  }
`;

export const Row3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  width: 100%;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const Row4 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;

  width: 100%;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const RowBox2 = styled.div`
  ${props => props.depositoOpened && css`
    display: none;
  `}

  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;

  margin-bottom: 30px;

  h3 {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 20px;
  }
`;

export const RowBox3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 30px;

  margin-bottom: 30px;

  h3 {
    margin-bottom: 10px;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

export const BoxInfo = styled.div`
  background: #F1F5F9;
  padding: 8px 16px;
  border-radius: 6px;
  margin-bottom: 16px;

  span {
    font-size: 14px;
  }

  &.modalBox {
    background: transparent;
    color: #F1F5F9;
    border: 1px solid #F1F5F9;
    margin: 10px 20px 10px;
  }
`;

export const ModalSection = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(55, 58, 60, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 5;

  ${props => props.modalOpened && css`
    display: none;
  `}
`;

export const ModalContent = styled.div`
  color: #fff;
  background: #10104F;
  padding: 30px;
  border-radius: 8px;
  max-width: 640px;
  /* max-height: 420px; */

  width: 100%;
  /* height: 100%; */

  > svg {
    margin-left: calc(100% - 30px);
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      color: ${shade(0.2, '#fff')};
    }
  }

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 30px;
  }

  div.buttonArea {
    display: flex;
    justify-content: center;

    button {
      padding: 8px 22px;
      width: 130px;
      background: #0073FC;
      border: 1px solid #0073FC;
      color: #FFFFFF;
      border: none;
      border-radius: 4px;

      margin-top: 20px;
      margin-right: 15px;
      transition: 0.2s;

      &:hover {
        background: ${shade(0.2, '#0073FC')};
      }
    }

    button.downloadPDF {
      width: 210px;
      background: transparent;
      border: 2px solid #0073FC;
      outline-style: none;

      margin-left: 15px;

      &:hover {
        background: #0073FC;
      }
    }
  }

  @media (max-width: 500px) {
    width: 94%;
    padding: 20px;
    margin-bottom: 20px;

    > p {
      br {
        display: none;
      }
    }
  }
`;

export const ModalClientName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;

  > p {
    margin: 0;

    @media (max-width: 500px) {
      display: none;
    }
  }

  span {
    font-size: 24px;
    font-weight: 600;
  }

  @media (max-width: 500px) {
    margin-bottom: 20px;
  }
`;

export const ModalValueSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
`;

export const ModalValueArea = styled.div`
  > label {
    display: block;
    margin: 0;
  }

  span {
    font-size: 24px;
    font-weight: 600;
  }

  @media (max-width: 500px) {
    &.dataArea {
      display: none;  
    }
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 11px;

  border: 1px solid #CCCCCC;
  border-radius: 4px;

  width: 200px;
`;

export const RowBoxBoleto = styled.div`
  display: flex;
  flex-direction: column;
  /* grid-template-columns: 1.5fr 1fr;
  gap: 30px; */

  margin-bottom: 30px;
  
  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;

  ${props => props.boletoOptions && css`
    display: none;
  `}

  .quantidadeBoletosSection {
    margin-bottom: 18px;
  }

  .countArea {
    display: flex;
  }

  span.count {
    font-size: 24px;
    font-weight: 700;
  }

  button.boletos {
    padding: 4px;
    margin: 0;
    background: #F1F5F9;
    color: #FE9902;
    margin-left: 8px;
    outline-style: none;

    &:hover {
      background: ${shade(0.03, '#F1F5F9')};
    }
  }

  button.addBoleto {
    margin: 32px 0 0;
    height: 40px;
    width: 155px;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

export const RowBoleto = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 0 0;

  > span {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;

    img {
      width: 20px;
      margin-right: 10px;
    }
  }
`;

export const ModalSectionDados = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(55, 58, 60, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 5;

  ${props => props.modalDadosHide && css`
    display: none;
  `}
`;

export const ModalContentDados = styled.div`
  color: #fff;
  background: #10104F;
  padding: 30px;
  border-radius: 8px;
  max-width: 640px;
  /* max-height: 420px; */

  width: 100%;
  /* height: 100%; */

  > span {
    font-size: 20px;
    display: block;
    /* margin-bottom: 10px; */
    text-align: center;
  }

  > p {
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
  }

  div.buttonArea {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    button {
      padding: 8px 22px;
      /* width: 130px; */
      background: #0073FC;
      border: 1px solid #0073FC;
      color: #FFFFFF;
      border: none;
      border-radius: 4px;

      margin-right: 15px;
      transition: 0.2s;

      &:hover {
        background: ${shade(0.2, '#0073FC')};
      }
    }

    button.cancelar {
      /* width: 210px; */
      background: transparent;
      border: 2px solid #0073FC;
      outline-style: none;

      margin-left: 15px;

      &:hover {
        background: #0073FC;
      }
    }
  }

  @media (max-width: 500px) {
    width: 94%;
    padding: 20px;
    margin-bottom: 20px;

    > p {
      br {
        display: none;
      }
    }
  }
`;

export const ModalSectionBoleto = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(55, 58, 60, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 5;

  ${props => props.modalBoletoHide && css`
    display: none;
  `}
`;

export const ModalContentBoleto = styled.div`
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  max-width: 640px;
  /* max-height: 420px; */

  width: 100%;
  /* height: 100%; */

  > span {
    font-size: 20px;
    display: block;
    text-align: center;
    /* color: #fff; */
  }

  > p {
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
    /* color: #fff; */
  }

  div.buttonArea {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    button {
      padding: 8px 22px;
      /* width: 130px; */
      background: #0073FC;
      border: 1px solid #0073FC;
      color: #FFFFFF;
      border: none;
      border-radius: 4px;

      margin-right: 15px;
      transition: 0.2s;

      &:hover {
        background: ${shade(0.2, '#0073FC')};
      }
    }

    button.cancelar {
      /* width: 210px; */
      background: transparent;
      border: 2px solid #0073FC;
      outline-style: none;

      margin-left: 15px;

      &:hover {
        background: #0073FC;
      }
    }
  }

  @media (max-width: 500px) {
    width: 94%;
    padding: 20px;
    margin-bottom: 20px;

    > p {
      br {
        display: none;
      }
    }
  }
`;

export const RowBoxBoletoModal = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 14px;
  margin-bottom: 30px;
  
  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;

  ${props => props.boletoOptions && css`
    display: none;
  `}

  .quantidadeBoletosSection {
    margin-bottom: 18px;
  }

  .countArea {
    display: flex;
  }

  span.count {
    font-size: 24px;
    font-weight: 700;
  }

  button {
    padding: 4px;
    margin: 6px 0 0 0;
    background: #0073FC;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    outline-style: none;

    &:hover {
      background: ${shade(0.03, '#F1F5F9')};
    }
  }

  button.addBoleto {
    margin: 20px 0 0;
    height: 40px;
    width: 100px;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

export const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(55, 58, 60, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 5;

  img {
    width: 220px;
  }

  ${props => props.loadingHide && css`
    display: none;
  `}
`;

export const Separator = styled.div`
  border-bottom: 1px solid #efefef;
  margin: 30px 0;
  font-size: 20px;
`;
