export default function FormatarValor(valor) {

  const valor_inicial = valor === undefined || valor === null ? "00" : valor.toString().split('.');
    const reais_valor = valor_inicial[0];
    const centavos_valor = valor_inicial[1]
      ? valor_inicial[1].substring(0, 2)
      : '00';
    const valor_final = `${reais_valor},${centavos_valor !== undefined && centavos_valor.length > 1 ? centavos_valor : `${centavos_valor}0`} `;
  
  return valor_final;

};
