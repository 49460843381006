import React from 'react';
import { Link } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';

import FormEditLA from '../../components/FormEditLA';

import { Container, Page, Content, TabContent, Back, Button } from './styles';

function ParceiroEditarLicenciados() {
  return (
    <Container>
      <MenuBar />
      <NewMobileMenu />

      <Page>
        <TopBar pageTitle="Editar Licenciado" />

        <Content>
          <Back>
            <Link to="/parceiro/licenciados">
              <Button>
                <MdArrowBack size={24} />
              </Button>
            </Link>
            <span>Voltar para Cadastro de Licenciado</span>
          </Back>

          <TabContent>
            <FormEditLA />
          </TabContent>
        </Content>
      </Page>
    </Container>
  );
};

export default ParceiroEditarLicenciados;
