import styled, { css } from 'styled-components';

import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #F1F5F9;
  height: 72px;
  box-sizing: border-box;
  padding: 12px 90px 12px 22px;

  @media (max-width: 500px) {
    height: 55px;
    padding: 0 20px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  > h1 {
    font-size: 28px;
    color: #373A3C;
    font-weight: 700;
    margin-bottom: 0;
  }

  > h2 {
    font-size: 28px;
    color: #08BBE9;
    font-weight: 700;
    margin-bottom: 0;
    margin-left: 6px;
  }

  @media (max-width: 500px) {
    > h1 {
      font-size: 22px;
    }

    > h2 {
      font-size: 22px;
    }
  }
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;

  position: relative;
  z-index: 2;
  background: #F1F5F9;
  height: 72px;

  @media (max-width: 500px) {
    height: 55px;
    display: none;
  }
`;

export const Notification = styled.div`
  cursor: pointer;
  margin-right: 30px;
  position: relative;

  .notify {
    position: absolute;
    top: -6px;
    left: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #FEAC02;
    border: 2px solid #373A3C;

    min-width: 18px;
    height: 18px;
    border-radius: 100px 100px 100px 100px;
    padding: 0 3px;

    &.hide {
      display: none;
    }
  }

  span {
    font-size: 12px;
    font-weight: 700;
  }

  img {
    width: 24px;
    height: 24px;
  }

  svg {
    width: 24px;
    height: 24px;
    color: #373A3C;
  }

  /* @media (max-width: 500px) {
    margin-right: 0;
  } */
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 40px;

  cursor: pointer;

  > span {
    font-size: 16px;
    margin-right: 10px;
  }

  div.arrow {
    transform: rotate(0deg);
    transition: 0.2s;

    > svg {
      width: 24px;
      height: 24px;
      color: #373A3C;
    }

    &.rotate {
      transform: rotate(180deg);
    }
  }
`;

export const Avatar = styled.div`
  width: 48px;
  height: 48px;
  display: none;

  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  margin-right: 20px;
`;

export const DropDownNotifications = styled.div`
  position: absolute;
  z-index: 99;
  top: 82px;
  right: 310px;

  display: flex;
  flex-direction: column;

  max-width: 440px;
  width: 100%;
  /* overflow-x: hidden;
  overflow-y: scroll;
  height: 80vh; */
  border-radius: 6px;
  padding: 0 20px 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);

  background: #fff;

  div.header {
    height: 50px;
    background: #fff;
    /* padding: 0 20px; */
    border-radius: 6px 6px 0 0;

    display: flex;
    align-items: center;

    margin-bottom: 2px;

    > span {
      font-size: 18px;
      font-weight: 600;
    }
  }

  a.verTodas {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 6px 20px 15px;

    button {
      padding: 8px 22px;
      background: #F1F5F9;
      color: #0073FC;
      font-weight: 600;
      outline-style: none;
      border: none;
      border-radius: 4px;

      transition: 0.2s;

      &:hover {
        background: ${shade(0.05, '#F1F5F9')}
      }
    }
  }

  div.footer {
    height: 40px;
    background: #F1F5F9;
    padding: 0 20px;
    border-radius: 0 0 6px 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    cursor: not-allowed;

    > span {
      font-weight: 600;
      color: #0073FC;
    }
  }

  span.noMessages {
    text-align: center;
    margin: 20px 0;
    color: #909090;
  }

  ${props => props.notifyOpen && css`
    display: none;
  `}

  a {
    text-decoration: none;
  }

  @media (max-width: 500px) {
    top: 70px;
    right: 5vw;
    max-width: 90vw;
  }
`;

export const Message = styled.div`
  display: flex;
  align-items: center;

  padding: 14px 20px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 2px;

  transition: 0.2s;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 14px;

    path {
      width: 24px;
      height: 24px;
    }
  }

  span.title {
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    color: #373a3c;
  }

  span.message {
    display: block;
    font-size: 14px;
    margin-bottom: 7px;
    color: #9FA7BA;
  }

  span.date {
    display: block;
    font-size: 10px;
    color: #9FA7BA;
  }

  &:hover {
    background: #F1F5F9;
  }

  @media (max-width: 500px) {
    padding: 14px;
  }
`;

export const DropDown = styled.div`
  position: absolute;
  z-index: 1;
  top: -60px;
  right: 80px;
  visibility: hidden;
  transition: 0.2s;

  display: flex;
  flex-direction: column;

  background: #F5F8FB;
  padding: 18px 29px;
  border-radius: 0 0 8px 8px;

  ${props => props.open && css`
    top: 72px;
    visibility: visible;
  `}

  > a {
    font-size: 16px;
    color: #373A3C;
    text-decoration: none;
    margin-bottom: 15px;

    transition: 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }

  > button {
    display: flex;
    align-items: center;

    background: transparent;
    border: none;
    outline: none;

    font-size: 16px;
    color: #373A3C;
    text-decoration: none;

    transition: 0.2s;

    &:hover {
      opacity: 0.7;
    }

    > img {
      margin-right: 6px;
    }
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const MobileNotification = styled.div`
  cursor: pointer;
  position: relative;
  z-index: 10;

  .notify {
    position: absolute;
    top: -6px;
    left: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #FEAC02;
    border: 2px solid #373A3C;

    min-width: 18px;
    height: 18px;
    border-radius: 100px 100px 100px 100px;
    padding: 0 3px;

    &.hide {
      display: none;
    }
  }

  span {
    font-size: 12px;
    font-weight: 700;
  }

  img {
    width: 21px;
    height: 21px;
  }

  @media (min-width: 500px) {
    display: none;
  }
`;
