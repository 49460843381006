import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
// import Select from 'react-select';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Tooltip } from '@material-ui/core/';

import api from '../../services/api';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';
import FormatarValor from '../../components/FormatarValor';

// import { MdFiberManualRecord } from 'react-icons/md';
import editIcon from '../../assets/images/edit_icon.svg';

import {
  Container,
  Page,
  Content,
  TableContent,
 } from './styles';

function ParceiroEstabelecimentos() {
  const history = useHistory();

  const [establishments, setEstablishments] = useState([]);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');
  const parceiroInfos = JSON.parse(localStorage.getItem('@DeixaNoAzul:parceiroId'));

  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get(`/partner/estabelecimento/report/${parceiroInfos.id}`, auth).then(response => {
      const result = response.data.data;
      // console.log('ecs', result);

      setEstablishments(result);
    });

  }, [parceiroInfos.id, userToken]);

  // Informações Head da tabela
  const headData = [
    { title: 'Responsável', field: 'resp', sorting: false },
    { title: 'Estabelecimento', field: 'est', sorting: false },
    { title: 'Data criação', field: 'data', sorting: false },
    { title: 'Licenciado Autorizado', field: 'la', sorting: false },
    { title: 'Revendedor', field: 'rv', sorting: false },
    { title: 'Saldo conta digital', field: 'saldo', sorting: false },
    // { title: 'Status', field: 'status', align: 'right', sorting: false },
    { title: '', field: 'edit', align: 'center', sorting: false },
  ];

  // Informações Body da tabela
  const bodyData = establishments === [] || establishments === "Nenhum estabelecimento encontrado" ? []
  : establishments.map(estabelecimento => {

    // let colorStatus = '';
    // if(estabelecimento.status === "0") {colorStatus = "#5CB85C"} // Aprovado
    // if(estabelecimento.status === "1") {colorStatus = "#FEAC02"} // Em análise
    // if(estabelecimento.status === "2") {colorStatus = "#5097FF"} // Documentos pendentes
    // if(estabelecimento.status === "3") {colorStatus = "#8850FF"} // Documentos checados
    // if(estabelecimento.status === "4") {colorStatus = "#F35757"} // Reprovado

    // let messageStatus = '';
    // if(estabelecimento.status === "0") {messageStatus = "Aprovado"} // Aprovado
    // if(estabelecimento.status === "1") {messageStatus = "Em análise"} // Em análise
    // if(estabelecimento.status === "2") {messageStatus = "Documentos pendentes"} // Documentos pendentes
    // if(estabelecimento.status === "3") {messageStatus = "Documentos checados"} // Documentos checados
    // if(estabelecimento.status === "4") {messageStatus = "Reprovado"} // Reprovado
    
    // const status = <Tooltip title={messageStatus} placement="top" arrow><span><MdFiberManualRecord color={colorStatus} /></span></Tooltip>

    const saldoFinal = estabelecimento.saldo_conta === null
      ? <span style={{ color: "#A6AAB4", fontWeight: 600 }}>R$ -</span>
      : <span style={{ color: "#0073FC", fontWeight: 600 }}>R$ {FormatarValor(estabelecimento.saldo_conta)}</span>;

    const revendedor = estabelecimento.revendedor === null ? <span style={{ color: "#A6AAB4", fontWeight: 600 }}>-</span> : estabelecimento.revendedor;

    const dataCriacao = estabelecimento.created_at.substring(0, 10).split('-').reverse().join('/');
    const horaCriacao = estabelecimento.created_at.substring(11, 16);

    const dataEHora = <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{dataCriacao}</span>
      <span style={{ color: '#A6AAB4', fontSize: 14 }}>{horaCriacao}</span>
    </div>;

    const licenciado = estabelecimento.licenciado === 'Escola Niteroi' || estabelecimento.la === 'La Default' ? '---' : estabelecimento.licenciado;

    return {
      resp: estabelecimento.responsavel,
      est: estabelecimento.nome_fantasia,
      data: dataEHora,
      la: licenciado,
      rv: revendedor,
      saldo: saldoFinal,
      // status: status,
      edit: <Tooltip title="Editar Dados do EC" placement="top" arrow><span /* className={disabled} */ style={{ cursor: "pointer" }} onClick={() => editEC(estabelecimento)}>
            <img src={editIcon} alt=""/>
        </span></Tooltip>
    }
  });

  const editEC = useCallback((estabelecimento) => {
    const idReverse = estabelecimento.estabelecimento_id.split('').reverse().join('');
    
    // redireciona para a página de edição
    history.push(`/parceiro/estabelecimentos/editar/${idReverse}`);
  }, [history]);

  /* const filterByStatus = useCallback((value) => {
    const filterValue = value.value;

    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    if (filterValue !== '5') {
      api.get(`/lista_ec_admin`, auth).then(response => {
        const arrayData = response.data;

        const filteredData = arrayData.filter(
          item => item.status === filterValue
        );
        
        setEstablishments(filteredData);
      });
    }

    if (filterValue === '5') {
      api.get(`/lista_ec_admin`, auth).then(response => {
        const arrayData = response.data;
        
        setEstablishments(arrayData);
      });
    }

  }, [userToken]); */

  return (
    <Container>
      <MenuBar active="AnaliseEC" />
      <NewMobileMenu active="AnaliseEC" />

      <Page>
        <TopBar pageTitle="Estabelecimentos" />

        <Content>
          <TableContent>

            {/* <Select
              placeholder="Selecione"
              onChange={(value) => filterByStatus(value)}
              className="filterStatusSelect"
              options={[
                { value: "0", label: 'Aprovado' },
                { value: "1", label: 'Em Analise' },
                { value: "2", label: 'Doc Pendentes' },
                { value: "3", label: 'Doc Checados' },
                { value: "4", label: 'Reprovado' },
                { value: "5", label: 'Todos' },
              ]}
            /> */}

            <MaterialTable
              title="Lista de Estabelecimentos"
              columns={headData}
              data={bodyData}
              components={{
                Toolbar: props => (
                  <div>
                    <MTableToolbar {...props} />
                    
                  </div>
                )
              }}
              localization={{
                pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{from}-{to} de {count}',
                  firstTooltip: 'Primeira página',
                  previousTooltip: 'Página anterior',
                  nextTooltip: 'Próxima página',
                  lastTooltip: 'Última página'
                },
                toolbar: {
                  searchPlaceholder: 'Buscar',
                  searchTooltip: 'Buscar'
                },
                body: {
                  emptyDataSourceMessage: 'Nenhum dado encontrado',
                }
              }}
              options={{
                actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: '#10104F',
                  color: '#FFF'
                },
                emptyValue: "Nenhum dado encontrado"
              }}
            />

          </TableContent>

        </Content>
      </Page>
    </Container>
  );
};

export default ParceiroEstabelecimentos;
