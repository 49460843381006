import React from "react";
import { useForm } from "react-hook-form";
import { MdErrorOutline } from "react-icons/md";

import soNumeroSimbolosMask from "../../components/InputMasks/soNumeroSimbolosMask";

import api from "../../services/api";

// Context API
import { useToast } from "../../hooks/ToastContext";

import {
  Container,
  Row4,
  InputArea,
  ButtonRow,
  Abas,
  Aba1,
  InputContainer,
  Error,
} from "./styles";

function FormAddTaxaComissao({ parceiros, typeTransacao, typeName }) {
  const { addToast } = useToast();

  // const licensed = JSON.parse(localStorage.getItem('@DeixaNoAzul:licensedId'));

  const userToken = localStorage.getItem("@DeixaNoAzul:accessToken");

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    console.log('data', data);

    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    try {
      await api.post(
        "/tax_comissoes",
        {
          numero_parcelas: typeTransacao === "TransacaoFgts" ? 0 : data.numero_parcela,
          taxa: Number(data.taxa),
          role: data.role,
          parceiro_id: data.parceiro === "Deixa no Azul" ? null : data.parceiro,
          transacao_type: typeTransacao,
        },
        auth
      ).then(response => {
        console.log('responseTaxaComissao', response);

        addToast({
          type: "success",
          title: "Deu tudo certo!",
          description: "Taxa de comissão cadastrada com sucesso.",
        });
  
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      });

    } catch (error) {
      console.log('errorTaxaComissao', error.response);

      if (error.response.data.message === "This tax already exists") {
        addToast({
          type: "error",
          title: "Erro no cadastro da taxa ",
          description: "Taxa já existente",
        });
      } else {
        addToast({
          type: "error",
          title: "Erro no cadastro da taxa ",
          description: "Erro ao cadastra uma nova taxa",
        });
      }
    }
  };
  const onError = (errors) => {
    addToast({
      type: "error",
      title: "Erro no envio do formulário",
      description:
        "Você precisa preencher todos os campos obrigatórios (marcados com *).",
    });
  };

  // Abas START
  const tab1 = true;

  return (
    <Container>
      <Abas>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Aba1 tab1={tab1}>
            {/* <Rows></Rows> */}
            <h4>Adicionar Taxa de {typeName}</h4>

            <Row4>
              <InputArea>
                <label>*Parceiro</label>
                <InputContainer
                  style={{ borderColor: errors.parceiro && "red" }}
                >
                  <select
                    name="parceiro"
                    placeholder="Selecione"
                    ref={register({ required: true })}
                  >
                    <option value="">Selecione</option>
                    <option value={null}>Deixa no Azul</option>
                    {parceiros.map(parceiro => (
                      <option value={parceiro.id}>{parceiro.nome_fantasia}</option>
                    ))}
                  </select>
                  {errors.parceiro && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              {typeTransacao !== 'TransacaoFgts' && (
                <InputArea>
                  <label>*Número de parcelas</label>
                  <InputContainer
                    style={{ borderColor: errors.numero_parcela && "red" }}
                  >
                    <select
                      name="numero_parcela"
                      placeholder="Selecione"
                      ref={register({ required: true })}
                    >
                      <option value="">Selecione</option>
                      <option value="0">0x</option>
                      <option value="1">1x</option>
                      <option value="2">2x</option>
                      <option value="3">3x</option>
                      <option value="4">4x</option>
                      <option value="5">5x</option>
                      <option value="6">6x</option>
                      <option value="7">7x</option>
                      <option value="8">8x</option>
                      <option value="9">9x</option>
                      <option value="10">10x</option>
                      <option value="11">11x</option>
                      <option value="12">12x</option>
                      <option value="13">13x</option>
                      <option value="14">14x</option>
                      <option value="15">15x</option>
                      <option value="16">16x</option>
                      <option value="17">17x</option>
                      <option value="18">18x</option>
                    </select>
                    {errors.numero_parcela && (
                      <Error title="Campo obrigatório">
                        <MdErrorOutline color="c53030" size={20} />
                      </Error>
                    )}
                  </InputContainer>
                </InputArea>
              )}

              <InputArea>
                <label>*Papel</label>
                <InputContainer style={{ borderColor: errors.role && "red" }}>
                  <select
                    name="role"
                    placeholder="Selecione"
                    ref={register({ required: true })}
                  >
                    <option value="">Selecione</option>
                    <option value="ROLE_PT">Parceiro</option>
                    <option value="ROLE_LA">Licenciado</option>
                    <option value="ROLE_RV">Revendedor</option>
                    <option value="ROLE_EC">Estabelecimento</option>
                  </select>
                  {errors.role && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Taxa de comissão</label>
                <InputContainer style={{ borderColor: errors.taxa && "red" }}>
                  <input
                    name="taxa"
                    type="text"
                    placeholder="00.00"
                    ref={register({ required: "Campo obrigatório" })}
                    onKeyUp={(e) => soNumeroSimbolosMask(e)}
                    // onBlur={() => validarCPF()}
                  />
                  {errors.taxa && (
                    <Error title={errors.taxa.message}>
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>
            </Row4>

            <ButtonRow>
              <button type="submit">Adicionar</button>
            </ButtonRow>
          </Aba1>
        </form>
      </Abas>
    </Container>
  );
}

export default FormAddTaxaComissao;
