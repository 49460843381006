import React, { useState, useCallback, useEffect } from 'react';
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import { useForm } from "react-hook-form";
import { MdErrorOutline } from 'react-icons/md';

import api from '../../services/api';

// Context API
import { useToast } from '../../hooks/ToastContext';
import { useSimulator } from '../../hooks/SimulatorContext';
import { useUserSimulatorInfos } from '../../hooks/UserSimulatorInfosContext';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';

import InfoClient from '../../components/InfoClient';

import paymentIcon from '../../assets/images/payment_icon.svg';
import settingsIcon from '../../assets/images/settings_icon.svg';
import vmIcon from '../../assets/images/vm_icon.svg';
import daeIcon from '../../assets/images/dae_icon.svg';
import loading from '../../assets/images/loading.gif';

// Máscaras
import telefoneMask from '../../components/InputMasks/telefoneMask';

import {
  Container, 
  Page, 
  Content, 
  SimuladorBox, 
  Head, 
  Row, 
  Row3, 
  InputArea,
  InfoBox,
  InfoEC,
  HeadInfoEC,
  BodyInfoEC,
  InputContainer,
  Error,
  RadioContainer,
  LoadingContainer,
} from './styles';

function Simulador() {
  const { addToast } = useToast();
  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');
  const loggedEstabilishment = JSON.parse(localStorage.getItem('@DeixaNoAzul:estabelecimentoId'));
  
  const { simulator, setSimulator } = useSimulator();
  const { setUserSimulatorInfos } = useUserSimulatorInfos();

  // Valor EC
  const valor_total_ec = simulator.valor_comissao_ec.split('.');
  const valorEC = `${valor_total_ec[0]},${valor_total_ec[1] ? valor_total_ec[1].substring(0, 2) : "00"}`

  const valor_acrescimo = Number(simulator.valor_acrescimo);
  const valor_pagamento = Number(simulator.valor_pagamento);
  const valor_liquido_mais_acrescimo = valor_acrescimo + valor_pagamento;


  const liquido_acrescimo = valor_liquido_mais_acrescimo.toString().split('.')
  const liquidoAcrescimo = `${liquido_acrescimo[0]},${liquido_acrescimo[1] ? liquido_acrescimo[1].substring(0, 2) : "00"}`

  const [loadingHide, setLoadingHide] = useState(true);

  // função que envia os dados do usuário para o CRM
  async function sendToCRM(data) {
    let token = '';
    const hash  = 'b8d14936-7c1d-473e-9313-038fae5b79fe'; 
    let personId = '';
    let pipelineId = '';

    // Pegar Token do Piperun
    var myHeadersAuth = new Headers();
    myHeadersAuth.append("Content-Type", "application/json");

    var rawAuth = JSON.stringify({"email":"ti@stalo.digital","password":"SenhaPiperunConfrapag"});

    var requestOptionsAuth = {
      method: 'POST',
      headers: myHeadersAuth,
      body: rawAuth,
      redirect: 'follow'
    };

    await fetch("https://api.pipe.run/v1/auth", requestOptionsAuth)
      .then(response => response.text())
      .then(result => {
        // console.log('auth')
        // console.log(JSON.parse(result))
        const retorno = JSON.parse(result);
        token = retorno.data.me.token;
        pipelineId = retorno.data.me.pipeline_id;
      })
      .catch(error => console.log('error', error));

    
    // Create Person
    var myHeadersPerson = new Headers();
    myHeadersPerson.append("Content-Type", "application/json");
    myHeadersPerson.append("token", token);

    const dataTelefone = data.telefone.replace("(", "").replace(")", "").replace("-", "")

    var rawPerson = JSON.stringify({
      "name":data.nome,
      "hash":hash,
      "contact_emails":[{"email":data.email}],
      "contact_phones":[{"phone":dataTelefone}]
    });

    var requestOptionsPerson = {
      method: 'POST',
      headers: myHeadersPerson,
      body: rawPerson,
      redirect: 'follow'
    };

    await fetch("https://api.pipe.run/v1/persons", requestOptionsPerson)
      .then(response => response.text())
      .then(result => {
        // console.log('person');
        // console.log(JSON.parse(result));
        const retorno = JSON.parse(result);
        personId = retorno.data.id;
      })
      .catch(error => console.log('error', error));


    // Deal
    var myHeadersDeals = new Headers();
    myHeadersDeals.append("Content-Type", "application/json");
    myHeadersDeals.append("token", token);

    const dataValue = data.valor_transacao.replace(".","").replace(",",".");
    const dataTitle = data.nome;

    var rawDeals = JSON.stringify({
      hash:hash,
      person_id:personId,
      // origin_id:84785,
      pipeline_id:pipelineId,
      stage_id:84810,
      title:dataTitle,
      value:Number(dataValue),
    });

    var requestOptionsDeals = {
      method: 'POST',
      headers: myHeadersDeals,
      body: rawDeals,
      redirect: 'follow'
    };

    await fetch("https://api.pipe.run/v1/deals", requestOptionsDeals)
      .then(response => response.text())
      /* .then(result => {
        // console.log('deals')
        // console.log(JSON.parse(result))
      }) */
      .catch(error => console.log('error', error));
  };

  const { register, handleSubmit, setValue, getValues, setError, clearErrors, errors } = useForm();
  const onSubmit = async (data) => {

    // Caso esteja trabalhando em dev, manter o código do CRM comentado,
    // quando for fazer a build pra prod, "descomentar" o código
    setLoadingHide(false);

    // Envia os dados do cliente para o CRM
    await sendToCRM(data);
    
    setUserSimulatorInfos({
      nome: data.nome,
      email: data.email,
      telefone: data.telefone
    });

    const formValue =  {
      numero_parcelas: data.numero_parcela,
      bandeira: data.bandeira,
      maquineta: data.maquineta,
      // conjunto_de_taxas: data.conjunto_de_taxa,
      conjunto_de_taxas: 'PDV4',
      valor_acrescimo: data.taxa_servico,
      valor_pagamento: data.valor_transacao.replace('.', '').replace(',', '.')
    };

    const url = `/get_sim?numero_parcelas=${formValue.numero_parcelas}&bandeira=${formValue.bandeira}&maquineta=${formValue.maquineta}&conjunto_de_taxas=${formValue.conjunto_de_taxas}&valor_acrescimo=${formValue.valor_acrescimo}&valor_pagamento=${formValue.valor_pagamento}&taxa_servico=${formValue.valor_acrescimo}`;

    try {

      api.get(url, {
        headers: {
          Authorization : "Bearer "+userToken
        }
      }).then(response => {
        const value = response.data.data;
        console.log('response simulação', response)
  
        setSimulator({
          bandeira: value.bandeira,
          conjunto_de_taxa: value.conjunto_de_taxa,
          maquineta: value.maquineta,
          quantidade_parcelas: value.quantidade_parcelas,
          taxa_servico: value.taxa_servico,
          valor_acrescimo: value.valor_acrescimo,
          valor_comissao_ec: value.valor_comissao_ec.toString(),
          valor_pagamento: value.valor_pagamento,
          valor_parcela: value.valor_parcela.toString(),
          valor_total: value.valor_total.toString(),
        });
  
        // Subir informações para localstorage
        const infosLocalStorage = {
          /** simulador */
          bandeira: value.bandeira,
          conjunto_de_taxa: value.conjunto_de_taxa,
          maquineta: value.maquineta,
          quantidade_parcelas: value.quantidade_parcelas,
          taxa_servico: value.taxa_servico,
          valor_acrescimo: value.valor_acrescimo,
          valor_comissao_ec: value.valor_comissao_ec.toString(),
          valor_pagamento: value.valor_pagamento,
          valor_parcela: value.valor_parcela.toString(),
          valor_total: value.valor_total.toString(),
          /** pessoais */
          nome: data.nome,
          email: data.email,
          telefone: data.telefone,
          cpf: '',
          rg: '',
          /** transação */
          numero_autorizacao: '',
          tipo_transacao: '',
          nome_titular: '',
          nome_banco: '',
          numero_banco: '',
          conta_juridica: '',
          cpf_cnpj: '',
          tipo_conta: '',
          agencia: '',
          numero_conta: '',
          operacao: '',
        };
  
        localStorage.setItem('@DeixaNoAzul:dadosSimulaçãoTransação', JSON.stringify(infosLocalStorage));

        setLoadingHide(true);

        // mostra as informações da simulação
        if (infosOpened === true) {
          setInfosOpened(false);
        };
        
      }).catch(err => {
        console.log('err', err.response);

        setLoadingHide(true);

        if (err.response.status === 500) {
          addToast({
            type: 'error',
            title: 'Erro na simulação',
            description: 'Encontramos um erro de comunicação com o servidor. Tente novamente mais tarde.',
          });

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na simulação',
          description: 'Algo deu errado. Tente novamente ou entre em contato com o Deixa no Azul.',
        });

        return;
      });
      
    } catch (error) {
      console.log(error.response);

      addToast({
        type: 'error',
        title: 'Erro na simulação',
        description: 'Algo deu errado. Tente novamente ou entre em contato com o Deixa no Azul.',
      });
    }
  };
  const onError = (errors) => {
    addToast({
      type: 'error',
      title: 'Erro no envio do formulário',
      description: 'Você precisa preencher todos os campos obrigatórios (marcados com *).',
    });
  };

  const [infoECOpened, setInfoECOpened] = useState(true);
  const [infosOpened, setInfosOpened] = useState(true);

  // Máscara do valor da transação
  const inputValueMask = useCallback((e) => {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
    e.currentTarget.value = value;
  }, []);

  // Faz a validação do E-mail
  function validarEmail() {
    const valorDigitado = getValues("email");
    const indexArroba = valorDigitado.indexOf("@");

    const usuario = valorDigitado.substring(0, indexArroba);
    const dominio = valorDigitado.substring(indexArroba + 1, valorDigitado.length);

    if (
      (usuario.length >= 1) &&
      (dominio.length >= 3) &&
      (usuario.search("@") === -1) &&
      (dominio.search("@") === -1) &&
      (usuario.search(" ") === -1) &&
      (dominio.search(" ") === -1) &&
      (dominio.search(".") !== -1) &&
      (dominio.indexOf(".") >= 1)&&
      (dominio.lastIndexOf(".") < dominio.length - 1)
    ) {
      // e-mail válido
      clearErrors("email");
    } else { 
      // e-mail inválido
      setError("email", {
        type: "manual",
        message: "O E-mail digitado é inválido."
      });
    }
  };

  // Carrega os valores de alguns campos automaticamente
  useEffect(() => {
    /* const infosLocalStorage = {
      // simulador
      bandeira: '',
      conjunto_de_taxa: '',
      maquineta: '',
      quantidade_parcelas: '',
      taxa_servico: '',
      valor_acrescimo: '',
      valor_comissao_ec: '',
      valor_pagamento: '',
      valor_parcela: '',
      valor_total: '',
      // pessoais
      nome: '',
      email: '',
      telefone: '',
      cpf: '',
      rg: '',
      // transação
      numero_autorizacao: '',
      tipo_transacao: '',
      nome_titular: '',
      nome_banco: '',
      numero_banco: '',
      conta_juridica: '',
      cpf_cnpj: '',
      tipo_conta: '',
      agencia: '',
      numero_conta: '',
      operacao: '',
    }; */

    localStorage.removeItem('@DeixaNoAzul:dadosSimulaçãoTransação');

    setValue("maquineta", 'Celer');
    setValue("conjunto_de_taxa", 'PDV4');
    setValue("numero_parcela", '12');
    setValue("taxa_servico", '50');
  }, [setValue]);

  return (
    <Container>
      <LoadingContainer loadingHide={loadingHide}>
        <img src={loading} alt="Carregando..."/>
      </LoadingContainer>

      <MenuBar active="Simulador" />
      <NewMobileMenu active="Simulador" />

      <Page>
        <TopBar pageTitle="Simulador" />

        <Content>
          <SimuladorBox infosOpened={infosOpened}>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <Head>
                <h3>Faça aqui a Simulação</h3>

                <div>
                  <InputContainer style={{ borderColor: errors.maquineta && "red" }}>
                    <img src={paymentIcon} className="imgSelect" alt=""/>
                    <select
                      name="maquineta"
                      ref={register({ required: true })}
                    >
                      <option value="">Selecione</option>
                      <option value="Celer">Celer</option>
                    </select>
                    {errors.maquineta && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>

                  {/* Quando retirar o disabled deixar o recebimento do dado no submit dinâmico */}
                  <InputContainer style={{ borderColor: errors.conjunto_de_taxa && "red" }}>
                    <img src={settingsIcon} className="imgSelect" alt=""/>
                    <select
                      name="conjunto_de_taxa"
                      ref={register({ required: true })}
                      disabled
                      style={{ cursor: "not-allowed" }}
                    >
                      <option value="">Selecione</option>
                      <option value="PDV4">PDV4</option>
                      <option value="PDV3">PDV3</option>
                      <option value="PDV2">PDV2</option>
                    </select>
                    {errors.conjunto_de_taxa && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </div>
              </Head>

              <Row>
                <InputArea>
                  <label>*Valor da transação</label>
                  <InputContainer style={{ borderColor: errors.valor_transacao && "red" }}>
                    <input
                    name="valor_transacao"
                    type="text"
                    ref={register({ required: true })}
                    onKeyUp={(e) => inputValueMask(e)}
                    placeholder="Digite o valor (apenas números)"
                    />
                    {errors.valor_transacao && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>

                <InputArea>
                  <label>*Quantidade de parcelas </label>
                  <InputContainer style={{ borderColor: errors.numero_parcela && "red" }}>
                    <select
                      name="numero_parcela"
                      placeholder="Selecione"
                      ref={register({ required: true })}
                    >
                      <option value="">Selecione</option>
                      <option value="0">Débito</option>
                      <option value="1">1x</option>
                      <option value="2">2x</option>
                      <option value="3">3x</option>
                      <option value="4">4x</option>
                      <option value="5">5x</option>
                      <option value="6">6x</option>
                      <option value="7">7x</option>
                      <option value="8">8x</option>
                      <option value="9">9x</option>
                      <option value="10">10x</option>
                      <option value="11">11x</option>
                      <option value="12">12x</option>
                      <option value="13">13x</option>
                      <option value="14">14x</option>
                      <option value="15">15x</option>
                      <option value="16">16x</option>
                      <option value="17">17x</option>
                      <option value="18">18x</option>
                    </select>
                    {errors.numero_parcela && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>
              </Row>

              <Row>
                <InputArea>
                  <label>*TS</label>
                  <InputContainer style={{ borderColor: errors.taxa_servico && "red" }}>
                    <select
                      name="taxa_servico"
                      placeholder="Selecione"
                      ref={register({ required: true })}
                    >
                      <option value="">Selecione</option>
                      <option value="0">+ R$ 0</option>
                      <option value="20">+ R$ 20</option>
                      <option value="35">+ R$ 35</option>
                      <option value="50">+ R$ 50</option>
                    </select>
                    {errors.taxa_servico && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>

                <InputArea>
                  <label>*Bandeiras</label>
                  <RadioContainer>
                    <div>
                      <input
                        name="bandeira"
                        type="radio"
                        value="VM"
                        id="VM"
                        ref={register({ required: true })}
                      />
                      <label htmlFor="VM"><img src={vmIcon} alt=""/></label>
                    </div>

                    <div>
                      <input
                        name="bandeira"
                        type="radio"
                        value="DAE"
                        id="DAE"
                        ref={register({ required: true })}
                      />
                      <label htmlFor="DAE"><img src={daeIcon} alt=""/></label>
                    </div>
                    {errors.bandeira && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </RadioContainer>
                </InputArea>
              </Row>

              <Row3>
                <InputArea>
                  <label>*Nome completo</label>
                  <InputContainer style={{ borderColor: errors.nome && "red" }}>
                    <input
                    name="nome"
                    type="text"
                    ref={register({ required: true })}
                    // onKeyUp={(e) => inputValueMask(e)}
                    placeholder="Digite o nome completo"
                    />
                    {errors.nome && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>

                <InputArea>
                  <label>*Email</label>
                  <InputContainer style={{ borderColor: errors.email && "red" }}>
                    <input
                    name="email"
                    type="text"
                    ref={register({ required: "Campo obrigatório" })}
                    placeholder="Digite o e-mail"
                    onBlur={() => validarEmail()}
                    />
                    {errors.email && <Error title={errors.email.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>

                <InputArea>
                  <label>*Telefone</label>
                  <InputContainer style={{ borderColor: errors.telefone && "red" }}>
                    <input
                    name="telefone"
                    type="text"
                    ref={register({ required: true })}
                    onKeyUp={(e) => telefoneMask(e)}
                    placeholder="Digite o telefone"
                    />
                    {errors.telefone && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>
              </Row3>

              <button type="submit">Simular</button>
            </form>
          </SimuladorBox>

          <InfoBox infosOpened={infosOpened} >
            <InfoClient hasButton hasInfos />

            <InfoEC>
              <HeadInfoEC>
                <h3>Informações para EC</h3> 
                <div onClick={() => setInfoECOpened(!infoECOpened)}>
                  {infoECOpened === true ?
                  <MdAddCircleOutline size={19} /> :
                  <MdRemoveCircleOutline size={19} />}
                </div>
              </HeadInfoEC>
              
              <BodyInfoEC infoECOpened={infoECOpened}>
                <div>
                  <label>Valor que ganhará nessa transação</label>
                  <span>R$ {loggedEstabilishment.licenciado_autorizado_id !== 'fec2f2a1-f662-4d51-a9f6-01e33172e934' ? valorEC : 'Indefinido'}</span>
                </div>

                <p>O cliente deve passar uma conta 
                  de <strong>R$ {liquidoAcrescimo}</strong> reais em <strong>{simulator.quantidade_parcelas}x</strong> vezes 
                  com o cartão de crédito na maquineta.
                </p>
              </BodyInfoEC>
            </InfoEC>
          </InfoBox>
        </Content>
      </Page>
    </Container>
  );
}

export default Simulador;