import React, { useState, useEffect } from "react";
// import Select from "react-select";
import MaterialTable, { MTableToolbar } from "material-table";
import { useLocation } from 'react-router-dom';


import TopBar from "../../components/TopBar";
import MenuBar from "../../components/MenuBar";
import NewMobileMenu from "../../components/NewMobileMenu";

import soNumeroSimbolosMask from "../../components/InputMasks/soNumeroSimbolosMask";

import { useToast } from "../../hooks/ToastContext";

import {
  MdAdd,
  MdRemove,
  // MdEdit,
  // MdNavigateBefore,
  // MdNavigateNext,
} from "react-icons/md";
import editIcon from "../../assets/images/edit_icon.svg";

import api from "../../services/api";
// import { useLicensed } from '../../hooks/LicensedContext';

import {
  Container,
  Page,
  Content,
  TableContent,
  AddLA,
  Button,
  FormSection,
  TableContentMobile,
  Header,
  Line,
  MobileBody,
  BoxEditaTaxa,
} from "./styles";

import FormAddTaxaComissaoFgts from "../../components/FormAddTaxaComissaoFgts";

function TaxaComissaoEprepag() {
  const { addToast } = useToast();
  const [formOpened, setFormOpened] = useState(true);

  const location = useLocation();

  const typeTransacao = location.pathname.replace('/admin/taxacomissao/', '');

  // console.log(typeTransacao);

  // const [taxaComissao, setTaxaComissao] = useState([]);
  const [taxaComissaoVisiveis, setTaxaComissaoVisiveis] = useState([]);

  const [editarTaxa, setEditarTaxa] = useState(false);
  const [editarTaxaId, setEditarTaxaId] = useState();

  const [taxaComissaoValor, setTaxaComissaoValor] = useState("");

  const [numeroDeParcelas, setNumeroDeParcelas] = useState("");
  const [taxaDeServico, setTaxaDeServico] = useState("");
  const [role, setRole] = useState("");

  const [taxaId, setTaxaId] = useState("");

  // const [filtroPapel, setFiltroPapel] = useState('TODOS');

  const [taxaComissaoValorMenorQueZero, setTaxaComissaoValorMenorQueZero] =
    useState(false);

  // const [selectParcelaMobile, setSelectParcelaMobile] = useState(false);
  const selectParcelaMobile = false;

  const [atualizaTaxaComissao, setAtualizaTaxaComissao] = useState(false);

  const userToken = localStorage.getItem("@DeixaNoAzul:accessToken");

  // Faz a requisição a /tax_comissoes para pegar as taxas e alimentar o array
  useEffect(() => {
    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    api.get("/tax_comissoes?conditions=transacao_type:=:TransacaoFgts", auth).then((response) => {
      const result = response.data.data;
      console.log('taxa_comissoes', response)

      // setTaxaComissao(result);  
      setTaxaComissaoVisiveis(result);
    });
  }, [userToken, typeTransacao, taxaId]);

  // console.log(taxaComissao);

  function verificaValorTaxa(e) {
    setTaxaComissaoValor(e);

    if (Number(taxaComissaoValor) <= 0) {
      setTaxaComissaoValorMenorQueZero(true);
    } else {
      setTaxaComissaoValorMenorQueZero(false);
    }

    if (e === "" || Number(e) <= 0) {
      setTaxaComissaoValorMenorQueZero(true);
    } else {
      setTaxaComissaoValorMenorQueZero(false);
    }
  }

  useEffect(() => {
    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    if (taxaId !== "") {
      async function update() {
        try {
          await api.put(
            `/tax_comissoes/${taxaId}`,
            {
              numero_parcelas: numeroDeParcelas,
              taxa: taxaDeServico,
              role: role,
            },
            auth
          );

          addToast({
            type: "success",
            title: "Deu tudo certo!",
            description: "Taxa atualizada com sucesso.",
          });

          setAtualizaTaxaComissao(!atualizaTaxaComissao);
          setEditarTaxa(false);
          setTaxaId("");
        } catch (error) {
          if (error.response.data.message === "This tax already exists") {
            addToast({
              type: "error",
              title: "Erro na atualização da taxa ",
              description: "Taxa já existente",
            });
          } else {
            addToast({
              type: "error",
              title: "Erro em alterar parcela",
              description: "Erro em alterar taxa",
            });
          }
        }
      }

      update();
    }
  }, [addToast, atualizaTaxaComissao, numeroDeParcelas, role, taxaDeServico, taxaId, userToken]);

  // Informações Head da tabela
  const headData = [
    { title: "Papel", field: "papel", sorting: false },
    { title: "", field: "", sorting: false },
    { title: "Taxas de Comissão", field: "taxa_comissao", sorting: false },
  ];
  
  const bodyData = taxaComissaoVisiveis.map((taxa) => {

    const role = (
      <p>
        {taxa.role === "ROLE_EC" && "Estabelecimento"}
        {taxa.role === "ROLE_LA" && "Licenciado"}
        {taxa.role === "ROLE_RV" && "Revendedor"}
        {taxa.role === "ROLE_PT" && "Parceiro"}
      </p>
    );

    const taxa_comissao = (
      <BoxEditaTaxa
        taxaComissaoValorMenorQueZero={taxaComissaoValorMenorQueZero}
      >
        {editarTaxa === true && editarTaxaId === taxa.id ? (
          <div className="boxInput">
            <div className="input">
              <span className="porcentagem">%</span>
              <input
                value={taxaComissaoValor}
                name="taxa_comissao"
                placeholder={taxa.taxa.toFixed(2)}
                onChange={(e) => verificaValorTaxa(e.target.value)}
                onKeyUp={(e) => soNumeroSimbolosMask(e)}
              />
            </div>
            <button
              onClick={() => {
                if (taxaComissaoValorMenorQueZero === false) {
                  setTaxaId(taxa.id);
                  setTaxaDeServico(taxaComissaoValor);
                } else {
                  return;
                }
              }}
            >
              ok
            </button>
            <button
              className="cancelar"
              onClick={() => {
                setEditarTaxa(false);
              }}
            >
              x
            </button>
          </div>
        ) : (
          <>
            <span>%</span> <span>{taxa.taxa.toFixed(2)}</span>{" "}
            <span
              style={{ cursor: "pointer", marginLeft: 15 }}
              onClick={() => {
                setEditarTaxa(true);
                setEditarTaxaId(taxa.id);
                setTaxaComissaoValor(taxa.taxa);
                setTaxaComissaoValorMenorQueZero(false);
              }}
            >
              <img src={editIcon} alt="" style={{ width: 12 }} />
            </span>
          </>
        )}
      </BoxEditaTaxa>
    );
    
    // let produto = '';

    // produtos.filter((prod) => {
    //   if(String(prod.id) === String(taxa.numero_parcelas)){
    //     produto = prod.nome;
    //   }
    // });

    return {
      papel: role,
      taxa_comissao: taxa_comissao,
    };
  });

  // function filterByRole(value) {
  //   const filterValue = value.value;

  //   if (filterValue !== 'TODOS') {
  //     const transacoesFiltradas = taxaComissao.filter(item => String(item.numero_parcelas) === String(filterValue));

  //     setTaxaComissaoVisiveis(transacoesFiltradas);
  //   }
  // }

  return (
    <>
      <Container>
        <MenuBar active="TaxaComissao" subMenuActive={"FGTS Taxas"} />
        <NewMobileMenu active="TaxaComissao" subMenuActive={"FGTS Taxas"} />

        <Page>
          <TopBar pageTitle="Taxas de Comissão - FGTS" />

          <Content>
            <AddLA>
              {/* <span>Adicionar Taxa de Simulação</span> */}
              <Button onClick={() => setFormOpened(!formOpened)}>
                {formOpened === true ? (
                  <MdAdd color="#fff" />
                ) : (
                  <MdRemove color="#fff" />
                )}
              </Button>
            </AddLA>

            <FormSection formOpened={formOpened}>
              <FormAddTaxaComissaoFgts />
            </FormSection>

            <TableContent style={{ zIndex: 0 }}>
              {/* <Select
                placeholder="Produto"
                onChange={(value) => {
                  setFiltroPapel(value.value);
                  filterByRole(value);
                }}
                className="select3"
                options={
                  produtos.map((prod) => ({ value:String(prod.id), label: String(prod.nome)})) 
                }
              /> */}

              <MaterialTable
                style={{ position: "relative" }}
                title="Lista de taxas"
                columns={headData}
                data={bodyData}
                components={{
                  Toolbar: (props) => (
                    <div>
                      <MTableToolbar {...props} />
                    </div>
                  ),
                }}
                localization={{
                  pagination: {
                    labelRowsSelect: "linhas",
                    labelDisplayedRows: "{from}-{to} de {count}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                  },

                  toolbar: {
                    searchPlaceholder: false,
                    searchTooltip: false,
                  },
                  body: {
                    emptyDataSourceMessage: "Nenhum dado encontrado",
                  },
                }}
                options={{
                  actionsColumnIndex: -1,
                  headerStyle: {
                    backgroundColor: "#10104F",
                    color: "#FFF",
                  },
                  emptyValue: "Nenhum dado encontrado",
                  search: false,
                }}
              />
            </TableContent>

            <TableContentMobile>
              <Header>
                <h3>Lista de taxas - FGTS</h3>

                {/* <span>Total: </span> */}
                {/* <Select
                  placeholder="Produto"
                  onChange={(value) => setFiltroPapel(value.value)}
                  className="select4"
                  options={
                    produtos.map((prod) => ({ value:String(prod.id), label: String(prod.nome)})) 
                  }
                /> */}
              </Header>

              <MobileBody>
                {taxaComissaoVisiveis.map((taxa) => {
                  let produto = '';

                  return (
                  <Line key={taxa.id} selectParcelaMobile={selectParcelaMobile}>
                    <div className="coluna1">
                      <div>
                        <p>Produto</p>
                        <div>
                          <span style={{fontSize: 22}}>{produto}</span>
                        </div>
                      </div>
                      <div>
                        <p>Papel</p>
                        <div>
                          <span style={{fontSize: 22}}>
                          {taxa.role === "ROLE_EC" && "Estabelecimento"}
                          {taxa.role === "ROLE_LA" && "Licenciado"}
                          {taxa.role === "ROLE_RV" && "Revendedor"}
                          {taxa.role === "ROLE_PT" && "Parceiro"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="coluna2">
                      <p>Taxa de Comissão</p>
                      <div>
                        <BoxEditaTaxa
                          taxaComissaoValorMenorQueZero={
                            taxaComissaoValorMenorQueZero
                          }
                        >
                          {editarTaxa === true && editarTaxaId === taxa.id ? (
                            <div className="boxInput">
                              <div className="input">
                                <span
                                  style={{ fontSize: 16 }}
                                  className="porcentagem"
                                >
                                  %
                                </span>
                                <input
                                  value={taxaComissaoValor}
                                  name="taxa_comissao"
                                  placeholder={taxa.taxa.toFixed(2)}
                                  onChange={(e) =>
                                    verificaValorTaxa(e.target.value)
                                  }
                                  onKeyUp={(e) => soNumeroSimbolosMask(e)}
                                />
                              </div>
                              <button
                                onClick={() => {
                                  if (taxaComissaoValorMenorQueZero === false) {
                                    setTaxaId(taxa.id);
                                    setTaxaDeServico(taxaComissaoValor);
                                    setNumeroDeParcelas(taxa.numero_parcelas);
                                    setRole(taxa.role);
                                  } else {
                                    return;
                                  }
                                }}
                              >
                                ok
                              </button>
                              <button
                                className="cancelar"
                                onClick={() => {
                                  setEditarTaxa(false);
                                }}
                              >
                                x
                              </button>
                            </div>
                          ) : (
                            <>
                              <span>%</span> <span>{taxa.taxa.toFixed(2)}</span>{" "}
                              <span
                                style={{ cursor: "pointer", marginLeft: 15 }}
                                onClick={() => {
                                  setEditarTaxa(true);
                                  setEditarTaxaId(taxa.id);
                                  setTaxaComissaoValor(taxa.taxa);
                                  setTaxaComissaoValorMenorQueZero(false);
                                }}
                              >
                                <img
                                  src={editIcon}
                                  alt=""
                                  style={{ width: 18 }}
                                />
                              </span>
                            </>
                          )}
                        </BoxEditaTaxa>
                      </div>
                    </div>
                  </Line>
                )})}
              </MobileBody>
            </TableContentMobile>
          </Content>
        </Page>
      </Container>
    </>
  );
}

export default TaxaComissaoEprepag;
