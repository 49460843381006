import styled, { css } from "styled-components";

import backgroundImg from "../../assets/images/background_2.jpg";

import { shade } from "polished";

import ToolTip from "../../components/Tooltip";

export const Container = styled.div``;

export const Page = styled.div`
  margin-left: 220px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  background: url(${backgroundImg}) no-repeat;
  background-position: top left;
  background-size: 100% 256px;
  box-sizing: border-box;

  padding: 100px 90px 20px 30px;

  /* display: grid; */
  /* grid-template-columns: 1.5fr 1fr; */
  /* gap: 20px; */

  @media (max-width: 500px) {
    padding: 50px 20px 20px;
  }
`;

// export const TransactionContent = styled.div``;

// export const MessageContent = styled.div``;

export const RowBox1 = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 20px;

  margin-bottom: 30px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

export const RowBoxBoleto = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 20px;

  margin-bottom: 30px;

  div.pagar-boleto {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    button {
      width: 100px;
      height: 36px;
      background: #10104f;
      border: 1px solid #0073fc;
      border-radius: 4px;
      color: #fff;
      font-weight: bold;
      text-align: center;
    }
  }

  ${(props) =>
    props.boletoOptions &&
    css`
      display: none;
    `}

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 20px;

    div.dados {
      flex-direction: column;
    }
  }
`;

export const AlterarStatus = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  span {
    font-weight: bold;
    font-size: 20px;
  }

  div.buttons {
    margin-bottom: 20px;

    button.salvar {
      color: #fff;
      margin-right: 20px;
      background: #0073fc;
      border: none;
      width: 110px;
      height: 38px;
      border-radius: 4px;
    }

    button.cancelar {
      color: #373a3c;
      margin-right: 20px;
      background: #f5f8fb;
      border: none;
      width: 110px;
      height: 38px;
      border-radius: 4px;
    }
  }

  span.anexar-comprovante {
    margin-top: -20px;
    margin-bottom: 30px;
  }

  span.escolher-arquivo {
    cursor: pointer;
    display: flex;
    border: 1px solid #fff;
    padding: 5px;
    border-radius: 5px;
    margin-top: -15px;
    margin-bottom: 40px;

    p {
      font-size: 15px;
      font-weight: 400;
      margin-left: 10px;
      margin-right: 7px;
    }
  }

  form {
    /* background-color: red; */
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const RowBoxSim = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 0;

  background: #0073fc;
  border-radius: 8px;

  margin-bottom: 30px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const BoxSim = styled.div`
  background: #0073fc;
  padding: 20px 30px;

  @media (max-width: 500px) {
    padding: 20px;
  }

  h3 {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 20px;
    color: #fff;
  }

  &.box1 {
    border-radius: 8px 0 0 0;
    border-right: 1px solid rgba(255, 255, 255, 0.4);
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);

    @media (max-width: 500px) {
      border-radius: 8px 8px 0 0;
      border-right: none;
    }
  }
  &.box2 {
    border-radius: 0 8px 0 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);

    @media (max-width: 500px) {
      border-radius: 0;
    }
  }
  &.box3 {
    border-radius: 0 0 0 8px;
    border-right: 1px solid rgba(255, 255, 255, 0.4);

    @media (max-width: 500px) {
      border-radius: 0;
      border-right: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }
  }
  &.box4 {
    border-radius: 0 0 8px 0;

    @media (max-width: 500px) {
      border-radius: 0 0 8px 8px;
    }
  }
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 18px;

  h3 {
    font-size: 20px;
    font-weight: normal;
    margin: 0;

    @media (max-width: 500px) {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const PaymentInfos = styled.div`
  display: flex;
  align-items: center;

  > div {
    display: flex;
    align-items: center;

    &:first-child {
      margin-right: 40px;
    }

    > img {
      margin-right: 10px;
    }

    > p {
      color: #fff;
      margin: 0;
    }
  }
`;

export const RowBox2 = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 20px;

  margin-bottom: 30px;

  h3 {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 20px;
  }

  ${(props) =>
    props.depositoOpened &&
    css`
      display: none;
    `}

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const RowBox3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;

  margin-bottom: 30px;

  ${(props) =>
    props.comprovantePagamentoHide &&
    css`
      display: none;
    `}

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const RowBox4 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;

  margin-bottom: 30px;

  ${(props) =>
    props.comprovantePagamentoHide &&
    css`
      display: none;
    `}

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const Box = styled.div`
  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;

  input.email {
    border: 2px solid #cccccc;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    color: #999999;
  }

  div.box-button {
    display: flex;
    justify-content: flex-end;
  }

  button.button-enviar {
    background-color: #0073fc;
    padding: 3px 5px;
    color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
  }

  h3 {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 20px;
  }

  > span {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
    cursor: pointer;

    img {
      width: 20px;
      margin-right: 10px;
    }
  }

  @media (max-width: 500px) {
    margin-top: 10px;
  }
`;

export const InputArea = styled.div`
  > label {
    display: block;

    &.marginTop {
      margin-top: 10px;
    }
  }

  button.downloadButton {
    display: inline-flex;
    background: transparent;
    padding: 0;
    margin: 5px 0 18px;
    color: #0073fc;
    border: none;
    outline-style: none;
    cursor: pointer;

    &:hover {
      background: transparent;
      color: ${shade(0.2, "#0073FC")};
    }
  }

  > p {
    color: #0073fc;
    margin: 5px 0 18px;

    &.link {
      cursor: pointer;
      text-decoration: underline;
      /* border-bottom: 1px solid #0073FC; */
    }
  }

  > button {
    padding: 8px 22px;
    width: 100%;
    background: #0073fc;
    border: 1px solid #0073fc;
    color: #ffffff;
    border: none;
    border-radius: 4px;

    margin-top: 20px;
    margin-bottom: 60px;
    transition: 0.2s;

    &:hover {
      background: ${shade(0.2, "#0073FC")};
    }
  }

  div.action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    img {
      width: 16px;
      height: 16px;

      cursor: pointer;
    }
  }

  @media (max-width: 500px) {
    &.campoAnexo {
      margin-bottom: 15px;
    }
  }
`;

export const InfoArea = styled.div`
  > label {
    color: #fff;
    display: block;
  }

  > p {
    color: #fff;
    font-weight: 700;
    margin: 5px 0 18px;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  width: 100%;

  &.marginTop {
    margin-top: 18px;
  }

  ${(props) =>
    props.downloadBoletoHide &&
    css`
      display: none;
    `}

  &.anexos {
    @media (max-width: 1366px) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  @media (max-width: 1040px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const Row3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  width: 100%;

  @media (max-width: 1220px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1040px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const BoletoOptions = styled.div`
  ${(props) =>
    props.boletoOptions &&
    css`
      display: none;
    `}

  background: #fff;
  padding: 0 30px 20px;
  border-radius: 8px;
  margin-top: -20px;
`;

export const SelectStatus = styled.div`
  margin-bottom: 30px;

  form {
    display: flex;
    align-items: center;

    .sc-cjzMPC.dRiRcZ {
      margin: 0;
    }

    button {
      padding: 8px 22px;
      background: #0073fc;
      color: #ffffff;
      border: none;
      border-radius: 4px;

      transition: 0.2s;
      outline-style: none;

      &:hover {
        background: ${shade(0.2, "#0073FC")};
      }
    }
  }

  ${(props) =>
    props.editStatusHide &&
    css`
      display: none;
    `}

  @media (max-width: 500px) {
    margin-bottom: 100px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 220px;
  height: 40px;

  background: #feac02;
  /* border: 1px solid #CCCCCC; */
  border-radius: 4px;
  box-sizing: border-box;

  margin-right: 30px;
  padding: 1px 11px 1px 1px;

  ${(props) =>
    props.isFocused &&
    css`
      border: 1px solid #0073fc;
    `}

  input {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;
  }

  select {
    border: none;
    /* background: #FEAC02; */
    color: #fff;

    height: 34px;
    width: 100%;
    padding: 0 10px;

    ${(props) =>
      props.statusColor === "1" &&
      css`
        background: #feac02;
      `}

    ${(props) =>
      props.statusColor === "2" &&
      css`
        background: #5097ff;
      `}

    ${(props) =>
      props.statusColor === "3" &&
      css`
        background: #5cb85c;
      `}

    ${(props) =>
      props.statusColor === "4" &&
      css`
        background: #F04148;
      `}

    ${(props) =>
      props.statusColor === "5" &&
      css`
        background: #8850ff;
      `}

    ${(props) =>
      props.statusColor === "6" &&
      css`
        background: #FF7C33;
      `}
  }

  option {
    color: #000;
    background: #fff;
  }

  ${(props) =>
    props.statusColor === "1" &&
    css`
      background: #feac02;
    `}

  ${(props) =>
    props.statusColor === "2" &&
    css`
      background: #5097ff;
    `}

    ${(props) =>
    props.statusColor === "3" &&
    css`
      background: #5cb85c;
    `}

    ${(props) =>
    props.statusColor === "4" &&
    css`
      background: #F04148;
    `}

    ${(props) =>
    props.statusColor === "5" &&
    css`
      background: #8850ff;
    `}

    ${(props) =>
    props.statusColor === "6" &&
    css`
      background: #FF7C33;
    `}
`;

export const ModalSection = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(55, 58, 60, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 5;

  ${(props) =>
    props.modalOpened &&
    css`
      display: none;
    `}
`;

export const ModalContent = styled.div`
  color: #fff;
  background: #10104f;
  padding: 30px;
  border-radius: 8px;
  max-width: 640px;
  /* max-height: 420px; */

  width: 100%;
  /* height: 100%; */

  > svg {
    margin-left: calc(100% - 30px);
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      color: ${shade(0.2, "#fff")};
    }
  }

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 30px;

    @media (max-width: 500px) {
      font-size: 18px;
      display: block;
      margin-bottom: 20px;
    }
  }

  div.buttonRow {
    margin: 30px auto 0;
    text-align: center;

    button {
      padding: 8px 27px;
      background: #0073fc;
      border: 1px solid #0073fc;
      color: #ffffff;
      border: none;
      border-radius: 4px;

      transition: 0.2s;

      &:hover {
        background: ${shade(0.2, "#0073FC")};
      }
    }

    button.cancelar {
      padding: 8px 27px;
      background: #f5f8fb;
      border: 1px solid #adadad;
      color: #373a3c;
      border-radius: 4px;

      margin-left: 30px;
      transition: 0.2s;

      &:hover {
        background: ${shade(0.2, "#F5F8FB")};
      }
    }

    @media (max-width: 500px) {
      margin: 20px auto 0;
    }
  }

  @media (max-width: 500px) {
    width: 94%;
    padding: 20px;
    margin-bottom: 20px;
  }
`;

export const Aprovado = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) =>
    props.aprovadoHide &&
    css`
      display: none;
    `}

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 14px;

    &:last-child {
      margin-top: 14px;
    }

    @media (max-width: 500px) {
      font-size: 18px;
    }
  }

  div.upload {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 210px;
  }

  @media (max-width: 500px) {
    div.upload {
      overflow-x: hidden;
      overflow-y: scroll;
      height: 150px;
      margin-top: -10px;
      margin-bottom: -15px;
    }
  }
`;

export const Mensagem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 30px;

  ${(props) =>
    props.messageHide &&
    css`
      display: none;
    `}

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 14px;

    &:last-child {
      margin-top: 14px;
    }

    @media (max-width: 500px) {
      font-size: 18px;
    }
  }

  div.inputArea {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 170px;

    textarea {
      border-radius: 4px;
    }

    div.labelRow {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 500px) {
    padding: 0;
  }
`;

export const Confirmar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) =>
    props.confirmarHide &&
    css`
      display: none;
    `}

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 14px;

    &:last-child {
      margin-top: 14px;
    }

    @media (max-width: 500px) {
      font-size: 18px;
      text-align: center;
    }
  }
`;

export const EditarPagamento = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) =>
    props.editarPagamentoHide &&
    css`
      display: none;
    `}

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 14px;

    &:last-child {
      margin-top: 14px;
    }

    @media (max-width: 500px) {
      font-size: 18px;
      text-align: center;
    }
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 11px;

  border: 1px solid #fff;
  border-radius: 4px;

  width: 200px;

  &.edição {
    border: 1px solid #cccccc;
    background: transparent;
  }

  ${(props) =>
    props.pagamentoHide &&
    css`
      display: none;
    `}
`;

export const Error = styled(ToolTip)`
  span {
    background: #c53030;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const BoxInfo = styled.div`
  background: transparent;
  border: 1px solid #f1f5f9;
  padding: 8px 16px;
  border-radius: 6px;
  margin-bottom: 16px;
  max-width: 300px;
  opacity: 0.6;

  span {
    font-size: 14px;
  }
`;

export const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(55, 58, 60, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 5;

  img {
    width: 220px;
  }

  ${(props) =>
    props.loadingHide &&
    css`
      display: none;
    `}
`;

export const Status = styled.span`
  color: #fff;
  border-radius: 5px;
  padding: 4px;
  font-weight: bold;

  ${(props) =>
    props.tipoDeStatus === "EM_ABERTO" &&
    css`
      background: #feac02;
    `}

  ${(props) =>
    props.tipoDeStatus === "PAGO" &&
    css`
      background: #5cb85c;
    `}

    ${(props) =>
    props.tipoDeStatus === "REJEITADO" &&
    css`
      background: #ef3434;
    `}
`;

export const DadosBoleto = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 270px;
  justify-content: flex-start;
`;

export const AcaoBoleto = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 140px;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: -100px;

  span.status {
    color: #0073fc;
    border: 1px solid #0073fc;
    border-radius: 5px;
    padding: 2px 4px;
    width: 60px;
    text-align: center;
    cursor: pointer;
  }

  span.pagar {
    margin-left: 10px;
    color: #fff;
    background: #0073fc;
    border-radius: 5px;
    padding: 4px;
    width: 60px;
    text-align: center;
    cursor: pointer;
  }

  @media (max-width: 500px) {
    margin-left: 5px;
    margin-top: 5px;
    justify-content: flex-start !important;
  }
`;

export const Buttons = styled.div`
  margin-top: 20px;

  margin-bottom: 20px;
  display: flex;
  justify-content: center;

  button.salvar {
    color: #fff;
    margin-right: 20px;
    background: #0073fc;
    border: none;
    width: 110px;
    height: 38px;
    border-radius: 4px;
  }

  button.cancelar {
    color: #373a3c;
    margin-right: 20px;
    background: #f5f8fb;
    border: none;
    width: 110px;
    height: 38px;
    border-radius: 4px;
  }
`;

export const ChatButton = styled.button`
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 50px;
  height: 50px;

  background: #10104f;
  border: none;
  color: #fff;
  border-radius: 25px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.chatOpened && css`
    border-radius: 0 0 25px 25px;
  `}
`;

export const ChatContainer = styled.div`
  position: fixed;
  right: 30px;
  bottom: 80px;

  background: #10104f;
  padding: 10px;
  border-radius: 8px 8px 0 8px;

  h3 {
    color: #FFF;
    margin: 6px 0;
  }
`;

export const ChatContent = styled.div`
  background: #FFFFFF;
  border-radius: 8px;
  padding: 10px;
  width: 300px;

  button {
    padding: 8px 22px;
    background: #0073fc;
    color: #ffffff;
    border: none;
    border-radius: 4px;

    transition: 0.2s;
    outline-style: none;

    &:hover {
      background: ${shade(0.2, "#0073FC")};
    }
  }
`;

export const ChatInputArea = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  flex: 1;

  label {
    font-size: 16px;
    color: #373A3C;
  }

  input[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;
  }
  input[type=number] { 
    -moz-appearance: textfield;
    appearance: textfield;
  }

  /* input {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    box-sizing: border-box;
    
    height: 34px;
    width: 100%;
    padding: 0 10px;

    &.errored {
      border: 1px solid #c53030;
    }

    ${props => props.isErrored && css`
      border: 1px solid #c53030;
    `}
  } */
`;

export const ChatInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  box-sizing: border-box;

  margin-bottom: 18px;
  padding: 1px 11px 1px 1px;

  ${props => props.isErrored && css`
    border: 1px solid #c53030;
  `}

  ${props => props.isFocused && css`
    border: 1px solid #0073FC;
  `}

  input {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset;
    }
  }

  textarea {
    border: none;

    /* height: 34px; */
    width: 100%;
    padding: 0 10px;
    margin-top: 5px;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset;
    }
  }

  select {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset;
    }
  }
`;
