import React from 'react';

import logoImg from "../../assets/images/logo.png";
import backgroundImg from "../../assets/images/background-credenciamento_interna.png";

import FormSelfCredenciamento from '../../components/FormSelfCredenciamento';

import { Container, Content, FormSection, Background } from './styles';

function SelfCredenciamentoCriar() {
  const anoAtual = new Date().getFullYear();

  return (
    <Container>
      <Content>
        <img src={logoImg} alt="Deixa no Azul" />

        <FormSection>
          <FormSelfCredenciamento />
        </FormSection>

      </Content>
      
      
      <span>© {anoAtual}. Todos os direitos reservados</span>
      <Background src={backgroundImg} alt="" />
    </Container>
  );
};

export default SelfCredenciamentoCriar;
