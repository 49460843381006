import React, { createContext, useState, useContext } from 'react';

const SimulatorContaDeLuzContext = createContext();

function SimulatorContaDeLuzProvider({ children }) {
  const [simuladorContaDeLuz, setSimuladorContaDeLuz] = useState({
    parcelas: '',
    valor_pagamento: '',
    valor_adicional: '',
    valor_total: '',
    taxa: '',
    valor_parcela: '',
  });
  const [userSimuladorContaDeLuz, setUserSimuladorContaDeLuz] = useState({
    nome: '',
    cpf: '',
    data_nascimento: '',
    email: '',
    telefone: '',
  });
  const [
    ciaEnergiaSimuladorContaDeLuz,
    setCiaEnergiaSimuladorContaDeLuz,
  ] = useState({
    cia_energia: '',
    numero_instalacao: '',
    data_leitura: '',
  });

  return (
    <SimulatorContaDeLuzContext.Provider
      value={{
        simuladorContaDeLuz,
        setSimuladorContaDeLuz,
        userSimuladorContaDeLuz,
        setUserSimuladorContaDeLuz,
        ciaEnergiaSimuladorContaDeLuz,
        setCiaEnergiaSimuladorContaDeLuz,
      }}
    >
      {children}
    </SimulatorContaDeLuzContext.Provider>
  );
};

function useSimulatorContaDeLuz() {
  const context = useContext(SimulatorContaDeLuzContext);
  const {
    simuladorContaDeLuz,
    setSimuladorContaDeLuz,
    userSimuladorContaDeLuz,
    setUserSimuladorContaDeLuz,
    ciaEnergiaSimuladorContaDeLuz,
    setCiaEnergiaSimuladorContaDeLuz,
  } = context;
  return {
    simuladorContaDeLuz,
    setSimuladorContaDeLuz,
    userSimuladorContaDeLuz,
    setUserSimuladorContaDeLuz,
    ciaEnergiaSimuladorContaDeLuz,
    setCiaEnergiaSimuladorContaDeLuz,
  };
};

export { SimulatorContaDeLuzProvider, useSimulatorContaDeLuz };
