import styled from 'styled-components';

import { shade } from 'polished';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BackgroundImg = styled.div`
  flex: 1;
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;

  > img {
    width: 84%;
    margin-left: -20%;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
  /* max-width: 600px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span {
    position: absolute;
    bottom: 40px;
  }

  > img {
    width: 180px;
    height: auto;
    margin-top: -30px;
    margin-bottom: 30px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #fff;
  border-radius: 8px;
  padding: 75px 50px 50px;

  max-width: 500px;

  span {
    font-family: 'DM Sans', sans-serif;
    font-size: 26px;
    line-height: 1.1em;
    text-align: center;
    margin-bottom: 30px;

    &.bold {
      font-weight: 700;
      margin-bottom: 50px;
    }

    blue {
      color: #08BBE9;
      font-weight: 700;
    }
  }

  a {
    width: 100%;
    height: 50px;
    background: linear-gradient(#08BBE9, #008AEB);
    color: #FFFFFF;
    font-weight: bold;
    border: none;
    border-radius: 4px;

    transition: 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      /* background: ${shade(0.2, '#0073FC')}; */
      filter: brightness(80%);
    }
  }

  @media (max-width: 500px) {
    padding: 60px 20px;

    > img {
      width: 170px;
      height: auto;
      margin-bottom: 30px;
    }
  }
`;
