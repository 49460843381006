import styled, { css } from 'styled-components';
import { shade } from 'polished';
import ToolTip from '../../components/Tooltip';

export const Container = styled.div`
  position: fixed;
  z-index: 5;

  width: 100vw;
  height: 100vh;
  background: rgba(55, 58, 60, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  color: #fff;
  background: #10104F;
  padding: 30px;
  border-radius: 8px;
  max-width: 640px;
  /* max-height: 420px; */

  width: 100%;
  /* height: 100%; */

  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    margin-left: calc(100% - 30px);
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      color: ${shade(0.2, '#fff')};
    }
  }

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 30px;

    @media (max-width: 500px) {
      font-size: 18px;
      display: block;
      margin-bottom: 20px;
    }
  }

  div.buttonRow {
    margin: 30px auto 0;
    text-align: center;

    button {
      padding: 8px 27px;
      background: #0073FC;
      border: 1px solid #0073FC;
      color: #FFFFFF;
      border: none;
      border-radius: 4px;

      transition: 0.2s;

      &:hover {
        background: ${shade(0.2, '#0073FC')};
      }
    }

    button.cancelar {
      padding: 8px 27px;
      background: #F5F8FB;
      border: 1px solid #ADADAD;
      color: #373A3C;
      border-radius: 4px;

      margin-left: 30px;
      transition: 0.2s;

      &:hover {
        background: ${shade(0.2, '#F5F8FB')};
      }
    }

    @media (max-width: 500px) {
      margin: 20px auto 0;
    }
  }

  @media (max-width: 500px) {
    width: 94%;
    padding: 20px;
    margin-bottom: 20px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props => props.aprovadoHide && css`
    display: none;
  `}

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 14px;
    text-align: center;

    /* &:last-child {
      margin-top: 14px;
    } */

    @media (max-width: 500px) {
      font-size: 18px;
      text-align: center;
    }
  }
`;

export const Subtitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props => props.aprovadoHide && css`
    display: none;
  `}

  > span {
    font-size: 18px;
    display: block;
    /* margin-bottom: 14px; */
    margin-top: -10px;
    text-align: center;

    &:last-child {
      margin-top: 14px;
    }

    @media (max-width: 500px) {
      font-size: 18px;
      text-align: center;
    }
  }
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  flex: 1;

  width: 100%;

  label {
    font-size: 16px;
    font-weight: 500;
    color: #10104F;
    margin-bottom: 8px;
  }

  input[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;
  }
  input[type=number] { 
    -moz-appearance: textfield;
    appearance: textfield;
  }

  ${props => props.isFocused && css`
    label {
      color: #08BBE9;
    }
  `}

  &.campoAnexo {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  div.upload {
    overflow-x: hidden;
    overflow-y: scroll;
    /* height: 210px; */
    /* height: 200px; */
    height: 80px;
    width: 100%;

    ${props => props.files > 1 && css`
      height: 130px;
    `}
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #FFFFFF;
  border: 1px solid #10104F;
  border-radius: 6px;
  box-sizing: border-box;

  margin-bottom: 18px;
  padding: 1px 11px 1px 1px;

  ${props => props.isErrored && css`
    border: 1px solid #F04148;
  `}

  ${props => props.isFocused && css`
    border: 1px solid #08BBE9;
  `}

  input {
    border: none;

    height: 42px;
    width: 100%;
    padding: 0 10px;
    color: #08BBE9;
    font-weight: 500;

    background: transparent;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset;
    }
  }

  textarea {
    border: none;

    /* height: 42px; */
    width: 100%;
    padding: 6px 10px;
    color: #08BBE9;
    font-weight: 500;

    background: transparent;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset;
    }
  }

  select {
    border: none;

    height: 40px;
    width: 100%;
    padding: 0 10px;

    background: transparent;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset;
    }
  }
`;

export const Error = styled(ToolTip)`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    background: #F04148;

    &::before {
      border-color: #F04148 transparent;
    }
  }
`;

export const BoxInfo = styled.div`
  background: transparent;
  border: 1px solid #f1f5f9;
  padding: 8px 16px;
  border-radius: 6px;
  /* margin-bottom: 16px; */
  margin: 0 auto 16px auto;
  max-width: 300px;
  opacity: 0.6;

  span {
    font-size: 14px;
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 11px;

  border: 1px solid #fff;
  border-radius: 4px;

  width: 200px;

  &.edição {
    border: 1px solid #cccccc;
    background: transparent;
  }

  ${(props) =>
    props.pagamentoHide &&
    css`
      display: none;
    `}
`;
