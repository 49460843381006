import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';
import TopBar from '../../components/TopBar';

import AdminTransacoesNovas from '../AdminTransacoesNovas';
import AdminContaDeLuzNovas from '../AdminContaDeLuzNovas';
import AdminCertificadoDigitalNovas from '../AdminCertificadoDigitalNovas';
import AdminFGTSNovas from '../AdminFGTSNovas';
import AdminConsultaSerasa from '../AdminConsultaSerasa';

import AdminColaboradores from '../AdminColaboradores';

import TaxaComissao from '../TaxaComissao';

import Arquivos from '../Arquivos';

import { Container, Page, Content } from './styles';

function AdminLayout() {
  const location = useLocation();
  // const currentLocation = location.pathname;

  // console.log('location', location);
  // console.log('currentLocation', currentLocation);

  /**
   * Pagamento de contas
   * Conta de Luz
   * Certificado Digital
   * Saque FGTS
   */
  const [paginaAtual, setPaginaAtual] = useState('');

  const [menuActive, setMenuActive] = useState('');
  const [subMenuActive, setSubMenuActive] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [pageSuttitle, setPageSubtitle] = useState('');

  useEffect(() => {
    if (location.pathname === '/admin/transacoes') {
      setPaginaAtual('Pagamento de contas');
      setMenuActive('AdminTransações');
      setSubMenuActive('Deixa no Azul');
      setPageTitle('Transações -');
      setPageSubtitle('Deixa no Azul');
    }
    if (location.pathname === '/admin/conta-luz') {
      setPaginaAtual('Conta de Luz');
      setMenuActive('AdminTransações');
      setSubMenuActive('Conta de Luz');
      setPageTitle('Transações -');
      setPageSubtitle('Conta de Luz');
    }
    if (location.pathname === '/admin/certificado-digital') {
      setPaginaAtual('Certificado Digital');
      setMenuActive('AdminTransações');
      setSubMenuActive('Certificado Digital');
      setPageTitle('Transações -');
      setPageSubtitle('Certificado Digital');
    }
    if (location.pathname === '/admin/saque-fgts') {
      setPaginaAtual('Saque FGTS');
      setMenuActive('AdminTransações');
      setSubMenuActive('Saque FGTS');
      setPageTitle('Transações -');
      setPageSubtitle('Saque FGTS');
    }
    if (location.pathname === '/admin/consulta-serasa') {
      setPaginaAtual('Consulta Serasa');
      setMenuActive('SerasaTransações');
      setSubMenuActive('Consulta Serasa');
      setPageTitle('Transações -');
      setPageSubtitle('Consulta Serasa');
    }
    if (location.pathname === '/admin/colaboradores') {
      setPaginaAtual('Colaboradores');
      setMenuActive('Colaboradores');
      setSubMenuActive('');
      setPageTitle('Colaboradores');
      setPageSubtitle('');
    }
    if (location.pathname === '/admin/taxa-comissao/deixa-no-azul') {
      setPaginaAtual('Taxa de Comissão');
      setMenuActive('TaxaComissão');
      setSubMenuActive('Taxa de Comissão - Deixa no Azul');
      setPageTitle('Taxa de Comissão -');
      setPageSubtitle('Deixa no Azul');
    }
    if (location.pathname === '/admin/taxa-comissao/conta-de-luz') {
      setPaginaAtual('Taxa de Comissão');
      setMenuActive('TaxaComissão');
      setSubMenuActive('Taxa de Comissão - Contas de Luz');
      setPageTitle('Taxa de Comissão -');
      setPageSubtitle('Contas de Luz');
    }
    if (location.pathname === '/admin/taxa-comissao/certificado-digital') {
      setPaginaAtual('Taxa de Comissão');
      setMenuActive('TaxaComissão');
      setSubMenuActive('Taxa de Comissão - Certificado Digital');
      setPageTitle('Taxa de Comissão -');
      setPageSubtitle('Certificado Digital');
    }
    if (location.pathname === '/admin/taxa-comissao/saque-fgts') {
      setPaginaAtual('Taxa de Comissão');
      setMenuActive('TaxaComissão');
      setSubMenuActive('Taxa de Comissão - Saque FGTS');
      setPageTitle('Taxa de Comissão -');
      setPageSubtitle('Saque FGTS');
    }
    if (location.pathname === '/arquivos') {
      setPaginaAtual('Arquivos');
      setMenuActive('');
      setSubMenuActive('');
      setPageTitle('Arquivos');
      setPageSubtitle('');
    }
  }, [location.pathname]);

  return (
    <Container>
      <MenuBar active={menuActive} subMenuActive={subMenuActive} />
      <NewMobileMenu active={menuActive} subMenuActive={subMenuActive} />

      <Page>
        <TopBar pageTitle={pageTitle} pageSubtitle={pageSuttitle} />

        <Content>
          {paginaAtual === 'Pagamento de contas' && <AdminTransacoesNovas />}
          {paginaAtual === 'Conta de Luz' && <AdminContaDeLuzNovas />}
          {paginaAtual === 'Certificado Digital' && <AdminCertificadoDigitalNovas />}
          {paginaAtual === 'Saque FGTS' && <AdminFGTSNovas />}
          {paginaAtual === 'Consulta Serasa' && <AdminConsultaSerasa />}
          {paginaAtual === 'Colaboradores' && <AdminColaboradores />}
          {paginaAtual === 'Taxa de Comissão' && <TaxaComissao pageURL={location.pathname} />}
          {paginaAtual === 'Arquivos' && <Arquivos />}
        </Content>
      </Page>
    </Container>
  );
};

export default AdminLayout;
