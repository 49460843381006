import styled, { css } from 'styled-components';
import { shade } from 'polished';

import ToolTip from '../../components/Tooltip';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BackgroundImg = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > img {
    /* max-width: 726px; */
    width: 85%;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
  /* max-width: 600px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span {
    position: absolute;
    bottom: 50px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #fff;
  border-radius: 8px;
  padding: 50px 50px 40px;

  max-width: 500px;

  > img {
    width: 188px;
    height: auto;
    margin-bottom: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      width: 100%;
      height: 50px;
      background: linear-gradient(#08BBE9, #41B6EF, #008AEB);
      color: #FFFFFF;
      border: none;
      border-radius: 8px;
      font-weight: 700;

      transition: 0.2s;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        filter: brightness(80%);
      }
    }
  }

  @media (max-width: 500px) {
    padding: 60px 20px;

    > img {
      width: 170px;
      height: auto;
      margin-bottom: 30px;
    }
  }
`;

export const RememberAndForgot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  margin-bottom: 32px;

  a {
    color: #08BBE9;
    text-decoration: none;
    transition: 200ms;

    &:hover {
      color: ${shade(0.2, '#08BBE9')};
    }
  }
`;

export const Remember = styled.div`
  label {
    margin-left: 8px;
  }
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  flex: 1;

  width: max(340px, 100px);

  label {
    font-size: 16px;
    font-weight: bold;
    color: #A0A0A0;
    margin-bottom: 8px;
  }

  input[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;
  }
  input[type=number] { 
    -moz-appearance: textfield;
    appearance: textfield;
  }

  /* input {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    box-sizing: border-box;
    
    height: 34px;
    width: 100%;
    padding: 0 10px;

    &.errored {
      border: 1px solid #c53030;
    }

    ${props => props.isErrored && css`
      border: 1px solid #c53030;
    `}
  } */
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
  box-sizing: border-box;

  margin-bottom: 18px;
  padding: 1px 11px 1px 1px;

  ${props => props.isErrored && css`
    border: 1px solid #c53030;
  `}

  ${props => props.isFocused && css`
    border: 1px solid #0073FC;
  `}

  input {
    border: none;

    height: 40px;
    width: 100%;
    padding: 0 10px;

    background: transparent;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset;
    }
  }

  select {
    border: none;

    height: 40px;
    width: 100%;
    padding: 0 10px;

    background: transparent;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset;
    }
  }
`;

export const Error = styled(ToolTip)`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    background: #c53030;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
