import styled, { css } from 'styled-components';

import { shade } from 'polished';

import ToolTip from '../../components/Tooltip';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BackgroundImg = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > img {
    max-width: 726px;
    width: 100%;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
  /* max-width: 600px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span {
    position: absolute;
    bottom: 50px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #fff;
  border-radius: 8px;
  padding: 90px 70px;

  max-width: 500px;

  > img {
    width: 188px;
    height: auto;
    margin-bottom: 30px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      width: 100%;
      height: 50px;
      background: #0073FC;
      color: #FFFFFF;
      border: none;
      border-radius: 4px;

      margin-top: 20px;

      transition: 0.2s;

      &:hover {
        background: ${shade(0.2, '#0073FC')}
      }
    }
  }

  a {
    margin-top: 20px;
    text-decoration: none;
  }

  @media (max-width: 500px) {
    padding: 60px 20px;

    > img {
      width: 170px;
      height: auto;
      margin-bottom: 30px;
    }
  }
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 20px;

  @media (max-width: 500px) {
    &.campoAnexo {
      margin-bottom: 15px;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  box-sizing: border-box;

  margin-bottom: 20px;
  padding: 1px 11px 1px 1px;

  width: max(340px, 100px);

  &.barCode {
    margin-bottom: 0;
  }

  ${props => props.isErrored && css`
    border: 1px solid #c53030;
  `}

  ${props => props.isFocused && css`
    border: 1px solid #0073FC;
  `}

  input {
    border: none;

    height: 34px;
    width: 90%;
    padding: 0 10px;
  }

  select {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;
  }

  img {
    margin-left: 10px;
  }

  @media (max-width: 500px) {
    width: max(300px, 100px);
  }
`;

export const Error = styled(ToolTip)`
  span {
    background: #c53030;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
