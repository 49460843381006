import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div``;

export const TableContent = styled.div`
  position: relative;

  .MuiTypography-root {
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
  }
  
  th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-paddingCheckbox {
    background: #10104F;
  }

  span.MuiButtonBase-root.MuiTableSortLabel-root.Mui-disabled {
    color: transparent;
  }

  .MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.Component-paginationToolbar-2.MuiToolbar-gutters {
    background-color: #fff;
  }

  tr.MuiTableRow-root {
    border: none;
  }

  tr.MuiTableRow-root:nth-of-type(odd) {
    background-color: #F5F5F5;
  }

  .MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-5.MuiToolbar-gutters {
    padding-bottom: 20px;
    height: 100px;
  }

  .MuiToolbar-regular {
    min-height: 84px;
  }
  
  .css-2b097c-container {
    /* position: absolute; */
    /* box-sizing: border-box; */
    width: 140px;
    /* top: 23px; */
    /* right: 200px; */
    /* z-index: 50; */
  }
  
  .css-2b097c-container.filterStatusSelect2 {
    /* position: absolute; */
    /* box-sizing: border-box; */
    width: 160px;
    margin-left: 20px;
    /* top: 23px; */
    /* right: 25px; */
    /* z-index: 50; */
  }

  .MuiInput-underline:before {
    border-bottom: none;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const TableContentMobile = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 20px 3px;
  margin-bottom: 100px;

  @media (min-width: 500px) {
    display: none;
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid #EEEEEE;
  padding: 0 17px 10px;

  h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .filterStatusSelect.css-2b097c-container {
    width: 200px;
    margin-bottom: 20px;
  }

  > span {
    margin-bottom: 20px;
  }
`;

export const Line = styled.div`
  padding: 15px 17px;
  border-bottom: 1px solid #EEEEEE;

  > span {
    font-size: 18px;
    color: #313131;
  }

  > p {
    font-size: 14px;
    color: #9FA7BA;
    margin: 0;
  }

  div.footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    > span {
      font-size: 12px;
      font-weight: 700;
      /* color: #5CB85C; */
    }
  }
`;

export const MobileBody = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  height: 448px;
`;

export const FilterArea = styled.div`
  position: relative;
  box-sizing: border-box;
  /* width: 610px; */
  width: 65%;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  /* top: 25px; */
  /* right: 25px; */
  /* z-index: 50; */

  /* display: flex; */
  align-items: center;

  display: grid;
  grid-template-columns: 0.7fr 1fr 1.2fr 1fr;
  gap: 10px;

  background: #ffffff;

  > .filterStatusSelect {
    /* width: 160px; */
    width: 100%;
    margin-right: 10px;
    position: relative;
    z-index: 20;
  }

  > .filterStatusSelect2 {
    margin-right: 0px;
  }
`;

export const FilterHeader = styled.span`
  /* position: absolute; */
  /* box-sizing: border-box; */
  /* width: 140px; */
  /* top: 26px; */
  /* right: 360px; */
  /* z-index: 50; */
  /* margin-right: 20px; */

  font-size: 20px;
  font-weight: 600;
  color: #373A3C;
`;

export const FilterDate = styled.div`
  position: relative;
  z-index: 55;
  /* top: 23px; */
  /* right: 455px; */
  /* right: 20px; */

  /* width: 150px; */
  width: 100%;
  
  display: flex;
  align-items: center;

  height: 38px;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 4px;
  /* padding: 0px 10px; */

  cursor: pointer;

  span {
    margin-right: 5px;
    color: #808080;
  }

  .apagarFiltro {
    position: absolute;
    z-index: 60;
    right: -10px;
    top: -10px;
    
    background: #FFFFFF;
    padding: 2px;
    border-radius: 50%;

    cursor: pointer;
  }
`;

export const DatePicker = styled.div`
  position: absolute;
  z-index: 55;
  top: 60px;
  /* right: 340px; */
  right: -95px;

  display: none;
  
  background: #ffffff;
  border-radius: 4px;
  padding: 15px 20px 15px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  
  margin-left: auto;
  margin-bottom: 20px;

  ${props => props.filtroAberto && css`
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* align-items: flex-end; */
  `}
`;

export const ButtonBox = styled.div`
  /* position: relative; */

  button {
    padding: 8px 20px;
    /* width: 110px; */
    background: #0073FC;
    border: 1px solid #0073FC;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;

    transition: 0.2s;

    &:hover {
      background: ${shade(0.2, '#0073FC')}
    }

    &.back {
      margin-right: 20px;

      width: 110px;
      color: #373A3C;
      background: #F5F8FB;
      border: 1px solid #ADADAD;

      &:hover {
        background: ${shade(0.1, '#F5F8FB')}
      }
    }
  }

  svg {
    position: absolute;
    right: -10px;
    top: -10px;
    
    background: #FFFFFF;
    padding: 2px;
    border-radius: 50%;

    cursor: pointer;
  }
`;
