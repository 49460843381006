import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Tooltip } from '@material-ui/core/';
import { MdFiberManualRecord } from 'react-icons/md';
import { FaEye } from 'react-icons/fa';

import api from '../../services/api';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';

import editIcon from '../../assets/images/edit_icon.svg';
import ciasEnergia from '../../assets/json/cias_energia.json';

import {
  Container,
  Page,
  Content,
  TableContent,
  TableContentMobile,
  FilterArea,
  FilterHeader,
  Header,
  Line,
  MobileBody,
} from './styles';

function TransacoesContaDeLuz() {
  const history = useHistory();

  const [transacoes, setTransacoes] = useState([]);
  const [transacoesVisiveis, setTransacoesVisiveis] = useState([]);
  const [clientes, setClientes] = useState([]);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');
  const estabelecimentoLogado = JSON.parse(localStorage.getItem('@DeixaNoAzul:estabelecimentoId'));

  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    async function getData() {
      await api.get(`/energy/transaction/client?png=999999`, auth).then(response => {
        const result = response.data.data;
        // console.log('client', result)
  
        setClientes(result);
      });

      await api.get('/energy/transaction?png=999999', auth).then(response => {
        const result = response.data.data;
        const resultFiltered = result.filter(item => item.estabelecimento_id === estabelecimentoLogado.id);
        // console.log('transaction', resultFiltered)
  
        setTransacoes(resultFiltered);
        setTransacoesVisiveis(resultFiltered);
      });
    }

    getData();
  }, [estabelecimentoLogado.id, userToken]);

  // Informações Head da tabela
  const headData = [
    { title: 'Data da solicitação', field: 'data_soliticacao', sorting: false },
    { title: 'Cia. Elétrica', field: 'cia_energia', sorting: false },
    { title: 'Cliente', field: 'cliente', sorting: false },
    { title: 'Valor', field: 'valor', sorting: false },
    { title: 'Status', field: 'status', align: 'right', sorting: false },
    { title: '', field: 'edit', align: 'center', sorting: false },
  ];

  // Informações Body da tabela
  const bodyData = transacoesVisiveis === [] || transacoesVisiveis === "Nenhuma transação encontrada" ? []
  : transacoesVisiveis.map(transacao => {

    let colorStatus = '';
    if(transacao.status === 'EM_ANALISE') {colorStatus = "#FEAC02"} // Em análise
    // if(transacao.status === 2) {colorStatus = "#5097FF"} // Solicitar documentos
    if(transacao.status === 'APROVADO') {colorStatus = "#5CB85C"} // Aprovada
    if(transacao.status === 'CANCELADO') {colorStatus = "#F35757"} // Cancelada
    if(transacao.status === 'REJEITADO') {colorStatus = "#F35757"} // Cancelada
    // if(transacao.status === 5) {colorStatus = "#8850FF"} // Checagem financeira

    let messageStatus = '';
    if(transacao.status === 'EM_ANALISE') {messageStatus = "Em análise"} // Em análise
    // if(transacao.status === 2) {messageStatus = "Solicitar documentos"} // Solicitar documentos
    if(transacao.status === 'APROVADO') {messageStatus = "Aprovada"} // Aprovada
    if(transacao.status === 'CANCELADO') {messageStatus = "Cancelada"} // Cancelada
    if(transacao.status === 'REJEITADO') {messageStatus = "Rejeitada"} // Cancelada
    // if(transacao.status === 5) {messageStatus = "Checagem financeira"} // Checagem financeira

    // console.log(transacao);

    const clienteData = clientes.filter(item => item.id === transacao.cliente_id);

    // console.log('clienteData', clienteData);

    const status = <Tooltip title={messageStatus} placement="top" arrow><span><MdFiberManualRecord color={colorStatus} /></span></Tooltip>

    // Valor Pagamento
    const total_tansacao = transacao.valor_pagamento.toString().split('.');
    const totalTransação = `${total_tansacao[0]},${total_tansacao[1] ? total_tansacao[1].substring(0, 2) : "00"}`

    const valor = <span style={{ color: "#0073FC", fontWeight: 600 }}>R$ {totalTransação}</span>;

    const imgEdit = /* transacao.status === 'APROVADO' ?  */<FaEye color="#333333" size={21} />/*  : <img src={editIcon} alt=""/> */;
    const iconTooltip = /* transacao.status === 'APROVADO' ?  */"Ver Dados da Transação"/*  : "Editar Dados da Transação" */;

    const dataTransacao = transacao.created_at.substring(0, 10).split('-').reverse().join('/');
    const horaTransacao = transacao.created_at.substring(11, 16);

    const dataEHora = <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{dataTransacao}</span>
      <span style={{ color: '#A6AAB4', fontSize: 14 }}>{horaTransacao}</span>
    </div>;

    return {
      data_soliticacao: dataEHora,
      cia_energia: transacao.companhia,
      cliente: clienteData !== undefined && clienteData[0].name,
      valor: valor,
      status: status,
      edit: <Tooltip title={iconTooltip} placement="top" arrow>
              <span style={{ cursor: "pointer" }} onClick={() => editTransacao(transacao)}>
                { imgEdit }
              </span>
            </Tooltip>
    }
  });

  const editTransacao = useCallback((transacao) => {
    const idReverse = transacao.id.split('').reverse().join('');
    // console.log(idReverse);

    // redireciona para a página de edição
    history.push(`/conta-de-luz/editar/${idReverse}`);
  }, [history]);

  const [statusSelecionado, setStatusSelecionado] = useState('TODOS');
  const [ciaSelecionada, setCiaSelecionada] = useState('TODOS');

  const filterByStatus = useCallback((value) => {
    const filterValue = value.value;
    // console.log(ciaSelecionada);

    if (filterValue !== 'TODOS' && ciaSelecionada === 'TODOS') {
      const transacoesFiltradas = transacoes.filter(item => item.status === filterValue);

      setTransacoesVisiveis(transacoesFiltradas);
    }
    if (filterValue !== 'TODOS' && ciaSelecionada !== 'TODOS') {
      const transacoesFiltradas = transacoes.filter(item => item.status === filterValue && item.companhia === ciaSelecionada);

      setTransacoesVisiveis(transacoesFiltradas);
    }
    if (filterValue === 'TODOS' && ciaSelecionada === 'TODOS') {
      setTransacoesVisiveis(transacoes);
    }
    if (filterValue === 'TODOS' && ciaSelecionada !== 'TODOS') {
      const transacoesFiltradas = transacoes.filter(item => item.companhia === ciaSelecionada);

      setTransacoesVisiveis(transacoesFiltradas);
    }
  }, [ciaSelecionada, transacoes]);

  const filterByCia = useCallback((value) => {
    const filterValue = value.value;
    // console.log(statusSelecionado);

    if (filterValue !== 'TODOS' && statusSelecionado === 'TODOS') {
      const transacoesFiltradas = transacoes.filter(item => item.companhia === filterValue);

      setTransacoesVisiveis(transacoesFiltradas);
    } 
    if (filterValue !== 'TODOS' && statusSelecionado !== 'TODOS') {
      const transacoesFiltradas = transacoes.filter(item => item.companhia === filterValue && item.status === statusSelecionado);

      setTransacoesVisiveis(transacoesFiltradas);
    }
    if (filterValue === 'TODOS' && statusSelecionado === 'TODOS') {
      setTransacoesVisiveis(transacoes);
    }
    if (filterValue === 'TODOS' && statusSelecionado !== 'TODOS') {
      const transacoesFiltradas = transacoes.filter(item => item.status === statusSelecionado);

      setTransacoesVisiveis(transacoesFiltradas);
    }
  }, [statusSelecionado, transacoes]);

  const clienteTransacao = useCallback((transacao) => {

    const clienteData = clientes.filter(item => item.id === transacao.cliente_id);

    return clienteData !== undefined && clienteData[0].name
  }, [clientes]);

  const valorPagoTransacao = useCallback((transacao) => {
    // console.log(transacao);

    const valor_total = transacao.valor_pagamento.toString().split('.');
    const valorTotal = `${valor_total[0]},${valor_total[1] ? valor_total[1].substring(0, 2) : "00"}`;

    return <strong>R$ {valorTotal}</strong>
  }, []);

  const transacaoStatus = useCallback((transacao) => {
    let colorStatus = '';
    if(transacao.status === 'EM_ANALISE') {colorStatus = "#FEAC02"} // Em análise
    // if(transacao.status === 2) {colorStatus = "#5097FF"} // Solicitar documentos
    if(transacao.status === 'APROVADO') {colorStatus = "#5CB85C"} // Aprovada
    if(transacao.status === 'CANCELADO') {colorStatus = "#F35757"} // Cancelado
    if(transacao.status === 'REJEITADO') {colorStatus = "#F35757"} // Checagem financeira

    let messageStatus = '';
    if(transacao.status === 'EM_ANALISE') {messageStatus = "Em análise"} // Em análise
    // if(transacao.status === 2) {messageStatus = "Solicitar documentos"} // Solicitar documentos
    if(transacao.status === 'APROVADO') {messageStatus = "Aprovada"} // Aprovada
    if(transacao.status === 'CANCELADO') {messageStatus = "Cancelada"} // Cancelado
    if(transacao.status === 'REJEITADO') {messageStatus = "Reprovadaa"} // Checagem financeira

    return <span style={{ color: colorStatus }}>{messageStatus}</span>
  }, []);

  const optionsCia = ciasEnergia.map(item => (
    { value: item.label, label: item.label }
  ));

  useEffect(() => {
    optionsCia.push({ value: 'TODOS', label: 'TODOS' });
  }, [optionsCia]);

  return (
    <Container>
      <MenuBar active="Transações" subMenuActive="Conta de Luz" />
      <NewMobileMenu active="Transações" subMenuActive="Conta de Luz" />

      <Page>
        <TopBar pageTitle="Transações -" pageSubtitle="Conta de Luz" />

        <Content>

          <TableContent>

            <FilterArea>
              <FilterHeader>Filtrar por:</FilterHeader>

              <Select
                placeholder="Status"
                onChange={(value) => {
                  setStatusSelecionado(value.value);
                  filterByStatus(value);
                }}
                className="filterStatusSelect"
                options={[
                  { value: 'EM_ANALISE', label: 'Em Análise' },
                  { value: 'APROVADO', label: 'Aprovada' },
                  { value: 'CANCELADO', label: 'Cancelada' },
                  { value: 'REJEITADO', label: 'Rejeitada' },
                  { value: 'TODOS', label: 'Todos' }
                ]}
              />

              <Select
                placeholder="Cia. de Energia"
                onChange={(value) => {
                  setCiaSelecionada(value.value);
                  filterByCia(value);
                }}
                className="filterStatusSelect filterStatusSelect2"
                options={optionsCia}
                // { value: '0', label: 'Todos' }
              />
            </FilterArea>

            <MaterialTable
              title="Lista de Transações"
              columns={headData}
              data={bodyData}
              components={{
                Toolbar: props => (
                  <div>
                    <MTableToolbar {...props} />
                    
                  </div>
                )
              }}
              localization={{
                pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{from}-{to} de {count}',
                  firstTooltip: 'Primeira página',
                  previousTooltip: 'Página anterior',
                  nextTooltip: 'Próxima página',
                  lastTooltip: 'Última página'
                },
                toolbar: {
                  searchPlaceholder: 'Buscar',
                  searchTooltip: 'Buscar'
                },
                body: {
                  emptyDataSourceMessage: 'Nenhum dado encontrado',
                }
              }}
              options={{
                search: false,
                actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: '#10104F',
                  color: '#FFF'
                },
                emptyValue: "Nenhum dado encontrado"
              }}
            />

            </TableContent>

          <TableContentMobile>
            <Header>
              <h3>Lista de Transações</h3>

              <Select
                placeholder="Status"
                onChange={(value) => {
                  setStatusSelecionado(value.value);
                  filterByStatus(value);
                }}
                className="filterStatusSelect"
                options={[
                  { value: 'EM_ANALISE', label: 'Em Análise' },
                  { value: 'APROVADO', label: 'Aprovada' },
                  { value: 'CANCELADO', label: 'Cancelada' },
                  { value: 'REJEITADO', label: 'Rejeitada' },
                  { value: 'TODOS', label: 'Todos' }
                ]}
              />

              <span>Total: {transacoesVisiveis.length}</span>
            </Header>

            <MobileBody>
              {transacoesVisiveis.map((transacao) => (
                <Line key={transacao.id}>
                  <span>{clienteTransacao(transacao)}</span>
                  <p>Valor pago: {valorPagoTransacao(transacao)}</p>
                  <p>Cia Energia: {transacao.companhia}</p>
          
                  <div className="footer">
                    <span>{transacaoStatus(transacao)}</span>
                    <img src={editIcon} style={{ display: transacao.status === 3 ? 'none' : 'block' }} alt="Editar transação" onClick={() => editTransacao(transacao)}/>
                  </div>
                </Line>
              ))}
            </MobileBody>

          </TableContentMobile>
        </Content>
      </Page>
    </Container>
  );
};

export default TransacoesContaDeLuz;
