import styled, { css } from 'styled-components';

import { shade } from "polished";

export const Container = styled.div`
  /* position: fixed; */
  /* bottom: 0; */
  /* right: 0; */
  /* z-index: 100; */

  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */

  /* width: 100px; */
  /* height: 100vh; */
  /* padding: 0 30px; */

  @media (min-width: 500px) {
    display: none;
  }
`;

export const Button = styled.button`
  position: fixed;
  z-index: 102;
  bottom: 30px;
  right: 25px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 50px;
  background: #FFFFFF;
  border-radius: 50%;
  border: none;
  outline-style: none;

  transition: 400ms ease;
`;

export const Logo = styled.div`
  margin: 20px 0 24px;
`;

export const Nav = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: fixed;
  z-index: 101;
  right: 0;

  width: 100px;
  height: 100vh;
  background: #10104f;
  padding: 0 10px;

  transform: translateX(100px);
  transition: 400ms ease;

  ${props => props.open && css`
    transform: translateX(0);
  `}

  a {
    text-decoration: none;
    margin-bottom: 14px;

    &.disabled {
      display: none;
    }
  }
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 4px;

  background: #10104f;
  padding: 6px 0;
  width: 80px;

  transition: 0.2s;

  &:hover {
    background: ${shade(0.2, "#10104F")};
  }

  &.active {
    background: #0073fc;
  }

  &.off {
    opacity: 0.3;
    cursor: not-allowed;
  }

  span {
    color: #ffffff;
    margin-top: 6px;
    font-size: 12px;
    text-align: center;
  }
`;

export const LogOut = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: none;
  outline-style: none;

  span {
    color: #ffffff;
    margin-top: 6px;
    font-size: 12px;
  }
`;

export const SubMenuItem = styled.div`
  display: flex;
  align-items: center;

  padding: 7px 0 7px 0px;

  border-radius: 4px;

  background: #10104F;

  transition: 0.2s;

  &:hover {
    background: ${shade(0.2, '#10104F')};
  }

  &.active {
    background: #0073FC;
  }

  img {
    width: 20px;
  }

  span {
    font-size: 10px;
    color: #fff;
    margin-left: 8px;
  }
`;

export const MenuSpace = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 68%;
  overflow-y: auto;

  @media (max-width: 800px) {
    height: 71%;
  }

  @media (max-width: 500px) {
    height: 68%;
  }
`;

export const SubMenuDropdown = styled.div`
  display: none;

  ${props => props.transacoesOpened && css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;
