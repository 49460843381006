import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';

import api from '../../services/api';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';

import FormEditEC from '../../components/FormEditEC';

import { Container, Page, Content, TabContent, Back, Button } from './styles';

function EditEC() {
  const location = useLocation();

  const idEstabelecimento= location.pathname.replace('/ec/editar/', '').split('').reverse().join('');

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  const [ecInfos, setEcInfos] = useState([]);
  const [ecInfosUsuario, setEcInfosUsuario] = useState([]);
  const [ecInfosEnderecoPessoa, setEcInfosEnderecoPessoa] = useState([]);
  const [ecInfosEnderecoEstabelecimento, setEcInfosEnderecoEstabelecimento] = useState([]);
  const [ecInfosDadosBancarios, setEcInfosDadosBancarios] = useState([]);
  const [ecInfosTelefoneEstabelecimento, setEcInfosTelefoneEstabelecimento] = useState([]);

  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    async function getInfos() {
      // Salvar dados do estabelecimento
      const responseEC = await api.get(`/estabelecimento/${idEstabelecimento}`, auth);
      const estabelecimento = responseEC.data.data;
      setEcInfos(estabelecimento);
      
      // salvar dados do usuário
      await api.get(`/user/${estabelecimento.user_id}`, auth).then(response => {
        const userEstabelecimento = response.data.data;
        setEcInfosUsuario(userEstabelecimento)
      });

      // Salvar dados do endereço pessoal
      await api.get(`/endereco/${estabelecimento.endereco_pessoa_id}`, auth).then(response => {
        const enderecoPessoa = response.data.data;
        setEcInfosEnderecoPessoa(enderecoPessoa)
      });

      // Salvar dados do endereço do estabelecimento
      await api.get(`/endereco/${estabelecimento.endereco_estabelecimento_id}`, auth).then(response => {
        const enderecoEstabelecimento = response.data.data;
        setEcInfosEnderecoEstabelecimento(enderecoEstabelecimento)
      });

      // Salvar dados bancários
      await api.get(`/conta/${estabelecimento.dados_bancarios_id}`, auth).then(response => {
        const dadosBancarios = response.data.data;
        setEcInfosDadosBancarios(dadosBancarios)
      })

      // Salvar dados do telefone
      await api.get('/telefone/estabelecimento?png=999999', auth).then(response => {
        const result = response.data.data;

        const dadosTelefones = result.filter(dado => dado.estabelecimento_id === estabelecimento.id);
        setEcInfosTelefoneEstabelecimento(dadosTelefones);
      });
    }

    getInfos()
  }, [idEstabelecimento, userToken]);

  return (
    <Container>
      <MenuBar />
      <NewMobileMenu />

      <Page>
        <TopBar pageTitle="Editar Estabelecimento Credenciado" />

        <Content>
          <Back>
            <Link to="/ec">
              <Button>
                <MdArrowBack size={24} />
              </Button>
            </Link>
            <span>Voltar para Estabelecimentos credenciados</span>
          </Back>

          <TabContent>
            <FormEditEC
              ecInfos={ecInfos}
              ecInfosUsuario={ecInfosUsuario}
              ecInfosEnderecoPessoa={ecInfosEnderecoPessoa}
              ecInfosEnderecoEstabelecimento={ecInfosEnderecoEstabelecimento}
              ecInfosDadosBancarios={ecInfosDadosBancarios}
              ecInfosTelefoneEstabelecimento={ecInfosTelefoneEstabelecimento}
            />
          </TabContent>
        </Content>
      </Page>
    </Container>
  );
}

export default EditEC;