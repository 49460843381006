import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Tooltip } from '@material-ui/core/';
import { MdFiberManualRecord } from 'react-icons/md';
// import { FaEye } from 'react-icons/fa';
import { FiCalendar, FiXCircle } from 'react-icons/fi';
import { DateRange } from 'react-date-range';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';
import moment from 'moment';

import api from '../../services/api';

// import TopBar from '../../components/TopBar';
// import MenuBar from '../../components/MenuBar';
// import NewMobileMenu from '../../components/NewMobileMenu';
import FormatarValor from '../../components/FormatarValor';

import editIcon from '../../assets/images/edit_icon.svg';
import ciasEnergia from '../../assets/json/cias_energia.json';

import {
  Container,
  // Page,
  // Content,
  TableContent,
  FilterArea,
  FilterHeader,
  TableContentMobile,
  Header,
  Line,
  MobileBody,
  FilterDate,
  DatePicker,
  ButtonBox,
} from './styles';

function ParceiroContaDeLuz() {
  const history = useHistory();

  const [transacoes, setTransacoes] = useState([]);
  const [transacoesVisiveis, setTransacoesVisiveis] = useState([]);
  const [clientes, setClientes] = useState([]);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  // requisição
  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    async function getData() {
      await api.get(`/energy/transaction/client?png=999999`, auth).then(response => {
        const result = response.data.data;
        // console.log('client', response);
  
        setClientes(result);
      });

      await api.get('/energy/transaction?png=999999', auth).then(response => {
        const result = response.data.data;
        // console.log('transaction', response);
  
        setTransacoes(result);
        setTransacoesVisiveis(result);
      });
    }

    getData();
  }, [userToken]);

  // Informações Head da tabela
  const headData = [
    { title: 'Data da solicitação', field: 'data_soliticacao', sorting: false },
    { title: 'Cia. Elétrica', field: 'cia_energia', sorting: false },
    { title: 'Cliente', field: 'cliente', sorting: false },
    { title: 'Valor', field: 'valor', sorting: false },
    { title: 'Estabelecimento', field: 'estabelecimento', sorting: false },
    { title: 'Status', field: 'status', align: 'right', sorting: false },
    { title: '', field: 'edit', align: 'center', sorting: false },
  ];

  // Informações Body da tabela
  const bodyData = transacoesVisiveis === [] || transacoesVisiveis === "Nenhuma transação encontrada" ? []
  : transacoesVisiveis.map(transacao => {

    let colorStatus = '';
    if(transacao.status === 'EM_ANALISE') {colorStatus = "#FEAC02"} // Em análise
    if(transacao.status === 'APROVADO') {colorStatus = "#5CB85C"} // Aprovada
    if(transacao.status === 'CANCELADO') {colorStatus = "#F35757"} // Cancelada
    if(transacao.status === 'REJEITADO') {colorStatus = "#F35757"} // Cancelada

    let messageStatus = '';
    if(transacao.status === 'EM_ANALISE') {messageStatus = "Em análise"} // Em análise
    if(transacao.status === 'APROVADO') {messageStatus = "Aprovada"} // Aprovada
    if(transacao.status === 'CANCELADO') {messageStatus = "Cancelada"} // Cancelada
    if(transacao.status === 'REJEITADO') {messageStatus = "Rejeitada"} // Cancelada

    const clienteData = clientes.filter(item => item.id === transacao.cliente_id);

    const status = <Tooltip title={messageStatus} placement="top" arrow><span><MdFiberManualRecord color={colorStatus} /></span></Tooltip>

    // const imgEdit = transacao.status === 'APROVADO' ? <FaEye color="#333333" size={21} /> : <img src={editIcon} alt=""/>;
    // const iconTooltip = transacao.status === 3 ? "Ver Dados da Transação" : "Editar Dados da Transação";

    const dataTransacao = transacao.created_at.substring(0, 10).split('-').reverse().join('/');
    const horaTransacao = transacao.created_at.substring(11, 16);
    // const horaTransacao = `${Number(transacao.created_at.substring(11, 13)) - 3}:${transacao.created_at.substring(14, 16)}`;

    const dataEHora = <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{dataTransacao}</span>
      <span style={{ color: '#A6AAB4', fontSize: 14 }}>{horaTransacao}</span>
    </div>;

    // console.log('clienteData', clienteData);

    return {
      data_soliticacao: dataEHora,
      cia_energia: transacao.companhia,
      cliente: clienteData === undefined || clienteData.length === 0 ? 'Dado não encontrado' : clienteData[0].name,
      valor: <span style={{ color: "#0073FC", fontWeight: 600 }}>R$ {FormatarValor(transacao.valor_pagamento)}</span>,
      estabelecimento: transacao.estabelecimento.data.razao_social,
      status: status,
      /* edit: <Tooltip title={iconTooltip} placement="top" arrow><span style={{ cursor: "pointer" }} onClick={() => editTransacao(transacao)}>
            { imgEdit }
        </span></Tooltip> */
    }
  });

  /* const editTransacao = useCallback((transacao) => {
    const idReverse = transacao.id.split('').reverse().join('');
    // console.log(idReverse);

    // redireciona para a página de edição
    history.push(`/admin/conta-luz/editar/${idReverse}`);
  }, [history]); */

  const [statusSelecionado, setStatusSelecionado] = useState('TODOS');
  const [ciaSelecionada, setCiaSelecionada] = useState('TODOS');

  const filterByStatus = useCallback((value) => {
    const filterValue = value.value;
    // console.log(ciaSelecionada);

    if (filterValue !== 'TODOS' && ciaSelecionada === 'TODOS') {
      const transacoesFiltradas = transacoes.filter(item => item.status === filterValue);

      setTransacoesVisiveis(transacoesFiltradas);
    }
    if (filterValue !== 'TODOS' && ciaSelecionada !== 'TODOS') {
      const transacoesFiltradas = transacoes.filter(item => item.status === filterValue && item.companhia === ciaSelecionada);

      setTransacoesVisiveis(transacoesFiltradas);
    }
    if (filterValue === 'TODOS' && ciaSelecionada === 'TODOS') {
      setTransacoesVisiveis(transacoes);
    }
    if (filterValue === 'TODOS' && ciaSelecionada !== 'TODOS') {
      const transacoesFiltradas = transacoes.filter(item => item.companhia === ciaSelecionada);

      setTransacoesVisiveis(transacoesFiltradas);
    }
  }, [ciaSelecionada, transacoes]);

  const filterByCia = useCallback((value) => {
    const filterValue = value.value;
    // console.log(statusSelecionado);

    if (filterValue !== 'TODOS' && statusSelecionado === 'TODOS') {
      const transacoesFiltradas = transacoes.filter(item => item.companhia === filterValue);

      setTransacoesVisiveis(transacoesFiltradas);
    } 
    if (filterValue !== 'TODOS' && statusSelecionado !== 'TODOS') {
      const transacoesFiltradas = transacoes.filter(item => item.companhia === filterValue && item.status === statusSelecionado);

      setTransacoesVisiveis(transacoesFiltradas);
    }
    if (filterValue === 'TODOS' && statusSelecionado === 'TODOS') {
      setTransacoesVisiveis(transacoes);
    }
    if (filterValue === 'TODOS' && statusSelecionado !== 'TODOS') {
      const transacoesFiltradas = transacoes.filter(item => item.status === statusSelecionado);

      setTransacoesVisiveis(transacoesFiltradas);
    }
  }, [statusSelecionado, transacoes]);

  const clienteTransacao = useCallback((transacao) => {

    const clienteData = clientes.filter(item => item.id === transacao.cliente_id);

    return clienteData === undefined || clienteData.length === 0 ? 'Dado não encontrado' : clienteData[0].name
  }, [clientes]);

  const valorPagoTransacao = useCallback((transacao) => {
    // console.log(transacao);

    const valor_total = transacao.valor_pagamento.toString().split('.');
    const valorTotal = `${valor_total[0]},${valor_total[1] ? valor_total[1].substring(0, 2) : "00"}`;

    return <strong>R$ {valorTotal}</strong>
  }, []);

  const transacaoStatus = useCallback((transacao) => {
    let colorStatus = '';
    if(transacao.status === 'EM_ANALISE') {colorStatus = "#FEAC02"} // Em análise
    // if(transacao.status === 2) {colorStatus = "#5097FF"} // Solicitar documentos
    if(transacao.status === 'APROVADO') {colorStatus = "#5CB85C"} // Aprovada
    if(transacao.status === 'CANCELADO') {colorStatus = "#F35757"} // Cancelado
    if(transacao.status === 'REJEITADO') {colorStatus = "#F35757"} // Checagem financeira

    let messageStatus = '';
    if(transacao.status === 'EM_ANALISE') {messageStatus = "Em análise"} // Em análise
    // if(transacao.status === 2) {messageStatus = "Solicitar documentos"} // Solicitar documentos
    if(transacao.status === 'APROVADO') {messageStatus = "Aprovada"} // Aprovada
    if(transacao.status === 'CANCELADO') {messageStatus = "Cancelada"} // Cancelado
    if(transacao.status === 'REJEITADO') {messageStatus = "Reprovadaa"} // Checagem financeira

    return <span style={{ color: colorStatus }}>{messageStatus}</span>
  }, []);

  const optionsCia = ciasEnergia.map(item => (
    { value: item.label, label: item.label }
  ));

  useEffect(() => {
    optionsCia.push({ value: 'TODOS', label: 'TODOS' });
  }, [optionsCia]);

  const [dataFiltrada, setDataFiltrada] = useState(false);
  const [filtroAberto, setFiltroAberto] = useState(false);

  const [selectionDate, setSelectionDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
    }
  ]);

  const filterByDate = useCallback(() => {
    const dataInicial = format(selectionDate[0].startDate, 'yyyy-MM-dd');
    const dataFinal = format(selectionDate[0].endDate, 'yyyy-MM-dd');

    const momentStartDate = moment(dataInicial);
    const momentEndDate = moment(dataFinal);

    // const anoInicial = Number(dataInicial.substring(0,4));
    // const mesInicial = Number(dataInicial.substring(5,7));
    // const diaInicial = Number(dataInicial.substring(8,10));

    // const anoFinal = Number(dataFinal.substring(0,4));
    // const mesFinal = Number(dataFinal.substring(5,7));
    // const diaFinal = Number(dataFinal.substring(8,10));

    /* const transacoesFiltradas = transacoes.filter(item => 
      Number(item.created_at.substring(0, 4)) >= anoInicial && Number(item.created_at.substring(0, 4)) <= anoFinal &&
      Number(item.created_at.substring(5, 7)) >= mesInicial && Number(item.created_at.substring(5, 7)) <= mesFinal &&
      Number(item.created_at.substring(8, 10)) >= diaInicial && Number(item.created_at.substring(8, 10)) <= diaFinal
    ); */

    const transacoesFiltradas = transacoes.filter(
      item =>
        moment(item.created_at) >= momentStartDate &&
        moment(item.created_at) <= momentEndDate,
    );

    // console.log(transacoesFiltradas);
    setTransacoesVisiveis(transacoesFiltradas);

    setDataFiltrada(true);
    setFiltroAberto(false);
  }, [selectionDate, transacoes]);

  return (
    <Container>
      <FilterArea>
        <FilterHeader>Filtrar por:</FilterHeader>

        <Select
          placeholder="Status"
          onChange={(value) => {
            setStatusSelecionado(value.value);
            filterByStatus(value);
          }}
          className="filterStatusSelect"
          options={[
            { value: 'EM_ANALISE', label: 'Em Análise' },
            { value: 'APROVADO', label: 'Aprovada' },
            { value: 'CANCELADO', label: 'Cancelada' },
            { value: 'REJEITADO', label: 'Rejeitada' },
            { value: 'TODOS', label: 'Todos' }
          ]}
        />

        <Select
          placeholder="Cia. de Energia"
          onChange={(value) => {
            setCiaSelecionada(value.value);
            filterByCia(value);
          }}
          className="filterStatusSelect filterStatusSelect2"
          options={optionsCia}
        />

        <FilterDate style={{ background: dataFiltrada ? "#EFF2F7" : "#FFFFFF" }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingLeft: 10,
              paddingRight: 10,
              width: '100%',
            }}
            onClick={() => setFiltroAberto(!filtroAberto)}
          >
            <span>Filtrar data</span>
            <FiCalendar color="#0073FC" />
          </div>

          {dataFiltrada && (
            <FiXCircle
              className="apagarFiltro"
              color="#c53030"
              size={22}
              onClick={() => {
                setTransacoesVisiveis(transacoes);
                setDataFiltrada(false);
                setFiltroAberto(false);
              }}
            />
          )}
        </FilterDate>

        <DatePicker filtroAberto={filtroAberto}>
          <DateRange
            locale={ptBR}
            dateDisplayFormat="dd/MM/yyyy"
            monthDisplayFormat="MMMMM"
            weekdayDisplayFormat="EEEEE"
            onChange={item => setSelectionDate([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={selectionDate}
          />

          <ButtonBox>
            <button type="button" className="back" onClick={() => setFiltroAberto(false)}>Cancelar</button>
            <button type="button" onClick={() => filterByDate()}>Filtrar</button>
          </ButtonBox>
        </DatePicker>
      </FilterArea>

      <TableContent>
        {/* <FilterArea>
          <FilterHeader>Filtrar por:</FilterHeader>

          <Select
            placeholder="Status"
            onChange={(value) => {
              setStatusSelecionado(value.value);
              filterByStatus(value);
            }}
            className="filterStatusSelect"
            options={[
              { value: 'EM_ANALISE', label: 'Em Análise' },
              { value: 'APROVADO', label: 'Aprovada' },
              { value: 'CANCELADO', label: 'Cancelada' },
              { value: 'REJEITADO', label: 'Rejeitada' },
              { value: 'TODOS', label: 'Todos' }
            ]}
          />

          <Select
            placeholder="Cia. de Energia"
            onChange={(value) => {
              setCiaSelecionada(value.value);
              filterByCia(value);
            }}
            className="filterStatusSelect filterStatusSelect2"
            options={optionsCia}
          />
        </FilterArea> */}

        <MaterialTable
          title="Lista de Transações"
          columns={headData}
          data={bodyData}
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                
              </div>
            )
          }}
          localization={{
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página',
              lastTooltip: 'Última página'
            },
            toolbar: {
              searchPlaceholder: 'Buscar',
              searchTooltip: 'Buscar'
            },
            body: {
              emptyDataSourceMessage: 'Nenhum dado encontrado',
            }
          }}
          options={{
            search: false,
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: '#10104F',
              color: '#FFF'
            },
            emptyValue: "Nenhum dado encontrado"
          }}
        />
      </TableContent>
    
      <TableContentMobile>
        <Header>
          <h3>Lista de Transações</h3>

          <Select
            placeholder="Status"
            onChange={(value) => filterByStatus(value)}
            className="filterStatusSelect"
            options={[
              { value: '1', label: 'Em Analise' },
              { value: '2', label: 'Solicitar documentos' },
              { value: '3', label: 'Aprovada' },
              { value: '5', label: 'Checagem financeira' },
              { value: '0', label: 'Todos' }
            ]}
          />

          <span>Total: {transacoesVisiveis.length}</span>
        </Header>

        <MobileBody>
          {transacoesVisiveis.map((transacao) => (
            <Line key={transacao.id}>
              <span>{clienteTransacao(transacao)}</span>
              <p>Valor pago: {valorPagoTransacao(transacao)}</p>
              <p>Cia Energia: {transacao.companhia}</p>
      
              <div className="footer">
                <span>{transacaoStatus(transacao)}</span>
                {/* <img src={editIcon} style={{ display: transacao.status === 3 ? 'none' : 'block' }} alt="Editar transação" onClick={() => editTransacao(transacao)}/> */}
              </div>
            </Line>
          ))}
        </MobileBody>

      </TableContentMobile>
    </Container>
  );
};

export default ParceiroContaDeLuz;
