/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Tooltip } from '@material-ui/core/';
import { MdFiberManualRecord } from 'react-icons/md';
import { FaEye } from 'react-icons/fa';

import api from '../../services/api';

import FormatarValor from '../../components/FormatarValor';

import editIcon from '../../assets/images/edit_icon.svg';

import {
  Container,
  TableContent,
  TableContentMobile,
  Header,
  Line,
  MobileBody,
} from './styles';

function AdminFGTSNovas() {
  const history = useHistory();

  const [transacoes, setTransacoes] = useState([]);
  const [transacoesVisiveis, setTransacoesVisiveis] = useState([]);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  const [estabelecimentos, setEstabelecimentos] = useState([]);
  const [licenciados, setLicenciados] = useState([]);

  // Faz as requisições das transações, estabelecimentos e licenciados
  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get('/fgts?png=99999', auth).then(response => {
      const result = response.data.data;
      // console.log('responseFGTS', response)

      setTransacoes(result);
      setTransacoesVisiveis(result);
    });

    api.get("/estabelecimento?png=99999", auth).then((response) => {
      const result = response.data.data;
      // console.log('estabelecimento', response)

      setEstabelecimentos(result)
    });

    api.get("/licenciado?png=99999", auth).then((response) => {
      const result = response.data.data;
      // console.log('las', result)

      setLicenciados(result);
    });

  }, [userToken]);

  // Informações Head da tabela
  const headData = [
    { title: 'Data da solicitação', field: 'data', sorting: false },
    { title: 'Cliente', field: 'cliente', sorting: false },
    { title: 'Licenciado', field: 'lic', sorting: false },
    { title: 'Estabelecimento', field: 'est', sorting: false },
    { title: 'Valor em conta', field: 'valor', align: 'center', sorting: false },
    { title: 'Valor negociado', field: 'valor_negociado', align: 'center', sorting: false },
    { title: 'Status', field: 'status', align: 'center', sorting: false },
    { title: '', field: 'edit', align: 'center', sorting: false },
  ];

  // let estabelecimetoNome = '';
  // let idLa = '';

  // Informações Body da tabela
  const bodyData = transacoesVisiveis === [] || transacoesVisiveis === "Nenhuma transação encontrada" ? []
    : transacoesVisiveis.map(transacao => {

    let colorStatus = '';
    if(transacao.transacao_status_id === 1) {colorStatus = "#FEAC02"} // Em análise
    if(transacao.transacao_status_id === 2) {colorStatus = "#5097FF"} // Solicitar documentos
    if(transacao.transacao_status_id === 3) {colorStatus = "#5CB85C"} // Aprovada
    if(transacao.transacao_status_id === 4) {colorStatus = "#F35757"} // Cancelada
    if(transacao.transacao_status_id === 5) {colorStatus = "#8850FF"} // Checagem financeira
    if(transacao.transacao_status_id === 6) {colorStatus = "#FF7C33"} // Em processamento

    let messageStatus = '';
    if(transacao.transacao_status_id === 1) {messageStatus = "Em análise"} // Em análise
    if(transacao.transacao_status_id === 2) {messageStatus = "Solicitar documentos"} // Solicitar documentos
    if(transacao.transacao_status_id === 3) {messageStatus = "Aprovada"} // Aprovada
    if(transacao.transacao_status_id === 4) {messageStatus = "Cancelada"} // Cancelada 
    if(transacao.transacao_status_id === 5) {messageStatus = "Checagem financeira"} // Checagem financeira
    if(transacao.transacao_status_id === 6) {messageStatus = "Em processamento"} // Em processamento

    const status = <Tooltip title={messageStatus} placement="top" arrow><span><MdFiberManualRecord color={colorStatus} /></span></Tooltip>

    const imgEdit = transacao.transacao_status_id === 3 || transacao.transacao_status_id === 4?  <FaEye color="#333333" size={21} /> : <img src={editIcon} alt=""/>;
    const iconTooltip = transacao.transacao_status_id === 3 ? "Ver Dados da Transação" : "Editar Dados da Transação";

    const dataTransacao = transacao.created_at.substring(0, 10).split('-').reverse().join('/');
    const horaTransacao = transacao.created_at.substring(11, 16);

    const dataEHora = <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{dataTransacao}</span>
      <span style={{ color: '#A6AAB4', fontSize: 14 }}>{horaTransacao}</span>
    </div>;

    // Filtra o estabelecimento da transação
    const estabelecimento = estabelecimentos.filter(item => item.id === transacao.estabelecimento_id);

    // Filtra o licenciado da transação
    const licenciado = estabelecimento.length > 0 ? licenciados.filter(item => item.id === estabelecimento[0].licenciado_autorizado_id) : [];

    return {
      data: dataEHora,
      cliente: transacao.nome,
      est: estabelecimento.length > 0 ? estabelecimento[0].nome_fantasia : '',
      lic: licenciado.length > 0 ? licenciado[0].nome_fantasia : '',
      valor: <span style={{ color: "#0073FC", fontWeight: 600 }}>R$ {FormatarValor(transacao.valor_em_conta)}</span>,
      valor_negociado: <span style={{ color: "#0073FC", fontWeight: 600 }}>R$ {FormatarValor(transacao.valor_a_retirar)}</span>,
      status: status,
      edit: <Tooltip title={iconTooltip} placement="top" arrow><span style={{ cursor: "pointer" }} onClick={() => editTransacao(transacao.id)}>
            { imgEdit }
        </span></Tooltip>
    }
  });

  /* useEffect(() => {
    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    async function loadLa() {
      const responseTransacao = await api.get(
        `/licenciado/${idLa}`,
        auth
      );

      setLicenciado(responseTransacao.data.data)
    }

    if(idLa !== '') {
      loadLa();
    }
  }, [idLa, userToken]); */

  const editTransacao = useCallback((id) => {
    history.push(`/admin/fgts/editar/${id}`);
  }, [history]);

  const filterByStatus = useCallback((value) => {
    const filterValue = Number(value.value);

    // transacoesVisiveis
    if (filterValue !== 0) {
      const transacoesFiltradas = transacoes.filter(item => item.transacao_status_id === filterValue);
  
      setTransacoesVisiveis(transacoesFiltradas);
    }

    if (filterValue === 0) {
      setTransacoesVisiveis(transacoes);
    }
  }, [transacoes]);

  const transacaoStatus = useCallback((transacao) => {
    let colorStatus = '';
    if(transacao.status_id === 1) {colorStatus = "#FEAC02"} // Em análise
    if(transacao.status_id === 2) {colorStatus = "#5097FF"} // Solicitar documentos
    if(transacao.status_id === 3) {colorStatus = "#5CB85C"} // Aprovada
    if(transacao.status_id === 4) {colorStatus = "#F35757"} // Cancelado
    if(transacao.status_id === 5) {colorStatus = "#8850FF"} // Checagem financeira
    if(transacao.status_id === 6) {colorStatus = "#FF7C33"} // Checagem financeira

    let messageStatus = '';
    if(transacao.status_id === 1) {messageStatus = "Em análise"} // Em análise
    if(transacao.status_id === 2) {messageStatus = "Solicitar documentos"} // Solicitar documentos
    if(transacao.status_id === 3) {messageStatus = "Aprovada"} // Aprovada
    if(transacao.status_id === 4) {messageStatus = "Cancelado"} // Cancelado
    if(transacao.status_id === 5) {messageStatus = "Checagem financeira"} // Checagem financeira
    if(transacao.status_id === 6) {messageStatus = "Em processamento"} // Em processamento

    return <span style={{ color: colorStatus }}>{messageStatus}</span>
  }, []);

  return (
    <Container>
      <TableContent>

        <Select
          placeholder="Status"
          onChange={(value) => filterByStatus(value)}
          className="filterStatusSelect"
          options={[
            { value: '1', label: 'Em análise' },
            { value: '6', label: 'Em processamento' },
            { value: '2', label: 'Solicitar documentos' },
            { value: '3', label: 'Aprovada' },
            { value: '4', label: 'Cancelada' },
            { value: '5', label: 'Checagem financeira' },
            { value: '0', label: 'Todos' }
          ]}
        />

        <MaterialTable
          title="Lista de Transações"
          columns={headData}
          data={bodyData}
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
              </div>
            )
          }}
          localization={{
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página',
              lastTooltip: 'Última página'
            },
            toolbar: {
              searchPlaceholder: 'Buscar',
              searchTooltip: 'Buscar'
            },
            body: {
              emptyDataSourceMessage: 'Nenhum dado encontrado',
            }
          }}
          options={{
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: '#10104F',
              color: '#FFF'
            },
            emptyValue: "Nenhum dado encontrado"
          }}
        />

      </TableContent>

      <TableContentMobile>
        <Header>
          <h3>Lista de Transações</h3>

          <Select
            placeholder="Status"
            onChange={(value) => filterByStatus(value)}
            className="filterStatusSelect"
            options={[
              { value: '1', label: 'Em Analise' },
              { value: '2', label: 'Solicitar documentos' },
              { value: '3', label: 'Aprovada' },
              { value: '4', label: 'Cancelada' },
              { value: '5', label: 'Checagem financeira' },
              { value: '0', label: 'Todos' }
            ]}
          />

          <span>Total: {transacoes.length}</span>
        </Header>

        <MobileBody>
          {transacoes.map((transacao) =>  { 
              let produto = '';

                /* produtos.filter((prod) => {
                  if(String(prod.id) === String(transacao.code)){
                    produto = prod.nome;
                  }
                }); */
                const dataTransacao = transacao.created_at.substring(0, 10).split('-').reverse().join('/');
                const horaTransacao = transacao.created_at.substring(11, 16);
            
                const dataEHora = <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <span>{dataTransacao}</span>
                  <span style={{ color: '#A6AAB4', fontSize: 14, marginLeft: 5 }}>{horaTransacao}</span>
                </div>;

                let estabelecimento = '';

                estabelecimentos.filter((esta) => {
                  if(String(esta.id) === String(transacao.estabelecimento_id)){
                    estabelecimento = esta.nome_fantasia;
                  }
                });

                return(
                  <Line key={transacao.id}>
                    <span>{produto}</span>
                    <p>{dataEHora}</p>

                    <p>Valor: {FormatarValor(transacao.valor)}</p>
                    
                    <p>{estabelecimento}</p>
            
                    <div className="footer">
                      <span>{transacaoStatus(transacao)}</span>
                      { transacao.status === 3 ? <img src={editIcon} alt="" style={{ opacity: 0.4 }}/> : <img src={editIcon} alt="" onClick={() => editTransacao(transacao.id)}/> }
                    </div>
                  </Line>
            )})}
        </MobileBody>
      </TableContentMobile>
    </Container>
  );
}

export default AdminFGTSNovas;
