import styled, { css } from "styled-components";

import backgroundImg from "../../assets/images/background_2.jpg";

export const Container = styled.div``;

export const Page = styled.div`
  margin-left: 220px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  /* height: calc(100vh - 72px); */
  background: url(${backgroundImg}) no-repeat;
  background-position: top left;
  background-size: 100% 256px;
  box-sizing: border-box;

  padding: 30px 160px 0 30px;

  @media (max-width: 500px) {
    padding: 50px 20px 20px;
  }
`;

export const AddLA = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-bottom: 30px;

  > span {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    margin-right: 30px;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #10104f;
  border-radius: 50%;
  border: none;
  outline-style: none;

  width: 50px;
  height: 50px;

  > svg {
    width: 30px;
    height: 30px;
  }
`;

export const FormSection = styled.div`
  margin-bottom: 60px;

  ${(props) =>
    props.formOpened &&
    css`
      display: none;
    `}
`;

export const TableContent = styled.div`
  position: relative;

  th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-paddingCheckbox {
    background: #10104f;
  }

  span.MuiButtonBase-root.MuiTableSortLabel-root.Mui-disabled {
    color: transparent;
  }

  .MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.Component-paginationToolbar-2.MuiToolbar-gutters {
    background-color: #fff;
  }

  tr.MuiTableRow-root {
    border: none;
  }

  tr.MuiTableRow-root:nth-of-type(odd) {
    background-color: #f5f5f5;
  }

  .MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-5.MuiToolbar-gutters {
    padding-bottom: 20px;
    height: 100px;
  }

  .MuiToolbar-regular {
    min-height: 84px;
  }

  .css-2b097c-container {
    position: absolute;
    box-sizing: border-box;
    width: 140px;
    top: 23px;
    right: 300px;
    z-index: 50;

    &.select1 {
      right: 320px;
      margin-right: 40px;
    }

    &.select2 {
      right: 160px;
      margin-right: 40px;
      width: 180px;
    }

    &.select3 {
      right: 0;
      margin-right: 20px;
      width: 160px;
    }
  }

  .MuiInput-underline:before {
    border-bottom: none;
  }

  @media (max-width: 500px) {
    display: none;
  }

  span.title {
    position: absolute;
    background-color: red;
    left: 0;
    color: red;
  }

  select.listagem {
    border: none;
    background-color: transparent;
  }
`;

export const TableContentMobile = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 20px 3px;
  margin-bottom: 100px;

  @media (min-width: 500px) {
    display: none;
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid #eeeeee;
  padding: 0 17px 10px;

  h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .filterStatusSelect.css-2b097c-container {
    width: 160px;
    margin-bottom: 20px;
  }

  > span {
    margin-bottom: 20px;
  }

  .select4 {
    width: 150px;
  }
`;

export const MobileBody = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  height: 448px;
`;

export const Line = styled.div`
  display: flex;
  padding: 15px 17px;
  border-bottom: 1px solid #eeeeee;
  flex-direction: row;
  justify-content: space-between;

  div.coluna1 {
    width: 50%;

    select {
      width: 70px;
      border: none;
      background-color: transparent;
      font-size: 30px;
      color: #9fa7ba;
      font-weight: bold;
    }
  }

  div.coluna2 {
    width: 50%;
  }

  span {
    font-size: 25px;
    color: #9fa7ba;
    font-weight: bold;
  }

  p {
    font-size: 14px;
    color: #9fa7ba;
  }

  div.footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    > span {
      font-size: 12px;
      font-weight: 700;
      /* color: #5CB85C; */
    }
  }
`;

export const BoxEditaTaxa = styled.div`
  div.boxInput {
    display: flex;
    flex-direction: row;
    /* background-color: red; */
    align-items: center;

    button {
      margin-left: 15px;
      width: 25px;
      height: 25px;
      padding: 3px;
      font-size: 15px;
      color: #fff;
      font-weight: bold;
      background-color: #0073fc;
      border: none;
      border-radius: 5px;

      ${(props) =>
        props.taxaComissaoValorMenorQueZero === true &&
        css`
          background-color: #cccccc;
        `}
    }

    button.cancelar {
      min-height: 25px;
      min-width: 25px;
      margin-left: 5px;
      background-color: #f35757;
    }
  }

  div.input {
    display: flex;
    flex-direction: row;

    ${(props) =>
      props.taxaComissaoValorMenorQueZero === false &&
      css`
        border: 1px solid #0073fc;
      `}

    ${(props) =>
      props.taxaComissaoValorMenorQueZero === true &&
      css`
        border: 1px solid #f35757;
      `}

      span.porcentagem {
      ${(props) =>
        props.taxaComissaoValorMenorQueZero === true &&
        css`
          color: #f35757;
        `}
    }

    max-width: 90px;
    padding: 5px;
    border-radius: 5px;

    input {
      border: none;
      background-color: transparent;
      margin-left: 15px;
      max-width: 40px;
    }
  }
`;
