import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Tooltip } from '@material-ui/core/';
import { MdFiberManualRecord } from 'react-icons/md';
import { FaEye } from 'react-icons/fa';
import { FiCalendar, FiXCircle } from 'react-icons/fi';
import { DateRange } from 'react-date-range';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';

import api from '../../services/api';

import editIcon from '../../assets/images/edit_icon.svg';

import FormatarValor from '../../components/FormatarValor';

import {
  Container,
  FilterArea,
  FilterHeader,
  FilterDate,
  DatePicker,
  ButtonBox,
  TableContent,
  TableContentMobile,
  Header,
  Line,
  MobileBody,
} from './styles';

function AdminTransacoesNovas() {
  const history = useHistory();

  const [transacoes, setTransacoes] = useState([]);
  const [transacoesVisiveis, setTransacoesVisiveis] = useState([]);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  // transações
  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get('/transacoes_admin', auth).then(response => {
      const result = response.data;
      // console.log(response)

      setTransacoes(result);
      setTransacoesVisiveis(result);
    });

  }, [userToken]);

  // Informações Head da tabela
  const headData = [
    { title: 'Responsável', field: 'resp', sorting: false },
    { title: 'Estabelecimento', field: 'est', sorting: false },
    { title: 'Licenciado Autorizado', field: 'la', sorting: false },
    { title: 'Valor', field: 'valor', sorting: false },
    { title: 'Data', field: 'data', sorting: false },
    { title: 'Status', field: 'status', align: 'right', sorting: false },
    { title: '', field: 'edit', align: 'center', sorting: false },
  ];

  // Informações Body da tabela
  const bodyData = transacoesVisiveis === [] || transacoesVisiveis === "Nenhuma transação encontrada" ? []
  : transacoesVisiveis.map(transacao => {

    let colorStatus = '';
    if(transacao.status === 1) {colorStatus = "#FEAC02"} // Em análise
    if(transacao.status === 2) {colorStatus = "#5097FF"} // Solicitar documentos
    if(transacao.status === 3) {colorStatus = "#5CB85C"} // Aprovada
    if(transacao.status === 4) {colorStatus = "#F04148"} // Cancelada
    if(transacao.status === 5) {colorStatus = "#8850FF"} // Checagem financeira
    if(transacao.status === 6) {colorStatus = "#FF7C33"} // Em processamento

    let messageStatus = '';
    if(transacao.status === 1) {messageStatus = "Em análise"} // Em análise
    if(transacao.status === 2) {messageStatus = "Solicitar documentos"} // Solicitar documentos
    if(transacao.status === 3) {messageStatus = "Aprovada"} // Aprovada
    if(transacao.status === 4) {messageStatus = "Cancelada"} // Cancelada
    if(transacao.status === 5) {messageStatus = "Checagem financeira"} // Checagem financeira
    if(transacao.status === 6) {messageStatus = "Em processamento"} // Em processamento

    // console.log(transacao);

    const status = <Tooltip title={messageStatus} placement="top" arrow><span><MdFiberManualRecord color={colorStatus} /></span></Tooltip>

    // const valor = <span style={{ color: "#0073FC", fontWeight: 600 }}>R$ {totalTransação}</span>;

    const imgEdit = transacao.status === 3 ? <FaEye color="#333333" size={21} /> : <img src={editIcon} alt=""/>;
    const iconTooltip = transacao.status === 3 ? "Ver Dados da Transação" : "Editar Dados da Transação";

    const dataTransacao = transacao.created_at.substring(0, 10).split('-').reverse().join('/');
    const horaTransacao = transacao.created_at.substring(11, 16);

    const dataEHora = <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{dataTransacao}</span>
      <span style={{ color: '#A6AAB4', fontSize: 14 }}>{horaTransacao}</span>
    </div>;

    return {
      resp: transacao.cliente,
      est: transacao.ec,
      la: transacao.la,
      valor: <span style={{ color: "#0073FC", fontWeight: 600 }}>R$ {FormatarValor(transacao.valor_final)}</span>,
      data: dataEHora,
      status: status,
      edit: <Tooltip title={iconTooltip} placement="top" arrow><span style={{ cursor: "pointer" }} onClick={() => editTransacao(transacao)}>
            { imgEdit }
        </span></Tooltip>
    }
  });

  const editTransacao = useCallback((transacao) => {
    const idReverse = transacao.id_transacao.split('').reverse().join('');

    // redireciona para a página de edição
    history.push(`/admin/transacoes/editar/${idReverse}`);
  }, [history]);

  const filterByStatus = useCallback((value) => {
    const filterValue = Number(value.value);

    if (filterValue !== 0) {
      const transacoesFiltradas = transacoes.filter(item => item.status === filterValue);
  
      setTransacoesVisiveis(transacoesFiltradas);
    }

    if (filterValue === 0) {
      setTransacoesVisiveis(transacoes);
    }

  }, [transacoes]);

  const [dataFiltrada, setDataFiltrada] = useState(false);
  const [filtroAberto, setFiltroAberto] = useState(false);

  const [selectionDate, setSelectionDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
    }
  ]);

  const filterByDate = useCallback(() => {
    const dataInicial = format(selectionDate[0].startDate, 'yyyy-MM-dd');
    const dataFinal = format(selectionDate[0].endDate, 'yyyy-MM-dd');

    const anoInicial = Number(dataInicial.substring(0,4));
    const mesInicial = Number(dataInicial.substring(5,7));
    const diaInicial = Number(dataInicial.substring(8,10));

    const anoFinal = Number(dataFinal.substring(0,4));
    const mesFinal = Number(dataFinal.substring(5,7));
    const diaFinal = Number(dataFinal.substring(8,10));

    const transacoesFiltradas = transacoes.filter(item => 
      Number(item.created_at.substring(0, 4)) >= anoInicial && Number(item.created_at.substring(0, 4)) <= anoFinal &&
      Number(item.created_at.substring(5, 7)) >= mesInicial && Number(item.created_at.substring(5, 7)) <= mesFinal &&
      Number(item.created_at.substring(8, 10)) >= diaInicial && Number(item.created_at.substring(8, 10)) <= diaFinal
    );

    // console.log(transacoesFiltradas);
    setTransacoesVisiveis(transacoesFiltradas);

    setDataFiltrada(true);
    setFiltroAberto(false);
  }, [selectionDate, transacoes]);

  const valorFinalTransacao = useCallback((transacao) => {
    const valor_total = transacao.valor_final.toString().split('.');
    const valorTotal = `${valor_total[0]},${valor_total[1] ? valor_total[1].substring(0, 2) : "00"}`;

    return <strong>R$ {valorTotal}</strong>
  }, []);

  const transacaoStatus = useCallback((transacao) => {
    let colorStatus = '';
    if(transacao.status === 1) {colorStatus = "#FEAC02"} // Em análise
    if(transacao.status === 2) {colorStatus = "#5097FF"} // Solicitar documentos
    if(transacao.status === 3) {colorStatus = "#5CB85C"} // Aprovada
    if(transacao.status === 4) {colorStatus = "#F35757"} // Cancelado
    if(transacao.status === 5) {colorStatus = "#8850FF"} // Checagem financeira
    if(transacao.status === 6) {colorStatus = "#FF7C33"} // Em processamento

    let messageStatus = '';
    if(transacao.status === 1) {messageStatus = "Em análise"} // Em análise
    if(transacao.status === 2) {messageStatus = "Solicitar documentos"} // Solicitar documentos
    if(transacao.status === 3) {messageStatus = "Aprovada"} // Aprovada
    if(transacao.status === 4) {messageStatus = "Cancelado"} // Cancelado
    if(transacao.status === 5) {messageStatus = "Checagem financeira"} // Checagem financeira
    if(transacao.status === 6) {messageStatus = "Em processamento"} // Em processamento

    return <span style={{ color: colorStatus }}>{messageStatus}</span>
  }, []);

  return (
    <Container>
      <FilterArea>
        <FilterHeader>Filtrar por:</FilterHeader>

        <Select
          placeholder="Status"
          onChange={(value) => filterByStatus(value)}
          className="filterStatusSelect"
          options={[
            { value: '1', label: 'Em análise' },
            { value: '6', label: 'Em processamento' },
            { value: '2', label: 'Solicitar documentos' },
            { value: '3', label: 'Aprovada' },
            { value: '4', label: 'Cancelada' },
            { value: '5', label: 'Checagem financeira' },
            { value: '0', label: 'Todos' }
          ]}
        />

        <FilterDate style={{ background: dataFiltrada ? "#EFF2F7" : "#FFFFFF" }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 10,
              paddingRight: 10,
            }}
            onClick={() => setFiltroAberto(!filtroAberto)}
          >
            <span>Filtrar data</span>
            <FiCalendar color="#0073FC" />
          </div>

          {dataFiltrada && (
            <FiXCircle
              className="apagarFiltro"
              color="#c53030"
              size={22}
              onClick={() => {
                setTransacoesVisiveis(transacoes);
                setDataFiltrada(false);
                setFiltroAberto(false);
              }}
            />
          )}
        </FilterDate>

        <DatePicker filtroAberto={filtroAberto}>
          <DateRange
            locale={ptBR}
            dateDisplayFormat="dd/MM/yyyy"
            monthDisplayFormat="MMMMM"
            weekdayDisplayFormat="EEEEE"
            onChange={item => setSelectionDate([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={selectionDate}
          />

          <ButtonBox>
            <button type="button" className="back" onClick={() => setFiltroAberto(false)}>Cancelar</button>
            <button type="button" onClick={() => filterByDate()}>Filtrar</button>
          </ButtonBox>
        </DatePicker>
      </FilterArea>

      <TableContent>
        <MaterialTable
          title="Lista de Transações"
          columns={headData}
          data={bodyData}
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                
              </div>
            )
          }}
          localization={{
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página',
              lastTooltip: 'Última página'
            },
            toolbar: {
              searchPlaceholder: 'Buscar',
              searchTooltip: 'Buscar'
            },
            body: {
              emptyDataSourceMessage: 'Nenhum dado encontrado',
            }
          }}
          options={{
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: '#10104F',
              color: '#FFF'
            },
            emptyValue: "Nenhum dado encontrado"
          }}
        />

      </TableContent>

      <TableContentMobile>
        <Header>
          <h3>Lista de Transações</h3>

          <Select
            placeholder="Status"
            onChange={(value) => filterByStatus(value)}
            className="filterStatusSelect"
            options={[
              { value: '1', label: 'Em Analise' },
              { value: '2', label: 'Solicitar documentos' },
              { value: '3', label: 'Aprovada' },
              { value: '4', label: 'Cancelada' },
              { value: '5', label: 'Checagem financeira' },
              { value: '0', label: 'Todos' }
            ]}
          />

          <span>Total: {transacoesVisiveis.length}</span>
        </Header>

        <MobileBody>
          {transacoesVisiveis.map((transacao) => (
            <Line key={transacao.id}>
              <span>{transacao.cliente}</span>
              <p>{transacao.ec}</p>

              <p>Valor: {valorFinalTransacao(transacao)}</p>
      
              <div className="footer">
                <span>{transacaoStatus(transacao)}</span>
                { transacao.status === 3 ? <img src={editIcon} alt="" style={{ opacity: 0.4 }}/> : <img src={editIcon} alt="" onClick={() => editTransacao(transacao)}/> }
              </div>
            </Line>
          ))}
        </MobileBody>

        {/* <Pagination>
          <span>Total: {total}</span>
          <PaginationButton>
            <PaginationItem><MdNavigateBefore /></PaginationItem>
            {pages.map(page => (
              <PaginationItem 
                key={page}
                onClick={() => setCurrentPage(page)}
              >{page}</PaginationItem>
            ))}
            <PaginationItem><MdNavigateNext /></PaginationItem>
          </PaginationButton>
        </Pagination> */}

      </TableContentMobile>
    </Container>
  );
};

export default AdminTransacoesNovas;
