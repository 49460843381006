import styled, { css } from 'styled-components';

import { shade } from 'polished';

import backgroundImg from '../../assets/images/background.jpg';

import ToolTip from '../../components/Tooltip';

export const Container = styled.div``;

export const Page = styled.div`
  margin-left: 220px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  /* height: calc(100vh - 72px); */
  background: url(${backgroundImg}) no-repeat;
  background-position: top left;
  background-size: 100% 256px;
  box-sizing: border-box;

  padding: 100px 160px 0 30px;

  button {
    padding: 8px 22px;
    /* width: 110px; */
    background: #0073FC;
    border: 1px solid #0073FC;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;

    margin-top: 20px;
    margin-bottom: 60px;
    transition: 0.2s;

    &:hover {
      background: ${shade(0.2, '#0073FC')}
    }

    @media (max-width: 500px) {
      margin-bottom: 100px;
    }
  }

  @media (max-width: 500px) {
    padding: 50px 20px 20px;
  }
`;

export const RowBox1 = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 30px;

  margin-bottom: 30px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

export const Box = styled.div`
  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;

  h3 {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 20px;
  }

  h4 {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 20px;
  }

  > span {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
    cursor: pointer;

    img {
      width: 20px;
      margin-right: 10px;
    }
  }
`;

export const BoletoOptions = styled.div`
  ${props => props.boletoOptions && css`
    display: none;
  `}

  background: #fff;
  padding: 0 30px 20px;
  border-radius: 8px;
  margin-top: -20px;
`;

export const InputArea = styled.div`
  &.campoAnexo {
    width: 200px;
  }

  button.downloadButton {
    display: inline-flex;
    background: transparent;
    padding: 0;
    margin: 5px 0 0;
    color: #0073FC;
    border: none;
    outline-style: none;
    cursor: pointer;

    &:hover {
      background: transparent;
      color: ${shade(0.2, '#0073FC')}
    }
  }

  p {
    color: #0073FC;
  }

  span {
    margin-left: 50px;
    color: #0073FC;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  div.action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    img {
      width: 16px;
      height: 16px;

      cursor: pointer;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  box-sizing: border-box;

  margin-bottom: 18px;
  padding: 1px 11px 1px 1px;

  ${props => props.isErrored && css`
    border: 1px solid #c53030;
  `}

  ${props => props.isFocused && css`
    border: 1px solid #0073FC;
  `}

  input {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;
  }

  select {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;
  }

  img {
    margin-left: 10px;
  }
`;

export const Error = styled(ToolTip)`
  span {
    background: #c53030;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const InputFileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid #CCCCCC;
  border-radius: 4px;
  padding-right: 10px;
  padding-bottom: -1px;
  width: 200px;

  > input {
    width: 170px;
  }

  @media(max-width: 1400px) {
    width: 180px;

    > input {
      width: 150px;
    }
  }

  @media(max-width: 1340px) {
    width: 160px;

    > input {
      width: 130px;
    }
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  width: 100%;

  &.marginTop {
    margin-top: 18px;
  }

  ${props => props.downloadBoletoHide && css`
    display: none;
  `}

  ${props => props.editBoletoHide && css`
    display: none;
  `}

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;

    &.marginTop {
      margin-top: 0;
    }
  }
`;

export const Row3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  width: 100%;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const RowBox2 = styled.div`
  ${props => props.depositoOpened && css`
    display: none;
  `}

  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;

  margin-bottom: 30px;

  h3 {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 20px;
  }
`;

export const RowBox3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 30px;

  margin-bottom: 30px;

  ${props => props.anexosHide && css`
    display: none;
  `}

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

export const InfoPaymentContainer = styled.div`
  background: #10104F;
  color: #fff;
  padding: 20px 30px;
  border-radius: 8px;

  h3 {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 30px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ValueArea = styled.div`
  > label {
    display: block;
    margin: 0;
  }

  span {
    font-size: 24px;
    font-weight: 600;
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 11px;

  border: 1px solid #CCCCCC;
  border-radius: 4px;

  width: 200px;

  ${props => props.comprovanteHide && css`
    display: none;
  `}

  ${props => props.termoHide && css`
    display: none;
  `}

  ${props => props.docFotoHide && css`
    display: none;
  `}
`;

export const RowBoxBoleto = styled.div`
  display: grid;
  /* flex-direction: column; */
  grid-template-columns: 1.5fr 1fr;
  gap: 30px;

  margin-bottom: 30px;
  
  /* background: #fff;
  padding: 20px 30px;
  border-radius: 8px; */

  ${props => props.boletoOptions && css`
    display: none;
  `}

  .quantidadeBoletosSection {
    margin-bottom: 18px;
  }

  .countArea {
    display: flex;
  }

  span.count {
    font-size: 24px;
    font-weight: 700;
  }

  button.boletos {
    padding: 4px;
    margin: 0;
    background: #F1F5F9;
    color: #FE9902;
    margin-left: 8px;
    outline-style: none;

    &:hover {
      background: ${shade(0.03, '#F1F5F9')};
    }
  }

  button.addBoleto {
    margin: 32px 0 0;
    height: 40px;
    width: 100px;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

export const RowBoleto = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 0 0;

  > span {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;

    img {
      width: 20px;
      margin-right: 10px;
    }
  }
`;

export const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(55, 58, 60, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 5;

  img {
    width: 220px;
  }

  ${props => props.loadingHide && css`
    display: none;
  `}
`;
