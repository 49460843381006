import React, { useState, useEffect } from 'react';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';

import api from '../../services/api';

import {
  Container,
  Page,
  Content,
  Box,
  Header,
  Body,
  NavegaçãoAbas,
  BotãoAba,
  Aba1,
  Aba2,
} from './styles';

function ListaLA() {
  const [licenciados, setLicenciados] = useState([]);
  const [revendedores, setRevendedores] = useState([]);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get('/licenciado?png=999999', auth).then(response => {
      const result = response.data.data;
      console.log('licenciados', result)

      setLicenciados(result)
    });

    api.get('/revendedor?png=999999', auth).then(response => {
      const result = response.data.data;
      console.log('revendas', result)

      setRevendedores(result)
    });

  }, [userToken]);

  /**
   * ABAS
   */
  const [tab1, setTab1] = useState(true);
  const [tab2, setTab2] = useState(false);

  const open1 = () => {
    setTab1(true)
    setTab2(false)
  }
  const open2 = () => {
    setTab1(false)
    setTab2(true)
  }

  return (
    <Container>
      <MenuBar />
      <NewMobileMenu />

      <Page>
        <TopBar pageTitle="Lista de Licenciados" />

        <Content>
          <NavegaçãoAbas>
            <BotãoAba tab1={tab1} onClick={open1}>Licenciados</BotãoAba>
            <BotãoAba tab2={tab2} onClick={open2}>Revendedores</BotãoAba>
          </NavegaçãoAbas>

          <Box>
            <Aba1 tab1={tab1}>
              <Header>
                <span>Nome</span>
                <span>CPF</span>
                <span>CNPJ</span>
              </Header>

              {licenciados.map(licenciado => (
                <Body>
                  <span>{licenciado.razao_social}</span>
                  <span>{licenciado.cpf}</span>
                  <span>{licenciado.cnpj}</span>
                </Body>
              ))}
            </Aba1>

            <Aba2 tab2={tab2}>
              <Header>
                <span>Nome</span>
                <span>CPF</span>
                <span>CNPJ</span>
              </Header>

              {revendedores.map(revendedor => (
                <Body>
                  <span>{revendedor.razao_social}</span>
                  <span>{revendedor.cpf}</span>
                  <span>{revendedor.cnpj}</span>
                </Body>
              ))}
            </Aba2>
          </Box>
        </Content>
      </Page>
    </Container>
  );
};

export default ListaLA;
