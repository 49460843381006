import styled from 'styled-components';

import { shade } from 'polished';

export const Container = styled.div`
  background: #10104F;
  color: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  /* max-height: 380px; */

  /* @media (max-width: 1460px) {
    height: 300px;
  }

  @media (max-width: 1255px) {
    height: 320px;
  }

  @media (max-width: 500px) {
    height: 380px;
  } */

  h3 {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 30px;
  }

  > div.valueContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1460px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }

    @media (max-width: 1255px) {
      grid-template-columns: 1fr;
      gap: 20px;
    }

    @media (max-width: 500px) {
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }

  button {
    padding: 8px 22px;
    width: 110px;
    background: #0073FC;
    border: 1px solid #0073FC;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;

    margin-top: 20px;
    transition: 0.2s;

    &:hover {
      background: ${shade(0.2, '#0073FC')}
    }

    &.hide {
      display: none;
    }
  }
`;

export const ValueArea = styled.div`
  > label {
    display: block;
    margin: 0;
  }

  span {
    font-size: 24px;
    font-weight: 600;
  }
`;

export const InfoHorario = styled.div`
  margin-top: 20px;

  svg {
    margin-right: 10px;
  }

  span {
    font-size: 15px;
    line-height: 1.4em;
  }

  &.hide {
    display: none;
  }
`;