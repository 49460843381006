import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from "react-hook-form";
import MaterialTable, { MTableToolbar } from 'material-table';
import Select from 'react-select';
import { FiCalendar, FiXCircle } from 'react-icons/fi';
// import { Tooltip } from '@material-ui/core/';
// import { MdFiberManualRecord } from 'react-icons/md';
// import { FaEye } from 'react-icons/fa';
import {
  MdAdd,
  MdRemove,
  MdErrorOutline
} from 'react-icons/md';
import { DateRange } from 'react-date-range';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';

import api from '../../services/api';

import loading from '../../assets/images/loading.gif';

// import editIcon from '../../assets/images/edit_icon.svg';

// Context API
import { useToast } from '../../hooks/ToastContext';

import {
  Container,
  TableContent,
  HeaderArea,
  FilterArea,
  FilterHeader,
  FilterDate,
  DatePicker,
  ButtonBox,
  AddColaborador,
  Button,
  FormSection,
  Row3,
  InputArea,
  InputContainer,
  Error,
  ButtonRow,
  LoadingContainer,
} from './styles';

function AdminColaboradores() {
  const { addToast } = useToast();

  const [loadingPage, setLoadingPage] = useState(false);

  const [colaboradores, setColaboradores] = useState([]);
  const [colaboradoresVisiveis, setColaboradoresVisiveis] = useState([]);

  const [users, setUsers] = useState([]);
  const [estabelecimentos, setEstabelecimentos] = useState([]);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    async function getInfos() {
      await api.get('/user?png=999999', auth).then(response => {
        const result = response.data.data;
        // console.log('resultUser', result);
  
        setUsers(result);
      });

      await api.get('/estabelecimento?png=999999', auth).then(response => {
        const result = response.data.data;
        // console.log('resultEC', result);
  
        setEstabelecimentos(result);
      });

      await api.get('/colaborador?png=999999', auth).then(response => {
        const result = response.data.data;
        // console.log('resultColab', result);
  
        setColaboradores(result);
        setColaboradoresVisiveis(result);
      });
    }

    getInfos();
  }, [userToken]);

  // Informações Head da tabela
  const headData = [
    { title: 'Colaborador', field: 'colaborador', sorting: false },
    { title: 'Estabelecimento', field: 'estabelecimento', sorting: false },
    { title: 'Data', field: 'data', sorting: false },
    // { title: '', field: 'edit', align: 'center', sorting: false },
  ];

  // Informações Body da tabela
  const bodyData = colaboradoresVisiveis === [] || colaboradoresVisiveis === "Nenhuma transação encontrada" ? []
  : colaboradoresVisiveis.map(colaborador => {

    // console.log(transacao);

    // const valor = <span style={{ color: "#0073FC", fontWeight: 600 }}>R$ {totalTransação}</span>;

    const user = users.filter(item => item.id === colaborador.user_id);
    const estabelecimento = estabelecimentos.filter(item => item.id === colaborador.estabelecimento_id);

    // const imgEdit = colaborador.status === 3 ? <FaEye color="#333333" size={21} /> : <img src={editIcon} alt=""/>;
    // const iconTooltip = colaborador.status === 3 ? "Ver Dados da Transação" : "Editar Dados da Transação";

    const dataTransacao = colaborador.created_at.substring(0, 10).split('-').reverse().join('/');
    const horaTransacao = colaborador.created_at.substring(11, 16);

    const dataEHora = <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{dataTransacao}</span>
      <span style={{ color: '#A6AAB4', fontSize: 14 }}>{horaTransacao}</span>
    </div>;

    return {
      colaborador: user[0].name === undefined ? '' : user[0].name,
      estabelecimento: estabelecimento[0].nome_fantasia === undefined ? '' : estabelecimento[0].nome_fantasia,
      data: dataEHora,
      /* edit: <Tooltip title={iconTooltip} placement="top" arrow><span style={{ cursor: "pointer" }} onClick={() => editTransacao(colaborador)}>
            { imgEdit }
        </span></Tooltip> */
    }
  });

  /* const editTransacao = useCallback((colaborador) => {
    console.log(colaborador);
    // const idReverse = transacao.id_transacao.split('').reverse().join('');

    // redireciona para a página de edição
    // history.push(`/admin/transacoes/editar/${idReverse}`);
  }, []); */

  const filterByEC = useCallback((value) => {
    const filterValue = value.value;

    if (filterValue !== '0') {
      const colaboradoresFiltradas = colaboradores.filter(item => item.estabelecimento_id === filterValue);
  
      setColaboradoresVisiveis(colaboradoresFiltradas);
    }

    if (filterValue === '0') {
      setColaboradoresVisiveis(colaboradores);
    }

  }, [colaboradores]);

  const [dataFiltrada, setDataFiltrada] = useState(false);
  const [filtroAberto, setFiltroAberto] = useState(false);

  const [selectionDate, setSelectionDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
    }
  ]);

  const filterByDate = useCallback(() => {
    const dataInicial = format(selectionDate[0].startDate, 'yyyy-MM-dd');
    const dataFinal = format(selectionDate[0].endDate, 'yyyy-MM-dd');

    const anoInicial = Number(dataInicial.substring(0,4));
    const mesInicial = Number(dataInicial.substring(5,7));
    const diaInicial = Number(dataInicial.substring(8,10));

    const anoFinal = Number(dataFinal.substring(0,4));
    const mesFinal = Number(dataFinal.substring(5,7));
    const diaFinal = Number(dataFinal.substring(8,10));

    const colaboradoresFiltradas = colaboradores.filter(item => 
      Number(item.created_at.substring(0, 4)) >= anoInicial && Number(item.created_at.substring(0, 4)) <= anoFinal &&
      Number(item.created_at.substring(5, 7)) >= mesInicial && Number(item.created_at.substring(5, 7)) <= mesFinal &&
      Number(item.created_at.substring(8, 10)) >= diaInicial && Number(item.created_at.substring(8, 10)) <= diaFinal
    );

    // console.log(transacoesFiltradas);
    setColaboradoresVisiveis(colaboradoresFiltradas);

    setDataFiltrada(true);
    setFiltroAberto(false);
  }, [colaboradores, selectionDate]);

  function optionsSelect() {
    const list = estabelecimentos.map(item => {
      return {
        value: item.id, label: item.nome_fantasia
      }
    })

    // Coloca o Todos no começo da lista
    list.unshift({ value: '0', label: 'Todos' })

    return list;
  }

  const [formOpened, setFormOpened] = useState(true);

  const {
    register,
    handleSubmit,
    // getValues,
    // setValue,
    // setError,
    // clearErrors,
    errors,
  } = useForm();
  const onSubmit = async (data) => {
    setLoadingPage(true);
    
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    try {
      
      await api.post('/colaborador', {
        nome: data.nome,
        email: data.email,
        estabelecimento_id: data.ec_responsavel,
      }, auth).then(() => {
        // console.log('responseCriarColab', response);

        setLoadingPage(false);

        addToast({
          type: 'success',
          title: 'Deu tudo certo!',
          description: 'O colaborador foi criado com sucesso.',
        });

        window.location.reload(true);
      });

    } catch (error) {
      console.log('error', error.response);

      setLoadingPage(false);

      if (error.response.data.message === "Email already registered") {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'O e-mail digitado já se encontra cadastrado! Preencha um novo e-mail.',
        });

        return;
      }

      addToast({
        type: 'error',
        title: 'Erro no envio do formulário',
        description: 'Algo deu errado. Tente novamente ou entre em contato com o Deixa no Azul.',
      });
    }

  };
  const onError = (errors) => {

    addToast({
      type: 'error',
      title: 'Erro no envio do formulário',
      description: 'Você precisa preencher todos os campos obrigatórios (marcados com *).',
    });
  };

  return (
    <Container>
      <HeaderArea>
        <FilterArea>
          <FilterHeader>Filtrar por:</FilterHeader>

          <Select
            placeholder="Estabelecimento"
            onChange={(value) => filterByEC(value)}
            className="filterStatusSelect"
            /* options={[
              { value: '1', label: 'Em Analise' },
              { value: '2', label: 'Solicitar documentos' },
              { value: '3', label: 'Aprovada' },
              { value: '4', label: 'Cancelada' },
              { value: '5', label: 'Checagem financeira' },
              { value: '0', label: 'Todos' }
            ]} */
            /* options={estabelecimentos.map(item => {
              return {
                value: item.id, label: item.nome_fantasia
              }
            })} */
            options={optionsSelect()}
          />

          <FilterDate style={{ background: dataFiltrada ? "#EFF2F7" : "#FFFFFF" }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 10,
                paddingRight: 10,
              }}
              onClick={() => setFiltroAberto(!filtroAberto)}
            >
              <span>Filtrar data</span>
              <FiCalendar color="#0073FC" />
            </div>

            {dataFiltrada && (
              <FiXCircle
                className="apagarFiltro"
                color="#c53030"
                size={22}
                onClick={() => {
                  setColaboradoresVisiveis(colaboradores);
                  setDataFiltrada(false);
                  setFiltroAberto(false);
                }}
              />
            )}
          </FilterDate>

          <DatePicker filtroAberto={filtroAberto}>
            <DateRange
              locale={ptBR}
              dateDisplayFormat="dd/MM/yyyy"
              monthDisplayFormat="MMMMM"
              weekdayDisplayFormat="EEEEE"
              onChange={item => setSelectionDate([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={selectionDate}
            />

            <ButtonBox>
              <button type="button" className="back" onClick={() => setFiltroAberto(false)}>Cancelar</button>
              <button type="button" onClick={() => filterByDate()}>Filtrar</button>
            </ButtonBox>
          </DatePicker>
        </FilterArea>

        <AddColaborador>
          <span>Adicionar Colaborador</span>
          <Button onClick={() => setFormOpened(!formOpened)}>
            {formOpened === true ? <MdAdd color="#fff" /> : <MdRemove color="#fff" />}
          </Button>
        </AddColaborador>
      </HeaderArea>

      <FormSection formOpened={formOpened}>
        <LoadingContainer loadingHide={loadingPage}>
          <img src={loading} alt="Carregando..."/>
        </LoadingContainer>

        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Row3>
            <InputArea>
              <label>*Nome</label>
              <InputContainer style={{ borderColor: errors.nome && "red" }}>
                <input
                  name="nome" 
                  type="text" 
                  placeholder="Digite o nome" 
                  ref={register({ required: true })}
                />
                {errors.nome && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
              </InputContainer>
            </InputArea>

            <InputArea>
              <label>*E-mail</label>
              <InputContainer style={{ borderColor: errors.nome && "red" }}>
                <input
                  name="email" 
                  type="text" 
                  placeholder="Digite o e-mail" 
                  ref={register({ required: true })}
                />
                {errors.nome && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
              </InputContainer>
            </InputArea>

            <InputArea>
              <label>*Estabelecimento responsável</label>
              <InputContainer style={{ borderColor: errors.ec_responsavel && "red" }}>
                <select
                  name="ec_responsavel"
                  placeholder="Selecione"
                  ref={register({ required: true })}
                >
                  <option value="">Selecione</option>
                  {estabelecimentos.map(estabelecimento => (
                    <option value={estabelecimento.id}>{estabelecimento.nome_fantasia}</option>
                  ))};
                </select>
                {errors.ec_responsavel && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
              </InputContainer>
            </InputArea>
          </Row3>

          <ButtonRow>
            <button type="submit">Finalizar</button>
          </ButtonRow>
        </form>
      </FormSection>

      <TableContent>
        <MaterialTable
          title="Lista de Colaboradores"
          columns={headData}
          data={bodyData}
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                
              </div>
            )
          }}
          localization={{
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página',
              lastTooltip: 'Última página'
            },
            toolbar: {
              searchPlaceholder: 'Buscar',
              searchTooltip: 'Buscar'
            },
            body: {
              emptyDataSourceMessage: 'Nenhum dado encontrado',
            }
          }}
          options={{
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: '#10104F',
              color: '#FFF'
            },
            emptyValue: "Nenhum dado encontrado"
          }}
        />

      </TableContent>
    </Container>
  );
}

export default AdminColaboradores;
