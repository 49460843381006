import React from 'react';
import { BrowserRouter } from 'react-router-dom';
// import * as firestore from "firebase/firestore";

import GlobalStyle from './styles/global';

import AppProvider from './hooks/index';

import Routes from './routes/index';

// import { firebaseDatabase } from './services/firebase';

function App() {

  // const emailsCollectionRef = firestore.collection(firebaseDatabase, 'emails');

  /* useEffect(() => {
    async function getEmails() {
      await firestore.getDocs(emailsCollectionRef).then(response => {
        const data = response.docs.map((doc) => ({...doc.data(), id: doc.id}));

        console.log('dataFirebase', data);
      });
    }

    getEmails();
  }, [emailsCollectionRef]); */

  return (
    <BrowserRouter>
      <AppProvider>
        <Routes />
      </AppProvider>

      <GlobalStyle />
    </BrowserRouter>
  );
}

export default App;