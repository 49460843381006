import React from 'react';
import { Link } from 'react-router-dom';
import { MdInfoOutline } from 'react-icons/md'

import { useSimulator } from '../../hooks/SimulatorContext';

import { Container, ValueArea, InfoHorario } from './styles';

function InfoClient({ hasButton, hasInfos }) {
  const { simulator } = useSimulator();

  // const total = Number(simulator.valor_total);
  // const taxaServico = Number(simulator.valor_acrescimo);

  // Valor parcela
  const valor_parcela = simulator.valor_parcela.split('.');
  const valorParcela = `${valor_parcela[0]},${valor_parcela[1] ? valor_parcela[1].substring(0, 2) : "00"}`;

  // Valor líquido
  const valor_liquido = simulator.valor_pagamento.split('.');
  const valorLiquido = `${valor_liquido[0]},${valor_liquido[1] ? valor_liquido[1].substring(0, 2) : "00"}`;

  // Valor Total transação
  const total_tansacao = simulator.valor_total.split('.');
  const totalTransação = `${total_tansacao[0]},${total_tansacao[1] ? total_tansacao[1].substring(0, 2) : "00"}`;

  const buttonClass = hasButton ? "" : "hide";
  const horarioClass = hasInfos ? "" : "hide";

  const atualData = new Date();
  // console.log(atualData.getHours(), typeof(atualData.getHours()))

  function mostrarHorario()  {
    if (atualData.getHours() >= 0 && atualData.getHours() < 13) {
      return <span>Transações realizadas entre <strong>0h e 12h59</strong> serão pagas até as <strong>15h</strong> do mesmo dia.</span>
    }

    if (atualData.getHours() >= 13 && atualData.getHours() < 16) {
      return <span>Transações realizadas entre às <strong>13h e às 15h59</strong> serão pagas até as <strong>20h</strong> do mesmo dia <i>(EXCETO QUANDO FOR TRANSFERÊNCIA/TED)</i>.</span>
    }

    if (atualData.getHours() >= 16 && atualData.getHours() <= 23) {
      return <span>Transações realizadas entre às <strong>16h e às 23h59</strong> serão pagas até as <strong>15h</strong> do dia seguinte.</span>
    }
  };

  return (
    <Container>
      <h3>Informações para Cliente final</h3>

      <div className="valueContainer">
        <ValueArea>
          <label>Valor da parcela</label>
          <span>{simulator.quantidade_parcelas}x R$ {valorParcela}</span>
        </ValueArea>

        <ValueArea>
          <label>Valor líquido</label>
          <span>R$ {valorLiquido}</span>
        </ValueArea>

        <ValueArea>
          <label>Valor total</label>
          <span>R$ {totalTransação}</span>
        </ValueArea>
      </div>

      <Link to="/transacoes/criar">
        <button className={buttonClass} type="button">Aceitar</button>
      </Link>

      <InfoHorario className={horarioClass}>
        <MdInfoOutline size={20} />
        {mostrarHorario()}
      </InfoHorario>
    </Container>
  );
}

export default InfoClient;