import styled, { css } from 'styled-components';

import { shade } from 'polished';

import backgroundImg from '../../assets/images/background.jpg';

export const Container = styled.div``;

export const Page = styled.div`
  margin-left: 220px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  /* height: calc(100vh - 72px); */
  background: url(${backgroundImg}) no-repeat;
  background-position: top left;
  background-size: 100% 256px;
  box-sizing: border-box;

  padding: 100px 160px 0 30px;

  @media (max-width: 500px) {
    padding: 50px 20px 20px;
  }
`;

export const TableContent = styled.div`
  position: relative;

  th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-paddingCheckbox {
    background: #10104F;
  }

  span.MuiButtonBase-root.MuiTableSortLabel-root.Mui-disabled {
    color: transparent;
  }

  .MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.Component-paginationToolbar-2.MuiToolbar-gutters {
    background-color: #fff;
  }

  tr.MuiTableRow-root {
    border: none;
  }

  tr.MuiTableRow-root:nth-of-type(odd) {
    background-color: #F5F5F5;
  }

  .MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-5.MuiToolbar-gutters {
    padding-bottom: 20px;
    height: 100px;
  }

  .MuiToolbar-regular {
    min-height: 84px;
  }
  
  .css-2b097c-container {
    position: absolute;
    box-sizing: border-box;
    width: 140px;
    top: 23px;
    right: 300px;
    z-index: 50;
  }

  .MuiInput-underline:before {
    border-bottom: none;
  }

  > form {
    display: flex;
    align-items: flex-end;

    position: absolute;
    z-index: 2;
    top: 10px;
    right: 30px;


    > button {
      padding: 8px 22px;
      background: #0073FC;
      border: 1px solid #0073FC;
      color: #FFFFFF;
      border: none;
      border-radius: 4px;

      transition: 0.2s;

      &:hover {
        background: ${shade(0.2, '#0073FC')}
      }
    }
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const Infos = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 30px;

  margin-top: 30px;
  margin-bottom: 60px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

export const FirstBox = styled.div`

  a {
    padding: 8px 34px;
    background: #F5F8FB;
    border: 1px solid #ADADAD;
    color: #373A3C;
    outline-style: none;
    border-radius: 4px;
    text-decoration: none;

    &:hover {
      background: ${shade(0.2, '#fff')};
    }
  }
`;

export const SecondBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  padding: 20px 22px;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 60px;

  max-height: 110px;

  > div {

    label {
      font-size: 18px;
      margin: 0;
    }

    p {
      font-size: 18px;
      color: #5097FF;
    }
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    margin-bottom: 80px;
    max-height: 100%;
  }
`;

export const ModalSection = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(55, 58, 60, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 25;

  ${props => props.modalOpened && css`
    display: none;
  `}
`;

export const ModalContent = styled.div`
  color: #fff;
  background: #10104F;
  padding: 30px;
  border-radius: 8px;
  max-width: 640px;
  /* max-height: 420px; */

  width: 100%;
  /* height: 100%; */

  > svg {
    margin-left: calc(100% - 30px);
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      color: ${shade(0.2, '#fff')};
    }
  }

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 30px;
    text-align: center;

    @media (max-width: 500px) {
      font-size: 18px;
      display: block;
      margin-bottom: 20px;
    }
  }

  div.buttonRow {
    margin: 30px auto 0;
    text-align: center;

    a {
      padding: 8px 27px;
      background: #0073FC;
      border: 1px solid #0073FC;
      color: #FFFFFF;
      border: none;
      border-radius: 4px;
      text-decoration: none;

      transition: 0.2s;

      &:hover {
        background: ${shade(0.2, '#0073FC')};
      }
    }

    button.cancelar {
      padding: 8px 27px;
      background: #F5F8FB;
      border: 1px solid #ADADAD;
      color: #373A3C;
      border-radius: 4px;

      margin-left: 30px;
      transition: 0.2s;

      &:hover {
        background: ${shade(0.2, '#F5F8FB')};
      }
    }

    @media (max-width: 500px) {
      margin: 20px auto 0;
    }
  }

  @media (max-width: 500px) {
    width: 94%;
    padding: 20px;
    margin-bottom: 20px;
  }
`;

export const TableContentMobile = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 20px 3px;
  /* margin-bottom: 100px; */

  @media (min-width: 500px) {
    display: none;
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid #EEEEEE;
  padding: 0 17px 10px;

  h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .filterStatusSelect.css-2b097c-container {
    width: 200px;
    margin-bottom: 20px;
  }

  > span {
    margin-bottom: 20px;
  }
`;

export const Line = styled.div`
  padding: 15px 17px;
  border-bottom: 1px solid #EEEEEE;

  > span {
    font-size: 18px;
    color: #313131;
  }

  > p {
    font-size: 14px;
    color: #9FA7BA;
    margin: 0;
  }

  div.footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    > span {
      font-size: 12px;
      font-weight: 700;
      /* color: #5CB85C; */
    }
  }
`;

export const MobileBody = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  height: 448px;
`;
