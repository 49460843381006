import styled from 'styled-components';

import backgroundImg from '../../assets/images/background.jpg';

export const Container = styled.div``;

export const Page = styled.div`
  margin-left: 220px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  height: calc(100vh - 72px);
  background: url(${backgroundImg}) no-repeat;
  background-position: top left;
  background-size: 100% 256px;
  box-sizing: border-box;

  padding: 30px 160px 0 30px;

  @media (max-width: 500px) {
    padding: 50px 20px 20px;
  }
`;

export const TabContent = styled.div`
  background: #fff;
  border-radius: 8px;
`;

export const Back = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 30px;

  > span {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    margin-left: 30px;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #fff;
  border-radius: 50%;
  border: none;
  outline: none;

  width: 50px;
  height: 50px;

  > svg {
    width: 30px;
    height: 30px;
  }
`;