import React from "react";
// import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { MdErrorOutline } from "react-icons/md";

import soNumeroSimbolosMask from "../../components/InputMasks/soNumeroSimbolosMask";

import api from "../../services/api";

// Context API
import { useToast } from "../../hooks/ToastContext";

import {
  Container,
  Row5,
  InputArea,
  ButtonRow,
  Abas,
  Aba1,
  InputContainer,
  Error,
} from "./styles";

function FormAddTaxaSimulacao() {
  const { addToast } = useToast();

  const userToken = localStorage.getItem("@DeixaNoAzul:accessToken");

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    // console.log(data);

    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    try {
      await api.post(
        "/tax_simul",
        {
          numero_parcela: data.numero_parcela,
          bandeira: data.bandeira,
          maquineta: data.maquineta,
          conjunto_de_taxa: data.conjunto_de_taxa,
          taxa_servico: Number(data.taxa_servico),
          transacao_type: "Transacao",
        },
        auth
      );

      addToast({
        type: "success",
        title: "Deu tudo certo!",
        description: "Taxa de simulação cadastrado com sucesso.",
      });

      setTimeout(() => {
        window.location.reload(true);
      }, 3000);
    } catch (error) {
      console.log('errorCriarTaxa', error.response);

      if (error.response.data.message === "This tax already exists") {
        addToast({
          type: "error",
          title: "Erro no cadastro de taxa simulação",
          description: "Taxa já existente",
        });
      } else {
        addToast({
          type: "error",
          title: "Erro no cadastro de taxa simulação",
          description: "Erro ao cadastra uma nova taxa de simulação",
        });
      }
    }
  };
  const onError = (errors) => {
    addToast({
      type: "error",
      title: "Erro no envio do formulário",
      description:
        "Você precisa preencher todos os campos obrigatórios (marcados com *).",
    });
  };

  // Abas START
  const tab1 = true;

  return (
    <Container>
      <Abas>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Aba1 tab1={tab1}>
            {/* <Rows></Rows> */}
            <Row5>
              <InputArea>
                <label>*Número de parcelas</label>
                <InputContainer
                  style={{ borderColor: errors.numero_parcela && "red" }}
                >
                  <select
                    name="numero_parcela"
                    placeholder="Selecione"
                    ref={register({ required: true })}
                  >
                    <option value="">Selecione</option>
                    <option value="0">0x</option>
                    <option value="1">1x</option>
                    <option value="2">2x</option>
                    <option value="3">3x</option>
                    <option value="4">4x</option>
                    <option value="5">5x</option>
                    <option value="6">6x</option>
                    <option value="7">7x</option>
                    <option value="8">8x</option>
                    <option value="9">9x</option>
                    <option value="10">10x</option>
                    <option value="11">11x</option>
                    <option value="12">12x</option>
                    <option value="13">13x</option>
                    <option value="14">14x</option>
                    <option value="15">15x</option>
                    <option value="16">16x</option>
                    <option value="17">17x</option>
                    <option value="18">18x</option>
                  </select>
                  {errors.numero_parcela && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Bandeira</label>
                <InputContainer
                  style={{ borderColor: errors.bandeira && "red" }}
                >
                  <select
                    name="bandeira"
                    placeholder="Selecione"
                    ref={register({ required: true })}
                  >
                    <option value="">Selecione</option>
                    {/* <option value="VME">Visa, Master, Elo</option> */}
                    {/* <option value="DA">Dinners Club, American Express</option> */}
                    <option value="VM">Visa, Master</option>
                    <option value="DAE">Dinners Club, American Express, Elo</option>
                  </select>
                  {errors.bandeira && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Maquineta</label>
                <InputContainer
                  style={{ borderColor: errors.maquineta && "red" }}
                >
                  <select
                    name="maquineta"
                    placeholder="Selecione"
                    ref={register({ required: true })}
                  >
                    {/* <option value="">Selecione</option> */}
                    <option value="Celer">Celer</option>
                  </select>
                  {errors.maquineta && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Conjunto de Taxas</label>
                <InputContainer
                  style={{ borderColor: errors.conjunto_de_taxa && "red" }}
                >
                  <select
                    name="conjunto_de_taxa"
                    placeholder="Selecione"
                    ref={register({ required: true })}
                  >
                    {/* <option value="">Selecione</option> */}
                    {/* <option value="PDV2">PDV2</option>
                    <option value="PDV3">PDV3</option> */}
                    <option value="PDV4">PDV4</option>
                  </select>
                  {errors.conjunto_de_taxa && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Taxa de serviço</label>
                <InputContainer
                  style={{ borderColor: errors.taxa_servico && "red" }}
                >
                  <input
                    name="taxa_servico"
                    type="text"
                    placeholder="00,00"
                    ref={register({ required: "Campo obrigatório" })}
                    onKeyUp={(e) => soNumeroSimbolosMask(e)}
                    // onBlur={() => validarCPF()}
                  />
                  {errors.taxa_servico && (
                    <Error title={errors.taxa_servico.message}>
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>
            </Row5>

            <ButtonRow>
              <button type="submit">Adicionar</button>
            </ButtonRow>
          </Aba1>
        </form>
      </Abas>
    </Container>
  );
}

export default FormAddTaxaSimulacao;
