import React, { useEffect } from 'react';
import {
  Route as ReactDOMRoute,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';

import { useAuth } from '../hooks/AuthContext';

function Route({ isPrivate = false, children, component: Component, ...rest }) {
  const { user, signOut } = useAuth();

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (user && location.pathname === '/credenciamento') {
      signOut();
      history.push('/credenciamento');
    }

    if (user !== undefined && user.email === "rogerio@one-consultoria.com") {
      if (
        location.pathname.includes('/admin/transacoes') ||
        location.pathname.includes('/admin/licenciados') ||
        location.pathname.includes('/admin/estabelecimentos') ||
        location.pathname.includes('/admin/pagamentos') ||
        location.pathname.includes('/admin/taxadesimulacao') ||
        location.pathname.includes('/admin/taxacomissao')
      ) {
        history.push('/admin/certificado-digital');
      }
    }
  }, [history, location, signOut, user]);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user ? 
          /* <Component /> */
          children
         : (
          <Redirect to={{
            pathname: isPrivate ? '/' : '/loading',
            state: { from: location },
          }} />
        );
      }}
    />
  )
}

export default Route;